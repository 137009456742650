import { withStyles } from '@material-ui/styles'
import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/EditUserModalCss'
import dictionary from './Dictionary/EditUserModalDictionary'
import {
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Close } from '@material-ui/icons'
import { MenuItem, TextField } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import PhoneField from '../../../common/PhoneField'
import DoneIcon from '@material-ui/icons/Done'
import { Button } from '../../../common'
import { groupActions } from '../../../../actions'
import { connect } from 'react-redux'
import AddressSearch from '../../../RentParkingPage/RentNavigationPages/AddressSearch'
import InputLabel from '../../../common/UsefullComponents/InputLabel'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'

const ToolTip2 = React.forwardRef(function ToolTipLink(props, ref) {
    const HtmlTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: '#ffffff',
            color: 'black',
            maxWidth: 330,
            marginTop: -8,
            border: '1px solid #dadde9',
            fontSize: '12px',
            '& span': {
                '&:hover': {
                    cursor: `pointer`,
                },
                fontWeight: `bold`,
                textDecoration: `underline`,
            },
        },
    }))(Tooltip)

    return (
        <HtmlTooltip interactive ref={ref} title={<p>{props.title}</p>}>
            <InfoIcon />
        </HtmlTooltip>
    )
})

class EditUserModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        let perm = []
        this.state = {
            ...this.props.user,
            openPerms: false,
            hasCompany: this.props.user?.hasCompany || false,
            boatsGroup: this.props.user?.boatsGroup || [],
            permissions: JSON.parse(JSON.stringify(this.props.user.rights)),
            rightChange: false,
            roleName: '',
            roleNameError: false,
            save: false,
        }
        this.myRef = React.createRef()
        this._renderHeader = this._renderHeader.bind(this)
        this._renderCore = this._renderCore.bind(this)
        this._changeCompanyPhone = this._changeCompanyPhone.bind(this)
        this._changeCompanyAddress = this._changeCompanyAddress.bind(this)
        this._changeCompanySiret = this._changeCompanySiret.bind(this)
        this._changeCompanyName = this._changeCompanyName.bind(this)
        this._changeRoleName = this._changeRoleName.bind(this)
        this._changeFleet = this._changeFleet.bind(this)
        this._hasCompany = this._hasCompany.bind(this)
        this._changeRole = this._changeRole.bind(this)
        this._renderPermissions = this._renderPermissions.bind(this)
        this.openPerms = this.openPerms.bind(this)
        this._renderSaveBar = this._renderSaveBar.bind(this)
        this._save = this._save.bind(this)
        this._changePerms = this._changePerms.bind(this)
        this.isModified = this.isModified.bind(this)
    }

    async _save() {
        const role = JSON.parse(JSON.stringify(this.state.roles))
        if (
            this.state.roles.id < 7 &&
            this.state.rightChange &&
            this.state.roleName === ''
        ) {
            this.setState({ roleNameError: true })
            return
        } else {
            this.setState({ roleNameError: false })
        }
        if (this.state.roles.id < 7 && !this.state.roles.userRoleId) {
            role.userRoleId = this.state.roles.id
        }
        if (
            this.state.roles.id < 7 &&
            this.state.roles.userRoleId &&
            this.state.rightChange
        ) {
            role.id = null
            role.userRole = this.state.roleName
            role.userGroupId = this.props.groups.id
        }
        this.setState({ save: true })
        const roles = this.props.roles.filter(
            role => role.id === this.state.roles.id,
        )

        await this.props.dispatch(
            groupActions.editUserToGroup(
                this.state.userRGUId,
                this.state.company ? this.state.company.companyName : '',
                this.state.company ? this.state.company.siret : '',
                this.state.company ? this.state.company.address : null,
                this.state.company
                    ? this.state.company.contactDetails?.length > 0
                        ? this.state.company.contactDetails[0]?.contact
                        : ''
                    : '',
                this.state.id || null,
                (this.state.roles?.id && this.state.roles?.id < 7) ||
                    roles.length === 0
                    ? role
                    : roles[0],
                this.state.company ? this.state.company.id || null : null,
                this.state.company
                    ? this.state.company.contactDetails?.length > 0
                        ? this.state.company.contactDetails[0]?.id || null
                        : null
                    : null,
                this.state.userGroupId,
                this.state.boatsGroup,
                this.state.rightChange ? this.state.permissions : null,
                this.state.hasCompany,
                this._isUserEditingCompanyOwner(),
            ),
        )
        if (this.state.save) {
            this.setState({ save: false })
        }
    }

    isModified() {
        for (let linkRGU of this.props.groups.linkRGU) {
            if (linkRGU.id === this.state.userRGUId) {
                for (var i = 0; i < this.state.permissions.length; i++) {
                    const role = this.props.roles.filter(
                        role => role.id === this.state.roles.id,
                    )
                    if (
                        JSON.stringify(this.state.permissions[i].right) !==
                        JSON.stringify(this.props.user.rights[i].right)
                    ) {
                        if (this.state.roles.id < 7) {
                            this.state.roles.userRoleId = this.state.roles.id
                        }
                        return true
                    }
                }
                if (this.state.rightChange) {
                    this.setState({
                        rightChange: false,
                    })
                }
                if (
                    linkRGU.user?.company && this.state.company
                        ? this.state.company.companyName !=
                              linkRGU.user.company.companyName ||
                          this.state.company?.contactDetails?.[0]?.contact !==
                              linkRGU.user.company?.contactDetails?.[0]
                                  ?.contact ||
                          this.state.company.siret !=
                              linkRGU.user.company.siret ||
                          this.state.company?.address?.fullAddress !=
                              linkRGU.user.company?.address?.fullAddress ||
                          (this.state.company?.address?.fullText
                              ? this.state.company?.address?.fullText !=
                                linkRGU.user.company?.address?.fullAddress
                              : false) ||
                          (linkRGU.user.company.contactDetails?.length > 0 &&
                          linkRGU.user.company.contactDetails.filter(
                              contactDetail =>
                                  contactDetail.contactType?.id === 1,
                          ).length > 0
                              ? linkRGU.user.company.contactDetails?.filter(
                                    contactDetail =>
                                        contactDetail.contactType?.id === 1,
                                )[0]?.contact !=
                                this.state.company.contactDetails?.filter(
                                    contactDetail =>
                                        contactDetail.contactType?.id === 1,
                                )[0]?.contact
                              : linkRGU.user.company.contactDetails?.length ==
                                    0 &&
                                this.state.company.contactDetails?.length > 0)
                        : false
                ) {
                    return true
                }
                if (linkRGU.userRole.id != this.state.roles.id) {
                    return true
                }
                if (linkRGU.user?.company == null && this.state.company) {
                    return true
                }
                if (linkRGU.boats.length !== this.state.boatsGroup.length) {
                    return true
                } else {
                    for (var i = 0; i < linkRGU.boats.length; i++) {
                        var boatIsSame = false
                        for (var j = 0; j < this.state.boatsGroup.length; j++) {
                            if (
                                linkRGU.boats[i].id ===
                                this.state.boatsGroup[j].id
                            ) {
                                boatIsSame = true
                            }
                        }
                        if (boatIsSame == false) {
                            return true
                        }
                    }
                }
                if (linkRGU.hasCompany !== this.state.hasCompany) {
                    return true
                }
            }
        }
        return false
    }

    _hasCompany() {
        this.setState({
            hasCompany: !this.state.hasCompany,
        })
    }

    _changeCompanyPhone(c) {
        this.setState({
            company: {
                ...this.state.company,
                contactDetails: [{ contact: c }],
            },
        })
    }

    _changeCompanyAddress(address) {
        this.setState(prevState => ({
            company: { ...prevState.company, address: address },
        }))
    }

    _changeCompanySiret({ target: { value } }) {
        this.setState({ company: { ...this.state.company, siret: value } })
    }

    _changeCompanyName({ target: { value } }) {
        this.setState({
            company: { ...this.state.company, companyName: value },
        })
    }

    _changeRoleName({ target: { value } }) {
        this.setState({
            roleName: value,
        })
    }

    _changeFleet(boats) {
        if (boats) {
            this.setState({ boatsGroup: boats })
        }
    }

    _changeRole({ target: { value } }) {
        this.setState({
            permissions: this.props.roles.filter(role => role.id === value)[0]
                .rights,
            roles: { id: value },
            rightChange: false,
        })
    }

    _changePerms(ind, i) {
        const { permissions } = this.state
        let perms = [...permissions]
        perms[ind].right[i] = !perms[ind].right[i]
        for (var j = 0; j < permissions.length; j++) {
            if (
                (permissions[j].right[0] == true ||
                    permissions[j].right[1] == true ||
                    permissions[j].right[2] == true) &&
                permissions[j].right[3] == false
            ) {
                if (ind == j && (i == 0 || i == 1 || i == 2)) {
                    perms[j].right[3] = true
                } else {
                    perms[j].right = [false, false, false, false]
                }
            }
        }
        this.setState({
            permissions: perms,
        })
        if (!this.state.rightChange) {
            this.setState({
                rightChange: true,
            })
        }
    }

    openPerms() {
        this.setState({ openPerms: !this.state.openPerms }, () => {
            if (this.container)
                this.container.scrollIntoView({ inline: 'end', block: 'end' })
        })
    }

    componentDidMount() {
        if (this.state.boats) {
            this.setState({ boatsGroup: this.props.user.boatsGroup })
        }
    }

    _renderPermissions() {
        const { classes } = this.props
        if (!this.state.openPerms) return null

        return (
            <>
                <div className={classes.subTwoField}>
                    <Typography className={classes.titlePerms}>
                        {' '}
                        {this.displayText(`perso`)}{' '}
                    </Typography>
                </div>
                {this.state.rightChange && this.state.roles.id < 7 ? (
                    <div className={classes.subTwoField}>
                        <TextField
                            label={this.displayText(`roleName`)}
                            onChange={this._changeRoleName}
                            className={classes.field}
                            value={this.state.roleName}
                            variant="outlined"
                            type="text"
                            error={this.state.roleNameError}
                            helperText={this.displayText(`roleDescription`)}
                        />
                    </div>
                ) : (
                    <></>
                )}
                <table className={classes.table} id="table">
                    <tbody>
                        <tr className={classes.columnsTitles}>
                            <th></th>
                            <th> {this.displayText(`add`)} </th>
                            <th> {this.displayText(`modif`)} </th>
                            <th> {this.displayText(`supp`)} </th>
                            <th> {this.displayText(`read`)} </th>
                        </tr>

                        {this.state.permissions.map((e, ind) => (
                            <tr key={ind} className={classes.rows}>
                                <td> {this.displayText(`acc` + (ind + 1))} </td>
                                {e.right.map((right, i) => (
                                    <td key={i} className={classes.row}>
                                        {(ind >= 0 && ind <= 4 && i < 3) ||
                                        (i !== 1 && i !== 3 && ind === 12) ? (
                                            <></>
                                        ) : (
                                            <div
                                                className={`${classes.check} ${
                                                    right
                                                        ? classes.activeCheck
                                                        : classes.notActiveCheck
                                                }`}
                                                onClick={() => {
                                                    this._changePerms(ind, i)
                                                }}
                                            >
                                                {right && (
                                                    <DoneIcon
                                                        style={{
                                                            color: `#303F9F`,
                                                            width: 14,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        )
    }

    _renderHeader() {
        const { classes, handleClose } = this.props

        return (
            <div>
                <div className={classes.header}>
                    <div className={classes.headerLeft}>
                        <div className={classes.profilePic}>
                            {this.state.firstName
                                ? this.state.firstName.substring(0, 1)
                                : ''}
                            {this.state.lastName
                                ? this.state.lastName.substring(0, 1)
                                : ''}
                        </div>
                        <div className={classes.miniDescription}>
                            <Typography variant="body1">
                                {this.state.firstName && this.state.lastName
                                    ? this.state.firstName +
                                      ' ' +
                                      this.state.lastName
                                    : ''}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={classes.miniDescriptionRole}
                            >
                                {this.state.roles.userRole
                                    ? this.state.roles.userRole
                                    : this.displayTextApi(
                                          this.state.roles.translation,
                                      )}
                            </Typography>
                        </div>
                    </div>
                    <Typography
                        className={`${classes.status} ${
                            this.state.userSubscribe !== null
                                ? classes.waiting
                                : !this.state.activeAccount
                                ? classes.inactivity
                                : classes.activity
                        }`}
                    >
                        {this.state.userSubscribe !== null
                            ? this.displayText(`attente`)
                            : !this.state.activeAccount
                            ? this.displayText(`suspendu`)
                            : this.displayText(`actif`)}
                    </Typography>
                    <IconButton
                        size={`small`}
                        id={`closeDialog`}
                        onClick={handleClose}
                        style={{
                            position: `absolute`,
                            right: `10px`,
                            top: `10px`,
                            color: `#C4C4C4`,
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            </div>
        )
    }

    _isUserEditingCompanyOwner() {
        const { connectedUser, user } = this.props

        return user.email === connectedUser.email
    }

    _renderCore() {
        const { classes, groups } = this.props
        const { hasCompany } = this.state
        const address =
            {
                ...this.state.company?.address,
                fullText: this.state.company?.address?.fullAddress,
            } || {}
        const isUserEditingCompanyOwner = this._isUserEditingCompanyOwner()

        return (
            <div className={classes.coreContainer}>
                {this.state.userSubscribe === null && (
                    <>
                        {' '}
                        <Typography className={classes.subtitle}>
                            {' '}
                            {this.displayText(`newU`)}{' '}
                        </Typography>
                        <RadioGroup
                            className={classes.radioButton}
                            aria-label="gender"
                            name="gender1"
                            value={hasCompany}
                            onChange={this._hasCompany}
                        >
                            <FormControlLabel
                                checked={hasCompany}
                                value={true}
                                control={<Radio style={{ color: `#303F9F` }} />}
                                label={this.displayText(`oui`)}
                            />
                            <FormControlLabel
                                checked={!hasCompany}
                                value={false}
                                control={<Radio style={{ color: `#303F9F` }} />}
                                label={this.displayText(`non`)}
                            />
                        </RadioGroup>
                        {hasCompany && (
                            <>
                                <div className={classes.twoField}>
                                    <div className={classes.subTwoField}>
                                        <TextField
                                            label={this.displayText(`societe`)}
                                            onChange={this._changeCompanyName}
                                            className={classes.field}
                                            value={
                                                this.state.company
                                                    ?.companyName || ''
                                            }
                                            variant="outlined"
                                            type="text"
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                        />
                                    </div>
                                    <div className={classes.subTwoField}>
                                        <BoatOnNumberField
                                            label={this.displayText(`SIRET`)}
                                            onChange={this._changeCompanySiret}
                                            className={classes.field}
                                            value={
                                                this.state.company?.siret || ''
                                            }
                                            variant="outlined"
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                            onKeyDown={e => {
                                                if (/\.|,/.test(e.key)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.twoField}>
                                    <div className={classes.subTwoFieldPhone}>
                                        <AddressSearch
                                            label={this.displayText(`adresse`)}
                                            setAddress={
                                                this._changeCompanyAddress
                                            }
                                            fullAddressInfo={address}
                                            margin="normal"
                                            style={{
                                                margin: `12px 0`,
                                                width: `100%`,
                                                color: `black`,
                                            }}
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                        ></AddressSearch>
                                    </div>
                                    <div className={classes.subTwoFieldPhone}>
                                        <PhoneField
                                            onChange={this._changeCompanyPhone}
                                            className={classes.fieldPhone}
                                            label={this.displayText(`phone`)}
                                            value={
                                                this.state.company?.contactDetails?.filter(
                                                    contactDetail =>
                                                        contactDetail
                                                            .contactType?.id ===
                                                        1,
                                                )[0]?.contact || ''
                                            }
                                            disabled={
                                                !isUserEditingCompanyOwner
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}{' '}
                    </>
                )}

                <Typography className={classes.subtitle}>
                    {' '}
                    {this.displayText(`infP`)}{' '}
                </Typography>

                <div className={classes.twoField}>
                    <div className={classes.subTwoFieldPhone}>
                        <div className={classes.disableEntryContainer}>
                            <Typography className={classes.disableEntry}>
                                {' '}
                                {this.displayText(`nom`)}{' '}
                            </Typography>
                            <Typography className={classes.disableEntry2}>
                                {' '}
                                {this.state.lastName}{' '}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.subTwoFieldPhone}>
                        <div className={classes.disableEntryContainer}>
                            <Typography className={classes.disableEntry}>
                                {' '}
                                {this.displayText(`prenom`)}{' '}
                            </Typography>
                            <Typography className={classes.disableEntry2}>
                                {' '}
                                {this.state.firstName}{' '}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.twoField}>
                    <div className={classes.subTwoFieldPhone}>
                        <div className={classes.disableEntryContainer}>
                            <Typography className={classes.disableEntry}>
                                {' '}
                                {this.displayText(`email`)}{' '}
                            </Typography>
                            <Typography className={classes.disableEntry2}>
                                {' '}
                                {this.state.email ||
                                    this.state.userSubscribe?.mail}{' '}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.subTwoFieldPhone}>
                        <div className={classes.disableEntryContainer}>
                            <Typography className={classes.disableEntry}>
                                {' '}
                                {this.displayText(`phone`)}{' '}
                            </Typography>
                            <Typography className={classes.disableEntry2}>
                                {' '}
                                {this.state.contactDetails?.filter(
                                    contactDetail =>
                                        contactDetail.contactType?.id === 1,
                                ).length > 0
                                    ? this.state.contactDetails?.filter(
                                          contactDetail =>
                                              contactDetail.contactType?.id ===
                                              1,
                                      )[0].contact
                                    : ''}{' '}
                            </Typography>
                        </div>
                    </div>
                </div>
                {this.state.email !== groups.user.email &&
                    this._renderBlocLink()}
            </div>
        )
    }

    _renderBlocLink() {
        const { classes } = this.props
        const roles = this.props.roles?.filter(
            role => role.id < 7 || role.userGroupId === this.props.groups?.id,
        )
        return (
            <>
                <InputLabel
                    boats={this.props.boats}
                    onTagsChange={this._changeFleet}
                    modalOpen={this.state.modalOpen}
                    handleOpenModal={this.handleOpenModal}
                    selected={
                        this.state.boatsGroup?.length > 0
                            ? this.state.boatsGroup
                            : []
                    }
                />
                <Typography className={classes.subtitle}>
                    {' '}
                    {this.displayText(`roleEtPerm`)}{' '}
                </Typography>
                <TextField
                    className={`${classes.field} ${classes.fieldRole}`}
                    id="boatTypeId"
                    required
                    select
                    variant="outlined"
                    value={this.state.roles.id}
                    onChange={this._changeRole}
                >
                    {[...roles].map((r, index) => (
                        <MenuItem
                            key={r.id}
                            className={classes.menuItemRole}
                            value={r.id}
                        >
                            {' '}
                            {r.userRole
                                ? r.userRole
                                : this.displayTextApi(r.translation)}
                            <ToolTip2
                                style={{ fontSize: `20px` }}
                                title={this.displayTextApi(
                                    r.translationDescription,
                                )}
                                aria-label="add"
                            />
                        </MenuItem>
                    ))}
                </TextField>
                <div style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Typography
                        className={classes.openPerms}
                        onClick={this.openPerms}
                    >
                        {' '}
                        {this.displayText(`persoRole`)}
                        {'     '}
                    </Typography>
                    {!this.state.openPerms ? (
                        <KeyboardArrowDown
                            style={{ paddingTop: 5, marginLeft: 2 }}
                            onClick={this.openPerms}
                        />
                    ) : (
                        <KeyboardArrowUp
                            style={{ paddingTop: 5, marginLeft: 2 }}
                            onClick={this.openPerms}
                        />
                    )}
                </div>
                {this.isModified() && !this.state.openPerms && (
                    <div style={{ height: 80 }}></div>
                )}
                {this._renderPermissions()}
                <div style={{ height: 80 }}></div>
            </>
        )
    }

    _renderSaveBar() {
        const { classes } = this.props
        return (
            <Paper elevation={0} className={classes.saveBar}>
                <Button
                    label={this.displayText(`save`)}
                    onClick={this._save}
                    disabled={this.state.save}
                />
            </Paper>
        )
    }

    render() {
        return (
            <div
                ref={element => {
                    this.container = element
                }}
            >
                {this._renderHeader()}
                {this._renderCore()}
                {this.isModified() && this._renderSaveBar()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        roles: state.group.roles,
        groups: state.group.groupsMembers,
        connectedUser: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditUserModal))
