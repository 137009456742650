import React, { createRef } from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/CalendarPageCss'
import CalendarPageDico from './Dictionary/CalendarPageDico'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import { typesActions } from '../../../../actions/types.actions'
import catamaranicon from '../../../../images/catamaranicon.svg'
import CalendarHeader from './components/CalendarHeader/CalendarHeader'
import Event from './components/Event/Event'
import {
    deleteAbsence,
    filterActions,
    getCycle,
    getImageToDisplay,
    groupActions,
    putBOBEvents,
    removeCheckupFromBOBEvent,
    removeDetailFromBOBEvent,
    removeFilesFromBOBEvent,
    removeTransactionFromBOBEvent,
    setBOBStateEvent,
} from '../../../../actions'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import BoatOnModal from '../../../common/BoatOnModal'
import RepairModal from '../RepairPage/Modal/RepairModal'
import DocumentModal from '../MyDocumentPage/DocumentModal'
import BudgetModal from '../ExpIncPage/BudgetModal'
import {
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'

// Icons
import { ContentCopy, Edit, Delete } from '@mui/icons-material'

//Calendar imports
import './Styles/Calendar.scss'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import ValidModal from '../RepairPage/Modal/ValidModal'
import UnvalidModal from '../RepairPage/Modal/UnvalidModal'
import NextEventModal from '../RepairPage/Modal/NextEventModal'
import InventoryModal from '../Inventory/InventoryModal/InventoryModal'
import CustomEventModal from './CustomEventModal/CustomEventModal'
import WorkTimeModal from '../WorkTimePage/Modal/WorkTimeModal'
import AbsenceModal from '../WorkTimePage/Modal/AbsenceModal'
import { absencesActions } from '../../../../actions/bob/absences.actions'
import { workTimeActions } from '../../../../actions/bob/workTime.actions'
import dayjs from 'dayjs'

const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const MIN_SWIPE_SPEED = 400
const MIN_SWIPE_DISTANCE = 25

class CalendarPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = CalendarPageDico

        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-calendar/')

        ReactPixel.init('181677145678471', null, {
            autoConfig: true,
            debug: false,
        })
        ReactPixel.pageView()

        const isMobile = window.innerWidth < 600

        this.state = {
            openModalConnection: false,
            openModalNoBoat: false,
            openModalNothing: false,
            update: true,
            yearShow: null,
            fullTab: null,
            spendings: null,
            checkups: null,
            automaticOpenModalNoBoat: false,
            events: [],
            event: null,
            openModal: null,
            budgetOpenAddModal: null,
            isActionMenuOpen: false,
            actionMenuAnchor: null,
            count: 0,
            view: isMobile ? 'timeGridDay' : 'timeGridWeek',
            date: new Date(),
            newFiles: undefined,
            nextEvent: null,
            userLink: this._getCurrentUserLink(),
            firstCalendarScroll: false,
        }

        this.setState({ isActionMenuOpen: false })
        this.calendarRef = createRef()

        this.touchstartX = createRef()
        this.touchstartY = createRef()
        this.touchendX = createRef()
        this.touchendY = createRef()
        this.touchTimestamp = createRef()

        this.handleClickedItem = this.handleClickedItem.bind(this)
        this.openModal = this.openModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.openActionMenu = this.openActionMenu.bind(this)
        this.closeActionsMenu = this.closeActionsMenu.bind(this)
        this.renderEventContent = this.renderEventContent.bind(this)
        this.editEvent = this.editEvent.bind(this)
        this.deleteEvent = this.deleteEvent.bind(this)
        this.duplicateEvent = this.duplicateEvent.bind(this)
        this.handleGesture = this.handleGesture.bind(this)
        this.unValidateEvent = this.unValidateEvent.bind(this)
        this.validateEvent = this.validateEvent.bind(this)
        this.validatedEvent = this.validatedEvent.bind(this)
    }

    componentDidMount() {
        const { user, groupId } = this.props

        window.addEventListener('scroll', this.handleScroll, true)

        this.props.dispatch(groupActions.getFleetInfos(groupId))

        if (!user) {
            this.setState({
                openModalConnection: true,
            })
        }

        this.props.dispatch(groupActions.requestUserGroups(groupId))
    }

    moveCalendarToStartWork() {
        const elements = document.querySelectorAll(
            '.fc-timegrid-slot-label-cushion.fc-scrollgrid-shrink-cushion',
        )
        let targetElement = null

        elements.forEach(element => {
            if (element.innerHTML.trim() === '08 h') targetElement = element
        })

        if (targetElement) {
            const parent = targetElement.closest('.fc-scroller-harness')

            if (parent) {
                const currentCoordTarget = targetElement.getBoundingClientRect()

                parent.scrollTop = currentCoordTarget.y
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.yearShow && this.yearEl.length > 0) {
            this.handleScroll()
        }
        const {
            boats,
            loading,
            events,
            absences,
            absencesManager,
            workTimes,
            workTimesManager,
            groupMembers,
            user,
            workTimesLoading,
            absencesLoading,
        } = this.props
        const { openModalNoBoat, userLink } = this.state

        if (prevProps.groupMembers != groupMembers || prevProps.user != user) {
            return this.setState({
                userLink: this._getCurrentUserLink(),
            })
        }

        if (userLink && prevState.userLink != userLink) {
            // Récupération des heures de travail et absences de l'utilisateur
            this.props.dispatch(
                absencesActions.requestUserAbsences(userLink.id),
            )
            this.props.dispatch(
                absencesActions.requestUserAbsencesByManager(userLink.id),
            )
            this.props.dispatch(workTimeActions.getWorkTimes(userLink.id))
            this.props.dispatch(
                workTimeActions.getWorkTimesByManager(userLink.id),
            )
            return
        }

        if (loading > 0 || workTimesLoading > 0 || absencesLoading > 0) {
            return
        }

        const bobEvents = (events || [])?.map(e => this.transformBobEvent(e))
        const tAbsences = (absences || [])
            .map(e => this.transformAbsenceEvent(e))
            .concat(
                (absencesManager || []).map(e => this.transformAbsenceEvent(e)),
            )
            .filter(item => item.event.absenceStatus.id !== 3)
            .filter(
                (e, index, array) =>
                    index === array.findIndex(element => e.id === element.id),
            )

        const dailyWorktimes = this.splitWorktimesByDay(workTimes)
        const dailyManagerWorktimes = this.splitWorktimesByDay(workTimesManager)
        const tWorkTimes = dailyWorktimes
            .filter(e => e.delimitedDate.startDate && e.delimitedDate.endDate)
            .map(e => this.transformWorkTimeEvent(e))
            .concat(
                dailyManagerWorktimes
                    .filter(
                        e =>
                            e.delimitedDate.startDate &&
                            e.delimitedDate.endDate,
                    )
                    .map(e => this.transformWorkTimeEvent(e)),
            )
            .filter(
                (e, index, array) =>
                    index === array.findIndex(element => e.id === element.id),
            )

        const allEvents = [...bobEvents, ...tAbsences, ...tWorkTimes]

        if (
            (prevProps.loading > 0 && loading === 0) ||
            events.length !== prevProps.events.length ||
            this.state.events.length !== allEvents.length
        ) {
            this.setState(
                {
                    events: allEvents,
                },
                () => {
                    if (this.calendarRef.current) {
                        // Workaround pour forcer la mise à jour des événements dans le calendrier
                        this.calendarRef.current.calendar.destroy()
                        this.calendarRef.current.calendar.render()
                    }
                },
            )
        } else if (!boats || !boats.boats || boats.boats.length === 0) {
            if (!openModalNoBoat && !prevState.openModalSubscribe) {
                // open no boat modal if user has no boat
                this.setState({ openModalNoBoat: true })
            }
        }
    }

    splitWorktimesByDay(workTimes) {
        const dailyWorktimes = []

        if (!workTimes || !(workTimes.length > 0)) return []

        for (const workTime of workTimes) {
            if (
                workTime.delimitedDate?.startDate &&
                workTime.delimitedDate?.endDate &&
                new Date(workTime.delimitedDate.startDate) !==
                    new Date(workTime.delimitedDate.endDate)
            ) {
                const start = new Date(workTime.delimitedDate.startDate)
                const end = new Date(workTime.delimitedDate.endDate)
                const timeDiff = Math.abs(end - start)
                const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
                let i = 0

                while (i <= dayDiff) {
                    const date = new Date(
                        new Date(start).setDate(start.getDate() + i),
                    )
                    let copy = JSON.parse(JSON.stringify(workTime))

                    copy.id = `${copy.id}-${i}`
                    copy.delimitedDate.startDate = date
                    copy.delimitedDate.endDate = date

                    dailyWorktimes.push(copy)
                    i++
                }
            } else {
                dailyWorktimes.push(workTime)
            }
        }

        return dailyWorktimes
    }

    shouldComponentUpdate(_, nextState) {
        if (
            this.state.openModal &&
            nextState.openModal &&
            nextState.openModal !== `nextEvent`
        )
            return false
        return true
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    _getCurrentUserLink() {
        const { groupMembers, user } = this.props

        const ul = groupMembers.find(
            gm =>
                (user?.email && gm?.user?.email === user.email) ||
                gm?.userSubscribe?.mail === user.email,
        )

        return ul
    }

    handleScroll = () => {
        const { yearShow } = this.state
        if (this.yearEl && this.yearEl.length > 0) {
            for (var i in this.yearEl) {
                if (!this.yearEl[i] || !this.yearEl[i].el) return false
                const top = this.yearEl[i].el.getBoundingClientRect().top
                if (
                    top < 400 &&
                    top > 200 &&
                    yearShow !== this.yearEl[i].year
                ) {
                    this.setState({ yearShow: this.yearEl[i].year })
                }
            }
        }
    }

    handleClickedItem(spending) {
        this.setState({ openSnack: spending })
    }

    openModal(type, event = null, newFiles = undefined) {
        this.setState({
            openModal: type,
            event: event,
            newFiles: newFiles,
            isActionMenuOpen: false,
        })
        if (event == null && type !== 'absence' && type !== 'workTime') {
            setBOBStateEvent(this)
        }
    }

    handleCloseModal(nextEvent) {
        if (!nextEvent || nextEvent.target) {
            this.setState({
                openModal: false,
                nextEvent: null,
                event: null,
            })
        } else {
            this.setState({
                openModal: `nextEvent`,
                openDoc: null,
                nextEvent: nextEvent,
            })
        }
    }

    editEvent() {
        const { workTimes, workTimesManager } = this.props
        const { event } = this.state

        let extendedProps = JSON.parse(
            JSON.stringify(event.event._def.extendedProps),
        )

        if (
            typeof extendedProps.event.id === 'string' &&
            extendedProps.event.id.search('-')
        ) {
            const id = extendedProps.event.id.split('-')[0]
            let currentEvent = null

            if (extendedProps.type === 'workTime') {
                currentEvent = workTimes.find(e => e.id === +id)

                if (currentEvent) {
                    extendedProps.event = JSON.parse(
                        JSON.stringify(currentEvent),
                    )
                } else {
                    currentEvent = workTimesManager.find(e => e.id === +id)

                    if (currentEvent)
                        extendedProps.event = JSON.parse(
                            JSON.stringify(currentEvent),
                        )
                }
            }
        }

        if (extendedProps.type == 'worktime') {
            this.openModal('workTime', {
                ...extendedProps.event,
                startTime: dayjs(extendedProps.event.startTime, 'HH:mm:ss'),
                endTime: dayjs(extendedProps.event.endTime, 'HH:mm:ss'),
            })
        } else if (extendedProps.type == 'absence') {
            this.openModal('absence', {
                ...extendedProps.event,
            })
        } else if (extendedProps.detail) {
            // Inventaire
            this.openModal('editDetail', {
                id: event.event._def.publicId,
                title: event.event._def.title,
                ...extendedProps,
            })
        } else if (extendedProps.checkup) {
            //Intervention
            this.openModal('editRepair', {
                id: event.event._def.publicId,
                title: event.event._def.title,
                ...extendedProps,
            })
        } else if (
            !extendedProps.detail &&
            !extendedProps.checkup &&
            extendedProps.transaction
        ) {
            //Transaction
            if (extendedProps.eventType.earning) {
                this.openModal('editEarning', {
                    id: event.event._def.publicId,
                    title: event.event._def.title,
                    ...extendedProps,
                })
            } else if (extendedProps.eventType.spending) {
                this.openModal('editExpense', {
                    id: event.event._def.publicId,
                    title: event.event._def.title,
                    ...extendedProps,
                })
            }
        } else {
            //Document
            this.openModal('document', {
                id: event.event._def.publicId,
                title: event.event._def.title,
                ...extendedProps,
            })
        }
    }

    async deleteEvent() {
        const { event, userLink } = this.state

        const currentID =
            typeof event.event._def.publicId === 'string' &&
            event.event._def.publicId.search('-')
                ? event.event._def.publicId.split('-')[0]
                : event.event._def.publicId

        if (window.confirm(this.displayText('validateDelete'))) {
            if (event.event._def.extendedProps.type == 'worktime') {
                await this.props.dispatch(
                    workTimeActions.deleteWorkTime({
                        workTimeId: currentID,
                        userLink: userLink,
                    }),
                )
            } else if (event.event._def.extendedProps.type == 'absence') {
                await this.props.dispatch(
                    deleteAbsence({
                        absenceId: currentID,
                        currentUserLink: userLink,
                    }),
                )
            }

            // Suppression Detail
            if (event.event._def.extendedProps.detail) {
                await this.props.dispatch(removeDetailFromBOBEvent(currentID))
            }

            // Suppression Checkup
            if (event.event._def.extendedProps.checkup) {
                await this.props.dispatch(removeCheckupFromBOBEvent(currentID))
            }

            // Suppression Transaction
            if (event.event._def.extendedProps.transaction) {
                await this.props.dispatch(
                    removeTransactionFromBOBEvent(currentID),
                )
            }

            //Suppression fichiers
            if (
                event?.event?._def.extendedProps?.files &&
                event.event._def.extendedProps.files.length > 0
            ) {
                await this.props.dispatch(removeFilesFromBOBEvent(currentID))
            }

            // Suppression date
            // await this.props.dispatch(removeDelimitedDateFromBOBEvent(event.event._def.publicId))

            this.setState({ isActionMenuOpen: false })
        }
    }

    async duplicateEvent() {
        const { event } = this.state

        const newEvent = { ...event.event._def.extendedProps }
        newEvent.newFiles = []

        let newFiles
        // Suppression des ID
        if (newEvent.transaction) {
            newEvent.transaction.id = undefined
            newEvent.transaction.amountIt =
                newEvent.transaction.amountIt?.toString() ?? null
            newEvent.transaction.amountEt =
                newEvent.transaction.amountEt?.toString() ?? null
        }
        if (newEvent.delimitedDate) {
            newEvent.delimitedDate.id = undefined
        }
        if (newEvent.checkup) {
            newEvent.checkup.id = undefined
        }
        if (newEvent.detail) {
            newEvent.detail.id = undefined
        }
        if (newEvent.id) {
            newEvent.id = undefined
        }

        if (newEvent.detail) {
            // Inventaire
        } else if (newEvent.checkup) {
            //Intervention
            this.openModal(
                'editRepair',
                {
                    title: event.event._def.title,
                    ...JSON.parse(JSON.stringify(newEvent)),
                },
                newFiles,
            )
        } else if (
            !newEvent.detail &&
            !newEvent.checkup &&
            newEvent.transaction
        ) {
            //Transation
            if (newEvent.eventType.earning) {
                this.openModal(
                    'editEarning',
                    {
                        title: event.event._def.title,
                        ...newEvent,
                    },
                    newFiles,
                )
            } else if (newEvent.eventType.spending) {
                this.openModal(
                    'editExpense',
                    {
                        title: event.event._def.title,
                        ...newEvent,
                    },
                    newFiles,
                )
            }

            // }else if(event.event._def.extendedProps)
        } else {
            //Document
            this.openModal(
                'document',
                {
                    id: event.event._def.publicId,
                    title: event.event._def.title,
                    ...event.event._def.extendedProps,
                },
                newFiles,
            )
        }
    }

    handleGesture() {
        const moveX = this.touchendX.current - this.touchstartX.current
        const moveY = this.touchendY.current - this.touchstartY.current

        // Si le swipe est plus vertical qu'horizontal, alors rien ne se passe
        if (Math.abs(moveY) > Math.abs(moveX)) {
            return
        }

        if (Math.abs(moveX) > MIN_SWIPE_DISTANCE && moveX < 0) {
            this.calendarRef.current.calendar.next()
        }
        if (Math.abs(moveX) > MIN_SWIPE_DISTANCE && moveX > 0) {
            this.calendarRef.current.calendar.prev()
        }

        //Workaround pour mettre à jour le Header
        let count = this.state.count + 1
        this.setState({ count: count })
    }

    render() {
        const { classes, eventTypes, boat, loading } = this.props
        const { isActionMenuOpen, actionMenuAnchor, nextEvent } = this.state
        this.yearEl = []
        const isMobile = window.innerWidth < 600
        const today = new Date()

        if (!boat && loading === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                </div>
            )
        }

        if (loading !== 0) return this.renderLoading(`100px`, `100px`, true)

        const days = [
            this.displayText('sunday'),
            this.displayText('monday'),
            this.displayText('tuesday'),
            this.displayText('wednesday'),
            this.displayText('thursday'),
            this.displayText('friday'),
            this.displayText('saturday'),
        ]

        return (
            <>
                <div className={classes.root}>
                    {isMobile && (
                        <Typography className={classes.title}>
                            {this.displayText('pageTitle')} - {boat.name}
                        </Typography>
                    )}
                    {this.calendarRef.current && (
                        <CalendarHeader
                            events={this.props.bob.events}
                            calendarRef={this.calendarRef.current}
                            openModal={this.openModal}
                            count={this.state.count}
                            navigateDay={action => {
                                if (action === 'previous') {
                                    this.calendarRef.current.calendar.prev()
                                } else if (action === 'next') {
                                    this.calendarRef.current.calendar.next()
                                } else if (action === 'today') {
                                    this.calendarRef.current.calendar.today()

                                    // En vue Liste, on veut scroll jusqu'au jour actuel
                                    if (
                                        this.calendarRef.current.calendar.view
                                            .type === 'listYear'
                                    ) {
                                        let today = new Date()
                                        const formatDate =
                                            today.getFullYear() +
                                            '-' +
                                            (today.getMonth() + 1)
                                                .toString()
                                                .padStart(2, 0) +
                                            '-' +
                                            today
                                                .getDate()
                                                .toString()
                                                .padStart(2, 0)
                                        const el = document.querySelector(
                                            `[data-date="${formatDate}"]`,
                                        )
                                        if (el)
                                            document.querySelector(
                                                '.calendar-container',
                                            ).scrollTop = el.offsetTop
                                    }
                                }
                                this.setState({
                                    date: this.calendarRef.current.calendar.getDate(),
                                })
                            }}
                            changeView={view => {
                                this.calendarRef.current.calendar.changeView(
                                    view,
                                )
                                this.setState({
                                    view: view,
                                })
                            }}
                        />
                    )}

                    {/* Gestion du swipe sur le conteneur du calendrier */}
                    <div
                        onTouchStart={event => {
                            if (isMobile) {
                                this.touchstartX.current =
                                    event.nativeEvent.changedTouches[0].screenX
                                this.touchstartY.current =
                                    event.nativeEvent.changedTouches[0].screenY
                                this.touchTimestamp.current = event.timeStamp
                            }
                        }}
                        onTouchEnd={event => {
                            if (isMobile) {
                                let diff =
                                    event.timeStamp -
                                    this.touchTimestamp.current

                                // Si le swipe est trop lent alors rien ne se passe
                                if (diff < MIN_SWIPE_SPEED) {
                                    this.touchendX.current =
                                        event.nativeEvent.changedTouches[0].screenX
                                    this.touchendY.current =
                                        event.nativeEvent.changedTouches[0].screenY
                                    this.touchTimestamp.current = 0
                                    this.handleGesture()
                                }
                            }
                        }}
                        className={`calendar-container ${classes.calendarContainer}`}
                    >
                        <FullCalendar
                            ref={ref => (this.calendarRef.current = ref)}
                            plugins={[
                                dayGridPlugin,
                                multiMonthPlugin,
                                timeGridPlugin,
                                listPlugin,
                                interactionPlugin,
                            ]}
                            initialView={this.state.view} //Vue 'Semaine' par défaut
                            initialDate={this.state.date}
                            weekends={true}
                            height="auto"
                            events={this.state.events}
                            eventBackgroundColor="transparent"
                            eventContent={this.renderEventContent}
                            headerToolbar={false} //Header par défaut masqué
                            firstDay={1} //Début de la semaine le lundi
                            dayHeaderContent={arg => {
                                if (isMobile) return days[arg.date.getDay()][0]
                                else
                                    return days[arg.date.getDay()]
                                        .slice(0, 3)
                                        .toUpperCase()
                            }}
                            locale={this.context}
                            multiMonthMaxColumns={isMobile ? 1 : 3}
                            multiMonthMinWidth={200}
                            showNonCurrentDates={true} //Affiche les jour du mois précédent et suivant
                            // Options TimeGridWeek
                            allDayContent={''} //Supprimer le label "all-day"
                            nowIndicator={true}
                            dayHeaders={true}
                            slotDuration={'01:00:00'}
                            businessHours={{
                                daysOfWeek: [1, 2, 3, 4, 5],
                                startTime: '09:00',
                                endTime: '17:30',
                            }}
                            moreLinkHint={e => {
                                return this.displayText('moreLinkHint').replace(
                                    '#1',
                                    e,
                                )
                                // return `Afficher ${e} événements`
                            }}
                            dateClick={e => {
                                if (e.view.type === 'timeGridDay') return
                                // En vue mobile, si l'utilisateur clique sur un jour en vue Semaine,
                                // alors la vue jour s'affiche
                                if (isMobile) {
                                    this.calendarRef.current.calendar.changeView(
                                        'timeGridDay',
                                    )
                                    this.calendarRef.current.calendar.gotoDate(
                                        e.date,
                                    )
                                    const count = this.state.count + 1

                                    this.setState({ count: count })
                                }
                            }}
                            views={{
                                // Modification des paramètres du calendrier spécifiquement pour les vues

                                // Paramètres vue Jour
                                timeGridDay: {
                                    aspectRatio: isMobile ? 0.7 : 1.3,
                                    dayHeaders: isMobile ? false : true, //Masque le header en mobile car on a un header custom
                                    eventClick: a => {
                                        this.openActionMenu(a, {
                                            current: a.el,
                                        })
                                    },
                                    eventMaxStack: isMobile ? 2 : 4,
                                    dayMaxEventRows: isMobile ? 2 : 4,
                                    dayMaxEvents: isMobile ? 2 : 4,
                                    viewDidMount: () =>
                                        this.moveCalendarToStartWork(),
                                },

                                // Paramètres vue Semaine
                                timeGridWeek: {
                                    aspectRatio: 1,
                                    dayMaxEvents: 2,
                                    dayMaxEventRows: 2,
                                    eventMaxStack: isMobile ? 1 : 2,
                                    stickyHeaderDates: true,
                                    moreLinkClassNames: classes.moreLink,
                                    moreLinkText: e => {
                                        if (e === 1)
                                            return `+${e} ${this.displayText(
                                                'more',
                                            )}`

                                        return `+${e} ${this.displayText(
                                            'morePlural',
                                        )}`
                                    },
                                    allDayDidMount: a => {
                                        //Manipulation du DOM permettant d'obtenir le numéro du jour dans la rangée "allDay"

                                        //Récupération de toutes les cellules de l'entête
                                        const cells = document.getElementsByClassName(
                                            'fc-daygrid-day',
                                        )
                                        for (let cell of cells) {
                                            // Parse l'attribut de la cellule pour obtenir la date
                                            let date = cell
                                                .getAttribute('data-date')
                                                .split('-')

                                            // Création de l'élément custom
                                            const span = document.createElement(
                                                'span',
                                            )
                                            span.setAttribute(
                                                'class',
                                                classes.dayMarker,
                                            )

                                            const container = document.createElement(
                                                'div',
                                            )
                                            container.setAttribute(
                                                'style',
                                                'display:flex;justify-content:center',
                                            )

                                            //Si la cellule est celle d'aujourd'hui, on rajoute la classe qui l'indique
                                            if (
                                                date[0] ===
                                                    today.getFullYear() &&
                                                date[1].replace(/^0+/, '') ===
                                                    today.getMonth() + 1 &&
                                                date[2].replace(/^0+/, '') ===
                                                    today.getDate()
                                            ) {
                                                span.setAttribute(
                                                    'class',
                                                    [
                                                        classes.dayMarker,
                                                        'today',
                                                    ].join(' '),
                                                )
                                            }

                                            //Ajoute le numéro du jour sans le premier 0
                                            span.appendChild(
                                                document.createTextNode(
                                                    date[2].replace(/^0+/, ''),
                                                ),
                                            )

                                            //Ajout de l'élément au DOM
                                            const cellContent = cell.querySelector(
                                                '.fc-daygrid-day-frame',
                                            )
                                            container.append(span)
                                            cellContent.prepend(container)
                                        }
                                    },
                                    eventClick: e => {
                                        // En vue mobile, si l'utilisateur clique sur un jour en vue Semaine,
                                        // alors la vue jour s'affiche
                                        if (isMobile) {
                                            this.calendarRef.current.calendar.changeView(
                                                'timeGridDay',
                                            )
                                            this.calendarRef.current.calendar.gotoDate(
                                                e.event._def.extendedProps
                                                    .delimitedDate.startDate,
                                            )
                                            const count = this.state.count + 1

                                            this.setState({ count: count })
                                        } else {
                                            this.openActionMenu(e, {
                                                current: e.el,
                                            })
                                        }
                                    },
                                    viewDidMount: () =>
                                        this.moveCalendarToStartWork(),
                                },

                                // Paramètres vue Mois
                                dayGridMonth: {
                                    // aspectRatio: isMobile ? 0.7 : 1,
                                    dayMaxEvents: 4,
                                    moreLinkClassNames: classes.moreLink,
                                    moreLinkText: e => {
                                        if (e === 1) {
                                            return `+${e} ${this.displayText(
                                                'more',
                                            )}`
                                        }
                                        return `+${e} ${this.displayText(
                                            'morePlural',
                                        )}`
                                    },
                                    eventClick: e => {
                                        // En vue mobile, si l'utilisateur clique sur un jour en vue Mois,
                                        // alors la vue jour s'affiche
                                        if (isMobile) {
                                            this.calendarRef.current.calendar.changeView(
                                                'timeGridDay',
                                            )
                                            this.calendarRef.current.calendar.gotoDate(
                                                e.event._def.extendedProps
                                                    .delimitedDate.startDate,
                                            )

                                            const count = this.state.count + 1

                                            this.setState({ count: count })
                                        } else {
                                            this.openActionMenu(e, {
                                                current: e.el,
                                            })
                                        }
                                    },
                                },

                                // Paramètres vue Année
                                multiMonthYear: {
                                    dayMaxEvents: 1,
                                    dayHeaderContent: arg =>
                                        days[arg.date.getDay()][0],
                                    moreLinkClassNames: classes.moreEventsLink,
                                    moreLinkContent: e => <></>,
                                    moreLinkClick: e => {
                                        // En vue mobile, on override l'ouverture du popover
                                        // et on affiche la vue du jour cliqué
                                        if (isMobile) {
                                            this.calendarRef.current.calendar.changeView(
                                                'timeGridDay',
                                            )
                                            this.calendarRef.current.calendar.gotoDate(
                                                e.date,
                                            )

                                            const count = this.state.count + 1

                                            this.setState({ count: count })

                                            document
                                                .querySelector('#nav')
                                                .scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                })
                                        }
                                    },
                                    moreLinkDidMount: o => {
                                        // Modification du DOM pour afficher les points indiquants le nombre d'événements
                                        // Récupération de la date depuis un td parent
                                        const date = new Date(
                                            o.el.parentElement.parentElement.parentElement.parentElement.getAttribute(
                                                'data-date',
                                            ),
                                        )

                                        if (this.calendarRef.current != null) {
                                            //Récupération des événements du calendrier
                                            const calendarEvents = this.calendarRef.current.calendar.getEvents()

                                            // Filtre uniquemement les événements du jour (le jour du jour du hook, pas aujourd'hui)
                                            const eventsThatDay = calendarEvents.filter(
                                                ev =>
                                                    ev._instance.range.start.getFullYear() ===
                                                        date.getFullYear() &&
                                                    ev._instance.range.start.getMonth() ===
                                                        date.getMonth() &&
                                                    ev._instance.range.start.getDate() ===
                                                        date.getDate(),
                                            )

                                            if (eventsThatDay.length > 0) {
                                                //Création des éléments du DOM
                                                const div = document.createElement(
                                                    'div',
                                                )
                                                div.setAttribute(
                                                    'class',
                                                    classes.row,
                                                )

                                                // Création des points avec la couleur de l'événement
                                                eventsThatDay.forEach(
                                                    (ev, index) => {
                                                        if (index < 5) {
                                                            const dot = document.createElement(
                                                                'div',
                                                            )
                                                            dot.setAttribute(
                                                                'class',
                                                                classes.dot,
                                                            )
                                                            dot.setAttribute(
                                                                'style',
                                                                `background-color: ${ev.backgroundColor}`,
                                                            )
                                                            div.appendChild(dot)
                                                        }
                                                    },
                                                )

                                                // Ajout des éléments au DOM
                                                o.el.append(div)
                                            }
                                        }

                                        // Lorsqu'on double clique sur un jour,
                                        // on va être redirigé vers la vue jour
                                        o.el.addEventListener('dblclick', e => {
                                            this.calendarRef.current.calendar.changeView(
                                                'timeGridDay',
                                            )
                                            this.calendarRef.current.calendar.gotoDate(
                                                date,
                                            )

                                            const count = this.state.count + 1

                                            this.setState({ count: count })
                                        })
                                    },
                                    dayPopoverFormat: {
                                        weekday: 'short',
                                        day: '2-digit',
                                    },
                                    eventClick: e => {
                                        this.openActionMenu(e, {
                                            current: e.el,
                                        })
                                    },
                                },

                                // Paramètres vue Liste
                                listYear: {
                                    dayHeaderContent: arg => (
                                        <span className={{}}>
                                            <b>{days[arg.date.getDay()]}</b>,{' '}
                                            {arg.text}
                                        </span>
                                    ),
                                    eventDidMount: arg => {
                                        // Si l'événement est sur toute la journée
                                        //Supprimer les colonnes et mettre attribut colspan=3 pour que la cellule prenne toute la largeur
                                        if (arg.event._def.allDay) {
                                            arg.el
                                                .querySelector(
                                                    'td.fc-list-event-time',
                                                )
                                                .remove()
                                            arg.el
                                                .querySelector(
                                                    'td.fc-list-event-title',
                                                )
                                                .setAttribute('colspan', 3)
                                        } else {
                                            //Sinon mettre en forme la cellule du temps et modifier colspan
                                            if (arg.event._def.hasEnd) {
                                                const time = arg.el.querySelector(
                                                    'td.fc-list-event-time',
                                                )
                                                const timeSplit = time.innerHTML.split(
                                                    ' - ',
                                                )

                                                if (timeSplit.length === 2) {
                                                    const start = document.createElement(
                                                        'b',
                                                    )
                                                    start.appendChild(
                                                        document.createTextNode(
                                                            timeSplit[0],
                                                        ),
                                                    )

                                                    const end = document.createElement(
                                                        'p',
                                                    )
                                                    end.setAttribute(
                                                        'style',
                                                        'font-weight: 100;color:#939393',
                                                    )
                                                    end.appendChild(
                                                        document.createTextNode(
                                                            timeSplit[1],
                                                        ),
                                                    )

                                                    time.innerHTML = ''
                                                    time.appendChild(start)
                                                    time.appendChild(end)
                                                }
                                            }

                                            arg.el
                                                .querySelector(
                                                    'td.fc-list-event-title',
                                                )
                                                .setAttribute('colspan', 2)
                                        }
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
                {/* Modales */}

                {/* ZONE MODAL */}
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        editDetail: (
                            <InventoryModal
                                noCross={false}
                                event={this.state.event}
                                type={
                                    this.state.event && this.state.event.part
                                        ? `INVENTORY_PART`
                                        : `INVENTORY_EQUIPMENT`
                                }
                                onlyDetails
                            />
                        ),
                        editRepair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                event={this.state.event}
                                language={this.getContext()}
                                handleSelfClose={this.handleCloseModal}
                                handleValidatedEvent={this.validatedEvent}
                                noCross={false}
                            />
                        ),
                        nextRepair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                type={`NEXT_REPAIR`}
                                language={this.getContext()}
                                noCross={false}
                            />
                        ),
                        prevRepair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                type={`LAST_REPAIR`}
                                language={this.getContext()}
                                noCross={false}
                            />
                        ),
                        document: (
                            <DocumentModal
                                eventTypes={eventTypes}
                                event={this.state.event}
                                noCross={false}
                            />
                        ),
                        addExpense: (
                            <BudgetModal
                                event={this.state.event}
                                openAddModal={1}
                                events={this.props.events}
                                eventTypes={this.props.eventTypes}
                                noCross={false}
                            />
                        ),
                        editExpense: (
                            <BudgetModal
                                event={this.state.event}
                                openAddModal={1}
                                events={this.props.events}
                                eventTypes={this.props.eventTypes}
                                noCross={false}
                            />
                        ),
                        addIncome: (
                            <BudgetModal
                                event={this.state.event}
                                openAddModal={2}
                                events={this.props.events}
                                eventTypes={this.props.eventTypes}
                                noCross={false}
                            />
                        ),
                        editEarning: (
                            <BudgetModal
                                event={this.state.event}
                                openAddModal={2}
                                events={this.props.events}
                                eventTypes={this.props.eventTypes}
                                noCross={false}
                            />
                        ),
                        showDocs: (
                            <BoatOnModalCore
                                noCross={false}
                                body={
                                    <BoatOnImageRender
                                        vertical
                                        multi
                                        bottomImage
                                        imgs={getImageToDisplay(
                                            this.state.event,
                                            [],
                                        )}
                                    />
                                }
                            />
                        ),
                        validEvent: (
                            <ValidModal
                                validatedEvent={this.validateEvent}
                                noCross={false}
                            />
                        ),
                        unvalidEvent: (
                            <UnvalidModal
                                unvalidatedEvent={this.unValidateEvent}
                                noCross={false}
                            />
                        ),
                        nextEvent: (
                            <NextEventModal event={nextEvent} noCross={false} />
                        ),
                        customEvent: (
                            <CustomEventModal
                                event={this.state.event}
                                noCross={false}
                            />
                        ),
                        workTime: (
                            <WorkTimeModal
                                workTime={this.state.event || null}
                                noCross={false}
                            />
                        ),
                        absence: (
                            <AbsenceModal
                                event={this.state.event}
                                noCross={false}
                            />
                        ),
                    }}
                    titles={{
                        editDetail: this.state.event?.title,
                        editRepair: this.state.event?.title,
                        nextRepair: this.displayText(`createNextRepair`),
                        prevRepair: this.displayText(`createPrevRepair`),
                        document: this.state.event?.id
                            ? this.state.event?.title
                            : this.displayText('userAddDocument'),
                        addExpense: this.displayText('userAddExpense'),
                        editExpense: this.state.event?.title,
                        addIncome: this.displayText('userAddIncome'),
                        editEarning: 'Edit ' + this.state.event?.title,
                        showDocs: this.displayText('myDocuments'),
                        validEvent: this.displayText(`validEventTitle`),
                        unvalidEvent: this.displayText(`unvalidEventTitle`),
                        nextEvent: this.displayText(`planNext`),
                        customEvent: this.displayText('titleModalCustomEvent'),
                        workTime: this.displayText('workTimeModalTitle'),
                        absence: this.displayText('leaveModalTitle'),
                    }}
                    maxWidth={{
                        editRepair: `sm`,
                        nextRepair: `sm`,
                    }}
                />
                <Popper
                    open={isActionMenuOpen}
                    anchorEl={actionMenuAnchor}
                    role={undefined}
                    transition
                    disablePortal
                    placement="bottom-end"
                    style={{
                        zIndex: 999,
                    }}
                >
                    {this._popOverRender()}
                </Popper>
            </>
        )
    }

    _popOverRender() {
        const { classes } = this.props
        const extendedProps = this.state.event?.event?._def?.extendedProps

        return ({ TransitionProps }) => (
            <Grow
                {...TransitionProps}
                style={{ transformOrigin: 'center top' }}
            >
                <Paper elevation={2}>
                    <ClickAwayListener onClickAway={this.closeActionsMenu}>
                        <MenuList
                            id="split-button-menu"
                            className={classes.actionMenu}
                        >
                            <BoatOnBlock
                                blockIds={[4, 11, 13, 21, 40, 54]}
                                onlyOneId={true}
                                addedCondition={blockId =>
                                    extendedProps &&
                                    ((blockId === 4 &&
                                        extendedProps.files &&
                                        extendedProps.files.length > 0 &&
                                        !extendedProps.checkup &&
                                        !extendedProps.detail &&
                                        !extendedProps.transaction) ||
                                        (blockId === 11 &&
                                            extendedProps.transaction &&
                                            ((!extendedProps.eventType
                                                .spending &&
                                                extendedProps.eventType
                                                    .earning) ||
                                                (extendedProps.eventType
                                                    .spending &&
                                                    extendedProps.eventType
                                                        .earning &&
                                                    extendedProps.transaction
                                                        .amountIt >= 0)) &&
                                            !extendedProps.checkup &&
                                            !extendedProps.detail) ||
                                        (blockId === 13 &&
                                            extendedProps.transaction &&
                                            ((extendedProps.eventType
                                                .spending &&
                                                !extendedProps.eventType
                                                    .earning) ||
                                                (extendedProps.eventType
                                                    .spending &&
                                                    extendedProps.eventType
                                                        .earning &&
                                                    extendedProps.transaction
                                                        .amountIt < 0)) &&
                                            !extendedProps.checkup &&
                                            !extendedProps.detail) ||
                                        (blockId === 21 &&
                                            extendedProps.checkup) ||
                                        (blockId === 40 &&
                                            extendedProps.detail) ||
                                        blockId === 54)
                                }
                                children={
                                    <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={this.editEvent}
                                    >
                                        <Edit />
                                        <p>{this.displayText('edit')}</p>
                                    </MenuItem>
                                }
                            />
                            <BoatOnBlock
                                blockIds={[2, 8, 9, 22, 54]}
                                onlyOneId={true}
                                addedCondition={blockId =>
                                    extendedProps &&
                                    ((blockId === 2 &&
                                        extendedProps.files &&
                                        extendedProps.files.length > 0 &&
                                        !extendedProps.checkup &&
                                        !extendedProps.detail &&
                                        !extendedProps.transaction) ||
                                        (blockId === 8 &&
                                            extendedProps.transaction &&
                                            ((!extendedProps.eventType
                                                .spending &&
                                                extendedProps.eventType
                                                    .earning) ||
                                                (extendedProps.eventType
                                                    .spending &&
                                                    extendedProps.eventType
                                                        .earning &&
                                                    extendedProps.transaction
                                                        .amountIt >= 0)) &&
                                            !extendedProps.checkup &&
                                            !extendedProps.detail) ||
                                        (blockId === 9 &&
                                            extendedProps.transaction &&
                                            ((extendedProps.eventType
                                                .spending &&
                                                !extendedProps.eventType
                                                    .earning) ||
                                                (extendedProps.eventType
                                                    .spending &&
                                                    extendedProps.eventType
                                                        .earning &&
                                                    extendedProps.transaction
                                                        .amountIt < 0)) &&
                                            !extendedProps.checkup &&
                                            !extendedProps.detail) ||
                                        (blockId === 22 &&
                                            extendedProps.checkup))
                                }
                                children={
                                    <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={this.duplicateEvent}
                                    >
                                        <ContentCopy />
                                        <p>{this.displayText('duplicate')}</p>
                                    </MenuItem>
                                }
                            />
                            <BoatOnBlock
                                blockIds={[6, 15, 23, 42, 54]}
                                onlyOneId={true}
                                addedCondition={blockId =>
                                    extendedProps &&
                                    ((blockId === 6 &&
                                        extendedProps.files &&
                                        extendedProps.files.length > 0 &&
                                        !extendedProps.checkup &&
                                        !extendedProps.detail &&
                                        !extendedProps.transaction) ||
                                        (blockId === 15 &&
                                            extendedProps.transaction &&
                                            !extendedProps.checkup &&
                                            !extendedProps.detail) ||
                                        (blockId === 23 &&
                                            extendedProps.checkup) ||
                                        (blockId === 42 &&
                                            extendedProps.detail) ||
                                        blockId === 54)
                                }
                                children={
                                    <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={this.deleteEvent}
                                    >
                                        <Delete />
                                        <p>{this.displayText('delete')}</p>
                                    </MenuItem>
                                }
                            />
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )
    }

    async validateEvent() {
        await this.saveRepair()
        const { event, error } = this.state
        if (!error) {
            let nextEvent = null
            if (event?.eventType?.id === 20) {
                const cycle = getCycle({ event: event })
                if (cycle?.cycleType?.id === 1) {
                    const startDate = new Date(event.delimitedDate.startDate)
                    const nextDate = new Date(
                        startDate.setMonth(
                            startDate.getMonth() + cycle.cycleLength || 12,
                        ),
                    )
                        .toISOString()
                        .split('T')[0]
                    nextEvent = {
                        title: event.title,
                        eventType: event.eventType,
                        checkup: event.checkup,
                        delimitedDate: { startDate: nextDate },
                        protocol: event.protocol,
                    }
                } else if (cycle?.cycleType?.id === 2) {
                    const actualDuration =
                        event.actualDuration + cycle.cycleLength
                    nextEvent = {
                        title: event.title,
                        actualDuration: actualDuration,
                        eventType: event.eventType,
                        checkup: event.checkup,
                        protocol: event.protocol,
                    }
                }
            }

            await this.props.dispatch(
                putBOBEvents(event.id, event.title, {
                    delimitedDate: {
                        ...event.delimitedDate,
                        startDate: event?.delimitedDate?.startDate
                            ? event.delimitedDate.startDate
                            : new Date(),
                        endDate: new Date(),
                    },
                }),
            )

            if (this.props.handleValidatedEvent && nextEvent)
                this.props.handleValidatedEvent(nextEvent)
        }
    }

    validatedEvent(nextEvent) {
        if (nextEvent !== null) this.handleCloseModal(nextEvent)
    }

    async unValidateEvent() {
        await this.saveRepair()
        const { event, error } = this.state
        if (!error) {
            this.props.dispatch(
                putBOBEvents(event.id, event.title, {
                    delimitedDate: {
                        ...event.delimitedDate,
                        endDate: null,
                    },
                }),
            )
        }
    }

    getBoatById(id) {
        const { fleetInfos } = this.props
        const boats = fleetInfos?.boats
        if (boats) {
            return boats.find(boat => boat.id === id)
        }
    }

    transformBobEvent(event) {
        if (!event.delimitedDate) {
            event.delimitedDate = {
                startDate: event.timestamp,
                endDate: event.timestamp,
            }
        }

        const e = {
            ...event,
            start: event.delimitedDate?.startDate ?? event.timestamp,
            end:
                event.delimitedDate?.endDate ??
                event.delimitedDate?.startDate ??
                event.timestamp,
            allDay: false,
            backgroundColor: event.eventType.color,
            boatDetails: this.getBoatById(event.boatId),
            type: 'bobevent',
            event: event,
        }

        if (event.detail) {
            e.start =
                event.detail.buyDate ??
                event.delimitedDate?.startDate ??
                event.detail.timestamp
            e.end =
                event.detail.buyDate ??
                event.delimitedDate?.startDate ??
                event.detail.timestamp
        }

        // Si l'évènement est un entretien on défini la date à la date de début sans prendre en compte la date de fin
        if (event.checkup) {
            e.start = event.delimitedDate.startDate
            e.end = event.delimitedDate.startDate
        }

        return e
    }

    transformWorkTimeEvent(event) {
        const e = {
            id: event.id,
            title: 'Travail',
            backgroundColor: '#263e9f',
            allDay: false,
            start: dayjs(event.delimitedDate.startDate)
                .hour(event.startTime.split(':')[0])
                .minute(event.startTime.split(':')[1])
                .second(event.startTime.split(':')[2])
                .toISOString(),
            end: dayjs(event.delimitedDate.endDate)
                .hour(event.endTime.split(':')[0])
                .minute(event.endTime.split(':')[1])
                .second(event.endTime.split(':')[2])
                .toISOString(),
            type: 'worktime',
            attachedTo: event.responsibleLink,
            event: event,
        }

        return e
    }

    transformAbsenceEvent(event) {
        let color = ''

        switch (event.absenceReasonType.id) {
            case 1:
                // Congés payés
                color = '#6BC070'
                break
            case 3:
                // Todo - il va y'avoir un décalage d'id lorsque la bdd sera mise à jour
                // Maladie/accidents
                color = '#E36B61'
                break
            case 4:
                // Injustifié
                color = '#E39861'
                break
            case 5:
                // Sans solde
                color = '#C1C1C1'
            case 6:
                // Spécial
                color = '#C961E3'
        }

        const e = {
            id: event.id,
            title: this.displayTextApi(event.absenceReasonType.translation),
            backgroundColor: color,
            allDay: true,
            start: event.delimitedDate.startDate,
            end: event.delimitedDate.endDate,
            type: 'absence',
            attachedTo: event.responsibleLink,
            event: event,
        }

        if (event.absenceTime) {
            e.start = dayjs(e.start)
                .hour(event.absenceTime.startTime.split(':')[0])
                .minute(event.absenceTime.startTime.split(':')[1])
                .second(event.absenceTime.startTime.split(':')[2])
                .toISOString()
            e.end = dayjs(e.end)
                .hour(event.absenceTime.endTime.split(':')[0])
                .minute(event.absenceTime.endTime.split(':')[1])
                .second(event.absenceTime.endTime.split(':')[2])
                .toISOString()
            e.allDay = false
        }

        if (e.allDay)
            e.end = dayjs(e.end)
                .add(dayjs.duration({ day: 1 }))
                .toISOString()

        return e
    }

    openActionMenu(event, anchorRef) {
        this.setState({
            event: event,
            isActionMenuOpen: true,
            actionMenuAnchor: anchorRef.current,
        })
    }

    closeActionsMenu() {
        this.setState({
            isActionMenuOpen: false,
            actionMenuAnchor: null,
        })
    }

    //Génère un composant personnalisé pour chaque event
    renderEventContent(eventInfo) {
        const { usersLinked } = this.props

        return (
            <Event
                eventInfo={eventInfo}
                usersLinked={usersLinked}
                openActionsMenu={this.openActionMenu}
                openDocModal={event => {
                    this.openModal('showDocs', {
                        id: eventInfo.event._def.publicId,
                        title: eventInfo.event._def.title,
                        ...eventInfo.event._def.extendedProps,
                    })
                }}
            />
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        user: state.authentication.user,
        groupId: state.group.currentGroupId,
        fleetInfos: state.group.fleetInfos,
        sub: state.fetch.sub,
        spendings: state.bob.spendings,
        checkups: state.bob.repairs,
        checkupTypes: state.fetch.checkupTypes,
        loading: state.bob.loading,
        roles: state.group.roles || [],
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                rangeDate: true,
                checkupTypesChecked: true,
                calendarEquipmentFamily: true,
                amountRange: true,
                rangeHour: true,
                equipment: true,
                userAttachedRole: true,
                managerSearch: true,
                searchString: true,
            },
            true,
            state.group?.groupsMembers,
        ),
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        boat: state.bob.boat,
        bob: state.bob,
        absences: state.absence.absences,
        workTimes: state.workTime.workTimes,
        workTimesLoading: state.workTime.loading,
        absencesLoading: state.absence.loading,
        absencesManager: state.absence.absencesManager,
        workTimesManager: state.workTime.workTimesManager,
        usersLinked: state.group?.groupsMembers?.linkRGU || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CalendarPage))
