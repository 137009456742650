import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/BudgetTabCss'
import dictionary from './Dictionary/BudgetTabDico'
import Typography from '@material-ui/core/Typography'
import { typesActions } from '../../../../actions/types.actions'
import HourAlert from '../HourAlert'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'

class BudgetTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this._renderExpensePieChart = this._renderExpensePieChart.bind(this)
        this._renderExpenseBarChart = this._renderExpenseBarChart.bind(this)
        this._renderIncomePieChart = this._renderIncomePieChart.bind(this)
        this._renderIncomeBarChart = this._renderIncomeBarChart.bind(this)

        ChartJS.register(
            ArcElement,
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend,
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.bobFilter !== prevProps.bobFilter) {
            this.props.handleChangePanel('accordionExpenseExpanded', false)
            this.props.handleChangePanel('accordionIncomeExpanded', false)
        }
    }

    componentWillReceiveProps(props) {
        if (
            props.boats &&
            (!props.spendings || props.update > 0 || !props.documents)
        ) {
            if (props.update > 0) this.props.updated()
        }
    }

    getTotalIncome(eventTypeId = null, month = null, abs = false) {
        const { spendings, eventTypes } = this.props
        let total = 0

        spendings.forEach(spending => {
            if (!spending.delimitedDate) return

            const spendingDate = new Date(spending.delimitedDate.startDate)
            const spendingMonth =
                spendingDate.getFullYear() * 12 + spendingDate.getMonth()
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                spending.eventType.id,
            )

            if (
                !eventTypeId &&
                eventTypes &&
                eventType &&
                spending.transaction.amountIt > 0 &&
                !eventType.spending &&
                eventType.earning &&
                (month === null || month === spendingMonth)
            ) {
                total += parseFloat(spending.transaction.amountIt)
            } else if (
                eventTypeId &&
                eventType &&
                eventTypeId === spending.eventType.id &&
                eventTypes &&
                spending.transaction.amountIt > 0 &&
                !eventType.spending &&
                eventType.earning &&
                (month === null || month === spendingMonth)
            ) {
                total += parseFloat(spending.transaction.amountIt)
            } else if (
                ((eventTypeId && eventTypeId === spending.eventType.id) ||
                    !eventTypeId) &&
                eventType &&
                eventTypes &&
                spending?.transaction?.amountIt > 0 &&
                eventType.spending &&
                eventType.earning &&
                (month === null || month === spendingMonth)
            ) {
                total += parseFloat(spending.transaction.amountIt)
            }
        })

        return abs ? Math.abs(total).toFixed(2) : total.toFixed(2)
    }

    getTotalExpense(eventTypeId = null, month = null, abs = false) {
        const { spendings, eventTypes } = this.props
        let total = 0

        spendings.forEach(spending => {
            if (!spending.delimitedDate) {
                return
            }
            const spendingDate = new Date(spending.delimitedDate.startDate)
            const spendingMonth =
                spendingDate.getFullYear() * 12 + spendingDate.getMonth()

            const eventType = typesActions.getEventTypeById(
                eventTypes,
                spending.eventType.id,
            )
            if (
                !eventTypeId &&
                eventTypes &&
                eventType &&
                spending.transaction.amountIt > 0 &&
                eventType.spending &&
                !eventType.earning &&
                (month === null || month === spendingMonth)
            ) {
                total += parseFloat(spending.transaction.amountIt * -1)
            } else if (
                eventTypeId &&
                eventType &&
                eventTypeId === spending.eventType.id &&
                eventTypes &&
                spending.transaction.amountIt > 0 &&
                eventType.spending &&
                !eventType.earning &&
                (month === null || month === spendingMonth)
            ) {
                total += parseFloat(spending.transaction.amountIt * -1)
            } else if (
                ((eventTypeId && eventTypeId === spending.eventType.id) ||
                    !eventTypeId) &&
                eventType &&
                eventTypes &&
                spending?.transaction?.amountIt < 0 &&
                eventType.spending &&
                eventType.earning &&
                (month === null || month === spendingMonth)
            ) {
                total += parseFloat(spending.transaction.amountIt)
            }
        })

        return abs ? Math.abs(total).toFixed(2) : total.toFixed(2)
    }

    _renderExpensePieChart() {
        const { eventTypes } = this.props

        const formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })

        const colorTab = []
        const nameTab = []
        const totalTab = []

        eventTypes.forEach(eventType => {
            const totalExpense = this.getTotalExpense(eventType.id)
            if (totalExpense !== null && totalExpense !== 0) {
                colorTab.push(eventType.color)
                nameTab.push(this.displayTextApi(eventType.translation))
                totalTab.push(totalExpense)
            }
        })

        if (nameTab.length === 0) {
            return null
        }

        return (
            <Doughnut
                legend={null}
                data={{
                    datasets: [
                        {
                            data: totalTab,
                            backgroundColor: colorTab,
                        },
                    ],
                    labels: nameTab,
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: a => {
                                    return (
                                        a.label +
                                        ': ' +
                                        formatter.format(parseFloat(a.raw))
                                    )
                                },
                            },
                        },
                    },
                }}
            />
        )
    }

    _renderExpenseBarChart() {
        const { eventTypes } = this.props

        const formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })

        //Lister les dépenses des 6 derniers mois par types pour chaque mois

        const { rangeDate } = this.props.bobFilter

        let monthNames = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ]

        var end = rangeDate.end !== null ? rangeDate.end : new Date()
        let monthNb = 6
        var d
        var month

        // Si le filtre date est défini on calcul la différence en mois entre les deux dates
        if (rangeDate.start !== null && rangeDate.end != null) {
            // Diff
            let m =
                (rangeDate.end.getFullYear() - rangeDate.start.getFullYear()) *
                12
            m -= rangeDate.start.getMonth()
            m += rangeDate.end.getMonth()
            monthNb = m > 0 ? m + 1 : 1
        }

        const labels = []
        const dataset = []
        const months = []

        // Récupération du nom de chaque mois en fonction de la période sélectionnée
        for (var i = monthNb; i > 0; i -= 1) {
            d = new Date(end.getFullYear(), end.getMonth() - (i - 1), 1)
            months.push(d.getFullYear() * 12 + d.getMonth())
            month = this.displayText(monthNames[d.getMonth()])

            let shortMonth =
                month.length <= 4
                    ? month
                    : this.displayText(monthNames[d.getMonth()]).slice(0, 3)
            if (d.getMonth() === 6 && this.context === 'fr') {
                //Juillet
                shortMonth = 'Juil'
            }

            labels.push(shortMonth)
        }

        // Création d'un dataset pour chaque type d'événement
        eventTypes.forEach(eventType => {
            const totalExpense = this.getTotalExpense(eventType.id)
            if (totalExpense !== null && totalExpense !== 0) {
                dataset.push({
                    label: this.displayTextApi(eventType.translation),
                    data: months.map(monthNb =>
                        this.getTotalExpense(eventType.id, monthNb, true),
                    ),
                    backgroundColor: eventType.color,
                })
            }
        })

        // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

        const data = {
            labels,
            datasets: dataset,
        }

        if (dataset.length === 0) {
            return null
        }

        return (
            <Bar
                options={{
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                title: a => {
                                    return ''
                                },
                                label: a => {
                                    return (
                                        a.dataset.label +
                                        ': ' +
                                        formatter.format(parseFloat(a.raw))
                                    )
                                },
                            },
                        },
                    },
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatter.format(value)
                                },
                            },
                            grid: {
                                color: 'transparent',
                                display: true,
                                drawBorder: false,
                                zeroLineColor: '#ccc',
                                zeroLineWidth: 1,
                            },
                        },
                    },
                }}
                data={data}
            />
        )
    }

    _renderIncomeBarChart() {
        const { eventTypes } = this.props

        const formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })

        //Lister les revenus des 6 derniers mois par types pour chaque mois

        const { rangeDate } = this.props.bobFilter

        let monthNames = [
            'january',
            'february',
            'march',
            'april',
            'may',
            'june',
            'july',
            'august',
            'september',
            'october',
            'november',
            'december',
        ]

        var end = rangeDate.end !== null ? rangeDate.end : new Date()
        let monthNb = 6
        var d
        var month

        // Si le filtre date est défini on calcul la différence en mois entre les deux dates
        if (rangeDate.start !== null && rangeDate.end != null) {
            // Diff
            let m =
                (rangeDate.end.getFullYear() - rangeDate.start.getFullYear()) *
                12
            m -= rangeDate.start.getMonth()
            m += rangeDate.end.getMonth()
            monthNb = m > 0 ? m + 1 : 1
        }

        const labels = []
        const dataset = []
        const months = []
        // Récupération du nom de chaque mois en fonction de la période sélectionnée
        for (var i = monthNb; i > 0; i -= 1) {
            d = new Date(end.getFullYear(), end.getMonth() - (i - 1), 1)
            months.push(d.getFullYear() * 12 + d.getMonth())
            month = this.displayText(monthNames[d.getMonth()])

            let shortMonth =
                month.length <= 4
                    ? month
                    : this.displayText(monthNames[d.getMonth()]).slice(0, 3)
            if (d.getMonth() === 6 && this.context === 'fr') {
                //Juillet
                shortMonth = 'Juil'
            }

            labels.push(shortMonth)
        }

        // Création d'un dataset pour chaque type d'événement
        eventTypes.forEach(eventType => {
            const totalIncome = this.getTotalIncome(eventType.id)
            if (totalIncome !== null && totalIncome !== 0) {
                dataset.push({
                    label: this.displayTextApi(eventType.translation),
                    data: months.map(monthNb =>
                        this.getTotalIncome(eventType.id, monthNb, true),
                    ),
                    backgroundColor: eventType.color,
                })
            }
        })

        if (dataset.length === 0) {
            return null
        }

        const options = {
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        title: a => {
                            return ''
                        },
                        label: a => {
                            return (
                                a.dataset.label +
                                ': ' +
                                formatter.format(parseFloat(a.raw))
                            )
                        },
                    },
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    ticks: {
                        callback: function(value, index, values) {
                            return formatter.format(value)
                        },
                    },
                    grid: {
                        color: 'transparent',
                        display: true,
                        drawBorder: false,
                        zeroLineColor: '#ccc',
                        zeroLineWidth: 1,
                    },
                },
            },
        }

        const data = {
            labels,
            datasets: dataset,
        }

        return <Bar options={options} data={data} />
    }

    _renderIncomePieChart() {
        const { eventTypes } = this.props

        const formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })

        const colorTab = []
        const nameTab = []
        const totalTab = []

        eventTypes.forEach(eventType => {
            const totalIncome = this.getTotalIncome(eventType.id)
            if (totalIncome !== null && totalIncome !== 0) {
                colorTab.push(eventType.color)
                nameTab.push(this.displayTextApi(eventType.translation))
            }
            totalTab.push(totalIncome)
        })

        if (nameTab.length === 0) {
            return null
        }

        return (
            <Doughnut
                legend={null}
                data={{
                    datasets: [
                        {
                            data: totalTab,
                            backgroundColor: colorTab,
                        },
                    ],
                    labels: nameTab,
                }}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            callbacks: {
                                label: a => {
                                    return (
                                        a.label +
                                        ': ' +
                                        formatter.format(parseFloat(a.raw))
                                    )
                                },
                            },
                        },
                    },
                }}
            />
        )
    }

    render() {
        const {
            classes,
            loading,
            eventTypes,
            checkedExpenses,
            spendings,
        } = this.props

        if (loading !== 0 || !eventTypes) return <></>

        const totalIncome = this.getTotalIncome()
        const totalExpense = this.getTotalExpense()

        const formatter = new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
        })

        const isMobile = window.innerWidth <= 500

        return (
            <div className={classes.root}>
                <div className={classes.firstLine}></div>
                <HourAlert />

                <Accordion
                    classes={{
                        expanded: classes.noMargin,
                        disabled: classes.accordionDisabled,
                    }}
                    expanded={this.props.accordionIncomeExpanded}
                    onChange={() => {
                        this.props.handleChangePanel('accordionIncomeExpanded')
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <div style={{ width: 25 }}>
                                {parseFloat(totalIncome) !== 0 && (
                                    <ExpandMoreIcon />
                                )}
                            </div>
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        classes={{
                            content: classes.accordionSummaryContent,
                        }}
                        disabled={parseFloat(totalIncome) == 0}
                    >
                        <Typography variant="inherit">
                            {this.displayText('income')}
                        </Typography>
                        <Typography variant="inherit">
                            {formatter.format(totalIncome)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionContent}>
                        <div className={classes.expense}>
                            <div className={classes.expenseBody}>
                                <div className={classes.row}>
                                    <div
                                        className={[
                                            classes.graph,
                                            classes.doughnut,
                                        ].join(' ')}
                                    >
                                        {this._renderIncomePieChart()}
                                    </div>
                                    <div
                                        className={[
                                            classes.graph,
                                            classes.bar,
                                        ].join(' ')}
                                    >
                                        {this._renderIncomeBarChart()}
                                    </div>
                                </div>
                                <div className={classes.compte}>
                                    {eventTypes.map((type, index) => {
                                        let total = this.getTotalIncome(type.id)

                                        if (
                                            total === null ||
                                            parseFloat(total) === 0
                                        )
                                            return null

                                        return (
                                            <div
                                                className={[
                                                    classes.line,
                                                    total === 0
                                                        ? classes.inactive
                                                        : undefined,
                                                ].join(' ')}
                                                key={index}
                                            >
                                                <img
                                                    src={
                                                        type.photo
                                                            ? require(`images/EventBobIcon/${type.photo}`)
                                                            : ''
                                                    }
                                                    alt={this.displayTextApi(
                                                        type.translation,
                                                    )}
                                                    width={
                                                        isMobile
                                                            ? '20px'
                                                            : '30px'
                                                    }
                                                    height={
                                                        isMobile
                                                            ? '20px'
                                                            : '30px'
                                                    }
                                                    className={classes.icon}
                                                />
                                                <div className={classes.texts}>
                                                    <Typography
                                                        className={classes.text}
                                                    >
                                                        {this.displayTextApi(
                                                            type.translation,
                                                        )}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    classes={{
                        expanded: classes.noMargin,
                        disabled: classes.accordionDisabled,
                    }}
                    expanded={this.props.accordionExpenseExpanded}
                    onChange={() => {
                        this.props.handleChangePanel('accordionExpenseExpanded')
                    }}
                >
                    <AccordionSummary
                        expandIcon={
                            <div style={{ width: 25 }}>
                                {parseFloat(totalExpense) !== 0 && (
                                    <ExpandMoreIcon />
                                )}
                            </div>
                        }
                        aria-controls="panel2-content"
                        id="panel2-header"
                        classes={{
                            content: classes.accordionSummaryContent,
                        }}
                        disabled={parseFloat(totalExpense) === 0}
                    >
                        <Typography variant="inherit">
                            {this.displayText('expense')}
                        </Typography>
                        <Typography variant="inherit">
                            {formatter.format(totalExpense)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionContent}>
                        <div className={classes.expense}>
                            <div className={classes.expenseBody}>
                                <div className={classes.row}>
                                    <div
                                        className={[
                                            classes.graph,
                                            classes.doughnut,
                                        ].join(' ')}
                                    >
                                        {this._renderExpensePieChart()}
                                    </div>
                                    <div
                                        className={[
                                            classes.graph,
                                            classes.bar,
                                        ].join(' ')}
                                    >
                                        {this._renderExpenseBarChart()}
                                    </div>
                                </div>
                                <div className={classes.compte}>
                                    {eventTypes.map((type, index) => {
                                        let total = this.getTotalExpense(
                                            type.id,
                                        )

                                        if (
                                            total === null ||
                                            parseFloat(total) === 0
                                        )
                                            return null

                                        return (
                                            <div
                                                className={[
                                                    classes.line,
                                                    total === 0
                                                        ? classes.inactive
                                                        : undefined,
                                                ].join(' ')}
                                                key={index}
                                            >
                                                <img
                                                    src={
                                                        type.photo
                                                            ? require(`images/EventBobIcon/${type.photo}`)
                                                            : ''
                                                    }
                                                    alt={this.displayTextApi(
                                                        type.translation,
                                                    )}
                                                    width={
                                                        isMobile
                                                            ? '20px'
                                                            : '30px'
                                                    }
                                                    height={
                                                        isMobile
                                                            ? '20px'
                                                            : '30px'
                                                    }
                                                    className={classes.icon}
                                                />
                                                <div className={classes.texts}>
                                                    <Typography
                                                        className={classes.text}
                                                    >
                                                        {this.displayTextApi(
                                                            type.translation,
                                                        )}
                                                    </Typography>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        boats: state.fetch.boats,
        documents: state.bob.documents,
        bob: state.bob,
        user: state.authentication.user,
        loading: state.bob.loading,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        bobFilter: state.filter.bobFilter,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BudgetTab))
