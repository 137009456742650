import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnModalCss'
import Dialog from '@material-ui/core/Dialog'
import { Slide, withWidth } from '@material-ui/core'
import _uniqueId from 'lodash/uniqueId'
import { Crisp } from 'crisp-sdk-web'
import { fixIosHeaderBug } from 'utils/usefullFunctions'

const Transition = React.forwardRef(function Transition(props, ref) {
    const { side } = props
    let dir = `up`
    if (side) {
        dir =
            side === `down`
                ? `up`
                : side === `right`
                ? `left`
                : side === `left`
                ? `right`
                : `up`
    }
    return <Slide direction={dir} ref={ref} {...props} />
})

class BoatOnModal extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.handleClose = this.handleClose.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { openCondition } = this.props
        if (openCondition !== false && prevProps.openCondition === false)
            Crisp.chat.hide()
        else if (openCondition === false && prevProps.openCondition !== false)
            Crisp.chat.show()
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.openCondition === nextProps.openCondition) {
            return false
        }
        return true
    }

    handleClose(...args) {
        const { handleClose } = this.props

        if (!handleClose) return

        fixIosHeaderBug()

        handleClose(...args)
    }

    render() {
        const {
            openCondition,
            handleClose,
            modalCores,
            maxWidth,
            fullScreenMobile,
            titles,
            classes,
            width,
            side,
            fullWidth = true,
            disableEnforceFocus,
        } = this.props
        if (openCondition === undefined || !handleClose || !modalCores) {
            console.error(
                'missing parameters: ',
                'openCondition:',
                openCondition === undefined,
                'handleClose:',
                !handleClose,
                'modalCores:',
                !modalCores,
            )
            return <></>
        }

        const Core = props => {
            return (
                <>
                    {React.cloneElement(props.children, {
                        handleClose: this.handleClose,
                        title: props.title,
                    })}
                </>
            )
        }

        //side = up, down, right, left
        let finalClass = classes.root
        if (side) {
            finalClass = `${classes.root2} ${
                side === `down`
                    ? classes.down
                    : side === `right`
                    ? classes.right
                    : side === `left`
                    ? classes.left
                    : classes.up
            }`
        }

        return (
            <Dialog
                fullScreen={
                    fullScreenMobile &&
                    fullScreenMobile[openCondition] &&
                    (width === `sm` || width === `xs`)
                        ? fullScreenMobile[openCondition]
                        : false
                }
                fullWidth={fullWidth}
                maxWidth={(maxWidth && maxWidth[openCondition]) || `md`}
                open={openCondition ? true : false}
                onClose={this.handleClose}
                keepMounted
                TransitionComponent={Transition}
                scroll={
                    fullScreenMobile &&
                    fullScreenMobile[openCondition] &&
                    (width === `sm` || width === `xs`)
                        ? `paper`
                        : `paper`
                }
                TransitionProps={{
                    side,
                }}
                className={finalClass}
                classes={{
                    paperFullWidth: classes.paperFullWidth,
                    paperWidthFalse: classes.paperWidthFalse,
                    paperScrollPaper: finalClass,
                    paperFullScreen: classes.paperFullScreenMobile,
                }}
                aria-describedby={`dialog-describedby-${
                    titles[openCondition]
                }-${_uniqueId()}`}
                aria-labelledby={`dialog-labelledby-${titles[openCondition]}`}
                disableEnforceFocus
            >
                {openCondition && (
                    <Core
                        handleClose={handleClose}
                        children={modalCores[openCondition]}
                        title={titles[openCondition]}
                    />
                )}
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.fetch.loading,
    }
}

export default withWidth()(
    connect(mapStateToProps)(withStyles(styles)(BoatOnModal)),
)
