export const getAlterateTo = function(
    language,
    pathname,
    locale,
    messages,
    appStrings,
) {
    let routeKey = getCurrentRouteKey()
    if (!routeKey) return `/${language}`
    const route = appStrings[language][routeKey]

    var splitedPathName = pathname.split('/')
    splitedPathName.shift()
    let splitedRoute = route.split('/')

    var finalRoute = ''

    for (let index = 1; index < splitedRoute.length; index++) {
        let regex = /^:\w*$/
        finalRoute +=
            '/' +
            (regex.test(splitedRoute[index])
                ? splitedPathName[index]
                : splitedRoute[index])
    }
    return `/${language}` + finalRoute
}

export const getCurrentRouteKey = function(pathname, locale, messages) {
    const [, route] = pathname.split('/' + locale)

    return Object.keys(messages).find(key => {
        let message = messages[key]
        let regexMessage = new RegExp(
            '^' + message.replace(/:\w*/, '(\\w|-)*') + '(\\/?)$',
        )

        //message == route
        return regexMessage.test(route)
    })
}

export const getCurrentRootRouteKey = function(pathname, messages) {
    const [, routeRoot] = pathname.split(/(?=\/)/g)

    return Object.keys(messages).find(key => {
        let message = messages[key]
        let regexMessage = new RegExp(
            '^' + message.replace(/:\w*/, '(\\w|-)*') + '(\\/?)$',
        )
        return regexMessage.test(routeRoot)
    })
}

export const getContextFromUrl = function(pathname) {
    const [, context] = pathname.split('/')
    return context
}
