import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import styles from './PaidVacationsDefaultSettingsTabCss'
import dictionary from './PaidVacationsDefaultSettingsTabDico'
import { Switch, Typography } from '@material-ui/core'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert'
import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'

class PaidVacationDefaultSettingsTab extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.state = {
            timeout: null,
        }

        this.dictionary = dictionary
        this.frenchLocale =
            frFR.components.MuiLocalizationProvider.defaultProps.localeText
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {}

    render() {
        const { classes, settings } = this.props

        return (
            <>
                {/* Message d'information */}
                <BoatOnAlert
                    severity={`info`}
                    description={this.displayText('info')}
                />

                {/* Début de la période de calcul */}
                {this._renderChampsPeriodeCalcul()}

                {/* Nombre de jours de congés autorisés */}
                {this._renderChampNbCongesAutorises()}

                {/* Autoriser cumul congés */}
                <div className={classes.settingsRow}>
                    <Typography className={classes.settingsLabel}>
                        {this.displayText('authorizeCumulativeLeave')}
                    </Typography>
                    <Switch
                        color="primary"
                        onClick={e =>
                            this.props.updateSettings(
                                'allowPaidVacationAddition',
                                e.target.checked,
                            )
                        }
                        checked={settings.allowPaidVacationAddition}
                    />
                </div>
            </>
        )
    }

    _renderChampsPeriodeCalcul() {
        const { classes, settings } = this.props

        const date = dayjs()
            .month(settings.monthStartRefPeriod - 1)
            .date(settings.dayStartRefPeriod)
        return (
            <div className={classes.settingsRow}>
                <Typography className={classes.settingsLabel}>
                    {this.displayText('paidVacationsPeriodStartLabel')}
                </Typography>

                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={this.context}
                >
                    <DatePicker
                        openTo="month"
                        label={this.displayText('datePickerPlaceholder')}
                        views={['month', 'day']}
                        format={this.context === `fr` ? 'DD/MM' : 'MM/DD'}
                        localeText={
                            this.context === `fr`
                                ? this.frenchLocale
                                : undefined
                        }
                        value={date}
                        onChange={e => {
                            const month = dayjs(e).month() + 1
                            const day = dayjs(e).date()
                            if (month != settings.monthStartRefPeriod) {
                                this.props.updateSettings(
                                    'monthStartRefPeriod',
                                    month,
                                )
                            }
                            if (day != settings.dayStartRefPeriod) {
                                this.props.updateSettings(
                                    'dayStartRefPeriod',
                                    day,
                                )
                            }
                        }}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    _renderChampNbCongesAutorises() {
        const { classes, settings } = this.props

        return (
            <div className={classes.settingsRow}>
                <Typography className={classes.settingsLabel}>
                    {this.displayText('nbPaidVacationAllowedLabel')}
                </Typography>
                <BoatOnNumberField
                    isDecimal
                    variant="outlined"
                    type="number"
                    className={classes.settingsInput}
                    value={settings.nbPaidVacationAllowed}
                    onChange={e =>
                        this.props.updateSettings(
                            'nbPaidVacationAllowed',
                            parseInt(e.target.value),
                        )
                    }
                />
                <Typography>{this.displayText('days')}</Typography>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groups: state.group.groupsMembers,
        currentGroupId: state.group.currentGroupId,
        paidVacationSettings: state.settings.paidVacationSettings,
        settingsLoading: state.settings.loading,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        absences: state.absence.absences,
        absencesGroup: state.absence.absencesGroup,
        absenceLoading: state.absence.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupLoading: state.group.loading,
        groupPaidVacationsAllowed: state.absence.paidVacationsAllowed,
        workTimeGroups: state.settings.workTimeSettings,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(PaidVacationDefaultSettingsTab),
)
