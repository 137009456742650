import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import dictionary from './Dictionary/LogBookCardDico'

import boatIcon from '../../../../images/boat_front_icon.svg'
import seatIcon from '../../../../images/seat_icon.svg'

import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet'
import L from 'leaflet'

import startIcon from '../../../../images/logbook/icons/map_pin_start.svg'
import stopOverIcon from '../../../../images/logbook/icons/map_pin_stop.svg'
import endIcon from '../../../../images/logbook/icons/map_pin_end.svg'

import {
    Place as PlaceIcon,
    AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
} from '@mui/icons-material/'

import { getDistanceFromCoordinates } from './NavigationPage/Utils'

import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookCardCss'

/**
 * This component display card in LogBookPage
 * @date 09/04/2024 - 12:13:27
 *
 * @class LogBookCard
 * @typedef {LogBookCard}
 * @extends {BoatOnComponent}
 *
 * @param {String} key - key of the tag html
 * @param {Object} navigation - navigation (va disparaitre dans le futur)
 * @param {Function} onClick - callback called on click on card element
 */

class LogBookCard extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {}

        this.startIconL = new L.Icon({
            iconUrl: startIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })
        this.stopOverIconL = new L.Icon({
            iconUrl: stopOverIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })
        this.endIconL = new L.Icon({
            iconUrl: endIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })

        this.getMapData = this.getMapData.bind(this)
        this.getNavigationDuration = this.getNavigationDuration.bind(this)
        this.getDistanceFromCoord = this.getDistanceFromCoord.bind(this)
    }

    getNavigationDuration() {
        const { navigation } = this.props

        let totalMinutes = 0
        if (
            navigation.nbBoardingPassengers === null ||
            !navigation.delimitedDate.startDate
        )
            return '00h00'

        let start = new Date(navigation.delimitedDate?.startDate)
        let end = null

        navigation.stopOvers.forEach(stop => {
            end = new Date(stop.delimitedDate?.startDate)
            totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60

            start = null
            end = null

            if (stop.delimitedDate?.endDate)
                start = new Date(stop.delimitedDate?.endDate)
        })

        if (start && navigation.delimitedDate?.endDate) {
            end = new Date(navigation.delimitedDate?.endDate)
            totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60
        }

        const h = Math.trunc(totalMinutes / 60)
        const min = Math.trunc(totalMinutes % 60)
        return `${h < 10 ? '0' : ''}${h}h${min < 10 ? '0' : ''}${min}`
    }

    concatDates(date, time) {
        if (!date || !time) return null

        const currentDate = new Date(date)
        const currentTime = new Date(time)

        return new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDay(),
            currentTime.getHours(),
            currentTime.getMinutes(),
            0,
        )
    }

    getMapData() {
        const { navigation, aisData } = this.props

        const mapData = {
            points: [],
            minPoint: {
                lat: 0,
                lon: 0,
            },
            maxPoint: {
                lat: 0,
                lon: 0,
            },
            center: {
                lat: 0,
                lon: 0,
            },
        }

        if (
            navigation?.departureAddress?.latitude &&
            navigation.departureAddress?.longitude
        ) {
            mapData.points.push({
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            })
            mapData.maxPoint = {
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            }
            mapData.minPoint = {
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            }
        }

        if (navigation.stopOvers)
            navigation.stopOvers.forEach(stop => {
                if (stop?.address) {
                    mapData.points.push({
                        lat: stop.address?.latitude,
                        lon: stop.address?.longitude,
                    })
                    if (
                        stop.address?.latitude &&
                        stop.address?.latitude < mapData.minPoint.lat
                    ) {
                        mapData.minPoint.lat = stop.address?.latitude
                    }
                    if (
                        stop.address?.longitude &&
                        stop.address?.longitude < mapData.minPoint.lon
                    ) {
                        mapData.minPoint.lon = stop.address?.longitude
                    }

                    if (
                        stop.address?.latitude &&
                        stop.address?.latitude > mapData.maxPoint.lat
                    ) {
                        mapData.maxPoint.lat = stop.address?.latitude
                    }
                    if (
                        stop.address?.longitude &&
                        stop.address?.longitude > mapData.maxPoint.lon
                    ) {
                        mapData.maxPoint.lon = stop.address?.longitude
                    }
                }
            })

        if (
            navigation?.arrivalAddress?.latitude &&
            navigation.arrivalAddress?.longitude
        ) {
            mapData.points.push({
                lat: navigation.arrivalAddress?.latitude,
                lon: navigation.arrivalAddress?.longitude,
            })

            if (
                navigation.arrivalAddress?.latitude &&
                navigation.arrivalAddress?.latitude < mapData.minPoint.lat
            ) {
                mapData.minPoint.lat = navigation.arrivalAddress?.latitude
            }
            if (
                navigation.arrivalAddress?.longitude &&
                navigation.arrivalAddress?.longitude < mapData.minPoint.lon
            ) {
                mapData.minPoint.lon = navigation.arrivalAddress?.longitude
            }

            if (
                navigation.arrivalAddress?.latitude &&
                navigation.arrivalAddress?.latitude > mapData.maxPoint.lat
            ) {
                mapData.maxPoint.lat = navigation.arrivalAddress?.latitude
            }
            if (
                navigation.arrivalAddress?.longitude &&
                navigation.arrivalAddress?.longitude > mapData.maxPoint.lon
            ) {
                mapData.maxPoint.lon = navigation.arrivalAddress?.longitude
            }
        }

        mapData.center = {
            lon: mapData.maxPoint.lon - mapData.minPoint.lon,
            lat: mapData.maxPoint.lat - mapData.minPoint.lat,
        }

        mapData.minPoint = {
            lon: mapData.minPoint.lon - 0.05,
            lat: mapData.minPoint.lat - 0.05,
        }

        mapData.maxPoint = {
            lon:
                typeof mapData.maxPoint.lon === 'string'
                    ? parseFloat(mapData.maxPoint.lon) + 0.05
                    : mapData.maxPoint.lon + 0.05,
            lat:
                typeof mapData.maxPoint.lat === 'string'
                    ? parseFloat(mapData.maxPoint.lat) + 0.05
                    : mapData.maxPoint.lat + 0.05,
        }

        if (navigation.isAisUsed) {
            const selectedAis = aisData.map(data => ({
                lon: data.longitude,
                lat: data.latitude,
            }))

            selectedAis.forEach(point => {
                if (point.lon > mapData.maxPoint.lon)
                    mapData.maxPoint.lon = point.lon
                if (point.lat > mapData.maxPoint.lat)
                    mapData.maxPoint.lat = point.lat
                if (point.lon < mapData.minPoint.lon)
                    mapData.minPoint.lon = point.lon
                if (point.lat < mapData.minPoint.lat)
                    mapData.minPoint.lat = point.lat
            })
        }

        return mapData
    }

    _renderHeader() {
        const { classes, context, navigation, currentBoat } = this.props
        const displayedStartDate = new Date(
            navigation.delimitedDate.startDate,
        ).toLocaleDateString(context)
        const localisation = `${this.getTextFromAddress(
            navigation.departureAddress,
        )} ${navigation.arrivalAddress ? '-' : ''} ${this.getTextFromAddress(
            navigation.arrivalAddress,
        )}`

        return (
            <div className={classes.header}>
                <div className={classes.headerLeft}>
                    <img
                        src={boatIcon}
                        alt={this.displayText('boat')}
                        title={this.displayText('boat')}
                        className={classes.boatIcon}
                    />
                    <div>
                        <div className={classes.title}>{localisation}</div>
                        <div className={classes.subtitle}>
                            {currentBoat?.name || ''}
                        </div>
                    </div>
                </div>
                <div className={classes.infoWrapper}>
                    <div className={classes.infoLabel}>
                        {this.displayText('date')}
                    </div>
                    <div className={classes.infoValue}>
                        {displayedStartDate}
                    </div>
                </div>
            </div>
        )
    }

    getTextFromAddress(address) {
        if (address?.city) return address.city
        if (address?.fullText) return address.fullText?.split(',')[0]
        if (address?.latitude && address?.longitude)
            return `${address.latitude}, ${address.longitude}`
        return ''
    }

    getDistanceFromCoord() {
        const { aisData, navigation } = this.props

        if (navigation.nbDepartingPassengers === null) return 0

        const selectedAis = []

        if (navigation.departureAddress)
            selectedAis.push([
                navigation.departureAddress.latitude,
                navigation.departureAddress.longitude,
            ])

        aisData.forEach(data =>
            selectedAis.push([data.latitude, data.longitude]),
        )

        const kmDistance = getDistanceFromCoordinates(selectedAis)

        return Math.round(kmDistance * 0.539957 * 100) / 100 //convert km to miles
    }

    _renderData() {
        const { classes, linkedUsers, isMobile, navigation } = this.props
        const captain = linkedUsers.find(
            user => user.id === navigation.captainLinkId,
        )
        const captainFistName =
            captain?.user?.firstName || captain?.userSubscribe?.mail || ''
        const captainLastName = captain?.user?.lastName || ''

        let passengers = navigation.nbDepartingPassengers
        const stopovers =
            navigation.stopOvers?.map((stop, index) => {
                passengers += stop.nbBoardingPassengers
                passengers -= stop.nbLeavingPassengers

                return (
                    <div
                        className={classes.stepContainer}
                        key={`stop-${index}`}
                    >
                        <PlaceIcon className={classes.placeIcon} />
                        <div className={classes.stepName}>
                            {this.getTextFromAddress(stop.address)}
                        </div>
                        <div className={classes.seats}>
                            <AirlineSeatReclineNormalIcon
                                className={classes.seatIcon}
                            />
                            {passengers}
                        </div>
                    </div>
                )
            }) || []

        const crew = navigation.navigationMembers.map((user, index) => {
            const crewM = linkedUsers.find(
                linkedUser => linkedUser.id === user.userLinkId,
            )

            if (!crewM) return null

            const crewMFistName =
                crewM.user?.firstName || crewM.userSubscribe?.mail || ''
            const crewMLastName = crewM.user?.lastName || ''

            return (
                <div className={classes.badge} key={`userbadge-${index}`}>
                    {crewMFistName?.charAt(0).toUpperCase()}
                    {crewMLastName?.charAt(0).toUpperCase()}
                </div>
            )
        })

        const navigationStatus =
            navigation.delimitedDate?.startDate &&
            navigation.nbDepartingPassengers !== null
                ? navigation.delimitedDate?.endDate
                    ? 'ended'
                    : 'in progress'
                : 'not started'

        const classContainer = navigation.isAisUsed
            ? navigationStatus !== 'ended'
                ? classes.dataStatus
                : classes.data
            : navigationStatus !== 'ended'
            ? classes.dataNoDistanceStatus
            : classes.dataNoDistance

        return (
            <div className={classContainer}>
                {navigationStatus === 'in progress' && (
                    <div
                        className={`${classes.navigationStatus} ${classes.inProgress}`}
                    >
                        {this.displayText('inProgress')}
                    </div>
                )}
                {navigationStatus === 'not started' && (
                    <div
                        className={`${classes.navigationStatus} ${classes.notStarted}`}
                    >
                        {this.displayText('notStarted')}
                    </div>
                )}

                {navigationStatus !== 'not started' && navigation.isAisUsed && (
                    <div
                        className={classes.infoWrapper}
                        style={{ gridArea: 'distance' }}
                    >
                        <div className={classes.infoLabel}>
                            {this.displayText('distance')}
                        </div>
                        <div className={classes.infoValue}>
                            {`${this.getDistanceFromCoord()} ${this.displayText(
                                'nauticMille',
                            )}`}
                        </div>
                    </div>
                )}

                {navigationStatus !== 'not started' && (
                    <div
                        className={classes.infoWrapper}
                        style={{ gridArea: 'navigation-time' }}
                    >
                        <div className={classes.infoLabel}>
                            {this.displayText('duration')}
                        </div>
                        <div className={classes.infoValue}>
                            {this.getNavigationDuration()}
                        </div>
                    </div>
                )}

                <div style={{ gridArea: 'captain' }}>
                    {!isMobile && (
                        <div className={classes.infoLabel}>
                            {this.displayText('captain')}
                        </div>
                    )}
                    <div className={classes.captainWrapper}>
                        <div className={classes.captainBadge}>
                            {captainFistName?.charAt(0).toUpperCase()}
                            {captainLastName?.charAt(0).toUpperCase()}
                        </div>
                        {captainFistName} {captainLastName}
                    </div>
                </div>

                {isMobile && navigationStatus !== 'not started' && (
                    <div
                        className={classes.passengers}
                        style={{ gridArea: 'passengers' }}
                    >
                        <img
                            src={seatIcon}
                            alt={this.displayText('passengers')}
                            title={this.displayText('passengers')}
                            className={classes.passengersIcon}
                        />
                        {navigation.nbDepartingPassengers}
                    </div>
                )}

                {!isMobile && (
                    <>
                        {crew.length > 0 && (
                            <div
                                className={classes.crewContainer}
                                style={{ gridArea: 'crew' }}
                            >
                                <div className={classes.infoLabel}>
                                    {this.displayText('crew')}
                                </div>
                                <div className={classes.badgesContainer}>
                                    {crew}
                                </div>
                            </div>
                        )}

                        {navigationStatus !== 'not started' && (
                            <div style={{ gridArea: 'passengers' }}>
                                <div className={classes.infoLabel}>
                                    {this.displayText('passengersOnBoard')}
                                </div>
                                <div className={classes.placeContainer}>
                                    <div className={classes.stepContainer}>
                                        <PlaceIcon
                                            className={classes.placeIcon}
                                        />
                                        <div className={classes.stepName}>
                                            {this.getTextFromAddress(
                                                navigation.departureAddress,
                                            )}
                                        </div>
                                        <div className={classes.seats}>
                                            <AirlineSeatReclineNormalIcon
                                                className={classes.seatIcon}
                                            />
                                            {navigation.nbDepartingPassengers}
                                        </div>
                                    </div>
                                    {stopovers}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    _getAisData() {
        const { aisData, navigation } = this.props

        const aisPoints = []

        if (navigation.departureAddress)
            aisPoints.push([
                navigation.departureAddress.latitude,
                navigation.departureAddress.longitude,
            ])

        aisData
            .sort(
                (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
            )
            .forEach((data, index, array) => {
                aisPoints.push([data.latitude, data.longitude])
                const nextData =
                    index < array.length - 1 ? array[index + 1] : null

                const stopOvers =
                    !nextData && navigation?.stopOvers?.length > 0
                        ? navigation.stopOvers.filter(
                              (stop, index, stopOvers) =>
                                  index + 1 === stopOvers.length &&
                                  stop.delimitedDate.startDate &&
                                  new Date(stop.delimitedDate.startDate) >
                                      new Date(data.createdAt),
                          )
                        : navigation?.stopOvers?.length > 0
                        ? navigation.stopOvers.filter(
                              stop =>
                                  stop.delimitedDate.startDate &&
                                  new Date(nextData.createdAt) >
                                      new Date(stop.delimitedDate.startDate) &&
                                  new Date(stop.delimitedDate.startDate) >
                                      new Date(data.createdAt),
                          )
                        : null

                if (stopOvers && stopOvers.length > 0) {
                    stopOvers.forEach(stop => {
                        aisPoints.push([
                            stop.address.latitude,
                            stop.address.longitude,
                        ])
                    })
                }
            })

        if (navigation.arrivalAddress)
            aisPoints.push([
                navigation.arrivalAddress.latitude,
                navigation.arrivalAddress.longitude,
            ])

        return aisPoints
    }

    _renderMap() {
        const { navigation } = this.props
        const { classes } = this.props
        const mapData = this.getMapData()
        const markers = mapData.points.map((point, index) => {
            if (index === 0) {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.startIconL}
                    />
                )
            } else if (
                index < mapData.points.length - 1 ||
                !navigation.delimitedDate.endDate
            ) {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.stopOverIconL}
                    />
                )
            } else {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.endIconL}
                    />
                )
            }
        })
        let aisPoints = []

        if (navigation.isAisUsed) aisPoints = this._getAisData()

        return (
            <div className={classes.mapWrapper}>
                <MapContainer
                    center={mapData.center}
                    bounds={[
                        [mapData.minPoint.lat, mapData.minPoint.lon],
                        [mapData.maxPoint.lat, mapData.maxPoint.lon],
                    ]}
                    maxZoom={13}
                    scrollWheelZoom={false}
                    dragging={false}
                >
                    <TileLayer
                        attribution={`&copy;
                            <a href="https://www.openstreetmap.org/copyright">
                                OpenStreetMap
                            </a> contributors`}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {navigation.nbDepartingPassengers !== null && (
                        <Polyline
                            pathOptions={{
                                color: '#303F9F',
                                bubblingMouseEvents: false,
                            }}
                            positions={aisPoints}
                        />
                    )}
                    {markers}
                </MapContainer>
            </div>
        )
    }

    render() {
        const { classes, navigation, onClick } = this.props

        return (
            <div
                className={classes.root}
                onClick={() => onClick(navigation.id)}
            >
                {this._renderHeader()}
                <div className={classes.dataWrapper}>
                    {this._renderData()}
                    {this._renderMap()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    const isMobile = window.innerWidth < 600
    const groupBoats = state.group?.groupsMembers?.boats || []

    const currentBoat =
        groupBoats.find(boat => boat.id === ownProps.navigation.boatId) || null

    const currentAis =
        (state.navigationApiData.ais || []).filter(
            ais => ais.navigationId === ownProps.navigation.id,
        )[0] || null

    return {
        context,
        isMobile,
        groupBoats,
        currentBoat,
        aisData: currentAis?.aisData || [],
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookCard))
