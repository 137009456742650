import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'
import BoatOnDateSelector from '../../../../common/UsefullComponents/BoatOnDateSelector'
import AddressSearch from '../../../../RentParkingPage/RentNavigationPages/AddressSearch'
import { Button } from '../../../../common/BoatOnButton'
import DisplayerImgModal from '../../../DisplayerImgModal'
import { withStyles } from '@material-ui/core/styles'
import { Divider, TextField } from '@material-ui/core'
import { InputAdornment } from '@mui/material'
import { MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import {
    Place as PlaceIcon,
    Add as AddIcon,
    AddAPhoto as AddAPhotoIcon,
    DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material/'
import {
    getImageToDisplay,
    addFileToBobEvent,
    deleteFileFromBobEvent,
    postDataBOBDocuments,
    removeDataBOBDocuments,
} from '../../../../../actions/bob.actions'
import { editNavigation } from '../../../../../actions'

import dictionary from './Dictionary/LogBookManualSetupModalDico'
import styles from './Styles/LogBookManualSetupModalCss'

class LogBookManualSetupModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            navigation: {
                nbDepartingPassengers: null,
                departureAddress: null,
                arrivalAddress: null,
                stopOvers: [],
                comment: '',
                delimitedDate: {
                    startDate: null,
                    endDate: null,
                    comment: '',
                },
                files: [],
            },

            loadingImage: false,
            error: false,

            fileNoUrl: { navigation: [] },
            displayNewFiles: { navigation: [] },
            deletedFiles: { navigation: [] },
        }

        this.inputDocRef = React.createRef()

        this.setTravelData = this.setTravelData.bind(this)
        this.setStopoverData = this.setStopoverData.bind(this)
        this.minimalDateAuthorized = this.minimalDateAuthorized.bind(this)
        this.minimalTravelTimeAuthorized = this.minimalTravelTimeAuthorized.bind(
            this,
        )
        this.getCloseDatePrevious = this.getCloseDatePrevious.bind(this)
        this.getCloseDateNext = this.getCloseDateNext.bind(this)
        this.maximalDateAuthorized = this.maximalDateAuthorized.bind(this)
        this.maximalTravelTimeAuthorized = this.maximalTravelTimeAuthorized.bind(
            this,
        )
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.addStopover = this.addStopover.bind(this)
        this.stopoverDateAuthorized = this.stopoverDateAuthorized.bind(this)
        this.stopoverTimeAuthorized = this.stopoverTimeAuthorized.bind(this)
        this.checkRequiredFields = this.checkRequiredFields.bind(this)
        this.setDateTravelData = this.setDateTravelData.bind(this)
        this.uploadNewImages = this.uploadNewImages.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        const { navigation } = this.props
        if (navigation) {
            this.setState({
                navigation,
            })
        } else {
            const now = new Date()
            this.setState({
                navigation: {
                    ...this.state.navigation,
                    delimitedDate: {
                        startDate: now,
                        endDate: null,
                        comment: '',
                    },
                },
            })
        }
    }

    checkRequiredFields() {
        const { navigation } = this.state

        const startDate = navigation?.delimitedDate?.startDate
            ? new Date(navigation.delimitedDate.startDate)
            : null
        const today = new Date().setHours(0, 0, 0, 0)
        let isValid = true

        if (
            !navigation.departureAddress ||
            navigation.departureAddress.fullText === '' ||
            !navigation.delimitedDate.startDate
        )
            isValid = false

        navigation.stopOvers.forEach((stop, index) => {
            if (this.passagersNumberOk(index)) isValid = false
            if (!stop.address || stop.address.fullText === '') isValid = false
        })

        if (
            ((navigation.arrivalAddress &&
                navigation.arrivalAddress.fullText) ||
                navigation.delimitedDate.endDate) &&
            (!(
                navigation.arrivalAddress && navigation.arrivalAddress.fullText
            ) ||
                !navigation.delimitedDate.endDate)
        )
            isValid = false

        if (navigation.arrivalAddress && navigation.arrivalAddress.fullText) {
            navigation.stopOvers.forEach(stop => {
                if (
                    !stop.delimitedDate.startDate ||
                    !stop.delimitedDate.endDate
                )
                    isValid = false
            })
        }

        if (startDate < today && navigation.nbDepartingPassengers === null)
            isValid = false

        this.setState({ error: !isValid })

        return isValid
    }

    async uploadNewImages() {
        const { fileNoUrl, deletedFiles, navigation } = this.state
        let files = []

        if (fileNoUrl.navigation && fileNoUrl.navigation.length > 0)
            files = await postDataBOBDocuments(fileNoUrl.navigation, 1)

        files.forEach(file => ({
            ...file,
            file_type_id: 1,
        }))

        removeDataBOBDocuments(deletedFiles.navigation)

        return [...navigation.files, ...files]
    }

    async save() {
        const { navigation } = this.state
        const { handleValid, onClose, dispatch } = this.props
        if (this.checkRequiredFields()) {
            navigation.files = await this.uploadNewImages()

            if (handleValid) {
                handleValid(navigation)
                return
            }

            dispatch(editNavigation(navigation))
            onClose()
        }
    }

    setTravelData(key, value) {
        const navigation = { ...this.state.navigation }

        navigation[key] = value

        this.setState({
            navigation,
            error: false,
        })
    }

    setDateTravelData(name, type, date) {
        const { navigation } = this.state
        const stopOvers = [...navigation.stopOvers]

        date = date ? new Date(date) : null

        if (!navigation.delimitedDate[name]) {
            navigation.delimitedDate[name] = new Date()
            navigation.delimitedDate[name].setSeconds(0)
            navigation.delimitedDate[name].setMilliseconds(0)
        } else {
            navigation.delimitedDate[name] = new Date(
                navigation.delimitedDate[name],
            )
        }

        if (date && type === 'date') {
            navigation.delimitedDate[name].setYear(date.getFullYear())
            navigation.delimitedDate[name].setMonth(date.getMonth())
            navigation.delimitedDate[name].setDate(date.getDate())
        } else if (date && type === 'time') {
            navigation.delimitedDate[name].setHours(date.getHours())
            navigation.delimitedDate[name].setMinutes(date.getMinutes())
        }

        const startDate = new Date(navigation.delimitedDate.startDate)
        const endDate = new Date(navigation.delimitedDate.endDate)

        if (name === 'startDate') {
            if (navigation.delimitedDate.endDate && startDate > endDate) {
                navigation.delimitedDate[name].setHours(endDate.getHours())
                navigation.delimitedDate[name].setMinutes(endDate.getMinutes())
            }

            const firstStopOver =
                stopOvers.sort(
                    (a, b) =>
                        new Date(a.delimitedDate.startDate) -
                        new Date(b.delimitedDate.startDate),
                )[0] || null

            if (
                firstStopOver?.delimitedDate?.startDate &&
                startDate > new Date(firstStopOver?.delimitedDate?.startDate)
            ) {
                navigation.delimitedDate[name] = new Date(
                    firstStopOver?.delimitedDate?.startDate,
                )
            }
        } else if (name === 'endDate') {
            if (navigation.delimitedDate.startDate && endDate < startDate) {
                navigation.delimitedDate[name].setHours(startDate.getHours())
                navigation.delimitedDate[name].setMinutes(
                    startDate.getMinutes(),
                )
            }

            const lastStopOver =
                stopOvers.sort(
                    (a, b) =>
                        new Date(b.delimitedDate.startDate) -
                        new Date(a.delimitedDate.startDate),
                )[0] || null

            if (
                lastStopOver?.delimitedDate?.endDate &&
                endDate < new Date(lastStopOver?.delimitedDate?.endDate)
            ) {
                navigation.delimitedDate[name] = new Date(
                    lastStopOver?.delimitedDate?.endDate,
                )
            }
        }

        this.setState({
            navigation,
            error: false,
        })
    }

    setStopoverData(index, key, value) {
        const { navigation } = this.state

        navigation.stopOvers[index][key] = value

        this.setState({
            navigation,
            error: false,
        })
    }

    setStopoverDate(index, name, type, date) {
        const navigation = JSON.parse(JSON.stringify(this.state.navigation))
        date = new Date(date)

        if (!navigation.stopOvers[index].delimitedDate[name]) {
            navigation.stopOvers[index].delimitedDate[name] = new Date()
            navigation.stopOvers[index].delimitedDate[name].setSeconds(0)
            navigation.stopOvers[index].delimitedDate[name].setMilliseconds(0)
        } else {
            navigation.stopOvers[index].delimitedDate[name] = new Date(
                navigation.stopOvers[index].delimitedDate[name],
            )
        }

        if (type === 'date') {
            navigation.stopOvers[index].delimitedDate[name].setYear(
                date.getFullYear(),
            )
            navigation.stopOvers[index].delimitedDate[name].setMonth(
                date.getMonth(),
            )
            navigation.stopOvers[index].delimitedDate[name].setDate(
                date.getDate(),
            )
        } else if (type === 'time') {
            navigation.stopOvers[index].delimitedDate[name].setHours(
                date.getHours(),
            )
            navigation.stopOvers[index].delimitedDate[name].setMinutes(
                date.getMinutes(),
            )
        }

        let startDate = new Date(
            navigation.stopOvers[index].delimitedDate.startDate,
        )
        let endDate = new Date(
            navigation.stopOvers[index].delimitedDate.endDate,
        )

        const startDateNavigation = new Date(navigation.delimitedDate.startDate)
        const endDateNavigation = new Date(navigation.delimitedDate.endDate)

        if (name === 'startDate') {
            if (
                navigation.stopOvers[index].delimitedDate.endDate &&
                startDate > endDate &&
                startDateNavigation < startDate &&
                endDateNavigation > startDate
            ) {
                navigation.stopOvers[index].delimitedDate.startDate.setHours(
                    endDate.getHours(),
                )
                navigation.stopOvers[index].delimitedDate.startDate.setMinutes(
                    endDate.getMinutes(),
                )
            } else if (
                navigation.delimitedDate.startDate &&
                startDateNavigation > startDate
            ) {
                navigation.stopOvers[
                    index
                ].delimitedDate.startDate = startDateNavigation
            }
        } else if (name === 'endDate') {
            if (
                navigation.stopOvers[index].delimitedDate.startDate &&
                endDate < startDate &&
                startDateNavigation < endDate &&
                endDateNavigation > endDate
            ) {
                navigation.stopOvers[index].delimitedDate.endDate.setHours(
                    startDate.getHours(),
                )
                navigation.stopOvers[index].delimitedDate.endDate.setMinutes(
                    startDate.getMinutes(),
                )
            } else if (
                navigation.delimitedDate.endDate &&
                endDateNavigation < endDate
            ) {
                navigation.stopOvers[
                    index
                ].delimitedDate.endDate = endDateNavigation
            }
        }

        startDate = new Date(
            navigation.stopOvers[index].delimitedDate.startDate,
        )
        endDate = new Date(navigation.stopOvers[index].delimitedDate.endDate)

        navigation.stopOvers.forEach((stop, number) => {
            if (number === index) return

            if (stop.delimitedDate.startDate && stop.delimitedDate.endDate) {
                const stopStart = new Date(stop.delimitedDate.startDate)
                const stopEnd = new Date(stop.delimitedDate.endDate)

                if (
                    name === 'startDate' &&
                    stopStart < startDate &&
                    startDate < stopEnd
                ) {
                    if (
                        startDate.getTime() - stopStart.getTime() >
                        stopEnd.getTime() - startDate.getTime()
                    )
                        navigation.stopOvers[index].delimitedDate[
                            name
                        ] = stopEnd
                    else
                        navigation.stopOvers[index].delimitedDate[
                            name
                        ] = stopStart
                } else if (
                    name === 'endDate' &&
                    stopStart < endDate &&
                    endDate < stopEnd
                ) {
                    if (
                        startDate.getTime() - stopStart.getTime() >
                        stopEnd.getTime() - startDate.getTime()
                    )
                        navigation.stopOvers[index].delimitedDate[
                            name
                        ] = stopEnd
                    else
                        navigation.stopOvers[index].delimitedDate[
                            name
                        ] = stopStart
                }
            }
        })
        this.setState({
            navigation,
            error: false,
        })
    }

    minimalDateAuthorized(day) {
        const { navigation } = this.state
        let minimalDay = null

        if (navigation.delimitedDate.endDate)
            minimalDay = new Date(navigation.delimitedDate.endDate)

        navigation.stopOvers.forEach(stop => {
            if (
                !minimalDay ||
                new Date(stop.delimitedDate.startDate) < minimalDay
            )
                minimalDay = new Date(stop.delimitedDate.startDate)
        })

        if (!minimalDay || minimalDay >= day) return false
        return true
    }

    maximalDateAuthorized(day) {
        const { navigation } = this.state
        let maximalDay = null

        if (navigation.delimitedDate.startDate)
            maximalDay = new Date(navigation.delimitedDate.startDate)

        navigation.stopOvers.forEach(stop => {
            if (
                !maximalDay ||
                new Date(stop.delimitedDate.endDate) > maximalDay
            )
                maximalDay = new Date(stop.delimitedDate.endDate)
        })

        if (maximalDay) {
            maximalDay.setHours(0, 0, 0, 0)
            day.setHours(0, 0, 0, 0)
        }

        if (!maximalDay || maximalDay <= day) {
            return false
        }
        return true
    }

    minimalTravelTimeAuthorized(day, view) {
        const { navigation } = this.state
        let isValid = true
        const currentDay = day.$d

        if (currentDay && view === 'hour') currentDay.setMinutes(0, 0, 0)

        if (!navigation.delimitedDate.startDate) return false

        if (
            navigation.delimitedDate.endDate &&
            navigation.delimitedDate.endDate <= currentDay
        )
            isValid = false

        navigation.stopOvers.forEach(stop => {
            if (
                stop.delimitedDate.startDate &&
                stop.delimitedDate.startDate < currentDay
            )
                isValid = false
            if (
                stop.delimitedDate.endDate &&
                stop.delimitedDate.endDate < currentDay
            )
                isValid = false
        })

        return !isValid
    }

    maximalTravelTimeAuthorized(day) {
        const { navigation } = this.state
        let isValid = true

        if (!navigation.delimitedDate.endDate) return false

        if (
            navigation.delimitedDate.startDate &&
            navigation.delimitedDate.startDate > day
        )
            isValid = false

        navigation.stopOvers.forEach(stop => {
            if (day <= stop.delimitedDate.endDate) isValid = false
        })

        return !isValid
    }

    getCloseDatePrevious(startDate, endDate) {
        const { navigation } = this.state

        let closeDate = null

        navigation.stopOvers
            .sort(
                (a, b) =>
                    new Date(a.delimitedDate.startDate) -
                    new Date(b.delimitedDate.startDate),
            )
            .map(stop => {
                const stopStart = new Date(stop.delimitedDate.startDate)
                const stopEnd = new Date(stop.delimitedDate.endDate)

                if (
                    stopStart.getTime() - startDate.getTime() === 0 &&
                    stopEnd.getTime() - endDate.getTime() === 0
                )
                    return

                if (stop.delimitedDate.endDate && stopEnd < endDate) {
                    closeDate = stopEnd
                }
            })
        return closeDate ? new Date(closeDate.setHours(0, 0, 0, 0)) : null
    }

    getCloseDateNext(startDate, endDate) {
        const { navigation } = this.state

        let closeDate = null

        navigation.stopOvers
            .sort(
                (a, b) =>
                    new Date(a.delimitedDate.startDate) -
                    new Date(b.delimitedDate.startDate),
            )
            .map(stop => {
                const stopStart = new Date(stop.delimitedDate.startDate)
                const stopEnd = new Date(stop.delimitedDate.endDate)

                if (
                    stopStart.getTime() - startDate.getTime() === 0 &&
                    stopEnd.getTime() - endDate.getTime() === 0
                )
                    return

                if (
                    closeDate === null &&
                    stop.delimitedDate.startDate &&
                    stopStart > startDate
                ) {
                    closeDate = stopStart
                }
            })

        return closeDate ? new Date(closeDate.setHours(0, 0, 0, 0)) : null
    }

    stopoverDateAuthorized(day, index, isStartDate = false) {
        const { navigation } = this.state
        const currentStopover = navigation.stopOvers[index]
        let isValid = true
        let closeDate = null

        if (!currentStopover) return false

        // day not inferior travel.startdate
        if (navigation.delimitedDate.startDate) {
            const startDate = new Date(
                navigation.delimitedDate.startDate,
            ).setHours(0, 0, 0, 0)
            if (startDate > day) isValid = false
        }

        // day not superior travel.end
        if (
            navigation.delimitedDate.endDate &&
            new Date(navigation.delimitedDate.endDate) < day
        )
            isValid = false

        // day not in other stopovers
        navigation.stopOvers.forEach((stop, stopIndex) => {
            if (index === stopIndex) return

            if (
                stop.delimitedDate.startDate &&
                stop.delimitedDate.endDate &&
                new Date(stop.delimitedDate.startDate) < day &&
                new Date(stop.delimitedDate.endDate).setHours(0, 0, 0, 0) > day
            )
                isValid = false
        })

        if (isStartDate) {
            // day is > endDate of current stopover
            if (currentStopover.delimitedDate.endDate) {
                closeDate = this.getCloseDatePrevious(
                    new Date(currentStopover.delimitedDate.startDate),
                    new Date(currentStopover.delimitedDate.endDate),
                )

                if (new Date(currentStopover.delimitedDate.endDate) < day)
                    isValid = false
                else if (
                    closeDate &&
                    !(
                        closeDate <= day &&
                        day < new Date(currentStopover.delimitedDate.endDate)
                    )
                )
                    isValid = false
            }
        } else {
            // day is < startDate of current stopover
            if (currentStopover.delimitedDate.startDate) {
                const currentStart = new Date(
                    currentStopover.delimitedDate.startDate,
                ).setHours(0, 0, 0, 0)
                closeDate = this.getCloseDateNext(
                    new Date(currentStopover.delimitedDate.startDate),
                    new Date(currentStopover.delimitedDate.endDate),
                )

                if (new Date(currentStart) > day) isValid = false
                else if (
                    closeDate &&
                    !(new Date(currentStart) < day && day <= closeDate)
                )
                    isValid = false
            }
        }

        return !isValid
    }

    stopoverTimeAuthorized(time, view, index, isStartDate = false) {
        const { navigation } = this.state
        const currentStopover = navigation.stopOvers[index]
        const currentTime = new Date(time.$d)
        let isValid = true
        let currentStart = null
        let currentEnd = null

        if (!currentStopover) return false

        if (currentTime && view === 'hours') currentTime.setMinutes(0, 0, 0)

        //Check start travel
        if (navigation.delimitedDate.startDate)
            currentStart = new Date(navigation.delimitedDate.startDate)

        if (currentStart && view === 'hours') currentStart.setMinutes(0, 0, 0)

        if (currentTime < currentStart) isValid = false

        //Check end travel
        if (
            navigation.delimitedDate.endDate &&
            navigation.delimitedDate.endDate < currentTime
        )
            isValid = false

        //check stopovers
        navigation.stopOvers.forEach((stop, stopIndex) => {
            if (index === stopIndex) return
            //Check start stop
            if (stop.delimitedDate.startDate && stop.delimitedDate.endDate) {
                currentStart = new Date(stop.delimitedDate.startDate)
                currentEnd = new Date(stop.delimitedDate.endDate)

                if (currentStart && currentEnd && view === 'hours') {
                    currentStart.setMinutes(0, 0, 0)
                    currentEnd.setMinutes(0, 0, 0)
                }
                if (currentStart < currentTime && currentTime < currentEnd)
                    isValid = false
            }
        })

        //check start stopover
        if (isStartDate) {
            if (
                currentStopover.delimitedDate.endDate &&
                currentStopover.delimitedDate.endDate < currentTime
            )
                isValid = false
        } else {
            if (currentStopover.delimitedDate.startDate) {
                currentStart = new Date(currentStopover.delimitedDate.startDate)

                if (currentStart && view === 'hours') {
                    currentStart.setMinutes(0, 0, 0)
                }
                if (currentTime < currentStart) isValid = false
            }
        }

        return !isValid
    }

    deleteDocumentNoUp(i) {
        const {
            deletedFiles,
            displayNewFiles,
            fileNoUrl,
            navigation,
        } = this.state

        deleteFileFromBobEvent(this, i, {
            deletedFiles: deletedFiles.navigation,
            displayNewFiles: displayNewFiles.navigation,
            fileNoUrl: fileNoUrl.navigation,
            files: navigation.files,
            name: 'navigation',
        })
    }

    addDocument(event) {
        const { fileNoUrl, displayNewFiles } = this.state

        if (
            event.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: fileNoUrl.navigation,
                    displayNewFiles: displayNewFiles.navigation,
                    name: 'navigation',
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: fileNoUrl.navigation,
                displayNewFiles: displayNewFiles.navigation,
                name: 'navigation',
            })
        }
    }

    addStopover() {
        this.setState({
            navigation: {
                ...this.state.navigation,
                stopOvers: [
                    ...this.state.navigation.stopOvers,
                    {
                        delimitedDate: {
                            startDate: new Date(),
                            endDate: null,
                            comment: '',
                        },
                        address: null,
                        nbLeavingPassengers: 0,
                        nbBoardingPassengers: 0,
                    },
                ],
            },
        })
    }

    passagersNumberOk(index) {
        const { navigation } = this.state
        let passagers = parseInt(
            JSON.parse(JSON.stringify(navigation.nbDepartingPassengers)),
        )

        for (let i = 0; i < index + 1; i++) {
            if (navigation.nbDepartingPassengers !== null)
                if (
                    passagers -
                        parseInt(navigation.stopOvers[i].nbLeavingPassengers) >=
                    0
                ) {
                    passagers +=
                        parseInt(navigation.stopOvers[i].nbBoardingPassengers) -
                        parseInt(navigation.stopOvers[i].nbLeavingPassengers)
                } else return true
        }

        return false
    }

    deleteStopover(index) {
        const { navigation } = this.state

        navigation.stopOvers.splice(index, 1)

        this.setState({ navigation })
    }

    _renderStopovers() {
        const { navigation, error } = this.state

        const { classes } = this.props

        return navigation.stopOvers.map((stop, index) => (
            <div className={classes.stopoverItem} key={`stop-${index}`}>
                <div className={classes.stopoverTitle}>
                    {stop.address?.fullText
                        ? `${this.displayText(
                              'stopover',
                          )} - ${stop.address.fullText?.split(',')[0] || ''}`
                        : this.displayText('stopover')}
                    <div
                        className={classes.deleteContainer}
                        onClick={() => this.deleteStopover(index)}
                    >
                        <DeleteOutlineOutlinedIcon />
                        {this.displayText('deleteStopover')}
                    </div>
                </div>
                <AddressSearch
                    required
                    city={true}
                    type="city"
                    label={this.displayText('stopoverPlace')}
                    variant="outlined"
                    margin="dense"
                    style={{ width: '100%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PlaceIcon />
                            </InputAdornment>
                        ),
                    }}
                    setAddress={value =>
                        this.setStopoverData(index, 'address', value)
                    }
                    fullAddressInfo={stop.address}
                    error={
                        error && (!stop.address || stop.address.fullText === '')
                    }
                />
                <div className={classes.dateTimeSection}>
                    <div className={classes.inputDate}>
                        <BoatOnDateSelector
                            required
                            label={this.displayText('stopoverStartDate')}
                            onChange={value =>
                                this.setStopoverDate(
                                    index,
                                    'startDate',
                                    'date',
                                    value,
                                )
                            }
                            value={
                                stop.delimitedDate.startDate
                                    ? new Date(stop.delimitedDate.startDate)
                                    : null
                            }
                            disabledDay={d =>
                                this.stopoverDateAuthorized(d, index, true)
                            }
                            error={error && !stop.delimitedDate.startDate}
                        />
                    </div>
                    <MobileTimePicker
                        className={classes.inputTime}
                        label={this.displayText('stopoverStartTime')}
                        slotProps={{
                            textField: {
                                size: 'small',
                                margin: 'dense',
                                error: error && !stop.delimitedDate.startDate,
                            },
                        }}
                        onAccept={value =>
                            this.setStopoverDate(
                                index,
                                'startDate',
                                'time',
                                value?.$d,
                            )
                        }
                        value={
                            stop.delimitedDate.startDate
                                ? dayjs(stop.delimitedDate.startDate)
                                : ''
                        }
                        shouldDisableTime={(time, view) =>
                            this.stopoverTimeAuthorized(time, view, index, true)
                        }
                    />
                </div>
                <div className={classes.dateTimeSection}>
                    <div className={classes.inputDate}>
                        <BoatOnDateSelector
                            required
                            label={this.displayText('stopoverEndDate')}
                            onChange={value =>
                                this.setStopoverDate(
                                    index,
                                    'endDate',
                                    'date',
                                    value,
                                )
                            }
                            value={
                                stop.delimitedDate.endDate
                                    ? new Date(stop.delimitedDate.endDate)
                                    : null
                            }
                            disabledDay={d =>
                                this.stopoverDateAuthorized(d, index, false)
                            }
                            disabled={!stop.delimitedDate.startDate}
                            error={
                                error &&
                                navigation.delimitedDate.endDate &&
                                !stop.delimitedDate.endDate
                            }
                        />
                    </div>
                    <MobileTimePicker
                        className={classes.inputTime}
                        label={this.displayText('stopoverEndTime')}
                        slotProps={{
                            textField: {
                                size: 'small',
                                margin: 'dense',
                                error:
                                    error &&
                                    navigation.delimitedDate.endDate &&
                                    !stop.delimitedDate.endDate,
                            },
                        }}
                        onAccept={value =>
                            this.setStopoverDate(
                                index,
                                'endDate',
                                'time',
                                value?.$d,
                            )
                        }
                        value={
                            stop.delimitedDate.endDate
                                ? dayjs(stop.delimitedDate.endDate)
                                : ''
                        }
                        shouldDisableTime={(t, v) =>
                            this.stopoverTimeAuthorized(t, v, index, false)
                        }
                        disabled={!stop.delimitedDate.startDate}
                    />
                </div>
                <div className={classes.passengersContainer}>
                    <BoatOnNumberField
                        fullWidth
                        label={this.displayText('nbPassengersIn')}
                        variant="outlined"
                        margin="dense"
                        onChange={e =>
                            this.setStopoverData(
                                index,
                                'nbBoardingPassengers',
                                e.target.value,
                            )
                        }
                        value={stop.nbBoardingPassengers}
                    />
                    <BoatOnNumberField
                        fullWidth
                        label={this.displayText('nbPassengersOut')}
                        variant="outlined"
                        margin="dense"
                        onChange={e =>
                            this.setStopoverData(
                                index,
                                'nbLeavingPassengers',
                                e.target.value,
                            )
                        }
                        value={stop.nbLeavingPassengers}
                        error={error && this.passagersNumberOk(index)}
                        helperText={
                            error && this.passagersNumberOk(index)
                                ? this.displayText('nbPassengersOutError')
                                : ''
                        }
                    />
                </div>
            </div>
        ))
    }

    _body() {
        const { navigation, error, loadingImage, displayNewFiles } = this.state

        const { classes, id } = this.props

        const startDate = navigation?.delimitedDate?.startDate
            ? new Date(navigation.delimitedDate.startDate)
            : null
        const endDate = navigation?.delimitedDate?.endDate
            ? new Date(navigation.delimitedDate.endDate)
            : null

        const today = new Date().setHours(0, 0, 0, 0)

        let displayArrival = null

        if (!id || navigation?.delimitedDate?.endDate) {
            displayArrival = (
                <div className={classes.section}>
                    <div className={classes.titleSection}>
                        {this.displayText('arrival')}
                    </div>
                    <AddressSearch
                        required={
                            !!navigation.delimitedDate.endDate ||
                            !!navigation.arrivalAddress?.fullText
                        }
                        type="city"
                        city={true}
                        label={this.displayText('arrivalPlace')}
                        variant="outlined"
                        margin="dense"
                        style={{ width: '100%' }}
                        className={classes.inputAddress}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceIcon />
                                </InputAdornment>
                            ),
                        }}
                        setAddress={value =>
                            this.setTravelData('arrivalAddress', value)
                        }
                        fullAddressInfo={navigation.arrivalAddress}
                        error={error && !navigation.arrivalAddress?.fullText}
                    />
                    <div className={classes.dateTimeSection}>
                        <div className={classes.inputDate}>
                            <BoatOnDateSelector
                                required={
                                    !!navigation.delimitedDate.endDate ||
                                    !!navigation.arrivalAddress?.fullText
                                }
                                label={this.displayText('arrivalDate')}
                                onChange={value =>
                                    this.setDateTravelData(
                                        'endDate',
                                        'date',
                                        value,
                                    )
                                }
                                value={endDate}
                                disabledDay={this.maximalDateAuthorized}
                                disabled={!navigation.delimitedDate.startDate}
                                error={
                                    error && !navigation.delimitedDate?.endDate
                                }
                            />
                        </div>
                        <MobileTimePicker
                            label={this.displayText('arrivalTime')}
                            className={classes.inputTime}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    margin: 'dense',
                                    error:
                                        error &&
                                        !navigation.delimitedDate?.endDate,
                                    required:
                                        !!navigation.delimitedDate.endDate ||
                                        !!navigation.arrivalAddress?.fullText,
                                },
                            }}
                            onAccept={value =>
                                this.setDateTravelData(
                                    'endDate',
                                    'time',
                                    value.$d,
                                )
                            }
                            value={
                                navigation.delimitedDate.endDate
                                    ? dayjs(navigation.delimitedDate.endDate)
                                    : ''
                            }
                            shouldDisableTime={this.maximalTravelTimeAuthorized}
                            disabled={!navigation.delimitedDate.startDate}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className={classes.section}>
                    <div className={classes.titleSection}>
                        {this.displayText('departure')}
                    </div>
                    <AddressSearch
                        required
                        type="city"
                        label={this.displayText('departurePlace')}
                        variant="outlined"
                        margin="dense"
                        style={{ width: '100%' }}
                        city={true}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceIcon />
                                </InputAdornment>
                            ),
                        }}
                        setAddress={value =>
                            this.setTravelData('departureAddress', value)
                        }
                        fullAddressInfo={navigation.departureAddress}
                        error={
                            error &&
                            (!navigation.departureAddress ||
                                navigation.departureAddress.fullText === '')
                        }
                    />
                    <div className={classes.dateTimeSection}>
                        <div className={classes.inputDate}>
                            <BoatOnDateSelector
                                required
                                label={this.displayText('departureDate')}
                                onChange={value =>
                                    this.setDateTravelData(
                                        'startDate',
                                        'date',
                                        value,
                                    )
                                }
                                value={startDate}
                                disabledDay={this.minimalDateAuthorized}
                                error={error && !startDate}
                            />
                        </div>
                        <MobileTimePicker
                            className={classes.inputTime}
                            label={this.displayText('departureTime')}
                            slotProps={{
                                textField: {
                                    required: true,
                                    size: 'small',
                                    margin: 'dense',
                                    error:
                                        error &&
                                        !navigation.delimitedDate.startDate,
                                },
                            }}
                            onAccept={value =>
                                this.setDateTravelData(
                                    'startDate',
                                    'time',
                                    value.$d,
                                )
                            }
                            value={
                                navigation.delimitedDate.startDate
                                    ? dayjs(navigation.delimitedDate.startDate)
                                    : ''
                            }
                            shouldDisableTime={this.minimalTravelTimeAuthorized}
                        />
                    </div>

                    {(id || (startDate && startDate < today)) && (
                        <BoatOnNumberField
                            required
                            label={this.displayText('nbPassengers')}
                            variant="outlined"
                            margin="dense"
                            onChange={e =>
                                this.setTravelData(
                                    'nbDepartingPassengers',
                                    e.target.value,
                                )
                            }
                            error={
                                error &&
                                navigation.nbDepartingPassengers === null
                            }
                            value={navigation.nbDepartingPassengers}
                        />
                    )}
                </div>

                {(id || (startDate && startDate < today)) && (
                    <>
                        {navigation.stopOvers.length > 0 && (
                            <div className={classes.stopoverContainer}>
                                {this._renderStopovers()}
                            </div>
                        )}

                        <Button
                            primary
                            label={
                                <>
                                    <AddIcon />{' '}
                                    {this.displayText('addStopover')}
                                </>
                            }
                            classNameOverride={classes.stopoverBtn}
                            type="text"
                            size="large"
                            onClick={this.addStopover}
                        />

                        {displayArrival}

                        <Divider className={classes.divider} />

                        <TextField
                            multiline
                            label={this.displayText('captainJudgment')}
                            className={classes.captainJudgment}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            minRows={4}
                            onChange={e =>
                                this.setTravelData('comment', e.target.value)
                            }
                            value={navigation.comment}
                        />

                        <div className={classes.section}>
                            <div className={classes.titleSection}>
                                {this.displayText('photoAndDocument')}
                            </div>
                            <div
                                className={classes.documentContainer}
                                onClick={() => {
                                    if (this.inputDocRef?.current)
                                        this.inputDocRef.current.click()
                                }}
                            >
                                <AddAPhotoIcon className={classes.AddDocIcon} />
                            </div>
                            <input
                                onChange={this.addDocument}
                                type="file"
                                id="file"
                                ref={this.inputDocRef}
                                style={{ display: 'none' }}
                                multiple
                                required
                            />
                            <DisplayerImgModal
                                file={getImageToDisplay(
                                    navigation,
                                    displayNewFiles.navigation,
                                )}
                                deleteDocumentNoUp={this.deleteDocumentNoUp}
                                loadingImage={loadingImage}
                            />
                        </div>
                    </>
                )}
            </div>
        )
    }

    render() {
        const {
            title,
            noCross = true,
            showFollowButton,
            onCancel,
            onClose,
            navigation,
        } = this.props

        let actions = [
            {
                action: this.save,
                label: this.displayText('save'),
            },
        ]

        if (onCancel && navigation?.delimitedDate?.endDate) {
            actions = [
                {
                    action: onCancel,
                    label: this.displayText('back'),
                    type: 'text',
                },
                {
                    action: this.save,
                    label: this.displayText('save'),
                },
            ]
        } else if (onClose || onCancel) {
            actions = [
                {
                    action: onClose ? onClose : onCancel,
                    label: this.displayText('back'),
                    type: 'text',
                },
                {
                    action: this.save,
                    label: this.displayText('save'),
                },
            ]
        }

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions(actions)}
            </>
        )
    }
}

function mapStateToProps(state, ownProps) {
    let navigation = null

    if (ownProps.id)
        navigation = state.logbook?.navigations.find(
            nav => nav.id === parseInt(ownProps.id),
        )

    return {
        navigation: navigation ? JSON.parse(JSON.stringify(navigation)) : null,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(LogBookManualSetupModal),
)
