export default {
    title: {
        FR: 'Mon temps de travail et mes absences',
        EN: 'My work time and leaves',
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essential information',
    },
    timesheetModalTitle: {
        FR:
            'Oups, il semble que vous ayez sélectionné une période inférieure à 1 mois',
        EN: 'Oops, it seems you have selected a period less than 1 month',
    },
    warningBubbleText: {
        FR: `Vous pouvez continuer ou revenir sur la page et sélectionner le mois entier`,
        EN: `You can continue or go back to the page and select the whole month`,
    },
    goBack: {
        FR: 'Retour',
        EN: 'Go back',
    },
    continue: {
        FR: 'Continuer',
        EN: 'Continue',
    },
    activity: {
        FR: 'Activité',
        EN: 'Activity',
    },
    pageLoadingLabel: {
        FR: 'Chargement de vos temps de travail et absences...',
        EN: 'Loading your working times and days off...',
    },
    travail: {
        FR: 'Travail',
        EN: 'Work',
    },
    maladie: {
        FR: 'Maladie',
        EN: 'Sick leave',
    },
    conge: {
        FR: 'Congé payé',
        EN: 'Day off',
    },
    semaine: {
        FR: 'Semaine',
        EN: 'Week',
    },
    cancelEvent: {
        FR: "Annuler l'évènement",
        EN: 'Cancel event',
    },
    cancelRequest: {
        FR: 'Annuler la demande',
        EN: 'Cancel request',
    },
    editEvent: {
        FR: "Modifier l'évènement",
        EN: 'Edit event',
    },
    viewEvent: {
        FR: "Voir l'évènement",
        EN: 'View event',
    },
    viewRequest: {
        FR: `Voir la demande`,
        EN: 'View request',
    },
    addWorkTime: {
        FR: 'Ajouter un temps de travail',
        EN: 'Add work time',
    },
    addLeave: {
        FR: 'Ajouter une absence',
        EN: 'Add a leave',
    },
    add: {
        FR: 'Ajouter',
        EN: 'Add',
    },
    download: {
        FR: 'Télécharger',
        EN: 'Download',
    },
    timesheet: {
        FR: 'Timesheet',
        EN: 'Timesheet',
    },
    monday: {
        FR: 'Lundi',
        EN: 'Monday',
    },
    tuesday: {
        FR: 'Mardi',
        EN: 'Tuesday',
    },
    wednesday: {
        FR: 'Mercredi',
        EN: 'Wednesday',
    },
    thursday: {
        FR: 'Jeudi',
        EN: 'Thursday',
    },
    friday: {
        FR: 'Vendredi',
        EN: 'Friday',
    },
    saturday: {
        FR: 'Samedi',
        EN: 'Saturday',
    },
    sunday: {
        FR: 'Dimanche',
        EN: 'Sunday',
    },
    january: {
        FR: 'Janvier',
        EN: 'January',
    },
    february: {
        FR: 'Février',
        EN: 'february',
    },
    march: {
        FR: 'Mars',
        EN: 'Mars',
    },
    april: {
        FR: 'Avril',
        EN: 'April',
    },
    may: {
        FR: 'Mai',
        EN: 'May',
    },
    june: {
        FR: 'Juin',
        EN: 'June',
    },
    july: {
        FR: 'Juillet',
        EN: 'July',
    },
    august: {
        FR: 'Août',
        EN: 'August',
    },
    september: {
        FR: 'Septembre',
        EN: 'September',
    },
    october: {
        FR: 'Octobre',
        EN: 'October',
    },
    november: {
        FR: 'Novembre',
        EN: 'Novembre',
    },
    december: {
        FR: 'Décembre',
        EN: 'December',
    },
    workTimeModalTitle: {
        FR: 'Temps de travail',
        EN: 'Work time',
    },
    leaveModalTitle: {
        FR: 'Absence',
        EN: 'Leave',
    },
    days_off_taken: {
        FR: 'congés payés pris',
        EN: 'paid leave taken',
    },
    days_off_remaining: {
        FR: 'congés payés à prendre',
        EN: 'paid leave remaining',
    },
    days_off_pending: {
        FR: 'congés en attente de validation',
        EN: 'paid leave pending',
    },
    days: {
        FR: 'j',
        EN: 'd',
    },
    hours: {
        FR: 'h',
        EN: 'h',
    },
    minutes: {
        FR: 'm',
        EN: 'm',
    },
    absenceAlreadyValidated: {
        FR: 'Cette absence a déjà été validée par votre manager.',
        EN: 'This leave has already been validated by your manager.',
    },
    absenceAlreayRejected: {
        FR: 'Cette absence a déjà été refusée par votre manager.',
        EN: 'This leave has already been rejected by your manager.',
    },
    confirmCancelWorkTime: {
        FR: "Confirmez vous l'annulation de ce temps de travail ?",
        EN: 'Do you confirm the cancellation of this work time?',
    },
    confirmCancelAbsence: {
        FR: "Confirmez vous l'annulation de cette demande d'absence ?",
        EN: 'Do you confirm the cancellation of this leave request?',
    },
    work: {
        FR: 'Travail',
        EN: 'Work',
    },
    allDay: {
        FR: 'Jour entier',
        EN: 'All day',
    },
    overtimeError: {
        FR: 'La journée de travail a été dépassée',
        EN: 'The working day has been exceeded',
    },
    undertimeError: {
        FR: 'La journée de travail est incomplète',
        EN: 'The working day is incomplete',
    },
    noData: {
        FR:
            "Aucun temps de travail ou absence n'a été enregistré pour cette période.",
        EN: 'No work time or leave has been recorded for this period.',
    },
    user: {
        FR: 'Utilisateur',
        EN: 'User',
    },
    totalWeek: {
        FR: `Votre temps de travail`,
        EN: `Your working time`,
    },
}
