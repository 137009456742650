import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button } from '../common/BoatOnButton'
import BoatOnModal from '../common/BoatOnModal'
import ReactGA from 'react-ga'
import BoatOnComponent from '../common/BoatOnComponent'
import styles from './Styles/LoginPageCss'
import dictionary from './Dictionary/LoginPageDico'
import Navigation from '../common/Navigation'
import { userActions } from '../../actions'
import { history } from '../../helpers'
import AppRoute from '../../constants/AppRoute'
import RenderBoatCreate from '../common/UsefullComponents/RenderBoatCreate'
import RenderAccountCreate from '../common/UsefullComponents/RenderAccountCreate'

class LoginPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            email: '',
            password: '',
            boatId: null,
            showPassword: false,
            signinModalOpen: props.register ? 'personnalInfos' : false,
            focused: false,
            userGroupId: null,
        }

        this.initializeReactGA()
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleSigninClose = this.handleSigninClose.bind(this)
        this.valid = this.valid.bind(this)
    }

    componentDidMount() {
        if (this.props.user) {
            this.historyPush(history, AppRoute.LogBook.Repair)
        }
        let url = this.props.location?.pathname?.split('/')
        if (url?.length === 4) {
            this.setState({ userGroupId: url[3] })
        }
    }

    componentDidUpdate() {
        if (this.props.user && !this.state.signinModalOpen) {
            if (this.state.userGroupId) {
                this.historyPush(history, AppRoute.LogBook.Repair, '', {
                    groupId: this.state.userGroupId,
                })
            } else {
                this.historyPush(history, AppRoute.LogBook.Repair)
            }
        }
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/login')
    }

    handleChange(value, key) {
        this.setState({ focused: key })
        this.setState({ [key]: value })
    }

    handleOpen() {
        this.setState({ signinModalOpen: 'personnalInfos' })
    }

    handleSigninClose() {
        // uncomment following lines to reset modal content on close
        if (this.state.signinModalOpen) {
            this.setState({
                signinModalOpen: false,
            })
        }
    }

    handleLoginSubmit(e) {
        e.preventDefault()
        const { email, password } = this.state
        if (email && password) {
            this.setState({ submitted: true })
            this.props.dispatch(
                userActions.login(email, password.trim(), this.context),
            )
        }
    }

    valid(next, boatId) {
        // if (next && typeof next === 'string')
        //     this.setState({ signinModalOpen: next, boatId: boatId })
        // else {
        // this.handleSigninClose()
        this.props.dispatch(userActions.init())
        if (this.state.userGroupId) {
            this.historyPush(history, AppRoute.LogBook.Repair, '', {
                groupId: this.state.userGroupId,
            })
        } else {
            this.historyPush(history, AppRoute.LogBook.Repair)
        }
        // }
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Navigation
                    location={this.props.location}
                    onRegisterClick={this.handleOpen}
                >
                    <div className={classes.root}>
                        <Typography
                            style={{ textAlign: 'center', fontSize: '26px' }}
                        >
                            {this.displayText('enjoyBoating')}
                        </Typography>
                        <form name="form" onSubmit={this.handleLoginSubmit}>
                            <TextField
                                id="email"
                                label={this.displayText('email')}
                                margin="normal"
                                type="email"
                                autoCorrect="off"
                                autoCapitalize="none"
                                required
                                value={this.state.email}
                                onChange={e => {
                                    this.handleChange(e.target.value, 'email')
                                }}
                                inputProps={{
                                    spellcheck: 'false',
                                    autocomplete: 'off',
                                }}
                                fullWidth
                            />
                            <TextField
                                id="password"
                                label={this.displayText('password')}
                                margin="normal"
                                required
                                type="password"
                                value={this.state.password}
                                onChange={e => {
                                    this.handleChange(
                                        e.target.value,
                                        'password',
                                    )
                                }}
                                name="new-password"
                                autoComplete="new-password"
                                fullWidth
                            />
                            <Button
                                type="submit"
                                color="primary"
                                fullWidth
                                variant="contained"
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                }}
                                label={this.displayText('connection')}
                            ></Button>
                            <div className={classes.newAccount}>
                                <Link
                                    to={`/${this.context}/forget`}
                                    className={classes.forget}
                                >
                                    {this.displayText('forgetPassword')}
                                </Link>
                            </div>
                            <div className={classes.newAccount}>
                                <Typography
                                    style={{
                                        marginTop: 'auto',
                                        marginBottom: 'auto',
                                    }}
                                >
                                    {this.displayText('noAccount')}
                                </Typography>
                                <a
                                    className={
                                        (classes.newAccount,
                                        classes.buttonCreateAccount)
                                    }
                                    onClick={this.handleOpen}
                                >
                                    {this.displayText('createOne')}
                                </a>
                            </div>
                        </form>
                    </div>
                    <BoatOnModal
                        openCondition={this.state.signinModalOpen}
                        handleClose={this.handleSigninClose}
                        modalCores={{
                            personnalInfos: (
                                <RenderAccountCreate valid={this.valid} />
                            ),
                            boatInfos: <RenderBoatCreate valid={this.valid} />,
                        }}
                        titles={{
                            personnalInfos: this.displayText('accountCreate'),
                            boatInfos: this.displayText('boatAdd'),
                        }}
                        maxWidth={{
                            personnalInfos: `sm`,
                            boatInfos: `sm`,
                            enginesInfos: `sm`,
                        }}
                    />
                </Navigation>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LoginPage))
