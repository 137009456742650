import { commentsConstants } from '../../constants/comments.constants'
import { requestApi } from '../../services/api.service'

const postComment = body => {
    return dispatch => {
        dispatch({ type: commentsConstants.SEND_COMMENT })
        return requestApi(`/comments`, `POST`, body, false, false)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    if (body.bobEventId)
                        dispatch(getBobEventComments(body.bobEventId))
                    else if (body.workTimeId)
                        dispatch(getWorkTimeComments(body.workTimeId))
                    else if (body.userAbsenceId)
                        dispatch(getAbsenceComments(body.userAbsenceId))
                    else if (body.navigationId)
                        dispatch(getNavigationComments(body.navigationId))
                    return result
                },
                error => {
                    console.error(error)
                    return null
                },
            )
            .catch(error => console.error(error))
    }
}

const updateComment = comment => {
    return dispatch => {
        dispatch({ type: commentsConstants.UPDATE_COMMENT })
        return requestApi(`/comments`, `PUT`, comment, false, false)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    return result
                },
                error => {
                    console.error(error)
                    return null
                },
            )
            .catch(error => console.error(error))
    }
}

const deleteComment = commentId => {
    return dispatch => {
        dispatch({ type: commentsConstants.DELETE_COMMENT })

        return requestApi(`/comments/${commentId}`, 'DELETE').then(
            result => {
                if (!result || result.status === 404) return null
                dispatch({
                    type: commentsConstants.DELETE_COMMENT_SUCCESS,
                })
            },
            error => {
                dispatch({ type: commentsConstants.DELETE_COMMENT_FAILURE })
            },
        )
    }
}

const getBobEventComments = eventId => {
    return dispatch => {
        dispatch({ type: commentsConstants.REQUEST_COMMENTS })

        return requestApi(`/comments/bob-event/${eventId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: commentsConstants.COMMENT_SUCCESS,
                        comments: result,
                    })
                },
                error => {
                    dispatch({ type: commentsConstants.COMMENT_FAILURE })
                },
            )
            .catch(error => console.error(error))
    }
}

const getWorkTimeComments = workTimeId => {
    return dispatch => {
        dispatch({ type: commentsConstants.REQUEST_COMMENTS })

        return requestApi(`/comments/work-time/${workTimeId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: commentsConstants.COMMENT_SUCCESS,
                        comments: result,
                    })
                },
                error => {
                    dispatch({ type: commentsConstants.COMMENT_FAILURE })
                },
            )
            .catch(error => console.error(error))
    }
}

const getAbsenceComments = absenceId => {
    return dispatch => {
        dispatch({ type: commentsConstants.REQUEST_COMMENTS })

        return requestApi(`/comments/absence/${absenceId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: commentsConstants.COMMENT_SUCCESS,
                        comments: result,
                    })
                },
                error => {
                    dispatch({ type: commentsConstants.COMMENT_FAILURE })
                },
            )
            .catch(error => console.error(error))
    }
}

const getNavigationComments = navigationId => {
    return dispatch => {
        dispatch({ type: commentsConstants.REQUEST_COMMENTS })

        return requestApi(`/comments/navigation/${navigationId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: commentsConstants.COMMENT_SUCCESS,
                        comments: result,
                    })
                },
                error => {
                    dispatch({ type: commentsConstants.COMMENT_FAILURE })
                },
            )
            .catch(error => console.error(error))
    }
}

export const commentsActions = {
    postComment,
    updateComment,
    getBobEventComments,
    getWorkTimeComments,
    getAbsenceComments,
    deleteComment,
    getNavigationComments,
}
