import React from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import BoatOnModal from '../../../../../common/BoatOnModal'
import BoatOnNumberField from '../../../../../common/UsefullComponents/BoatOnNumberField'
import BoatOnDateSelector from '../../../../../common/UsefullComponents/BoatOnDateSelector'
import FillAddressAisModal from './FillAddressAisModal'

import AddressSearch from '../../../../../RentParkingPage/RentNavigationPages/AddressSearch'
import DisplayerImgModal from '../../../../DisplayerImgModal'

import { TextField } from '@material-ui/core'
import { InputAdornment } from '@mui/material'
import { MobileTimePicker } from '@mui/x-date-pickers'
import {
    AddAPhoto as AddAPhotoIcon,
    Place as PlaceIcon,
} from '@mui/icons-material/'

import {
    getImageToDisplay,
    addFileToBobEvent,
    deleteFileFromBobEvent,
    postDataBOBDocuments,
    removeDataBOBDocuments,
    putBOBEvents,
} from '../../../../../../actions/bob.actions'
import { editNavigation } from '../../../../../../actions'
import { getWorkingHours, getFillingLevels } from '../../Utils/eventsFilter'
import { getTranslatedTitleFromEvent } from '../../../Utils'

import dictionary from './Dictionary/EndNavigationModalDico'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EndNavigationModalCss'

class EndNavigationModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            tabIndex: 0,
            navigation: null,
            equipmentInUse: [],
            equipmentCapacities: [],
            userEquipmentsUsed: [],
            error: false,
            fileNoUrl: { navigation: [] },
            displayNewFiles: { navigation: [] },
            deletedFiles: { navigation: [] },
            selectedModal: false,
            remplacementAddress: null,
        }

        this.inputDocRef = React.createRef()

        this.changeTab = this.changeTab.bind(this)
        this.onChangeLevel = this.onChangeLevel.bind(this)
        this.onChangeWorktime = this.onChangeWorktime.bind(this)
        this.onChangeArrivalAdress = this.onChangeArrivalAdress.bind(this)
        this.getOlderDate = this.getOlderDate.bind(this)
        this.setComment = this.setComment.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.uploadNewImages = this.uploadNewImages.bind(this)
        this.getUpdatedEvents = this.getUpdatedEvents.bind(this)
        this.checkFieldsAreValid = this.checkFieldsAreValid.bind(this)
        this.save = this.save.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.setRemplacementAddress = this.setRemplacementAddress.bind(this)
    }

    componentDidMount() {
        const { events, navigation } = this.props

        if (events) this._initState()

        this.setState({
            navigation: JSON.parse(JSON.stringify(navigation)),
        })
    }

    closeModal() {
        this.setState({ selectedModal: false })
    }

    setRemplacementAddress(remplacementAddress) {
        this.setState(
            {
                remplacementAddress,
            },
            () => this.save(),
        )
    }

    _initState() {
        const { events, userEquipments } = this.props

        const workingHours = getWorkingHours(events)
        const fillingLevels = getFillingLevels(events, userEquipments)

        this.setState({
            equipmentInUse: workingHours.equipmentInUse,
            equipmentCapacities: fillingLevels.bobEventUsed,
            userEquipmentsUsed: fillingLevels.userEquipmentsUsed,
        })
    }

    async uploadNewImages() {
        const { fileNoUrl, deletedFiles, navigation } = this.state
        const files = await postDataBOBDocuments(fileNoUrl.navigation, 1)

        files.forEach(file => ({
            ...file,
            file_type_id: 1,
        }))

        removeDataBOBDocuments(deletedFiles.navigation)

        return [...navigation.files, ...files]
    }

    getUpdatedEvents() {
        const { navigation } = this.state

        const events = JSON.parse(JSON.stringify(this.props.events))

        const workingEquipmentHours = navigation.workingEquipmentHours
        const workingEquipmentHoursID = workingEquipmentHours.map(
            item => item.equipment.id,
        )
        const eventsHours = events.filter(event => {
            if (
                !(
                    event?.detail?.equipment &&
                    workingEquipmentHoursID.includes(event.detail.equipment.id)
                )
            )
                return false

            const equipment = workingEquipmentHours.find(
                item => item.equipment.id === event.detail.equipment.id,
            )
            if (!equipment) return false
            if (equipment.hours <= event.detail.hourActual) return false

            return true
        })

        const fillingLevels = navigation.fillingLevels
        const fillingLevelsID = fillingLevels.map(item => item.equipment.id)

        const eventsFilledLevel = events.filter(event => {
            if (
                !(
                    event?.detail?.equipment &&
                    fillingLevelsID.includes(event.detail.equipment.id)
                )
            )
                return false

            const equipment = fillingLevels.find(
                item => item.equipment.id === event.detail.equipment.id,
            )

            if (!equipment) return false

            if (equipment.level === event.detail.optionnalDetail?.fillingLevel)
                return false

            return true
        })

        let eventsToUpdate = eventsHours.concat(eventsFilledLevel)

        eventsToUpdate = eventsToUpdate.reduce((map, event) => {
            map[event.id] = event
            return map
        }, {})

        eventsToUpdate = Object.values(eventsToUpdate)

        const updatedEvent = eventsToUpdate.map(event => {
            const equipmentHours = workingEquipmentHours.find(
                item => item.equipment.id === event.detail.equipment.id,
            )

            if (
                equipmentHours &&
                event.detail.hourActual < equipmentHours.hours
            )
                event.detail.hourActual = parseInt(equipmentHours.hours)

            const equipmentFillingLevel = fillingLevels.find(
                item => item.equipment.id === event.detail.equipment.id,
            )
            const optionnalDetail = event.detail.optionnalDetail
            const optionnalDetailActivated =
                event.detail?.equipment?.equipmentType?.optionnalDetailActivated

            if (
                equipmentFillingLevel &&
                optionnalDetailActivated?.fillingLevel
            ) {
                if (
                    optionnalDetail?.fillingLevel &&
                    optionnalDetail.fillingLevel !== equipmentFillingLevel.level
                )
                    event.detail.optionnalDetail.fillingLevel = parseInt(
                        equipmentFillingLevel.level,
                    )
                else {
                    event.detail.optionnalDetail = {
                        ...event.detail.optionnalDetail,
                        fillingLevel: parseInt(equipmentFillingLevel.level),
                    }
                }
            }

            return event
        })

        return updatedEvent
    }

    checkFieldsAreValid() {
        const { navigation } = this.state
        let isValid = true

        if (!navigation.isAisUsed && !navigation.arrivalAddress) isValid = false
        if (!navigation.isAisUsed && !navigation.delimitedDate?.endDate)
            isValid = false

        this.setState({ error: !isValid })

        return isValid
    }

    getLastAisData() {
        const { aisData } = this.props
        const { remplacementAddress } = this.state

        const lastAisData = aisData.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        )[aisData.length - 1]

        if (!lastAisData)
            return remplacementAddress ? remplacementAddress : null

        const currentDate = new Date()
        const treshold = 20
        const oldCurrentDate = new Date(
            currentDate.getTime() - treshold * 60 * 1000,
        )

        if (new Date(lastAisData.createdAt) < oldCurrentDate)
            return remplacementAddress ? remplacementAddress : null

        return lastAisData
    }

    async save() {
        if (!this.checkFieldsAreValid()) return

        const { dispatch, onClose } = this.props
        const { navigation } = this.state
        const eventsToUpdate = this.getUpdatedEvents()

        if (navigation.isAisUsed) {
            const lastAisData = this.getLastAisData()

            if (!lastAisData) {
                this.setState({ selectedModal: 'replacementAddressModal' })
                return
            }

            navigation.delimitedDate.endDate = new Date()
            navigation.arrivalAddress = {
                longitude: lastAisData.longitude,
                latitude: lastAisData.latitude,
            }
        }

        navigation.files = await this.uploadNewImages()

        eventsToUpdate.forEach(event => {
            dispatch(
                putBOBEvents(event.id, event.title, {
                    detail: event.detail,
                }),
            )
        })

        dispatch(editNavigation(navigation))
        onClose()
    }

    changeTab(tabIndex) {
        this.setState({ tabIndex })
    }

    onChangeLevel(value, equipmentId) {
        const { navigation } = this.state
        const index = navigation.fillingLevels.findIndex(
            item => equipmentId === item.equipment.id,
        )

        if (index === -1) {
            navigation.fillingLevels.push({
                equipment: {
                    id: equipmentId,
                },
                level: value,
            })
        } else {
            navigation.fillingLevels[index].level = value
        }

        this.setState({
            navigation,
            updated: true,
        })
    }

    onChangeWorktime(value, equipmentId) {
        const { navigation } = this.state
        const index = navigation.workingEquipmentHours.findIndex(
            item => item.equipment.id === equipmentId,
        )

        navigation.workingEquipmentHours[index].hours = value

        this.setState({
            navigation,
        })
    }

    onChangeArrivalAdress(address) {
        this.setState({
            navigation: {
                ...this.state.navigation,
                arrivalAddress: address,
            },
        })
    }

    getOlderDate() {
        const { navigation } = this.state

        let olderDate = new Date(navigation.delimitedDate.startDate)

        navigation.stopOvers.forEach(stop => {
            const stopEnd = new Date(stop.delimitedDate.endDate)

            if (stopEnd > olderDate) olderDate = stopEnd
        })

        return olderDate
    }

    onChangeArrivalDateTime(type, date) {
        const { navigation } = this.state
        const olderDate = this.getOlderDate()

        date = date ? new Date(date) : null

        if (!navigation.delimitedDate.endDate) {
            navigation.delimitedDate.endDate = new Date()
            navigation.delimitedDate.endDate.setSeconds(0)
            navigation.delimitedDate.endDate.setMilliseconds(0)
        } else {
            navigation.delimitedDate.endDate = new Date(
                navigation.delimitedDate.endDate,
            )
        }

        if (date && type === 'date') {
            navigation.delimitedDate.endDate.setYear(date.getFullYear())
            navigation.delimitedDate.endDate.setMonth(date.getMonth())
            navigation.delimitedDate.endDate.setDate(date.getDate())
        } else if (date && type === 'time') {
            navigation.delimitedDate.endDate.setHours(date.getHours())
            navigation.delimitedDate.endDate.setMinutes(date.getMinutes())
        }

        if (new Date(navigation.delimitedDate.endDate) < olderDate) {
            navigation.delimitedDate.endDate = olderDate
        }

        this.setState({
            navigation,
        })
    }

    setComment(value) {
        this.setState({
            navigation: {
                ...this.state.navigation,
                comment: value,
            },
        })
    }

    addDocument(event) {
        const { fileNoUrl, displayNewFiles } = this.state

        if (
            event.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: fileNoUrl.event,
                    displayNewFiles: displayNewFiles.event,
                    name: 'navigation',
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: fileNoUrl.event,
                displayNewFiles: displayNewFiles.event,
                name: 'navigation',
            })
        }
    }

    deleteDocumentNoUp(i) {
        const {
            deletedFiles,
            displayNewFiles,
            fileNoUrl,
            navigation,
        } = this.state

        deleteFileFromBobEvent(this, i, {
            deletedFiles: deletedFiles.navigation,
            displayNewFiles: displayNewFiles.navigation,
            fileNoUrl: fileNoUrl.navigation,
            files: navigation.files,
            name: 'navigation',
        })
    }

    _renderDetails() {
        const { classes } = this.props
        const { navigation, displayNewFiles, selectedModal, error } = this.state

        if (navigation === null) return 'loading...'

        let addressDisplay = null

        if (!navigation.isAisUsed) {
            addressDisplay = (
                <>
                    <div className={classes.bigTitle}>
                        {this.displayText('addressTitle')}
                    </div>
                    <AddressSearch
                        required
                        type="city"
                        city={true}
                        label={this.displayText('arrivalPlace')}
                        variant="outlined"
                        margin="dense"
                        style={{ width: '100%' }}
                        className={classes.inputAddress}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceIcon />
                                </InputAdornment>
                            ),
                        }}
                        setAddress={this.onChangeArrivalAdress}
                        fullAddressInfo={navigation.arrivalAddress}
                        error={error && !navigation.arrivalAddress}
                    />
                    <div className={classes.dateTimeSection}>
                        <div className={classes.inputDate}>
                            <BoatOnDateSelector
                                required
                                label={this.displayText('arrivalDate')}
                                onChange={value =>
                                    this.onChangeArrivalDateTime('date', value)
                                }
                                value={
                                    navigation.delimitedDate.endDate
                                        ? new Date(
                                              navigation.delimitedDate.endDate,
                                          )
                                        : null
                                }
                                disabledDay={d =>
                                    d < this.getOlderDate().setHours(0, 0, 0, 0)
                                }
                                error={
                                    error && !navigation.delimitedDate?.endDate
                                }
                            />
                        </div>
                        <MobileTimePicker
                            className={classes.inputTime}
                            label={this.displayText('arrivalHour')}
                            slotProps={{
                                textField: {
                                    required: true,
                                    size: 'small',
                                    margin: 'dense',
                                    error:
                                        error &&
                                        !navigation.delimitedDate?.endDate,
                                },
                            }}
                            onAccept={value =>
                                this.onChangeArrivalDateTime('time', value?.$d)
                            }
                            value={
                                navigation.delimitedDate.endDate
                                    ? dayjs(navigation.delimitedDate.endDate)
                                    : ''
                            }
                            shouldDisableTime={(time, view) => {
                                let olderDate = this.getOlderDate()

                                if (view === 'hours') {
                                    olderDate = new Date(olderDate).setMinutes(
                                        0,
                                    )
                                }

                                return time < olderDate
                            }}
                        />
                    </div>
                </>
            )
        }

        return (
            <div className={classes.root}>
                {addressDisplay}
                <div className={classes.title}>
                    {this.displayText('commentTitle')}
                </div>
                <div>
                    <div className={classes.bigTitle}>
                        {this.displayText('captainJugment')}
                    </div>
                    <TextField
                        multiline
                        label={this.displayText('comment')}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        minRows={4}
                        onChange={e => this.setComment(e.target.value)}
                        value={navigation.comment || ''}
                    />
                </div>
                <div>
                    <div className={classes.bigTitle}>
                        {this.displayText('photoAndDocs')}
                    </div>
                    <div
                        className={classes.documentContainer}
                        onClick={() => this.inputDocRef.current.click()}
                    >
                        <AddAPhotoIcon className={classes.AddDocIcon} />
                    </div>
                    <input
                        onChange={this.addDocument}
                        type="file"
                        id="file"
                        ref={this.inputDocRef}
                        style={{ display: 'none' }}
                        multiple
                        required
                    />
                    <DisplayerImgModal
                        file={getImageToDisplay(
                            navigation,
                            displayNewFiles.navigation,
                        )}
                        deleteDocumentNoUp={this.deleteDocumentNoUp}
                    />
                </div>
                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.closeModal}
                    modalCores={{
                        replacementAddressModal: (
                            <FillAddressAisModal
                                onClose={this.closeModal}
                                onValid={this.setRemplacementAddress}
                                noCross={false}
                            />
                        ),
                    }}
                    maxWidth={{
                        replacementAddressModal: 'sm',
                    }}
                    titles={{
                        replacementAddressModal: this.displayText(
                            'aisWithoutAddress',
                        ),
                    }}
                />
            </div>
        )
    }

    _renderWorktime() {
        const { classes, context } = this.props
        const { equipmentInUse, navigation } = this.state

        const renderEquipment = equipmentInUse
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const value = navigation.workingEquipmentHours.find(
                    item => item.equipment.id === equipment.id,
                )?.hours

                return (
                    <div
                        className={classes.equipmentWrapper}
                        key={`equipment-${index}`}
                    >
                        <img
                            src={imgSrc}
                            alt={familyType}
                            className={classes.equipmentIcon}
                        />
                        <div className={classes.equipmentDetails}>
                            <div className={classes.equipmentLabel}>
                                {getTranslatedTitleFromEvent(this, event)}
                            </div>
                            <BoatOnNumberField
                                isDecimal
                                label={this.displayText('worktime')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            h
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="dense"
                                onChange={e =>
                                    this.onChangeWorktime(
                                        e.target.value,
                                        equipment.id,
                                    )
                                }
                                min={0}
                                value={value}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                )
            })

        return (
            <div className={classes.root}>
                {renderEquipment && renderEquipment.length > 0 ? (
                    <>
                        <div className={classes.title}>
                            {this.displayText('titleWorktimes')}
                        </div>
                        {renderEquipment}
                    </>
                ) : (
                    this.displayText('emptyWorktime')
                )}
            </div>
        )
    }

    _renderStock() {
        const { classes, context } = this.props

        const {
            navigation,
            userEquipmentsUsed,
            equipmentCapacities,
        } = this.state

        const renderStockLevel = equipmentCapacities
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const value = navigation.fillingLevels.find(
                    item => item.equipment.id === equipment.id,
                )?.level

                const equipmentDefault = userEquipmentsUsed.find(
                    item =>
                        item.equipmentType.id === equipment.equipmentType.id,
                )

                const unit =
                    equipmentDefault.equipmentType.optionnalDetailActivated
                        ?.unit
                const capacity = event.detail.optionnalDetail?.capacity
                const capacityRemaining = (value * capacity) / 100

                return (
                    <div
                        className={classes.stockContainer}
                        key={`stocklevelItem-${index}`}
                    >
                        <div className={classes.stockIconContainer}>
                            <img
                                src={imgSrc}
                                alt={familyType}
                                className={classes.stockIcon}
                            />
                        </div>
                        <div className={classes.stockVerticalContainer}>
                            {getTranslatedTitleFromEvent(this, event)}
                            <div className={classes.stockRowContainer}>
                                <BoatOnNumberField
                                    label={this.displayText('actualLevel')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={e =>
                                        this.onChangeLevel(
                                            e.target.value,
                                            equipment.id,
                                        )
                                    }
                                    className={classes.stockInput}
                                    value={value}
                                    max={100}
                                    min={0}
                                    isDecimal
                                />
                                <div className={classes.stockInfoContainer}>
                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${this.displayText(
                                                'capacity',
                                            )} ${capacity} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                {this.displayText('capacity')} ?{' '}
                                                {unit}
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${capacityRemaining} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                ? {unit}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <div className={classes.root}>
                {renderStockLevel && renderStockLevel.length > 0 ? (
                    <>
                        <div className={classes.title}>
                            {this.displayText('titleLevels')}
                        </div>
                        {renderStockLevel}
                    </>
                ) : (
                    this.displayText('emptyStock')
                )}
            </div>
        )
    }

    render() {
        const { tabIndex } = this.state
        const { title, noCross = true, showFollowButton, onClose } = this.props

        let actions = null

        switch (tabIndex) {
            default:
            case 0:
                actions = [
                    {
                        action: onClose,
                        type: 'text',
                        label: this.displayText('cancel'),
                    },
                    {
                        action: () => this.changeTab(tabIndex + 1),
                        label: this.displayText('next'),
                    },
                ]
                break
            case 1:
                actions = [
                    {
                        action: () => this.changeTab(tabIndex - 1),
                        type: 'text',
                        label: this.displayText('back'),
                    },
                    {
                        action: () => this.changeTab(tabIndex + 1),
                        label: this.displayText('next'),
                    },
                ]
                break
            case 2:
                actions = [
                    {
                        action: () => this.changeTab(tabIndex - 1),
                        type: 'text',
                        label: this.displayText('back'),
                    },
                    {
                        action: this.save,
                        label: this.displayText('save'),
                    },
                ]
                break
        }

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    [
                        this.displayText('stockLevelsTab'),
                        this.displayText('worktimeTab'),
                        this.displayText('detailTab'),
                    ],
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    bodies: [
                        this._renderStock(),
                        this._renderWorktime(),
                        this._renderDetails(),
                    ],
                })}
                {this._renderActions(actions)}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook.navigations.find(
        nav => nav.id === parseInt(id),
    )

    const currentAis =
        (state.navigationApiData.ais || []).filter(
            ais => ais.navigationId === navigation.id,
        )[0] || null

    return {
        navigation,
        aisData: currentAis?.aisData || [],
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        events: state.bob.events,
        userEquipments: state.types?.userEquipments || [],
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EndNavigationModal))
