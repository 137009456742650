import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/InventoryBarDico'
import BoatOnComponent from '../../../common/BoatOnComponent'
import Button from '@material-ui/core/Button'
import { MailOutline, Receipt, GetApp, Delete } from '@material-ui/icons'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'

const styles = theme => ({
    saveBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 1499,
        padding: '12px',
        maxHeight: '7%',
    },
    button: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
    buttonDelete: {
        color: 'black',
        backgroundColor: '#e46b62',
        '&:hover': {
            backgroundColor: '#e46b62',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
})

class InventoryBar extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { classes } = this.props
        const isMobile = window.innerWidth < 600

        if (this.props.open) {
            return (
                <BoatOnBlock
                    blockIds={[52, 51, 50, 42]}
                    onlyOneId={true}
                    children={
                        <div className={classes.saveBottom}>
                            {/*<Button
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        onClick={this.props.sendMail}
                    >
                        {this.displayText('control')}
                    </Button>*/}
                            <BoatOnBlock
                                blockIds={52}
                                children={
                                    <Button
                                        className={classes.button}
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        endIcon={!isMobile ? <GetApp /> : <></>}
                                        onClick={this.props.downloadExcel}
                                    >
                                        {this.displayText('download')}
                                    </Button>
                                }
                            />
                            <BoatOnBlock
                                blockIds={51}
                                children={
                                    <Button
                                        className={classes.button}
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        endIcon={
                                            !isMobile ? <MailOutline /> : <></>
                                        }
                                        onClick={this.props.sendEmail}
                                    >
                                        {this.displayText('email')}
                                    </Button>
                                }
                            />
                            <BoatOnBlock
                                blockIds={50}
                                children={
                                    <Button
                                        className={classes.button}
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        endIcon={
                                            !isMobile ? <Receipt /> : <></>
                                        }
                                        onClick={this.props.downloadOrder}
                                    >
                                        {this.displayText('orderForm')}
                                    </Button>
                                }
                            />
                            <BoatOnBlock
                                blockIds={42}
                                children={
                                    <Button
                                        className={classes.buttonDelete}
                                        size="medium"
                                        color="secondary"
                                        variant="contained"
                                        endIcon={!isMobile ? <Delete /> : <></>}
                                        onClick={this.props.deleteSelected}
                                    >
                                        {this.displayText('delete')}
                                    </Button>
                                }
                            />
                        </div>
                    }
                />
            )
        } else {
            return null
        }
    }
}

export default withStyles(styles)(InventoryBar)
