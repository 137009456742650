import React, { useState } from 'react'
import styles from './CalendarHeaderCss.js'
import { withStyles } from '@material-ui/core/styles'
import { Button, MenuItem, Select } from '@mui/material'
import { BobFilter } from '../../../BobFilter.jsx'
import { filterActions } from '../../../../../../actions/filter.actions.js'
import { getContextFromUrl } from '../../../../../../languages/LocalizerUtils.js'
import { typesActions } from '../../../../../../actions/types.actions.js'
import { connect } from 'react-redux'
import {
    Checkbox,
    Popover,
    TextField,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Button as ButtonBoat } from '../../../../../common/BoatOnButton.jsx'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import CalendarPageDico from '../../Dictionary/CalendarPageDico.js'
import creditcard from '../../../../../../images/creditcard.png'
import repairer from '../../../../../../images/repairer.png'
import { getRepairsExcel } from '../../../../../../actions/bob.actions'
import { AttachFile, IosShare } from '@mui/icons-material'
import BoatOnModal from '../../../../../common/BoatOnModal'
import LoadingRepairModal from '../../../RepairPage/Modal/LoadingRepairModal'
import TimelapseIcon from '@mui/icons-material/Timelapse'
import { fixIosHeaderBug } from '../../../../../../utils/usefullFunctions.js'

const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
]

const jours = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]

class CalendarHeader extends BobFilter {
    constructor(props) {
        super(props)

        const { bobFilter } = this.props
        // this.dictionary = CalendarPageDico;

        this.dictionary = {
            ...this.dictionary,
            ...CalendarPageDico,
        }

        this.state = {
            currentViewTitle: '',
            equipmentType: bobFilter.equipmentType,
            commissioningDate: {
                start: bobFilter.commissioningDate.start,
                end: bobFilter.commissioningDate.end,
            },
            replacementDate: {
                start: bobFilter.replacementDate.start,
                end: bobFilter.replacementDate.end,
            },
            rangeDate: {
                start: bobFilter.rangeDate.start,
                end: bobFilter.rangeDate.end,
            },
            checkupTypesChecked: bobFilter.checkupTypesChecked,
            rangeAmount: bobFilter.rangeAmount,
            rangeHour: bobFilter.rangeHour,
            userAttachedUserRole: bobFilter.userAttachedUserRole,
            userAttachedRoleType: bobFilter.userAttachedRoleType,
            userManagerSearch: bobFilter.userManagerSearch,
            unregisteredManagerSearch: bobFilter.unregisteredManagerSearch,
            profileOpen: false,
            modalOpen: null,
            searchString: '',
        }

        this.optionsVues = [
            {
                nom: 'day',
                vue: 'timeGridDay',
            },
            {
                nom: 'week',
                vue: 'timeGridWeek',
            },
            {
                nom: 'month',
                vue: 'dayGridMonth',
            },
            {
                nom: 'year',
                vue: 'multiMonthYear',
            },
            {
                nom: 'list',
                vue: 'listYear',
            },
        ]

        this.toggleAddButton = this.toggleAddButton.bind(this)
        this.closeAddMenu = this.closeAddMenu.bind(this)
        this.addButtonRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.calendarRef) {
            this.updateCalendarTitle()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { bobFilter } = this.props

        if (this.props !== prevProps) {
            this.updateCalendarTitle()
        }

        if (JSON.stringify(bobFilter) !== JSON.stringify(prevProps.bobFilter)) {
            this.setState({
                equipmentFamilyType: bobFilter.equipmentFamilyType,
                userEquipmentFamily: bobFilter.userEquipmentFamily,
                equipmentType: bobFilter.equipmentType,
                commissioningDate: {
                    start: bobFilter.commissioningDate.start,
                    end: bobFilter.commissioningDate.end,
                },
                replacementDate: {
                    start: bobFilter.replacementDate.start,
                    end: bobFilter.replacementDate.end,
                },
                rangeDate: {
                    start: bobFilter.rangeDate.start,
                    end: bobFilter.rangeDate.end,
                },
                checkupTypesChecked: bobFilter.checkupTypesChecked,
                rangeAmount: bobFilter.rangeAmount,
                rangeHour: bobFilter.rangeHour,
                userAttachedUserRole: bobFilter.userAttachedUserRole,
                userAttachedRoleType: bobFilter.userAttachedRoleType,
                userManagerSearch: bobFilter.userManagerSearch,
                unregisteredManagerSearch: bobFilter.unregisteredManagerSearch,
            })
        }
    }

    toggleAddButton() {
        this.setState(prevState => ({
            addMenuOpen: !prevState.addMenuOpen,
        }))
    }

    closeAddMenu() {
        this.setState(prevState => ({
            addMenuOpen: false,
        }))
    }

    downloadExcel() {
        this.props.dispatch(getRepairsExcel(this.context))
    }

    /**
     * Fonction utilisée pour formatter le mois + année
     * Appellée à chaque navigation de mois
     */
    updateCalendarTitle = () => {
        const calendar = this.props.calendarRef.calendar

        let title
        if (calendar.view.type === 'timeGridDay') {
            if (this.context === 'en') {
                title = calendar.getDate().toDateString()
            } else {
                const month = this.displayText(
                    monthNames[calendar.getDate().getMonth()],
                )
                let shortMonth =
                    month.length <= 4
                        ? month
                        : this.displayText(
                              monthNames[calendar.getDate().getMonth()],
                          ).slice(0, 3)
                if (
                    calendar.getDate().getMonth() === 6 &&
                    this.context === 'fr'
                ) {
                    //Juillet
                    shortMonth = 'Juil'
                }

                title =
                    this.displayText(jours[calendar.getDate().getDay()]).slice(
                        0,
                        3,
                    ) +
                    ' ' +
                    calendar
                        .getDate()
                        .getDate()
                        .toString()
                        .padStart(2, 0) +
                    ' ' +
                    shortMonth +
                    '. ' +
                    calendar.getDate().getFullYear()
            }
        } else if (calendar.view.type === 'multiMonthYear') {
            title = calendar.getDate().getFullYear()
        } else if (calendar.view.type === 'listYear') {
            title = calendar.getDate().getFullYear()
        } else {
            title =
                this.displayText(monthNames[calendar.getDate().getMonth()]) +
                ' ' +
                calendar.getDate().getFullYear()
        }

        // Mise à jour du titre du calendrier
        this.setState({ currentViewTitle: title })
    }

    _showPreviousDays() {
        const { classes, calendarRef } = this.props
        const today = calendarRef.calendar.getDate()

        const yesterday = getPreviousDay(today)
        const tomorrow = getNextDay(today)

        const bYesterday = getPreviousDay(yesterday)
        const aTomorrow = getNextDay(tomorrow)

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                }}
            >
                {/* Avant veille */}
                <div
                    className={classes.dayCell}
                    onClick={() => {
                        calendarRef.calendar.gotoDate(bYesterday)
                        this.forceUpdate()
                    }}
                >
                    <span className={classes.dayLetter}>
                        {this.displayText(jours[bYesterday.getDay()])[0]}
                    </span>
                    {bYesterday.getDate()}
                </div>

                {/* Veille */}
                <div
                    className={classes.dayCell}
                    onClick={() => {
                        calendarRef.calendar.gotoDate(yesterday)
                        this.forceUpdate()
                    }}
                >
                    <span className={classes.dayLetter}>
                        {this.displayText(jours[yesterday.getDay()])[0]}
                    </span>
                    {yesterday.getDate()}
                </div>

                {/* Jour actuel */}
                <div className={[classes.dayCell, 'current'].join(' ')}>
                    <span className={classes.dayLetter}>
                        {this.displayText(jours[today.getDay()])[0]}
                    </span>
                    {today.getDate()}
                </div>

                {/* Lendemain */}
                <div
                    className={classes.dayCell}
                    onClick={() => {
                        calendarRef.calendar.gotoDate(tomorrow)
                        this.forceUpdate()
                    }}
                >
                    <span className={classes.dayLetter}>
                        {this.displayText(jours[tomorrow.getDay()])[0]}
                    </span>
                    {tomorrow.getDate()}
                </div>

                {/* Sur-lendemain */}
                <div
                    className={classes.dayCell}
                    onClick={() => {
                        calendarRef.calendar.gotoDate(aTomorrow)
                        this.forceUpdate()
                    }}
                >
                    <span className={classes.dayLetter}>
                        {this.displayText(jours[aTomorrow.getDay()])[0]}
                    </span>
                    {aTomorrow.getDate()}
                </div>
            </div>
        )
    }

    render() {
        const { classes, calendarRef, downloadLoading } = this.props

        const isMobile = window.innerWidth < 600

        const { addMenuOpen } = this.state

        const filtersButton = this.filterButton()
        const nbActiveFilters = this.countNbElementsUsed()

        return (
            <div className={classes.fcHeader}>
                <div className={classes.titleContainer}>
                    <h3 className={classes.fcCurentViewTitle}>
                        {this.state.currentViewTitle}
                    </h3>
                    <div
                        className={classes.exportButton}
                        onClick={() => this.downloadExcel()}
                    >
                        <IosShare />
                        {isMobile
                            ? this.displayText('exportMobile')
                            : this.displayText('export')}
                    </div>
                </div>

                <div className={classes.fcToolbar}>
                    {isMobile && (
                        <>
                            {/* Section centrale de l'entête  */}
                            <div className={classes.fcSection}>
                                <div className={classes.fcButtonGroup}>
                                    <Button
                                        className={[
                                            'fc-next-button',
                                            'fc-button',
                                            classes.fcButtonSecondary,
                                            classes.fcButton,
                                            classes.small,
                                        ].join(' ')}
                                        onClick={() => {
                                            if (this.props.calendarRef) {
                                                this.props.calendarRef.calendar.prev()
                                                this.updateCalendarTitle()
                                            }
                                        }}
                                    >
                                        <span className="fc-icon fc-icon-chevron-left"></span>
                                    </Button>
                                    <Button
                                        className={[
                                            'fc-today-button',
                                            'fc-button',
                                            classes.fcButtonSecondary,
                                            classes.fcButton,
                                        ].join(' ')}
                                        onClick={() => {
                                            if (this.props.calendarRef) {
                                                this.props.calendarRef.calendar.today()
                                                this.updateCalendarTitle()
                                            }
                                        }}
                                    >
                                        {this.displayText('today')}
                                    </Button>
                                    <Button
                                        className={[
                                            'fc-next-button',
                                            'fc-button',
                                            classes.fcButtonSecondary,
                                            classes.fcButton,
                                            classes.small,
                                        ].join(' ')}
                                        type="button"
                                        onClick={() => {
                                            if (this.props.calendarRef) {
                                                this.props.calendarRef.calendar.next()
                                                this.updateCalendarTitle()
                                            }
                                        }}
                                    >
                                        <span className="fc-icon fc-icon-chevron-right"></span>
                                    </Button>
                                </div>

                                <div className={classes.fcButton}>
                                    {/* Sélecteur du type de vue (Jour, Semaine, Mois, Année) */}
                                    <Select
                                        className={[
                                            classes.viewDropdown,
                                            'select-no-border',
                                        ].join(' ')}
                                        onChange={e => {
                                            if (this.props.calendarRef) {
                                                //Mise à jour du type de vue
                                                this.props.changeView(
                                                    e.target.value,
                                                )

                                                //Mise à jour du titre
                                                this.updateCalendarTitle()

                                                //Masque l'entête si on est en vue Jour
                                                if (
                                                    e.target.value ==
                                                    'timeGridDay'
                                                ) {
                                                    this.props.calendarRef.calendar.setOption(
                                                        'dayHeaders',
                                                        false,
                                                    )
                                                } else {
                                                    this.props.calendarRef.calendar.setOption(
                                                        'dayHeaders',
                                                        true,
                                                    )
                                                }
                                            }
                                        }}
                                        value={calendarRef.calendar.view.type}
                                    >
                                        {this.optionsVues.map(vue => {
                                            return (
                                                <MenuItem
                                                    key={vue.vue}
                                                    className={styles.fcButton}
                                                    value={vue.vue}
                                                >
                                                    {this.displayText(vue.nom)}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className={classes.fcSection}>
                                <TextField
                                    id="search"
                                    margin="dense"
                                    variant="outlined"
                                    placeholder={this.displayText('search')}
                                    className={classes.field}
                                    value={this.state.searchString}
                                    onChange={e => {
                                        this.setState({
                                            searchString: e.target.value,
                                        })
                                    }}
                                    onBlur={e => {
                                        fixIosHeaderBug()
                                        this.props.dispatch(
                                            filterActions.updateFilterBob({
                                                searchString: this.state
                                                    .searchString,
                                            }),
                                        )
                                    }}
                                />
                            </div>
                            <div className={classes.fcSection}>
                                {/* Bouton Plus de filtres */}
                                {filtersButton && (
                                    <ButtonBoat
                                        containerStyle={{
                                            display: 'flex',
                                            flex: 1,
                                        }}
                                        fullWidth={true}
                                        onCancel={this.handleResetFilters}
                                        isCancelActived={nbActiveFilters > 0}
                                        selectedCount={nbActiveFilters}
                                        label={this.displayText('moreFilters')}
                                        variant="contained"
                                        type="primary"
                                        size="small"
                                        onClick={this.handleFilters}
                                        startIcon={
                                            !this.state.filters ? (
                                                <KeyboardArrowDown />
                                            ) : (
                                                <KeyboardArrowUp />
                                            )
                                        }
                                    />
                                )}

                                {/* Bouton Ajouter */}
                                <div
                                    ref={this.addButtonRef}
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                    }}
                                >
                                    <ButtonBoat
                                        fullWidth={true}
                                        type="primary"
                                        label={this.displayText('add')}
                                        style={{
                                            borderLeft: '1px solid black',
                                            height: '100%',
                                            minWidth: '46px',
                                        }}
                                        endIcon={<ArrowDropDownIcon />}
                                        aria-controls={
                                            addMenuOpen
                                                ? 'split-button-menu'
                                                : undefined
                                        }
                                        aria-expanded={
                                            addMenuOpen ? 'true' : undefined
                                        }
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={this.toggleAddButton}
                                    ></ButtonBoat>
                                </div>
                            </div>

                            {calendarRef.calendar.view.type ==
                                'timeGridDay' && (
                                <div>{this._showPreviousDays()}</div>
                            )}
                        </>
                    )}
                    {/* Fin - Header en mode Mobile */}

                    {/* Header en mode desktop */}
                    {!isMobile && (
                        <>
                            {/* Section gauche de l'entête  */}
                            <div className={classes.fcSection}>
                                <div className={classes.secondLine}>
                                    <div className={classes.searchFilterUnfold}>
                                        {/* Partie rechercher */}
                                        <div className={classes.recherche}>
                                            <TextField
                                                id="search"
                                                margin="dense"
                                                variant="outlined"
                                                placeholder={this.displayText(
                                                    'search',
                                                )}
                                                className={classes.field}
                                                value={
                                                    // (bobFilter && bobFilter.searchString) || ''
                                                    this.state.searchString
                                                }
                                                onChange={e => {
                                                    this.setState({
                                                        searchString:
                                                            e.target.value,
                                                    })
                                                }}
                                                onBlur={e => {
                                                    this.props.dispatch(
                                                        filterActions.updateFilterBob(
                                                            {
                                                                searchString: this
                                                                    .state
                                                                    .searchString,
                                                            },
                                                        ),
                                                    )
                                                }}
                                            />
                                        </div>
                                        {/* Fin partie rechercher */}

                                        {/* Bouton "Plus de filtre" */}
                                        {filtersButton && (
                                            <div>
                                                <ButtonBoat
                                                    className={
                                                        classes.filtersButton
                                                    }
                                                    onCancel={
                                                        this.handleResetFilters
                                                    }
                                                    isCancelActived={
                                                        nbActiveFilters > 0
                                                    }
                                                    selectedCount={
                                                        nbActiveFilters
                                                    }
                                                    label={this.displayText(
                                                        'moreFilters',
                                                    )}
                                                    variant="contained"
                                                    type="primary"
                                                    size="large"
                                                    onClick={this.handleFilters}
                                                    startIcon={
                                                        !this.state.filters ? (
                                                            <KeyboardArrowDown />
                                                        ) : (
                                                            <KeyboardArrowUp />
                                                        )
                                                    }
                                                ></ButtonBoat>
                                            </div>
                                        )}
                                        {/* Fin bouton "Plus de filtre" */}
                                    </div>
                                </div>
                            </div>

                            {/* Section centrale de l'entête  */}
                            <div className={classes.fcSection}>
                                <div className={classes.fcButtonGroup}>
                                    <Button
                                        className={[
                                            'fc-next-button',
                                            'fc-button',
                                            classes.fcButtonSecondary,
                                            classes.fcButton,
                                            classes.small,
                                        ].join(' ')}
                                        onClick={() => {
                                            if (this.props.calendarRef) {
                                                this.props.navigateDay(
                                                    'previous',
                                                )
                                                this.updateCalendarTitle()
                                            }
                                        }}
                                    >
                                        <span className="fc-icon fc-icon-chevron-left"></span>
                                    </Button>
                                    <Button
                                        className={[
                                            'fc-today-button',
                                            'fc-button',
                                            classes.fcButtonSecondary,
                                            classes.fcButton,
                                        ].join(' ')}
                                        onClick={() => {
                                            if (this.props.calendarRef) {
                                                this.props.navigateDay('today')
                                                this.updateCalendarTitle()
                                            }
                                        }}
                                    >
                                        {this.displayText('today')}
                                    </Button>
                                    <Button
                                        className={[
                                            'fc-next-button',
                                            'fc-button',
                                            classes.fcButtonSecondary,
                                            classes.fcButton,
                                            classes.small,
                                        ].join(' ')}
                                        type="button"
                                        onClick={() => {
                                            if (this.props.calendarRef) {
                                                this.props.navigateDay('next')
                                                this.updateCalendarTitle()
                                            }
                                        }}
                                    >
                                        <span className="fc-icon fc-icon-chevron-right"></span>
                                    </Button>
                                </div>
                            </div>

                            {/* Section droite de l'entête  */}
                            <div className={classes.fcSection}>
                                <div className={classes.fcButton}>
                                    {/* Sélecteur du type de vue (Jour, Semaine, Mois, Année) */}
                                    <Select
                                        className={[
                                            classes.viewDropdown,
                                            'select-no-border',
                                        ].join(' ')}
                                        onChange={e => {
                                            if (this.props.calendarRef) {
                                                //Mise à jour du type de vue
                                                this.props.changeView(
                                                    e.target.value,
                                                )

                                                //Mise à jour du titre
                                                this.updateCalendarTitle()

                                                //Masque l'entête si on est en vue Jour
                                                if (
                                                    e.target.value ==
                                                    'timeGridDay'
                                                ) {
                                                    this.props.calendarRef.calendar.setOption(
                                                        'dayHeaders',
                                                        false,
                                                    )
                                                } else {
                                                    this.props.calendarRef.calendar.setOption(
                                                        'dayHeaders',
                                                        true,
                                                    )
                                                }
                                            }
                                        }}
                                        value={calendarRef.calendar.view.type}
                                    >
                                        {this.optionsVues.map(vue => {
                                            return (
                                                <MenuItem
                                                    key={vue.vue}
                                                    className={styles.fcButton}
                                                    value={vue.vue}
                                                >
                                                    {this.displayText(vue.nom)}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div ref={this.addButtonRef}>
                                    <ButtonBoat
                                        className={classes.actionDropdown}
                                        type="primary"
                                        label={this.displayText('add')}
                                        size="large"
                                        style={{
                                            borderLeft: '1px solid black',
                                            height: '100%',
                                            minWidth: '46px',
                                        }}
                                        endIcon={<ArrowDropDownIcon />}
                                        aria-controls={
                                            addMenuOpen
                                                ? 'split-button-menu'
                                                : undefined
                                        }
                                        aria-expanded={
                                            addMenuOpen ? 'true' : undefined
                                        }
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={this.toggleAddButton}
                                    ></ButtonBoat>
                                </div>
                            </div>
                        </>
                    )}

                    <Popper
                        open={addMenuOpen}
                        anchorEl={this.addButtonRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement="bottom-end"
                        style={{
                            zIndex: 10,
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin: 'center top',
                                }}
                            >
                                <Paper elevation={2}>
                                    <ClickAwayListener
                                        onClickAway={this.closeAddMenu}
                                    >
                                        <MenuList
                                            id="split-button-menu"
                                            className={classes.addEventMenu}
                                        >
                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'prevRepair',
                                                    )
                                                }}
                                            >
                                                <img
                                                    src={repairer}
                                                    className={classes.iconMenu}
                                                    alt="repair"
                                                />
                                                <p>
                                                    {this.displayText(
                                                        'userAddPrevMaintenance',
                                                    )}
                                                </p>
                                            </MenuItem>
                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'nextRepair',
                                                    )
                                                }}
                                            >
                                                <img
                                                    src={repairer}
                                                    className={classes.iconMenu}
                                                    alt="repair"
                                                />
                                                <p>
                                                    {this.displayText(
                                                        'userAddNextMaintenance',
                                                    )}
                                                </p>
                                            </MenuItem>

                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'document',
                                                    )
                                                }}
                                            >
                                                <AttachFile />
                                                <p>
                                                    {this.displayText(
                                                        'userAddDocument',
                                                    )}
                                                </p>
                                            </MenuItem>
                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'addExpense',
                                                    )
                                                }}
                                            >
                                                <img
                                                    src={creditcard}
                                                    className={classes.iconMenu}
                                                    alt="credit card icon"
                                                />
                                                <p>
                                                    {this.displayText(
                                                        'userAddExpense',
                                                    )}
                                                </p>
                                            </MenuItem>
                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'addIncome',
                                                    )
                                                }}
                                            >
                                                <img
                                                    src={creditcard}
                                                    className={classes.iconMenu}
                                                    alt="credit card icon"
                                                />
                                                <p>
                                                    {this.displayText(
                                                        'userAddIncome',
                                                    )}
                                                </p>
                                            </MenuItem>
                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'workTime',
                                                    )
                                                }}
                                            >
                                                <TimelapseIcon
                                                    className={classes.iconMenu}
                                                />
                                                <p>
                                                    {this.displayText(
                                                        'addWorktime',
                                                    )}
                                                </p>
                                            </MenuItem>
                                            <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'absence',
                                                    )
                                                }}
                                            >
                                                <TimelapseIcon
                                                    className={classes.iconMenu}
                                                />
                                                <p>
                                                    {this.displayText(
                                                        'addAbsence',
                                                    )}
                                                </p>
                                            </MenuItem>

                                            {/* Evenement personnalisé */}
                                            {/* <MenuItem
                                                className={
                                                    classes.addEventMenuItem
                                                }
                                                onClick={() => {
                                                    this.props.openModal(
                                                        'customEvent',
                                                    )
                                                }}
                                            >
                                                <EventIcon style={{
                                                    width: 20,
                                                    height: 20
                                                }}/>
                                                <p>
                                                    {this.displayText(
                                                        'addCustomEvent',
                                                    )}
                                                </p>
                                            </MenuItem> */}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>

                {/* Liste des filtres */}
                <div className={classes.row}>
                    <div
                        className={
                            this.state.filters
                                ? classes.filtersBar
                                : classes.hidde
                        }
                    >
                        {filtersButton &&
                            filtersButton.map((filter, i) => {
                                return (
                                    <ButtonBoat
                                        onCancel={filter.reset}
                                        isCancelActived={filter.used}
                                        selectedCount={filter.nbUsed || null}
                                        key={i}
                                        label={filter.name}
                                        type={filter.used ? 'primary' : 'light'}
                                        variant="contained"
                                        size="small"
                                        style={{
                                            marginLeft: 0,
                                            marginBlock: '3px',
                                        }}
                                        onClick={e =>
                                            this.handleOpenPopOver(
                                                e,
                                                filter.child,
                                            )
                                        }
                                    />
                                )
                            })}
                    </div>
                </div>

                {downloadLoading > 0 && (
                    <BoatOnModal
                        openCondition={'loading'}
                        handleClose={() => {}}
                        modalCores={{
                            loading: <LoadingRepairModal />,
                        }}
                        titles={{
                            loading: this.displayText('loading'),
                        }}
                        maxWidth={{
                            loading: `sm`,
                        }}
                    />
                )}

                {/* Popover des filtres */}
                <Popover
                    id={'popover'}
                    open={this.state.openPopOver}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClosePopOver}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ marginTop: '5px' }}
                    classes={
                        this.state.childPopOver?.key === 'dateRange'
                            ? { paper: classes.paper, root: classes.rootPaper }
                            : ''
                    }
                >
                    {this.state.childPopOver}
                </Popover>
            </div>
        )
    }
}

function mapStateToProps(state, myprops) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        bobFilter: state.filter.bobFilter,
        checkupType: state.fetch.checkupType,
        boat: state.bob.boat,
        eventTypesCheckup: typesActions.getEventTypeCheckup(
            state.types.eventTypes,
        ),
        downloadLoading: state.download.loading,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        roles: state.group.roles || [],
        userRole: filterActions.filterUserRole(
            state.group?.groupsMembers?.linkRGU || [],
            state.authentication.user,
        ),
        activeButton: {
            rangeDate: true,
            checkupType: true,
            equipmentFamily: true,
            rangeAmount: true,
            rangeHour: true,
            equipment: true,
            userAttachedRole: true,
            managerSearch: true,
        },
        repair: true,
    }
}

function CheckboxFilter({ handleChange, checked }) {
    const [value, setChange] = useState(checked)
    return (
        <Checkbox
            checked={value}
            color="primary"
            onChange={e => {
                handleChange(e.target.checked)
                setChange(!value)
            }}
        ></Checkbox>
    )
}

function getPreviousDay(date = new Date()) {
    const previous = new Date(date.getTime())
    previous.setDate(date.getDate() - 1)

    return previous
}
function getNextDay(date = new Date()) {
    const previous = new Date(date.getTime())
    previous.setDate(date.getDate() + 1)

    return previous
}

export default connect(mapStateToProps)(withStyles(styles)(CalendarHeader))
