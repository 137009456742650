import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import AddressSearch from '../../../RentParkingPage/RentNavigationPages/AddressSearch'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookForecastCss'
import dictionary from './Dictionary/LogBookForecastDico'

class LogBookForecast extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.state = {
            zoom: 10,
            address: {
                fullText: 'Bordeaux',
                latitude: 44.841225,
                longitude: -0.5800364,
            },
        }

        this.setAddress = this.setAddress.bind(this)
    }

    componentDidMount() {
        this.updateScripts()
    }

    componentDidUpdate() {
        this.updateScripts()
    }

    updateScripts() {
        const scriptMap = document.createElement('script')
        const scriptForecast = document.createElement('script')
        const scriptWind = document.createElement('script')

        scriptMap.src = 'https://windy.app/widget3/windy_map_async.js?v289'
        scriptMap.async = true
        scriptMap.type = 'text/javascript'
        scriptMap.dataset.cfasync = false
        scriptMap.id = 'script-map'

        scriptForecast.src =
            'https://windy.app/widgets-code/forecast/windy_forecast_async.js?v1.4.6'
        scriptForecast.async = true
        scriptForecast.type = 'text/javascript'
        scriptForecast.dataset.cfasync = false
        scriptForecast.id = 'script-forecast'

        scriptWind.src = 'https://windy.app/widget2/windy_stats_async.js?v31'
        scriptWind.async = true
        scriptWind.type = 'text/javascript'
        scriptWind.dataset.cfasync = false
        scriptWind.id = 'script-wind'

        const findScriptMap = document.getElementById('script-map')
        const findScriptForecast = document.getElementById('script-forecast')
        const findScriptWind = document.getElementById('script-wind')

        if (findScriptMap) findScriptMap.remove()
        if (findScriptForecast) findScriptForecast.remove()
        if (findScriptWind) findScriptWind.remove()

        document.head.appendChild(scriptMap)
        document.head.appendChild(scriptForecast)
        document.head.appendChild(scriptWind)
    }

    setAddress(value) {
        this.setState({
            address: value,
        })
    }

    render() {
        const { zoom, address } = this.state
        const { classes, isMobile } = this.props

        return (
            <div className={classes.root}>
                <AddressSearch
                    style={{
                        minWidth: 200,
                        maxWidth: 350,
                        marginLeft: 0,
                    }}
                    label={this.displayText('city')}
                    placeholder="Bordeaux"
                    variant="outlined"
                    margin="dense"
                    setAddress={this.setAddress}
                    fullAddressInfo={address}
                />

                <div
                    className={classes.map}
                    data-windywidget="map"
                    data-thememode="white"
                    data-appid="widgets_39b3bb172a"
                    data-lat={address.latitude}
                    data-lng={address.longitude}
                    data-zoom={zoom}
                    data-height={isMobile ? '350px' : '500px'}
                    data-width="100%"
                />

                <div
                    className={classes.forecast}
                    data-windywidget="forecast"
                    data-thememode="white"
                    data-tempunit="C"
                    data-appid="widgets_00dac2d6ab"
                    data-lat={address.latitude}
                    data-lng={address.longitude}
                />

                {
                    // TODO : fix in the future : si pas affichées assez vite, le widget plante
                }
                <div
                    className={classes.wind}
                    data-windywidget="stats"
                    data-thememode="white"
                    data-responsive="true"
                    data-horizontal="true"
                    data-appid="widgets_f8128f4a18"
                    data-lat={address.latitude}
                    data-lng={address.longitude}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600

    return {
        isMobile,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookForecast))
