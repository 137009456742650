import React from 'react'
import { connect } from 'react-redux'
import AppRoute from '../../../../../constants/AppRoute'
import { history } from '../../../../../helpers'

import BoatOnComponent from '../../../../common/BoatOnComponent'
import BoatOnLoading from '../../../../common/UsefullComponents/BoatOnLoading'
import BoatOnModal from '../../../../common/BoatOnModal'
import BoatOnSpeedometer from '../../../../common/BoatOnSpeedometer'
import { Button as ButtonBON } from '../../../../common/BoatOnButton'
import { getContextFromUrl } from '../../../../../languages/LocalizerUtils'
import { Alert } from '@mui/material'
import { getTranslatedTitleFromEvent } from '../../Utils'

import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet'
import L from 'leaflet'

import startIcon from '../../../../../images/logbook/icons/map_pin_start.svg'
import stopOverIcon from '../../../../../images/logbook/icons/map_pin_stop.svg'
import endIcon from '../../../../../images/logbook/icons/map_pin_end.svg'

import dictionary from './Dictionary/TravelTabDico'
import {
    FlagOutlined as FlagOutlinedIcon,
    ModeEdit as ModeEditIcon,
    IosShare as IosShareIcon,
    Info as InfoIcon,
    AirlineStops as AirlineStopsIcon,
    Place as PlaceIcon,
    AirlineSeatReclineNormal as AirlineSeatReclineNormalIcon,
    MoreHoriz as MoreHorizIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material/'
import { Popover } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/TravelTabCss'

import PassengersModal from './Modals/PassengersModal'
import LogBookManualSetupModal from '../Modals/LogBookManualSetupModal'
import EditLevelsModal from './Modals/EditLevelsModal'
import EditWorktimesModal from './Modals/EditWorktimesModal'
import EndNavigationModal from './Modals/EndNavigationModal'
import RepairModal from '../../RepairPage/Modal/RepairModal'
import EditNavigationModal from './Modals/EditNavigationModal'
import EditCrewModal from './Modals/EditCrewModal'
import DisplayerImgModal from '../../../DisplayerImgModal'
import ConfirmationModal from './Modals/ConfirmationModal'

import { editNavigation, deleteNavigation } from '../../../../../actions'
import { getImageToDisplay } from '../../../../../actions/bob.actions'
import { getFillingLevels, getWorkingHours } from '../Utils/eventsFilter'
import { getDistanceFromCoordinates } from './Utils'
import FillAddressAisModal from './Modals/FillAddressAisModal'

class TravelTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedModal: false,
            equipmentCapacities: [],
            equipmentInUse: [],
            popoverIsOpen: false,
            anchorPopover: null,
            remplacementAddress: null,
        }

        this.startIconL = new L.Icon({
            iconUrl: startIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })
        this.stopOverIconL = new L.Icon({
            iconUrl: stopOverIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })
        this.endIconL = new L.Icon({
            iconUrl: endIcon,
            iconSize: [30, 30],
            iconAnchor: [15, 30],
            popupAnchor: [-3, -76],
        })

        this.getNavigationDuration = this.getNavigationDuration.bind(this)
        this.getDistanceFromCoord = this.getDistanceFromCoord.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.openModal = this.openModal.bind(this)
        this.openPopover = this.openPopover.bind(this)
        this.startStopover = this.startStopover.bind(this)
        this.deleteNavigation = this.deleteNavigation.bind(this)
        this.getMapData = this.getMapData.bind(this)
        this.getLastAisData = this.getLastAisData.bind(this)
        this.setRemplacementAddress = this.setRemplacementAddress.bind(this)
    }

    componentDidMount() {
        if (this.props.events) this._initState()
    }

    componentDidUpdate(prevProps) {
        const { navigation, events } = this.props

        if (events && events !== prevProps.events) {
            this._initState()
        }

        if (navigation !== prevProps.navigation) {
            this.setState({ navigation })
        }
    }

    _initState() {
        const { events, userEquipments } = this.props

        const workingHours = getWorkingHours(events)
        const fillingLevels = getFillingLevels(events, userEquipments)

        this.setState({
            equipmentInUse: workingHours.equipmentInUse,
            equipmentCapacities: fillingLevels.bobEventUsed,
        })
    }

    closeModal() {
        this.setState({ selectedModal: false })
    }

    deleteNavigation() {
        this.props.dispatch(deleteNavigation(this.props.navigation))
        this.historyPush(history, AppRoute.LogBook.LogBook, '', {}, {})
    }

    openModal(modal) {
        this.setState({ selectedModal: modal })
    }

    openPopover(e, open) {
        this.setState({
            popoverIsOpen: open,
            anchorPopover: e?.currentTarget || null,
        })
    }

    getNavigationDuration() {
        const { navigation } = this.props

        let totalMinutes = 0
        if (!navigation.delimitedDate?.startDate) return '00h00'

        let start = new Date(navigation.delimitedDate?.startDate)
        let end = null

        navigation.stopOvers.forEach(stop => {
            end = new Date(stop.delimitedDate?.startDate)
            totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60

            start = null
            end = null

            if (stop.delimitedDate?.endDate)
                start = new Date(stop.delimitedDate?.endDate)
        })

        if (start && navigation.delimitedDate?.endDate) {
            end = new Date(navigation.delimitedDate?.endDate)
            totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60
        }

        const h = Math.trunc(totalMinutes / 60)
        const min = Math.trunc(totalMinutes % 60)
        return `${h < 10 ? '0' : ''}${h}h${min < 10 ? '0' : ''}${min}`
    }

    getDistanceFromCoord() {
        const { aisData, navigation } = this.props

        if (navigation.nbDepartingPassengers === null) return 0

        const selectedAis = []

        if (navigation.departureAddress)
            selectedAis.push([
                navigation.departureAddress.latitude,
                navigation.departureAddress.longitude,
            ])

        aisData.forEach(data =>
            selectedAis.push([data.latitude, data.longitude]),
        )

        const kmDistance = getDistanceFromCoordinates(selectedAis)

        return Math.round(kmDistance * 0.539957 * 100) / 100 //convert km to miles
    }

    setRemplacementAddress(remplacementAddress) {
        this.setState({ remplacementAddress }, () => this.startStopover())
    }

    getLastAisData() {
        const { aisData } = this.props
        const { remplacementAddress } = this.state

        const lastAisData = aisData.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        )[aisData.length - 1]

        if (!lastAisData)
            return remplacementAddress ? remplacementAddress : null

        const currentDate = new Date()
        const treshold = 20
        const oldCurrentDate = new Date(
            currentDate.getTime() - treshold * 60 * 1000,
        )

        if (new Date(lastAisData.createdAt) < oldCurrentDate)
            return remplacementAddress ? remplacementAddress : null

        return lastAisData
    }

    startStopover() {
        const { navigation, dispatch } = this.props

        if (navigation.isAisUsed) {
            const lastAisData = this.getLastAisData()

            if (!lastAisData) {
                this.setState({ selectedModal: 'replacementAddressModal' })
                return
            }

            const address = {
                fullText: lastAisData?.fullText ? lastAisData.fullText : null,
                latitude: lastAisData.latitude,
                longitude: lastAisData.longitude,
            }

            if (navigation.stopOvers && navigation.stopOvers?.length > 0) {
                navigation.stopOvers = [
                    ...this.props.navigation.stopOvers,
                    {
                        delimitedDate: {
                            startDate: new Date(),
                            endDate: null,
                            comment: '',
                        },
                        address: address,
                        nbLeavingPassengers: 0,
                        nbBoardingPassengers: 0,
                    },
                ]
            } else {
                navigation.stopOvers = [
                    {
                        delimitedDate: {
                            startDate: new Date(),
                            endDate: null,
                            comment: '',
                        },
                        address: address,
                        nbLeavingPassengers: 0,
                        nbBoardingPassengers: 0,
                    },
                ]
            }

            this.setState({ navigation })
            dispatch(editNavigation(navigation))
        } else {
            this.openModal('editTravel')
        }
    }

    getMapData() {
        const { navigation, aisData } = this.props

        const mapData = {
            points: [],
            minPoint: {
                lat: 0,
                lon: 0,
            },
            maxPoint: {
                lat: 0,
                lon: 0,
            },
            center: {
                lat: 0,
                lon: 0,
            },
        }

        if (
            navigation?.departureAddress?.latitude &&
            navigation.departureAddress?.longitude
        ) {
            mapData.points.push({
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            })
            mapData.maxPoint = {
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            }
            mapData.minPoint = {
                lat: navigation.departureAddress?.latitude,
                lon: navigation.departureAddress?.longitude,
            }
        }

        if (navigation.stopOvers)
            navigation.stopOvers.forEach(stop => {
                if (stop?.address) {
                    mapData.points.push({
                        lat: stop.address?.latitude,
                        lon: stop.address?.longitude,
                    })
                    if (
                        stop.address?.latitude &&
                        stop.address?.latitude < mapData.minPoint.lat
                    ) {
                        mapData.minPoint.lat = stop.address?.latitude
                    }
                    if (
                        stop.address?.longitude &&
                        stop.address?.longitude < mapData.minPoint.lon
                    ) {
                        mapData.minPoint.lon = stop.address?.longitude
                    }

                    if (
                        stop.address?.latitude &&
                        stop.address?.latitude > mapData.maxPoint.lat
                    ) {
                        mapData.maxPoint.lat = stop.address?.latitude
                    }
                    if (
                        stop.address?.longitude &&
                        stop.address?.longitude > mapData.maxPoint.lon
                    ) {
                        mapData.maxPoint.lon = stop.address?.longitude
                    }
                }
            })

        if (
            navigation?.arrivalAddress?.latitude &&
            navigation.arrivalAddress?.longitude
        ) {
            mapData.points.push({
                lat: navigation.arrivalAddress?.latitude,
                lon: navigation.arrivalAddress?.longitude,
            })

            if (
                navigation.arrivalAddress?.latitude &&
                navigation.arrivalAddress?.latitude < mapData.minPoint.lat
            ) {
                mapData.minPoint.lat = navigation.arrivalAddress?.latitude
            }
            if (
                navigation.arrivalAddress?.longitude &&
                navigation.arrivalAddress?.longitude < mapData.minPoint.lon
            ) {
                mapData.minPoint.lon = navigation.arrivalAddress?.longitude
            }

            if (
                navigation.arrivalAddress?.latitude &&
                navigation.arrivalAddress?.latitude > mapData.maxPoint.lat
            ) {
                mapData.maxPoint.lat = navigation.arrivalAddress?.latitude
            }
            if (
                navigation.arrivalAddress?.longitude &&
                navigation.arrivalAddress?.longitude > mapData.maxPoint.lon
            ) {
                mapData.maxPoint.lon = navigation.arrivalAddress?.longitude
            }
        }

        mapData.center = {
            lon: mapData.maxPoint.lon - mapData.minPoint.lon,
            lat: mapData.maxPoint.lat - mapData.minPoint.lat,
        }

        mapData.minPoint = {
            lon: mapData.minPoint.lon - 0.05,
            lat: mapData.minPoint.lat - 0.05,
        }

        mapData.maxPoint = {
            lon:
                typeof mapData.maxPoint.lon === 'string'
                    ? parseFloat(mapData.maxPoint.lon) + 0.05
                    : mapData.maxPoint.lon + 0.05,
            lat:
                typeof mapData.maxPoint.lat === 'string'
                    ? parseFloat(mapData.maxPoint.lat) + 0.05
                    : mapData.maxPoint.lat + 0.05,
        }

        if (navigation.isAisUsed) {
            const selectedAis = aisData.map(data => ({
                lon: data.longitude,
                lat: data.latitude,
            }))

            selectedAis.forEach(point => {
                if (point.lon > mapData.maxPoint.lon)
                    mapData.maxPoint.lon = point.lon
                if (point.lat > mapData.maxPoint.lat)
                    mapData.maxPoint.lat = point.lat
                if (point.lon < mapData.minPoint.lon)
                    mapData.minPoint.lon = point.lon
                if (point.lat < mapData.minPoint.lat)
                    mapData.minPoint.lat = point.lat
            })
        }

        return mapData
    }

    _renderSharedButtons() {
        const { popoverIsOpen, anchorPopover } = this.state
        const { classes, navigation, openModal } = this.props

        if (
            !navigation.delimitedDate?.startDate ||
            navigation.nbDepartingPassengers === null
        )
            return null

        const isEnded =
            navigation.delimitedDate?.startDate &&
            navigation.delimitedDate?.endDate

        return (
            <div className={classes.sharedButtons}>
                <ButtonBON
                    classNameOverride={classes.outlinedBtn}
                    type="error"
                    label={
                        <>
                            <FlagOutlinedIcon />
                            {this.displayText('declareIncident')}
                        </>
                    }
                    size={`large`}
                    fullWidth
                    onClick={() => this.openModal('incident')}
                />
                {isEnded && (
                    <>
                        <MoreHorizIcon
                            className={classes.travelIcons}
                            onClick={e => this.openPopover(e, true)}
                        />

                        <Popover
                            open={popoverIsOpen}
                            onClose={() => this.openPopover(null, false)}
                            anchorEl={anchorPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className={classes.popoverContent}>
                                <div
                                    className={classes.popoverRow}
                                    onClick={() => {
                                        this.openModal('editNav')
                                        this.openPopover(null, false)
                                    }}
                                >
                                    <ModeEditIcon
                                        className={classes.travelIcons}
                                    />
                                    {this.displayText('editNavigation')}
                                </div>
                                <div
                                    className={classes.popoverRow}
                                    onClick={() => {
                                        openModal('share')
                                        this.openPopover(null, false)
                                    }}
                                >
                                    <IosShareIcon
                                        className={classes.travelIcons}
                                    />
                                    {this.displayText('shareNavigation')}
                                </div>
                                <div
                                    className={classes.popoverRow}
                                    onClick={() => {
                                        this.openModal('confirmationModal')
                                        this.openPopover(null, false)
                                    }}
                                >
                                    <DeleteIcon
                                        className={classes.travelIcons}
                                    />
                                    {this.displayText('deleteNavigation')}
                                </div>
                            </div>
                        </Popover>
                    </>
                )}
            </div>
        )
    }

    _renderTravelStepButton() {
        const { classes, navigation, aisData } = this.props

        if (
            !navigation.delimitedDate?.startDate ||
            navigation.nbDepartingPassengers === null
        )
            return (
                <div className={classes.stepBtns}>
                    <div className={classes.travelVerticalContainer}>
                        <Alert
                            severity="info"
                            className={classes.alert}
                            icon={<InfoIcon className={classes.alertIcon} />}
                        >
                            {navigation.isAisUsed &&
                            aisData.length <= 0 &&
                            !navigation.departureAddress
                                ? this.displayText('impossibleToStart')
                                : this.displayText('getReadyMsg')}
                        </Alert>
                        <ButtonBON
                            type="primary"
                            label={this.displayText('startNavigation')}
                            size={`large`}
                            fullWidth
                            onClick={() => this.openModal('passengersModal')}
                            disabled={
                                navigation.isAisUsed &&
                                aisData.length <= 0 &&
                                !navigation.departureAddress
                            }
                        />
                    </div>
                </div>
            )

        let isInStopover = false

        navigation.stopOvers.forEach(stop => {
            if (!stop.delimitedDate?.endDate) isInStopover = true
        })

        if (
            navigation.delimitedDate?.startDate &&
            !navigation.delimitedDate?.endDate &&
            isInStopover
        )
            return (
                <div className={classes.stepBtns}>
                    <ButtonBON
                        type="primary"
                        label={this.displayText('leaveStopover')}
                        size={`large`}
                        fullWidth
                        onClick={() => this.openModal('passengersModal')}
                    />
                </div>
            )

        if (
            navigation.delimitedDate?.startDate &&
            !navigation.delimitedDate?.endDate
        )
            return (
                <div className={classes.stepBtns}>
                    <div className={classes.travelHorizontalContainer}>
                        <ButtonBON
                            type="primary"
                            label={
                                <>
                                    <AirlineStopsIcon />
                                    {this.displayText('stopover')}
                                </>
                            }
                            size={`large`}
                            fullWidth
                            onClick={this.startStopover}
                        />
                        <ButtonBON
                            type="error"
                            label={
                                <>
                                    <FlagOutlinedIcon
                                        className={classes.hideIcon}
                                    />
                                    {this.displayText('endNavigation')}
                                </>
                            }
                            size={`large`}
                            fullWidth
                            onClick={() => this.openModal('end')}
                        />
                    </div>
                </div>
            )

        return null
    }

    _getAisData() {
        const { aisData, navigation } = this.props

        const aisPoints = []

        if (navigation.departureAddress)
            aisPoints.push([
                navigation.departureAddress.latitude,
                navigation.departureAddress.longitude,
            ])

        aisData
            .sort(
                (a, b) =>
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime(),
            )
            .forEach((data, index, array) => {
                aisPoints.push([data.latitude, data.longitude])
                const nextData =
                    index < array.length - 1 ? array[index + 1] : null

                const stopOvers =
                    !nextData && navigation?.stopOvers?.length > 0
                        ? navigation.stopOvers.filter(
                              (stop, index, stopOvers) =>
                                  index + 1 === stopOvers.length &&
                                  stop.delimitedDate.startDate &&
                                  new Date(stop.delimitedDate.startDate) >
                                      new Date(data.createdAt),
                          )
                        : navigation?.stopOvers?.length > 0
                        ? navigation.stopOvers.filter(
                              stop =>
                                  stop.delimitedDate.startDate &&
                                  new Date(nextData.createdAt) >
                                      new Date(stop.delimitedDate.startDate) &&
                                  new Date(stop.delimitedDate.startDate) >
                                      new Date(data.createdAt),
                          )
                        : null

                if (stopOvers && stopOvers.length > 0) {
                    stopOvers.forEach(stop => {
                        aisPoints.push([
                            stop.address.latitude,
                            stop.address.longitude,
                        ])
                    })
                }
            })

        if (navigation.arrivalAddress)
            aisPoints.push([
                navigation.arrivalAddress.latitude,
                navigation.arrivalAddress.longitude,
            ])

        return aisPoints
    }

    _renderMap() {
        const { classes, navigation } = this.props
        const mapData = this.getMapData()
        const markers = mapData.points.map((point, index) => {
            if (index === 0) {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.startIconL}
                    />
                )
            } else if (
                index < mapData.points.length - 1 ||
                !navigation.delimitedDate.endDate
            ) {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.stopOverIconL}
                    />
                )
            } else {
                return (
                    <Marker
                        position={point}
                        key={`marker-${index}`}
                        icon={this.endIconL}
                    />
                )
            }
        })
        let aisPoints = []

        if (navigation.isAisUsed) {
            aisPoints = this._getAisData()
        }

        return (
            <div className={classes.mapContainer}>
                <MapContainer
                    center={mapData.center}
                    bounds={[
                        [mapData.minPoint.lat, mapData.minPoint.lon],
                        [mapData.maxPoint.lat, mapData.maxPoint.lon],
                    ]}
                    maxZoom={13}
                >
                    <TileLayer
                        attribution={`&copy;
                            <a href="https://www.openstreetmap.org/copyright">
                                OpenStreetMap
                            </a> contributors`}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {navigation.nbDepartingPassengers !== null && (
                        <Polyline
                            pathOptions={{
                                color: '#303F9F',
                                bubblingMouseEvents: false,
                            }}
                            positions={aisPoints}
                        />
                    )}
                    {markers}
                </MapContainer>
            </div>
        )
    }

    _renderCrew() {
        const { classes, navigation, linkedUsers, isMobile } = this.props

        const captain = linkedUsers.find(
            user => user.id === navigation.captainLinkId,
        )
        const captainFistName =
            captain?.user?.firstName || captain?.userSubscribe?.mail || ''
        const captainLastName = captain?.user?.lastName || ''

        const navHasStart =
            (navigation.isAisUsed && navigation.delimitedDate.startDate) ||
            navigation.stopOvers.length > 0 ||
            navigation.delimitedDate?.endDate

        const crew = navigation.navigationMembers.map((user, index) => {
            const crewM = linkedUsers.find(
                linkedUser => linkedUser.id === user.userLinkId,
            )

            if (!crewM) return null

            const crewMFistName =
                crewM?.user?.firstName || crewM?.userSubscribe?.mail || ''
            const crewMLastName = crewM?.user?.lastName || ''

            if (isMobile)
                return (
                    <div className={classes.badge} key={`userbadge-${index}`}>
                        {crewMFistName?.charAt(0).toUpperCase()}
                        {crewMLastName?.charAt(0).toUpperCase()}
                    </div>
                )

            return (
                <div className={classes.crewItem} key={`userbadge-${index}`}>
                    <div className={classes.badge}>
                        {crewMFistName?.charAt(0).toUpperCase()}
                        {crewMLastName?.charAt(0).toUpperCase()}
                    </div>
                    {`${crewMFistName} ${crewMLastName}`}
                </div>
            )
        })

        return (
            <div className={classes.containerCrew}>
                {!navHasStart && (
                    <ModeEditIcon
                        className={classes.iconEditCrew}
                        onClick={() => this.openModal('editCrew')}
                    />
                )}
                <div
                    className={classes.containerCrewTitle}
                    style={{ gridArea: 'captain-title' }}
                >
                    {this.displayText('captain')}
                </div>
                {crew.length > 0 && (
                    <div
                        className={classes.containerCrewTitle}
                        style={{ gridArea: 'crew-title' }}
                    >
                        {this.displayText('crewMembers')}
                    </div>
                )}
                <div
                    className={classes.containerCrewData}
                    style={{ gridArea: 'captain-data' }}
                >
                    <div className={classes.badge}>
                        {captainFistName?.charAt(0).toUpperCase()}
                        {captainLastName?.charAt(0).toUpperCase()}
                    </div>
                    {`${captainFistName} ${captainLastName}`}
                </div>
                <div
                    className={classes.containerCrewDataGroup}
                    style={{ gridArea: 'crew-data' }}
                >
                    {crew}
                </div>
            </div>
        )
    }

    _renderHour(time) {
        const dateTime = new Date(time)
        const hours =
            dateTime.getHours() < 10
                ? `0${dateTime.getHours()}`
                : dateTime.getHours()
        const minutes =
            dateTime.getMinutes() < 10
                ? `0${dateTime.getMinutes()}`
                : dateTime.getMinutes()

        return `${hours}h${minutes}`
    }

    _renderSteps() {
        const { classes, navigation } = this.props

        const stopoversOrdered = navigation.stopOvers.sort(
            (a, b) => a.delimitedDate?.startDate - b.delimitedDate?.endDate,
        )

        let passengers = navigation.nbDepartingPassengers

        const renderStopOvers = stopoversOrdered.map((stop, index) => {
            passengers += stop.nbBoardingPassengers
            passengers -= stop.nbLeavingPassengers

            const address = stop.address
            const localisationAddress = `${
                address?.city
                    ? address.city
                    : address?.fullText
                    ? address.fullText.split(',')[0]
                    : address?.latitude && address?.longitude
                    ? `${address.latitude}, ${address.longitude}`
                    : ''
            }`

            const startDate = new Date(stop.delimitedDate.startDate)

            return (
                <div className={classes.rowStep} key={`stop-${index}`}>
                    <div className={classes.stepHours}>
                        {startDate.toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                        })}
                    </div>
                    <div className={classes.stepDotContainer}>
                        <div className={classes.stepDotRoute} />
                        <div className={classes.stepDotStop} />
                    </div>
                    <div className={classes.stepDataContainer}>
                        <div className={classes.stepDataHourStopover}>
                            {stop.delimitedDate.endDate
                                ? `${this._renderHour(
                                      stop.delimitedDate?.startDate,
                                  )} - ${this._renderHour(
                                      stop.delimitedDate?.endDate,
                                  )}`
                                : this._renderHour(
                                      stop.delimitedDate?.startDate,
                                  )}
                        </div>
                        <div className={classes.stepDataPlaceLabel}>
                            {this.displayText('stopover')}
                            <PlaceIcon className={classes.stepPlaceIcon} />
                            <span className={classes.stepDataPlace}>
                                {localisationAddress}
                            </span>
                        </div>
                    </div>
                    {stop.delimitedDate?.endDate && (
                        <div className={classes.stepSeatsContainer}>
                            <AirlineSeatReclineNormalIcon
                                className={classes.seatsIcon}
                            />
                            {passengers}
                        </div>
                    )}
                </div>
            )
        })

        const departure = navigation.departureAddress
        const arrival = navigation.arrivalAddress
        const localisationDeparture = `${
            departure?.city
                ? departure.city
                : departure?.fullText
                ? departure.fullText.split(',')[0]
                : departure?.latitude && departure?.longitude
                ? `${departure.latitude}, ${departure.longitude}`
                : ''
        }`
        const localisationArrival = `${
            arrival?.city
                ? arrival.city
                : arrival?.fullText
                ? arrival.fullText.split(',')[0]
                : arrival?.latitude && arrival?.longitude
                ? `${arrival.latitude}, ${arrival.longitude}`
                : ''
        }`

        const startDate = new Date(navigation.delimitedDate.startDate)
        const endDate = navigation.delimitedDate.endDate
            ? new Date(navigation.delimitedDate.endDate)
            : null

        return (
            <div className={classes.travelSteps}>
                <div className={classes.travelStepsTime}>
                    <div>
                        <div className={classes.travelTimeData}>
                            {this.getNavigationDuration()}
                        </div>
                        <div className={classes.travelTimeTitle}>
                            {this.displayText('navigationDuration')}
                        </div>
                    </div>
                    {navigation.isAisUsed && (
                        <div>
                            <div
                                className={`
                                    ${classes.travelTimeData} ${classes.black}
                            `}
                            >
                                {this.getDistanceFromCoord()}
                                <span className={classes.travelTimeSubtitle}>
                                    {this.displayText('nauticMille')}
                                </span>
                            </div>
                            <div className={classes.travelTimeTitle}>
                                {this.displayText('travelDistance')}
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.travelStepsDetails}>
                    <div className={classes.rowStep}>
                        <div className={classes.stepHours}>
                            {startDate.toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                            })}
                        </div>
                        <div className={classes.stepDotContainer}>
                            <div className={classes.stepDot} />
                        </div>
                        <div className={classes.stepDataContainer}>
                            <div className={classes.stepDataHour}>
                                {this._renderHour(
                                    navigation.delimitedDate?.startDate,
                                )}
                            </div>
                            <div className={classes.stepDataPlaceLabel}>
                                {this.displayText('departure')}
                                <PlaceIcon className={classes.stepPlaceIcon} />
                                <span className={classes.stepDataPlace}>
                                    {localisationDeparture}
                                </span>
                            </div>
                        </div>
                        <div className={classes.stepSeatsContainer}>
                            <AirlineSeatReclineNormalIcon
                                className={classes.seatsIcon}
                            />
                            {navigation.nbDepartingPassengers}
                        </div>
                    </div>

                    {renderStopOvers}

                    {navigation.delimitedDate?.endDate && (
                        <div className={classes.rowStep}>
                            <div className={classes.stepHours}>
                                {endDate.toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: '2-digit',
                                })}
                            </div>
                            <div className={classes.stepDotContainer}>
                                <div className={classes.stepDotRoute} />
                                <div className={classes.stepDot} />
                            </div>
                            <div className={classes.stepDataContainer}>
                                <div className={classes.stepDataHour}>
                                    {this._renderHour(
                                        navigation.delimitedDate?.endDate,
                                    )}
                                </div>
                                <div className={classes.stepDataPlaceLabel}>
                                    {this.displayText('arrival')}
                                    <PlaceIcon
                                        className={classes.stepPlaceIcon}
                                    />
                                    <span className={classes.stepDataPlace}>
                                        {localisationArrival}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    _renderStocks() {
        const { equipmentCapacities } = this.state

        const { context, classes, navigation } = this.props

        const displayLevels = equipmentCapacities
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null
                const type = equipment?.equipmentFamily?.type || null
                const optionnalDetail = event.detail?.optionnalDetail || null

                if (!equipment) return null

                const imgSrc = type?.image
                    ? require(`images/${type?.image}`)
                    : null
                const value =
                    navigation?.fillingLevels.find(
                        item => item.equipment.id === equipment.id,
                    )?.level || 0

                const unit =
                    equipment.equipmentType?.optionnalDetailActivated?.unit

                return (
                    <div
                        className={classes.stockItemContainer + ' case'}
                        key={`stockItem-${index}`}
                    >
                        <div className={classes.stockItemTitle}>
                            <img
                                src={imgSrc}
                                alt={type?.translation[context] || ''}
                                className={classes.stockIcon}
                            />
                            {getTranslatedTitleFromEvent(this, event)}
                        </div>
                        <div className={classes.stockItemLevels}>
                            <div className={classes.stockItemGraph}>
                                <BoatOnSpeedometer
                                    inverted
                                    forceNormalRender
                                    removeGraduate
                                    minValue={0}
                                    maxValue={100}
                                    value={value}
                                />
                            </div>
                            <div className={classes.stockItemValues}>
                                <div
                                    className={
                                        value < 25
                                            ? `${classes.stockPercent} ${classes.redColor}`
                                            : classes.stockPercent
                                    }
                                >
                                    {`${value}%`}
                                </div>
                                <div className={classes.stockCapacity}>
                                    {optionnalDetail?.capacity
                                        ? `${optionnalDetail?.capacity ||
                                              ''} ${unit}`
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <div className={classes.stocks}>
                <div
                    className={`${classes.sectionTitle} ${classes.stockTitle}`}
                >
                    {this.displayText('stockTitle')}
                </div>
                <div className={classes.stockContainer}>{displayLevels}</div>

                <ButtonBON
                    type="text"
                    classNameOverride={classes.updateStocks}
                    label={
                        <>
                            <ModeEditIcon className={classes.updateIcon} />
                            {this.displayText('updateStocks')}
                        </>
                    }
                    onClick={() => this.openModal('editLevels')}
                />
            </div>
        )
    }

    _renderWorkingTimes() {
        const { equipmentInUse } = this.state

        const { context, classes, navigation } = this.props

        const displayWokingEquipment = equipmentInUse
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null
                const type = equipment.equipmentFamily?.type || null

                if (!equipment) return null

                const imgSrc = type?.image
                    ? require(`images/${type?.image}`)
                    : null

                const value =
                    navigation.workingEquipmentHours.find(
                        item => item.equipment.id === equipment.id,
                    )?.hours || 0

                return (
                    <div
                        className={classes.worktimeItemContainer + ' case'}
                        key={`worktimeItem-${index}`}
                    >
                        <img
                            src={imgSrc}
                            alt={type?.translation[context] || ''}
                            className={classes.worktimeIcon}
                        />
                        <div className={classes.worktimeDataContainer}>
                            <div className={classes.worktimeItemTitle}>
                                {getTranslatedTitleFromEvent(this, event)}
                            </div>
                            <div className={classes.worktimeItemValue}>
                                {`${value} h`}
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <div className={classes.workingtime}>
                <div
                    className={`${classes.sectionTitle} ${classes.worktimeTitle}`}
                >
                    {this.displayText('worktimeTitle')}
                </div>
                <div className={classes.worktimeContainer}>
                    {displayWokingEquipment}
                </div>

                <ButtonBON
                    type="text"
                    classNameOverride={classes.updateWorktime}
                    label={
                        <>
                            <ModeEditIcon className={classes.updateIcon} />
                            {this.displayText('updateWorktimes')}
                        </>
                    }
                    onClick={() => this.openModal('editWorktimes')}
                />
            </div>
        )
    }

    render() {
        const {
            classes,
            id,
            navigation,
            isMobile,
            onChangeTab,
            isLoading,
        } = this.props
        const { selectedModal } = this.state
        const isEnded =
            navigation?.delimitedDate?.startDate &&
            navigation?.delimitedDate?.endDate

        if (navigation === undefined || (isLoading > 0 && !navigation))
            return <BoatOnLoading />

        return (
            <div
                className={
                    isEnded
                        ? `${classes.navigationContainer} ${classes.navWithoutBtn}`
                        : classes.navigationContainer
                }
            >
                {isMobile && this._renderSharedButtons()}
                {this._renderTravelStepButton()}
                {this._renderCrew()}
                {navigation.delimitedDate?.startDate &&
                    navigation.nbDepartingPassengers !== null &&
                    this._renderSteps()}
                {this._renderMap()}
                {isMobile && isEnded && (
                    <div className={classes.detailsBtn}>
                        <ButtonBON
                            type="outline"
                            classNameOverride={classes.outlinedPrimaryBtn}
                            label={this.displayText('navDetails')}
                            size={`large`}
                            fullWidth={isMobile ? true : false}
                            onClick={() => onChangeTab(1)}
                        />
                    </div>
                )}
                {this._renderStocks()}
                {this._renderWorkingTimes()}
                <div
                    className={
                        (navigation.comment ||
                            (navigation.files &&
                                navigation.files.length > 0)) &&
                        classes.containerFeedback
                    }
                >
                    {navigation.comment && (
                        <>
                            <div className={classes.sectionTitle}>
                                {this.displayText('judgmentTitle')}
                            </div>
                            <div className={classes.judgmentValue}>
                                {navigation.comment}
                            </div>
                        </>
                    )}
                    {navigation.files && navigation.files.length > 0 && (
                        <>
                            <div className={classes.sectionTitle}>
                                {this.displayText('imagesTitle')}
                            </div>
                            <div className={classes.containerHorizontalImages}>
                                <DisplayerImgModal
                                    file={getImageToDisplay(navigation)}
                                />
                            </div>
                        </>
                    )}
                </div>

                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.closeModal}
                    modalCores={{
                        passengersModal:
                            navigation.isAisUsed ||
                            !navigation.delimitedDate.startDate ||
                            navigation.nbDepartingPassengers === null ? (
                                <PassengersModal
                                    onClose={this.closeModal}
                                    noCross={false}
                                />
                            ) : (
                                <LogBookManualSetupModal
                                    id={id}
                                    onClose={this.closeModal}
                                />
                            ),
                        end: <EndNavigationModal onClose={this.closeModal} />,
                        incident: (
                            <RepairModal
                                language={this.getContext()}
                                handleSelfClose={this.closeModal}
                                handleValidatedEvent={this.closeModal}
                                noCross={false}
                                showFollowButton={true}
                                type={`NEXT_REPAIR`}
                                reparation={true}
                            />
                        ),
                        editCrew: (
                            <EditCrewModal
                                onClose={this.closeModal}
                                noCross={false}
                            />
                        ),
                        editLevels: (
                            <EditLevelsModal
                                onClose={this.closeModal}
                                noCross={false}
                            />
                        ),
                        editWorktimes: (
                            <EditWorktimesModal
                                onClose={this.closeModal}
                                noCross={false}
                            />
                        ),
                        editNav: (
                            <EditNavigationModal
                                onClose={this.closeModal}
                                changeModal={this.openModal}
                                noCross={false}
                            />
                        ),
                        editTravel: (
                            <LogBookManualSetupModal
                                id={id}
                                onClose={this.closeModal}
                                onCancel={() => this.openModal('editNav')}
                                noCross={false}
                            />
                        ),
                        confirmationModal: (
                            <ConfirmationModal
                                onClose={this.closeModal}
                                onValid={this.deleteNavigation}
                                noCross={false}
                            />
                        ),
                        replacementAddressModal: (
                            <FillAddressAisModal
                                onClose={this.closeModal}
                                onValid={this.setRemplacementAddress}
                                noCross={false}
                            />
                        ),
                    }}
                    maxWidth={{
                        passengersModal: 'sm',
                        end: 'sm',
                        incident: 'sm',
                        editLevels: 'sm',
                        editWorktimes: 'sm',
                        editNav: 'sm',
                        editTravel: 'sm',
                        editCrew: 'sm',
                        confirmationModal: 'sm',
                        replacementAddressModal: 'sm',
                    }}
                    titles={{
                        passengersModal: this.displayText('passengersNumber'),
                        end: this.displayText('endNavigationTitle'),
                        incident: this.displayText('declareIncidentTitle'),
                        editLevels: this.displayText('editLevel'),
                        editWorktimes: this.displayText('editWorktimes'),
                        editNav: this.displayText('editNavigation'),
                        editTravel: this.displayText('editTravel'),
                        editCrew: this.displayText('editCrew'),
                        confirmationModal: this.displayText(
                            'confirmationModal',
                        ),
                        replacementAddressModal: this.displayText(
                            'aisWithoutAddress',
                        ),
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = (state.logbook.navigations || []).find(
        nav => nav.id === parseInt(id),
    )
    const context = getContextFromUrl(url)
    const isMobile = window.innerWidth < 600
    const groupBoats = state.group?.groupsMembers?.boats || []
    const currentBoat = groupBoats.find(boat => boat.id === navigation?.boatId)

    const currentAis = navigation
        ? (state.navigationApiData.ais || []).filter(
              ais => ais.navigationId === navigation.id,
          )[0] || null
        : null

    return {
        isMobile,
        context,
        id,
        navigation,
        currentBoat,
        aisData: currentAis?.aisData || [],
        isLoading: state.logbook.loading,
        events: state.bob.events,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        userEquipments: state.types?.userEquipments || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(TravelTab))
