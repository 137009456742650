import React from 'react'
import ReactGA from 'react-ga'
import { connect } from 'react-redux'
import ReactPixel from 'react-facebook-pixel'
import { withStyles } from '@material-ui/core/styles'

import Navigation from '../common/Navigation'
import BoatOnComponent from '../common/BoatOnComponent'
import LogbookNavigation from './LogbookNavigation'
import styles from './Styles/LogbookHomeCss'
import { initBobWithBoat } from '../../actions/bob.actions'
import ModalNoBoat from '../common/UsefullComponents/ModalConnection/ModalNoBoat'
import LandingPage from '../LandingPage/LandingPage'
import { userActions } from '../../actions/user.actions'
import AppRoute from '../../constants/AppRoute'
import { appStrings } from '../../languages'
import PricesPage from '../PricesPage/PricesPage'
import BoatOnBlock from '../common/Blocks/BoatOnBlock'
import { getBoatIdToShow } from '../../actions/bob.actions'
import { groupActions, typesActions } from '../../actions'

class LogbookHome extends BoatOnComponent {
    constructor(props) {
        super(props)

        const options = {
            autoConfig: true,
            debug: false,
        }

        this.state = {
            openModalNoBoat: false,
            modalNoBoatShow: false,
        }

        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook')

        this.handleClose = this.handleClose.bind(this)
        this.updateBOB = this.updateBOB.bind(this)
        this.getBOB = this.getBOB.bind(this)
    }

    componentDidMount() {
        const { user, currentGroupId } = this.props

        if (!currentGroupId) {
        }

        if (user && !this.props.subscriptions) {
            this.props.dispatch(userActions.requestSubscriptions())
        }

        if (user && currentGroupId) {
            this.updateBOB()
        }
        const { boatTypes, loadingTypes } = this.props
        if (!boatTypes && !loadingTypes) {
            this.props.dispatch(typesActions.requestBoatTypes())
        }
    }

    componentDidUpdate(prevProps) {
        const {
            user,
            bob,
            location,
            loading,
            loadingUser,
            loadingBoat,
            currentGroupId,
        } = this.props
        if (
            prevProps.location &&
            prevProps.location.pathname &&
            location.pathname.toString() !==
                prevProps.location.pathname.toString()
        ) {
            this.setState({ openModalNoBoat: false })
        }
        if (location.pathname !== prevProps.location.pathname) {
            this.setState({ openModalNoBoat: false })
        }
        if (this.props.boat?.id && this.state.openModalNoBoat === true) {
            this.setState({ openModalNoBoat: false })
        }
        if (
            loading === 0 &&
            ((((loadingUser === 0 && prevProps.loadingUser !== 0) ||
                (loadingBoat === 0 && prevProps.loadingBoat !== 0)) &&
                user) ||
                currentGroupId !== prevProps.currentGroupId ||
                bob.events === null)
        ) {
            this.updateBOB(prevProps)
        }

        if (
            user &&
            !this.props.boat?.id &&
            getBoatIdToShow(user, currentGroupId) === null &&
            ((prevProps.location &&
                prevProps.location.pathname &&
                location.pathname.toString() !==
                    prevProps.location.pathname.toString()) ||
                (prevProps.loadingBoat !== 0 && loadingBoat === 0)) &&
            !(
                location.pathname ===
                this.getLogBookPath(AppRoute.LogBook.Pricing)
            )
        ) {
            this.setState({ openModalNoBoat: true })
        }
        if (
            !this.props.boat?.id &&
            bob.events !== null &&
            loading === 0 &&
            this.state.openModalNoBoat === false
        ) {
            this.setState({ openModalNoBoat: true })
        }
    }

    getBoatById(id) {
        const { boats } = this.props
        if (boats) {
            return boats.find(boat => boat.id == id)
        }
    }

    async getBOB() {
        const {
            user,
            eventLoading,
            currentGroupId,
            location: { pathname },
        } = this.props
        if (eventLoading === 0) {
            if (!this.props.events) {
                await this.props.dispatch(
                    initBobWithBoat({ user, currentGroupId }),
                )
            }
            if (this.props.boat?.id) {
                this.setState({ openModalNoBoat: false })
            }
        } else if (
            eventLoading === 0 &&
            !(
                pathname === this.getLogBookPath(AppRoute.LogBook.Pricing) &&
                !this.props.boat?.id
            )
        ) {
            this.setState({ openModalNoBoat: true })
        }
    }

    async updateBOB() {
        const { user, currentGroupId } = this.props
        if (
            (user && currentGroupId) ||
            (user && user.userGroup && user.userGroup.length > 0) ||
            (user && user.ownGroup && user.ownGroup.id)
        ) {
            await this.getBOB()
        }
    }

    async handleClose() {
        this.setState({ openModalNoBoat: false })
    }

    getLogBookPath(route) {
        let context = this.getContext()
        return `/${context}` + appStrings[context][route]
    }

    renderNotSubscribe() {
        const { user, location } = this.props
        const { pathname } = location

        if (
            !user &&
            pathname !== this.getLogBookPath(AppRoute.LogBook.Pricing)
        ) {
            return <LandingPage />
        }
        if (pathname === this.getLogBookPath(AppRoute.LogBook.Pricing)) {
            return <PricesPage logbook={true} />
        }
    }

    render() {
        const {
            classes,
            subscriptions,
            loadingUser,
            user,
            location,
        } = this.props
        const { pathname } = location
        return (
            <div className={classes.root}>
                <Navigation>
                    {loadingUser !== 0 ? (
                        this.renderLoading('100px', '100px', true)
                    ) : user &&
                      subscriptions &&
                      pathname !==
                          this.getLogBookPath(AppRoute.LogBook.Pricing) ? (
                        <LogbookNavigation
                            pathname={this.props.location.pathname}
                            notSubscribed={false}
                        />
                    ) : user &&
                      pathname !==
                          this.getLogBookPath(AppRoute.LogBook.Pricing) ? (
                        <LogbookNavigation
                            pathname={this.props.location.pathname}
                            notSubscribed={true}
                        />
                    ) : (
                        this.renderNotSubscribe()
                    )}
                </Navigation>
                {user && (
                    <BoatOnBlock
                        blockIds={18}
                        children={
                            <ModalNoBoat
                                open={this.state.openModalNoBoat}
                                handleClose={this.handleClose}
                                modalShow="infoPage"
                                location={this.props.location}
                            />
                        }
                    />
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boat: state.bob.boat,
        loadingBoat: state.boat.loading,
        loading: state.bob.loading,
        bob: state.bob || null,
        loadingUser: state.authentication.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        events: state.bob.events,
        eventLoading: state.bob.loading,
        groupLoading: state.group.loading,
        currentGroupId: state.group.currentGroupId,
        boatTypes: state.types.boatTypes,
        loadingTypes: state.types.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogbookHome))
