import { requestApi } from '../../services/api.service'

export const getDetailsExcel = (arrayId = [], context = 'FR') => {
    return requestApi(
        `/inventory-details/generate-excel/${context.toUpperCase()}`,
        `POST`,
        arrayId,
        false,
        false,
    )
        .then(
            result => {
                if (!result || result.status === 404) return null
                return result.blob()
            },
            error => {
                console.error(error)
                return null
            },
        )
        .then(blob => {
            if (blob) {
                const href = window.URL.createObjectURL(blob)
                const link = document.createElement(`a`)
                link.href = href
                link.setAttribute(`download`, `inventaire.xlsx`)
                document.body.appendChild(link)
                link.click()
            }
        })
        .catch(error => console.error(error))
}

export const downloadOrderFormPdf = (
    infos = [],
    date = new Date(),
    address,
    receiverName,
    context = 'FR',
    orderNumber,
) => {
    return requestApi(
        `/inventory-details/generate-pdf/${context.toUpperCase()}`,
        `POST`,
        {
            bodyInfos: infos,
            headerInfos: {
                date: date.toISOString().slice(0, 10),
                receiverAddress: address.fullText || address,
                receiverName: receiverName,
                orderNumber: orderNumber,
            },
        },
        false,
        false,
    )
        .then(result => {
            return result.blob()
        })
        .then(blob => {
            if (blob) {
                const href = window.URL.createObjectURL(blob)
                const link = document.createElement(`a`)
                link.href = href
                link.setAttribute(`download`, `orderForm.pdf`)
                document.body.appendChild(link)
                link.click()
            }
        })
        .catch(error => console.error(error))
}

export const getDetailsEmail = (arrayId = [], context = 'FR') => {
    return requestApi(
        `/inventory-details/generate-email/${context.toUpperCase()}`,
        `POST`,
        arrayId,
    )
        .then(
            result => {
                return result
            },
            error => {
                console.error(error)
                return null
            },
        )
        .catch(error => console.error(error))
}
