import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/BoatOnBookPageCss'
import dictionary from './Dictionary/BoatOnBookPageDico'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import topBoatIcon from '../../../../images/carnet-d-entretien/Bateau.png'
import bottomBoatIcon from '../../../../images/carnet-d-entretien/boatonlogo.png'
import fill from '../../../../images/carnet-d-entretien/Fill 154.png'
import link from '../../../../images/carnet-d-entretien/Stroke 141.png'
import notFullLink from '../../../../images/carnet-d-entretien/Stroke.png'
import linkLineLeft from '../../../../images/carnet-d-entretien/Group 4.png'
import linkLineRight from '../../../../images/carnet-d-entretien/Group 5.png'
import repairIcon from '../../../../images/repairIcon.png'
import ModalNothingBoatonBook from '../../../common/UsefullComponents/ModalConnection/ModalNothingBoatonBook'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import Snackbar from '@material-ui/core/Snackbar'
import { typesActions } from '../../../../actions/types.actions'
import catamaranicon from '../../../../images/catamaranicon.svg'

class BoatOnBookPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook/')

        ReactPixel.init('181677145678471', null, {
            autoConfig: true,
            debug: false,
        })
        ReactPixel.pageView()

        this.state = {
            openModalConnection: false,
            openModalNoBoat: false,
            openModalNothing: false,
            update: true,
            yearShow: null,
            fullTab: null,
            spendings: null,
            checkups: null,
            automaticOpenModalNoBoat: false,
        }

        this.handleClose = this.handleClose.bind(this)
        this.handleClickedItem = this.handleClickedItem.bind(this)
    }

    componentDidMount() {
        const { user, events, eventTypes } = this.props
        window.addEventListener('scroll', this.handleScroll, true)
        if (!user) {
            this.setState({
                openModalConnection: true,
            })
        } else if (events && eventTypes) {
            this.initBook()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.yearShow && this.yearEl.length > 0) {
            this.handleScroll()
        }
        const { boats, loading, events, eventTypes } = this.props
        const { openModalNoBoat } = this.state
        if (prevProps.loading > 0 && loading === 0 && events && eventTypes) {
            this.initBook()
        } else if (!boats || !boats.boats || boats.boats.length === 0) {
            if (!openModalNoBoat && !prevState.openModalSubscribe) {
                // open no boat modal if user has no boat
                this.setState({ openModalNoBoat: true })
            }
        }
        // if(!boat && loading === 0 && openModalNoBoat !== true){
        //     this.setState({openModalNoBoat: true, automaticOpenModalNoBoat: true})
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    initBook() {
        const { events, eventTypes } = this.props
        const chrono = events.filter(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            return (
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                eventType &&
                (event.transaction || event.checkup)
            )
        })
        this.setState({ fullTab: chrono })
    }

    handleScroll = () => {
        const { yearShow } = this.state
        if (this.yearEl && this.yearEl.length > 0) {
            for (var i in this.yearEl) {
                if (!this.yearEl[i] || !this.yearEl[i].el) return false
                const top = this.yearEl[i].el.getBoundingClientRect().top
                if (
                    top < 400 &&
                    top > 200 &&
                    yearShow !== this.yearEl[i].year
                ) {
                    this.setState({ yearShow: this.yearEl[i].year })
                }
            }
        }
    }

    handleClickedItem(spending) {
        this.setState({ openSnack: spending })
    }

    handleClose() {
        this.setState({
            openModalConnection: false,
            openModalNoBoat: false,
            openModalNothing: false,
            openSnack: false,
        })
    }

    getYear() {
        return 1900 + new Date().getYear()
    }

    render() {
        const { classes, eventTypes, boat, loading } = this.props
        const {
            fullTab,
            openModalConnection,
            openSnack,
            openModalNothing,
        } = this.state
        var { yearShow } = this.state
        this.yearEl = []
        if (!boat && loading === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                </div>
            )
        }

        const isMobile = window.innerWidth < 600

        if (loading !== 0) return this.renderLoading(`100px`, `100px`, true)
        return (
            <div className={classes.root} key="alerts">
                {isMobile && (
                    <Typography className={classes.mobileTitle}>
                        {this.displayText('pageTitle')} - {boat.name}
                    </Typography>
                )}
                <div className={classes.year}>
                    <Typography className={classes.yearLabel}>
                        {yearShow}
                    </Typography>
                </div>
                <div className={classes.main}>
                    <div className={classes.intro}>
                        <div className={classes.firstLine}>
                            <Typography>
                                <b>{this.displayText('boatOnBook')}</b>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <img
                            src={topBoatIcon}
                            alt="topBoatIcon"
                            className={classes.boatIcon}
                        />
                        {fullTab != null &&
                            fullTab.map((spending, i) => {
                                const eventType = typesActions.getEventTypeById(
                                    eventTypes,
                                    spending.eventType.id,
                                )
                                return (
                                    <div
                                        key={i}
                                        className={classes.schema}
                                        ref={el => {
                                            this.yearEl[i] = {
                                                el: el,
                                                year:
                                                    1900 +
                                                    new Date(
                                                        spending.delimitedDate.startDate,
                                                    ).getYear(),
                                            }
                                        }}
                                    >
                                        <img
                                            src={
                                                new Date() <
                                                new Date(
                                                    spending.delimitedDate.startDate,
                                                )
                                                    ? notFullLink
                                                    : link
                                            }
                                            alt="link"
                                            className={classes.link}
                                        />
                                        <div
                                            className={classes.line}
                                            onClick={() =>
                                                this.handleClickedItem(spending)
                                            }
                                        >
                                            <div
                                                className={
                                                    i % 2 === 0
                                                        ? classes.linkLineLeft
                                                        : classes.linkLineRight
                                                }
                                            >
                                                <div
                                                    className={
                                                        i % 2 === 0
                                                            ? classes.infosLeft
                                                            : classes.infosRight
                                                    }
                                                >
                                                    <img
                                                        src={
                                                            eventType.photo
                                                                ? require(`images/EventBobIcon/${eventType.photo}`)
                                                                : ''
                                                        }
                                                        alt="stockage"
                                                        className={
                                                            i % 2 === 0
                                                                ? classes.iconLeft
                                                                : classes.iconRight
                                                        }
                                                    />
                                                    <Typography
                                                        noWrap
                                                        className={
                                                            classes.title
                                                        }
                                                    >
                                                        {spending.checkup &&
                                                        spending.checkup
                                                            .checkupType &&
                                                        spending.checkup
                                                            .checkupType
                                                            .translation
                                                            ? this.displayTextApi(
                                                                  spending
                                                                      .checkup
                                                                      .checkupType
                                                                      .translation,
                                                              )
                                                            : spending.title}
                                                    </Typography>
                                                </div>
                                                <img
                                                    src={
                                                        i % 2 === 0
                                                            ? linkLineLeft
                                                            : linkLineRight
                                                    }
                                                    alt="linkLine"
                                                    className={
                                                        classes.linkLineIcon
                                                    }
                                                />
                                            </div>
                                            <div className={classes.dates}>
                                                <img
                                                    src={fill}
                                                    alt="fill"
                                                    className={classes.fill}
                                                />
                                                <div
                                                    className={
                                                        classes.circleDate
                                                    }
                                                >
                                                    <Typography
                                                        className={classes.date}
                                                    >
                                                        <b>
                                                            {new Date(
                                                                spending.delimitedDate.startDate,
                                                            ).getDate() || ''}
                                                        </b>
                                                    </Typography>
                                                    <Typography
                                                        className={classes.date}
                                                    >
                                                        {new Date(
                                                            spending.delimitedDate.startDate,
                                                        ).toLocaleString(
                                                            'default',
                                                            {
                                                                month: 'short',
                                                            },
                                                        )}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        <img
                            src={link}
                            alt="link"
                            className={classes.link}
                            style={{
                                marginBottom: '-17px',
                                zIndex: '2',
                            }}
                        />
                        <img
                            src={bottomBoatIcon}
                            alt="bottomBoatIcon"
                            className={classes.boatIcon}
                        />
                    </div>
                </div>
                <ModalConnection
                    open={openModalConnection}
                    handleClose={this.handleClose}
                />
                <ModalNothingBoatonBook
                    open={openModalNothing}
                    handleClose={this.handleClose}
                />
                <Snackbar
                    className={classes.snackbar}
                    open={this.state.openSnack ? true : false}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    message={
                        openSnack ? (
                            <div className={classes.snackbarMessage}>
                                <img
                                    src={
                                        openSnack.eventType &&
                                        openSnack.eventType.photo
                                            ? require(`images/EventBobIcon/${openSnack.eventType.photo}`)
                                            : repairIcon
                                    }
                                    alt="stockage"
                                    className={classes.iconLeft}
                                    style={{ marginRight: '20px' }}
                                />
                                <Typography className={classes.title}>
                                    {openSnack.title ||
                                        (openSnack.eventType &&
                                            this.displayTextApi(
                                                openSnack.eventType.translation,
                                            ))}
                                </Typography>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                ></Snackbar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        sub: state.fetch.sub,
        spendings: state.bob.spendings,
        checkups: state.bob.repairs,
        loading: state.bob.loading,
        events: state.bob.events,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatOnBookPage))
