export default {
    orderForm: {
        FR: `Bon de commande`,
        EN: `Order form`,
    },
    for: {
        FR: `Pour`,
        EN: `For`,
    },
    companyName: {
        FR: `Nom de la société`,
        EN: `Company name`,
    },
    address: {
        FR: `Adresse`,
        EN: `Address`,
    },
    date: {
        FR: `Fait le`,
        EN: `Done`,
    },
    partEquipment: {
        FR: `Pièce & équipement`,
        EN: `Part & equipment`,
    },
    maker: {
        FR: `Marque`,
        EN: `Maker`,
    },
    model: {
        FR: `Modèle`,
        EN: `Model`,
    },
    reference: {
        FR: `Référence`,
        EN: `Reference`,
    },
    quantity: {
        FR: `Quantité`,
        EN: `Quantity`,
    },
    AddLine: {
        FR: `Ajouter une ligne`,
        EN: `Add a line`,
    },
    addingLine: {
        FR: `Ajout d'une ligne`,
        EN: `Adding a line`,
    },
    editingLine: {
        FR: `Édition d'une ligne`,
        EN: `Editing a line`,
    },
    equipmentDesignation: {
        FR: `Désignation de l'équipement`,
        EN: `Equipment designation`,
    },
    partDesignation: {
        FR: `Désignation de la pièce`,
        EN: `Part designation`,
    },
    optionnal: {
        FR: `(falcultatif)`,
        EN: `(optionnal)`,
    },
    add: {
        FR: `Ajouter`,
        EN: `Add`,
    },
    edit: {
        FR: `Éditer`,
        EN: `Edit`,
    },
    addLabelButton: {
        FR: `Créer une pièce ou un équipement`,
        EN: `Create a part or an equipment`,
    },
    addingPartOrEquipment: {
        FR: `Création d'un équipement ou d'une pièce`,
        EN: `Creation of an equipment or a part`,
    },
    save: {
        FR: `Enregistrer`,
        EN: `Save`,
    },
    download: {
        FR: `Télécharger`,
        EN: `Download`,
    },
    errorCompany: {
        FR: `Indiquez le nom du fournisseur`,
        EN: `Indicate the name of the supplier`,
    },
    errorAddress: {
        FR: `Une adresse doit être renseignée`,
        EN: `An address must be filled in`,
    },
}
