import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import { withStyles } from '@material-ui/styles'
import styles from './AllowedVacationsModalCss.js'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert.jsx'
import { TextField, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import dictionary from './AllowedVacationsModalDico.js'
import { groupActions } from '../../../../../actions/group.actions.js'
import { absencesActions } from '../../../../../actions/bob/absences.actions.js'
import worktimesUtils, {
    DEFAULT_NB_HOURS_PER_DAY,
} from '../../../../../utils/worktimesUtils.js'
import { settingsActions } from '../../../../../actions/bob/settings.actions.js'

class AllowedVacationsModal extends BoatOnModalCore {
    constructor(props) {
        super(props)

        this.state = {
            selectedUsers: props.selectedUsers,
            isExpanded: false,
            ranges: [],
            hoveredLine: null,
            previousYearsDisabled: props.selectedUsers.length > 1,
            selectedUserLinks: [],
        }

        this.dictionary = dictionary

        this.save = this.save.bind(this)
        this.applyEverywhere = this.applyEverywhere.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    _getContextButton() {
        let baseTab = []

        // Save button
        baseTab.push({
            disabled: false,
            label: this.displayText('save'),
            action: () => {
                this.save(2)
            },
            type: `primary`,
            blockIds: 21,
        })

        return baseTab
    }

    componentDidMount() {
        const { selectedUsers } = this.state

        const {
            paidVacationSettings,
            groupMembers,
            user,
            currentGroupId,
            groupPaidVacationsAllowed,
        } = this.props

        const userLink = groupMembers.find(
            gm =>
                gm?.user?.email === user.email ||
                gm?.userSubscribe?.mail === user.email,
        )

        const selectedUserLinks = selectedUsers.map(userLinkId => {
            return groupMembers.find(gm => gm.id === userLinkId)
        })

        // if (selectedUserLinks.length == 1) {
        //     this.props.dispatch(
        //         absencesActions.requestUserAbsences(selectedUserLinks[0].id),
        //     )
        // }

        if (!groupPaidVacationsAllowed?.length) {
            this.props.dispatch(
                absencesActions.getUserLinkPaidVacationsAllowed(
                    groupMembers,
                    paidVacationSettings,
                ),
            )
        }

        if (!paidVacationSettings) {
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: currentGroupId,
                }),
            )
        }

        this.setState({
            userLink,
            selectedUserLinks,
        })
    }

    componentDidUpdate(prevProps) {
        const {
            groupPaidVacationsAllowed,
            paidVacationSettings,
            absencesGroup,
        } = this.props
        const { ranges, selectedUserLinks } = this.state

        if (
            groupPaidVacationsAllowed !== prevProps.groupPaidVacationsAllowed ||
            (groupPaidVacationsAllowed.length && !ranges.length)
        ) {
            // Récupération de l'info de l'utilisateur qui a été créé le plus tôt
            let length = 0
            let oldest = groupPaidVacationsAllowed[0]?.value ?? []
            selectedUserLinks.forEach(av => {
                const userPaidVacationsAllowed = groupPaidVacationsAllowed.find(
                    pvg => pvg.userLinkId === av.id,
                )
                if (userPaidVacationsAllowed.value.length > length) {
                    length = userPaidVacationsAllowed.value.length
                    oldest = userPaidVacationsAllowed.value
                }
            })
            const { startDate } = worktimesUtils.getPeriodDates(
                paidVacationSettings,
            )
            // Calcul du nombre d'années depuis earliestDate
            // Pour toutes les années depuis earliestDate, on crée un range
            // On ajoute 1 an à la date de début et on soustrait 1 jour à la date de fin
            const ranges = []
            for (let i = 0; i < oldest.length; i++) {
                const range = {
                    year: oldest[i].year,
                    start: startDate.year(oldest[i].year),
                    end: startDate.year(oldest[i].year + 1).subtract(1, 'day'),
                    value: worktimesUtils.getUserLinkAllowedVacations(
                        oldest,
                        paidVacationSettings,
                        oldest[i].year,
                    ),
                    paidVacationsUsed: selectedUserLinks.reduce(
                        (acc, userLink) => {
                            return {
                                ...acc,
                                [userLink.id]: this._getPaidVacationsUsedInPeriod(
                                    worktimesUtils.filterAbsenceForPeriod(
                                        absencesGroup
                                            .find(
                                                a =>
                                                    a.userLinkId ===
                                                    userLink.id,
                                            )
                                            .absences.filter(
                                                a =>
                                                    a.absenceStatus.id === 2 &&
                                                    a.absenceReasonType.id ===
                                                        1,
                                            ),
                                        oldest[i].year,
                                        paidVacationSettings,
                                    ),
                                    userLink,
                                ),
                            }
                        },
                        {},
                    ),
                }
                ranges.push(range)
            }

            this.setState({
                ranges,
            })
        }
    }

    _renderToggle() {
        const { isExpanded } = this.state
        const { classes } = this.props
        return (
            <Typography
                onClick={() => {
                    this.setState({
                        isExpanded: !isExpanded,
                    })
                }}
                className={classes.toggle}
            >
                {isExpanded
                    ? this.displayText('reduce')
                    : this.displayText('showMore')}
            </Typography>
        )
    }

    _renderEssential() {
        const { ranges, previousYearsDisabled } = this.state
        const { classes } = this.props

        const info = previousYearsDisabled
            ? this.displayText('infoMultiple')
            : this.displayText('info')

        return (
            <div className={classes.modalContent}>
                {/* Message d'information */}
                <BoatOnAlert severity={`info`} description={info} />

                {/* Liste des ranges */}
                <div className={classes.yearList}>
                    {ranges.map((range, index) => {
                        const disabled = previousYearsDisabled && index > 0

                        if (index < 3 || this.state.isExpanded)
                            return this._renderLine(range, index, disabled)
                    })}
                </div>

                {ranges.length > 3 && this._renderToggle()}
            </div>
        )
    }

    save() {
        const {
            groupMembers,
            groupPaidVacationsAllowed,
            currentGroupId,
        } = this.props

        const { selectedUsers, ranges, previousYearsDisabled } = this.state

        const nbYearsToUpdate = previousYearsDisabled ? 1 : ranges.length

        const newUserLinks = selectedUsers.map(userLinkId => {
            // Récupération des données du userLink dans les groupMembers
            const userLink = groupMembers.find(gm => gm.id === userLinkId)

            // workaround, la fonction d'update multiple utilise un champ userLinkId
            userLink.userLinkId = userLink.id

            userLink.userLinkAllowedVacations = [
                ...groupPaidVacationsAllowed.find(
                    g => g.userLinkId === userLinkId,
                ).value,
            ]

            for (let i = 0; i < nbYearsToUpdate; i++) {
                if (!userLink.userLinkAllowedVacations[i]) {
                    break
                }

                userLink.userLinkAllowedVacations[i].nbAllowedVacations =
                    ranges[i].value
            }

            return userLink
        })

        this.props.dispatch(
            groupActions.updateMultipleUserLink(newUserLinks, currentGroupId),
        )
        this.props.closeModal()
    }

    applyEverywhere(value) {
        const { ranges, selectedUserLinks } = this.state

        let errorRanges = []
        ranges.forEach((range, index) => {
            selectedUserLinks.forEach(userLink => {
                if (
                    value * 60 * DEFAULT_NB_HOURS_PER_DAY <
                    range.paidVacationsUsed[userLink.id]
                ) {
                    errorRanges.push(index)
                }
            })
        })

        this.setState({
            ranges: ranges.map((r, index) => {
                if (errorRanges.includes(index)) return r
                return {
                    ...r,
                    value,
                }
            }),
            errors: errorRanges.reduce((acc, index) => {
                acc[index] = this.displayText('errorCantBeLessThanUsed')
                return acc
            }, {}),
        })
    }

    _getPaidVacationsUsedInPeriod(absences, userLink) {
        const { workTimeGroups } = this.props

        // Récupération des settings du groupe de l'utilisateur
        // Ou le groupe par défaut
        const userGroup =
            workTimeGroups.find(wtg =>
                wtg.userLinks.find(ul => ul.id === userLink.id),
            ) ?? workTimeGroups.find(wtg => wtg.byDefault)

        return (
            absences?.reduce((acc, absence) => {
                // L'absence doit être un congé payé validée
                if (
                    absence.absenceStatus.id !== 2 ||
                    absence.absenceReasonType.id !== 1
                )
                    return acc

                return (
                    acc +
                    worktimesUtils.calculateAbsenceDuration(absence, userGroup)
                )
            }, 0) ?? 0
        )
    }

    onChange = (e, index) => {
        const { ranges, selectedUserLinks } = this.state

        const newValue = parseInt(e.target.value)
        if (newValue < 0) return
        const editedRange = ranges[index]

        // Pour tous les utilisateurs on vérifie sur la période modifiée si le nombre de congés autorisés
        // est supérieur ou égal au nombre de congés déjà pris
        let canSubmit = selectedUserLinks.every(userLink => {
            // Calculer le total de congés déjà pris dans la période modifiée
            // Todo - prendre en compte ici le cumul des congés
            // Actuellement on ne vérifie que si le nombre de congés autorisés sur la période actuelle
            // est supérieur ou égal au nombre de congés déjà pris
            // Alors que si le cumul des congés est autorisé, il faut vérifier:
            // Si le total du nombre de congés autorisés sur toutes les périodes est supérieur ou égal au nombre de congés déjà pris

            return (
                newValue * 60 * DEFAULT_NB_HOURS_PER_DAY >=
                editedRange.paidVacationsUsed[userLink.id]
            )
        })

        if (canSubmit) {
            this.setState({
                ranges: ranges.map((r, i) => {
                    if (i === index) {
                        return {
                            ...r,
                            value: parseInt(e.target.value),
                        }
                    }
                    return r
                }),
                errors: {
                    [index]: undefined,
                },
            })
        } else {
            this.setState({
                errors: {
                    [index]: this.displayText('errorCantBeLessThanUsed'),
                },
            })
        }
    }

    _renderLine(range, index, disabled = false) {
        const { classes } = this.props
        const {
            ranges,
            hoveredLine,
            previousYearsDisabled,
            errors,
        } = this.state

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
                key={index}
            >
                <div
                    className={[
                        classes.yearRow,
                        disabled ? classes.disabled : undefined,
                    ].join(' ')}
                    onMouseEnter={() => {
                        if (!disabled && !previousYearsDisabled)
                            this.setState({
                                hoveredLine: index,
                            })
                    }}
                >
                    <Typography className={classes.dateRangeLabel}>
                        {`${this.displayText('from')}\u00A0${range.start.format(
                            'DD/MM/YYYY',
                        )} ${this.displayText('to')}\u00A0${range.end.format(
                            'DD/MM/YYYY',
                        )}`}
                    </Typography>
                    <div className={classes.inputContainer}>
                        <TextField
                            label={this.displayText('allowedVacations')}
                            type={`number`}
                            variant={`outlined`}
                            value={range.value}
                            onChange={e => {
                                this.onChange(e, index)
                            }}
                            margin="none"
                            className={classes.yearInput}
                            disabled={disabled}
                            error={errors?.[index] !== undefined}
                        />
                        <Typography>{this.displayText('days')}</Typography>
                    </div>
                    <div className={classes.applyEverywhere}>
                        {index === hoveredLine && !previousYearsDisabled && (
                            <Typography
                                onClick={() => {
                                    this.applyEverywhere(range.value)
                                }}
                            >
                                {this.displayText('applyEverywhere')}
                            </Typography>
                        )}
                    </div>
                </div>
                {errors?.[index] && (
                    <Typography className={classes.error}>
                        {errors?.[index]}
                    </Typography>
                )}
            </div>
        )
    }

    _renderModalTitle() {
        const { selectedUserLinks } = this.state

        return (
            <p>
                {this.displayText('userList')}
                {': '}
                <strong>
                    {selectedUserLinks
                        .map(userLink => {
                            const { user, userSubscribe } = userLink
                            if (user) {
                                return `${user.firstName} ${user.lastName}`
                            }
                            if (userSubscribe) {
                                return userSubscribe.mail
                            }
                        })
                        .join(', ')}
                </strong>
            </p>
        )
    }

    render() {
        const { classes, absencesLoading, groupLoading } = this.props

        const isLoading = absencesLoading > 0 || groupLoading > 0

        return (
            <div className={classes.modal}>
                {this._renderTitle(null, false, null, {
                    titleHtml: this._renderModalTitle(),
                })}

                {isLoading ? (
                    this.renderLoading()
                ) : (
                    <>
                        {this._renderBody({
                            body: this._renderEssential(),
                        })}
                        {this._renderActions(this._getContextButton())}
                    </>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        paidVacationSettings: state.settings.paidVacationSettings,
        paidVacationsGroup: state.absence.paidVacationsGroup,
        settingsLoading: state.settings.loading,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        groupLoading: state.group.loading,
        absencesGroup: state.absence.absencesGroup,
        absencesLoading: state.absence.loading,
        currentGroupId: state.group.currentGroupId,
        absences: state.absence.absences,
        workTimeGroups: state.settings.workTimeSettings,
        groupPaidVacationsAllowed: state.absence.paidVacationsAllowed,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(AllowedVacationsModal),
)
