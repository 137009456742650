import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/RepairListCss'
import dictionary from './Dictionary/RepairListDico'
import Typography from '@material-ui/core/Typography'
import AttachFile from '@material-ui/icons/AttachFile'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import RepairModal from './Modal/RepairModal'
import RepairCategory from './RepairCategory'
import {
    removeCheckupFromBOBEvent,
    getImageToDisplay,
    getCycleHours,
    getCycle,
    getNameFromRepair,
    putBOBEvents,
    getHoursOfLastCheckup,
    getCheckupEquipment,
    getElementWithDetails,
} from '../../../../actions/bob.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import { typesActions } from '../../../../actions/types.actions'
import BoatOnSpeedometer from '../../../common/BoatOnSpeedometer'
import { filterActions } from '../../../../actions/filter.actions'
import RepairValidator from './RepairValidor'
import {
    IconButton,
    Popover,
    Button as ButtonMui,
    Checkbox,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import BoatOnAppBar from '../../../common/BoatOnAppBar'
import BoatOnRenderDate from '../../../common/UsefullComponents/BoatOnRenderDate'
import NextEventModal from './Modal/NextEventModal'
import VisibilityIcon from '@material-ui/icons/Visibility'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import { groupActions } from '../../../../actions/group.actions'
import { redirectActions } from '../../../../actions/redirect.actions'

const monthThreshold = 3
const hoursThreshold = 75 //%

class RepairList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            idRepairCategory: 0,
            repairEvents: null,
            openAddModal: false,
            openMenu: false,
            modalOpen: false,
            event: null,
            seeMore: false,
            repairsDone: [],
            repairs3months: [],
            repairsSoon: [],
            nextEvent: null,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.newNextRepair = this.newNextRepair.bind(this)
        this.newLastRepair = this.newLastRepair.bind(this)
        this.handleOpenModalMyDocument = this.handleOpenModalMyDocument.bind(
            this,
        )
        this.validate = this.validate.bind(this)
        this.handleOpenPopOverMore = this.handleOpenPopOverMore.bind(this)
        this.handleClosePopOverMore = this.handleClosePopOverMore.bind(this)
        this._renderLineRepair = this._renderLineRepair.bind(this)
        this.changeIdRepair = this.changeIdRepair.bind(this)
        this.validatedEvent = this.validatedEvent.bind(this)
        this.unValidate = this.unValidate.bind(this)
        this.handleCheckRepair = this.handleCheckRepair.bind(this)
        this.nbCheckedEvents = this.nbCheckedEvents.bind(this)
    }

    newNextRepair() {
        this.setState({
            modalOpen: 'nextRepair',
            event: null,
        })
    }

    newLastRepair() {
        this.setState({
            modalOpen: 'lastRepair',
            event: null,
        })
    }

    componentDidMount() {
        const { boat, eventTypes } = this.props
        if (boat) {
            this.props.dispatch(
                typesActions.requestUserCheckups(boat.id || null),
            )
            this.props.dispatch(
                typesActions.requestUserEquipmentFamilies(boat.id || null),
            )
            this.props.dispatch(
                typesActions.requestUserEquipments(boat.id || null),
            )
            this.props.dispatch(typesActions.requestUserParts(boat.id || null))
        }
        this.props.dispatch(groupActions.requestRoles())

        if (!eventTypes) {
            return this.props.dispatch(typesActions.requestEventTypes())
        }
    }

    shouldComponentUpdate(_, nextState) {
        if (
            this.state.modalOpen &&
            nextState.modalOpen &&
            nextState.modalOpen !== `nextEvent`
        )
            return false
        return true
    }

    changeIdRepair(newValue) {
        this.setState({ idRepairCategory: newValue })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            events,
            eventTypes,
            loadingEvents,
            bobFilter,
            boat,
            allEvents,
            redirectEvent,
            redirectParams,
            groupLoading,
            typesLoading,
        } = this.props
        if (
            this.props.boat &&
            events &&
            eventTypes &&
            ((loadingEvents === 0 && prevProps.loadingEvents !== 0) ||
                (prevState.modalOpen && !this.state.modalOpen) ||
                JSON.stringify(prevProps.bobFilter) !==
                    JSON.stringify(bobFilter) ||
                this.state.repairEvents === null)
        ) {
            const repairs = []
            let repairsDone = [],
                repairs3months = [],
                repairsSoon = []
            let idRepairCategory = -1
            for (const event of events) {
                let modified = false
                repairs.push(event)
                if (this.state.repairEvents) {
                    if (!this.state.repairEvents.find(e => e.id === event.id)) {
                        modified = true
                    }
                }
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event?.eventType?.id,
                )
                if (event.checkup && eventType && eventType.repair) {
                    const repairByHours = this._isRepairByHours(event)
                    const checkupDate = event?.delimitedDate?.startDate
                        ? new Date(event.delimitedDate.startDate)
                        : new Date()

                    if (event?.delimitedDate?.endDate) {
                        if (modified) {
                            idRepairCategory = 2
                            modified = false
                        }
                        repairsDone.push(event)
                    } else if (repairByHours) {
                        const eventWithActualHours = getElementWithDetails(
                            allEvents,
                            getCheckupEquipment(event),
                        )
                        const minValue =
                            getHoursOfLastCheckup(allEvents, event) || 0
                        const maxValue =
                            event.actualDuration || getCycleHours(event)
                        const value =
                            eventWithActualHours?.detail?.hourActual || minValue
                        if (
                            !isNaN(value) &&
                            maxValue - minValue > 0 &&
                            ((value - minValue) * 100) /
                                (maxValue - minValue) >=
                                hoursThreshold
                        ) {
                            if (modified) {
                                idRepairCategory = 0
                                modified = false
                            }
                            repairsSoon.push(event)
                        } else {
                            if (
                                this.expired(
                                    checkupDate,
                                    event?.delimitedDate?.endDate,
                                ) &&
                                value === minValue
                            ) {
                                repairsSoon.push(event)
                            } else {
                                repairs3months.push(event)
                            }
                        }
                    } else if (
                        !repairByHours &&
                        this.soonExpire(
                            checkupDate,
                            event?.delimitedDate?.endDate,
                        )
                    ) {
                        if (modified) {
                            idRepairCategory = 0
                            modified = false
                        }
                        repairsSoon.push(event)
                    } else {
                        if (modified) {
                            idRepairCategory = 1
                            modified = false
                        }
                        repairs3months.push(event)
                    }
                }
            }
            this.setState({
                repairEvents: repairs,
                repairsDone,
                repairs3months,
                repairsSoon,
                idRepairCategory:
                    idRepairCategory !== -1
                        ? idRepairCategory
                        : this.state.idRepairCategory,
            })
        }
        if (!loadingEvents && prevProps.loadingEvents && boat) {
            this.props.dispatch(
                typesActions.requestUserCheckups(boat.id || null),
            )
            this.props.dispatch(
                typesActions.requestUserEquipmentFamilies(boat.id || null),
            )
            this.props.dispatch(
                typesActions.requestUserEquipments(boat.id || null),
            )
            this.props.dispatch(typesActions.requestUserParts(boat.id || null))
        }

        if (
            redirectEvent &&
            redirectParams &&
            loadingEvents === 0 &&
            groupLoading === 0 &&
            typesLoading === 0 &&
            !this.state.modalOpen
        ) {
            this.setState({
                modalOpen: redirectParams.modalName,
                event: redirectEvent,
            })
            this.props.dispatch(redirectActions.resetRedirectionParams())
        }
    }

    handleOpenModalMyDocument(document) {
        this.setState({ modalOpen: 'showDocs', openDoc: document })
    }

    handleOpenPopOverMore(e, event) {
        this.setState({
            event: event,
            openPopOverMore: true,
            anchorEl: e.currentTarget,
        })
    }

    handleClosePopOverMore() {
        this.setState({
            openPopOverMore: false,
            anchorEl: null,
            event: null,
        })
    }

    clickDocumentLast() {
        this.refs.fileUploaderLast.click()
    }

    clickDocumentNext() {
        this.refs.fileUploaderNext.click()
    }

    deleteDocumentNoUp(i) {
        this.setState({ fileLast: [] })
        this.setState({ fileNoUrlLast: [] })
        this.setState({ fileNext: [] })
        this.setState({ fileNoUrlNext: [] })
    }

    edit(repair) {
        if (repair != null) {
            this.setState({
                modalOpen: 'editRepair',
                event: repair ? JSON.parse(JSON.stringify(repair)) : null,
            })
        }
    }

    handleCloseModal(nextEvent) {
        if (!nextEvent || nextEvent.target)
            // if nextDate.target === event
            this.setState({
                modalOpen: false,
                openDoc: null,
                nextEvent: null,
                event: null,
            })
        else
            this.setState({
                modalOpen: `nextEvent`,
                openDoc: null,
                nextEvent: nextEvent,
            })
    }

    delete(repair) {
        this.props.dispatch(removeCheckupFromBOBEvent(repair.id))
        this.setState({
            openPopOverMore: false,
        })
        this.props.uncheckAll()
    }

    soonExpire(checkupDate, validedDate) {
        if (!checkupDate || validedDate) return false

        let actualDate = new Date()
        actualDate.setHours(0, 0, 0, 0)
        actualDate.setMonth(actualDate.getMonth() + monthThreshold)

        if (
            actualDate.getTime() > new Date(checkupDate).getTime() &&
            !validedDate
        )
            return true
        return false
    }
    expired(checkupDate, validedDate) {
        if (!checkupDate || validedDate) return false

        let actualDate = new Date()
        actualDate.setHours(0, 0, 0, 0)

        if (
            actualDate.getTime() > new Date(checkupDate).getTime() &&
            !validedDate
        )
            return true
        return false
    }

    overdue(checkupDate, repair, minValue, maxValue, value) {
        let actualDate = new Date()
        actualDate.setHours(0, 0, 0, 0)
        const byHours = this._isRepairByHours(repair)

        if (
            ((byHours && this._overdueHours(minValue, maxValue, value)) ||
                (checkupDate &&
                    actualDate.getTime() > new Date(checkupDate).getTime())) &&
            !repair?.delimitedDate?.endDate
        )
            return true
        return false
    }

    _overdueHours(minValue, maxValue, value) {
        if (
            value !== undefined &&
            maxValue !== undefined &&
            minValue !== undefined &&
            maxValue - minValue > 0 &&
            ((value - minValue) * 100) / (maxValue - minValue) >= 100
        ) {
            return true
        }
        return false
    }

    valided(validedDate) {
        if (validedDate) return true
        return false
    }

    validatedEvent(nextEvent) {
        if (nextEvent !== null) this.handleCloseModal(nextEvent)
    }

    validate() {
        const { event } = this.state
        this.props.dispatch(
            putBOBEvents(event.id, event.title, {
                delimitedDate: {
                    ...event.delimitedDate,
                    startDate: event?.delimitedDate?.startDate
                        ? event.delimitedDate.startDate
                        : new Date(),
                    endDate: new Date(),
                },
            }),
        )
    }

    unValidate() {
        const { event } = this.state
        this.props.dispatch(
            putBOBEvents(event.id, event.title, {
                delimitedDate: {
                    ...event.delimitedDate,
                    endDate: null,
                },
            }),
        )
        this.handleCloseModal()
    }

    renderDocuments() {
        const { openDoc } = this.state

        return (
            <BoatOnModalCore
                body={
                    <BoatOnImageRender
                        vertical
                        multi
                        bottomImage
                        imgs={getImageToDisplay(openDoc, [])}
                    />
                }
            />
        )
    }

    _isRepairByHours(repair) {
        const cycle = getCycle({ event: repair })
        return !(cycle?.cycleType?.id === 1)
    }

    handleCheckRepair(repairId) {
        this.props.handleCheckRepair(repairId)
    }

    _renderLineRepair(repair, index, _, byHours = false) {
        const { classes, allEvents, checkedRepair } = this.props
        var checkupDate =
            repair.delimitedDate && repair.delimitedDate.startDate
                ? new Date(repair.delimitedDate.startDate)
                : null

        const eventWithActualHours = getElementWithDetails(
            JSON.parse(JSON.stringify(allEvents)),
            getCheckupEquipment(repair),
        )
        const minValue =
            getHoursOfLastCheckup(
                JSON.parse(JSON.stringify(allEvents)),
                repair,
            ) || 0

        const maxValue = repair.actualDuration || getCycleHours(repair)
        const value =
            eventWithActualHours?.detail?.hourActual ||
            getHoursOfLastCheckup(
                JSON.parse(JSON.stringify(allEvents)),
                repair,
            ) ||
            0
        const late = this.overdue(
            checkupDate,
            repair,
            minValue,
            maxValue,
            value,
        )
        const valid = this.valided(repair?.delimitedDate?.endDate)
        const normal = !late && !valid

        let titre = getNameFromRepair(repair, this.getContext())
        const nomEntretien = repair?.checkup?.checkupType
            ? this.displayTextApi(repair.checkup.checkupType.translation)
            : repair?.checkup?.userCheckup
            ? repair.checkup.userCheckup.name
            : repair.title

        return (
            <div
                key={'div1' + index}
                className={`${classes.line} ${
                    index % 2 === 1 ? `` : classes.blueLine
                } ${late ? classes.lateLine : valid ? classes.validLine : ``}`}
            >
                <div className={classes.infos}>
                    <Checkbox
                        color="primary"
                        checked={checkedRepair.includes(repair.id)}
                        onChange={() => this.handleCheckRepair(repair.id)}
                        className={classes.checkbox}
                    />
                    {!byHours ? (
                        <div className={classes.dates}>
                            <BoatOnRenderDate date={checkupDate} />
                        </div>
                    ) : (
                        <div
                            className={classes.dates}
                            style={{ height: '100%' }}
                        >
                            <BoatOnSpeedometer
                                minValue={minValue}
                                maxValue={maxValue}
                                value={value}
                            />
                        </div>
                    )}
                    <div className={classes.titleAndType}>
                        <div className={classes.titleContainer}>
                            <Typography
                                noWrap
                                className={
                                    classes.subtitle + ` ` + classes.pieces
                                }
                                variant="body1"
                            >
                                {titre}
                            </Typography>
                            <Typography
                                noWrap
                                className={
                                    classes.subtitle +
                                    ` ` +
                                    classes.titleSeparator
                                }
                                variant="body1"
                            >
                                {` - `}
                            </Typography>
                            <Typography
                                noWrap
                                className={
                                    classes.subtitle + ` ` + classes.opName
                                }
                                variant="body1"
                            >
                                {nomEntretien}
                            </Typography>
                        </div>

                        <div className={classes.type}>
                            <Typography
                                className={classes.typeText}
                                variant="body2"
                                style={{ marginBottom: byHours ? 0 : 5 }}
                            >
                                {repair?.eventType &&
                                    this.displayTextApi(
                                        repair.eventType.translation,
                                    )}

                                {repair?.transaction &&
                                    repair?.transaction?.amountIt !== null &&
                                    ` | ${`
                                        ${repair.transaction.amountIt}
                                        ${this.displayText('euro')}
                                    `}
                                `}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.icons}>
                    <RepairValidator
                        status={{
                            normal: normal,
                            valid: valid,
                            late: late,
                        }}
                    />

                    {repair.files && repair.files.length > 0 && (
                        <BoatOnBlock
                            blockIds={26}
                            children={
                                <IconButton
                                    onClick={() =>
                                        this.handleOpenModalMyDocument(repair)
                                    }
                                    className={classes.iconButton}
                                >
                                    <AttachFile />
                                </IconButton>
                            }
                        />
                    )}
                    <IconButton
                        onClick={() => {
                            this.edit(repair)
                        }}
                        className={classes.iconButton}
                    >
                        <Edit />
                    </IconButton>

                    <BoatOnBlock
                        blockIds={23}
                        children={
                            <IconButton
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            this.displayText('validateDelete'),
                                        )
                                    )
                                        this.delete(repair)
                                }}
                                className={classes.iconButton}
                            >
                                <Delete />
                            </IconButton>
                        }
                    />
                </div>
                <div className={classes.iconsMobile}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={e => {
                            this.handleOpenPopOverMore(e, repair)
                        }}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                    <Checkbox
                        color="primary"
                        checked={checkedRepair.includes(repair.id)}
                        onChange={() => this.handleCheckRepair(repair.id)}
                        className={classes.checkboxMobile}
                    />
                </div>
            </div>
        )
    }

    renderPopup() {
        const { classes } = this.props
        const { event } = this.state

        if (!event) return <></>

        return (
            <Popover
                id={'popover'}
                open={this.state.openPopOverMore}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClosePopOverMore}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.iconsMobile}>
                    {false && (
                        <ButtonMui
                            className={classes.actionMobile}
                            startIcon={<VisibilityIcon />}
                        >
                            {this.displayText(`apercu`)}
                        </ButtonMui>
                    )}
                    <ButtonMui
                        onClick={() => {
                            this.handleClosePopOverMore()
                            this.edit(event)
                        }}
                        className={classes.actionMobile}
                        startIcon={<Edit />}
                    >
                        {this.displayText(`edit`)}
                    </ButtonMui>
                    {event.files && event.files.length > 0 && (
                        <ButtonMui
                            onClick={() => {
                                this.handleClosePopOverMore()
                                this.handleOpenModalMyDocument(event)
                            }}
                            className={classes.actionMobile}
                            startIcon={<AttachFile />}
                        >
                            {this.displayText(`files`)}
                        </ButtonMui>
                    )}
                    <ButtonMui
                        onClick={() => {
                            if (
                                window.confirm(
                                    this.displayText('validateDelete'),
                                )
                            ) {
                                //this.handleOpenModalMyDocument(event)
                                this.delete(event)
                            }
                        }}
                        className={classes.actionMobile}
                        startIcon={<Delete />}
                    >
                        {this.displayText(`supprimerEntretien`)}
                    </ButtonMui>
                </div>
            </Popover>
        )
    }

    nbCheckedEvents(repairs) {
        const { checkedRepair } = this.props
        let nb = 0
        repairs.forEach(repair => {
            if (checkedRepair.includes(repair.id)) nb++
        })
        return nb
    }

    _setTitle(event) {
        if (event === null) return ''
        return event?.checkup?.checkupType
            ? this.displayTextApi(event.checkup.checkupType.translation)
            : event?.checkup?.userCheckup
            ? event.checkup.userCheckup.name
            : event.title
    }

    render() {
        const { classes, loadingEvents, boat } = this.props
        const { repairEvents, nextEvent } = this.state

        if (loadingEvents !== 0 || (!repairEvents && this.props.boat))
            return this.renderLoading(`100px`, `100px`, true)
        return (
            <div className={classes.root}>
                <div className={classes.firstLine}>
                    <Typography className={classes.title}>
                        {this.displayText('entretiens')} - {boat.name}
                    </Typography>
                </div>

                <BoatOnAppBar
                    tabIndex={this.state.idRepairCategory}
                    displayBodyOut={this.changeIdRepair}
                    labels={[
                        this.displayText(`soonDone`),
                        this.displayText(`more3month`),
                        this.displayText(`maintened`),
                    ]}
                />
                {this.state.idRepairCategory === 0 ? (
                    <RepairCategory
                        newNextRepair={this.newNextRepair}
                        newLastRepair={this.newLastRepair}
                        nbCheckedEvents={this.nbCheckedEvents(
                            this.state.repairsSoon,
                        )}
                        checkedBoxes={this.props.checkedRepair}
                        perEquipment={false}
                        repairEvents={this.state.repairsSoon}
                        renderLine={this._renderLineRepair}
                        isRepairByHours={this._isRepairByHours}
                        selectAll={checked =>
                            this.props.handleCheckAllRepair(
                                checked,
                                this.state.repairsSoon,
                            )
                        }
                    />
                ) : this.state.idRepairCategory === 1 ? (
                    <RepairCategory
                        newNextRepair={this.newNextRepair}
                        newLastRepair={this.newLastRepair}
                        nbCheckedEvents={this.nbCheckedEvents(
                            this.state.repairs3months,
                        )}
                        selectAll={checked =>
                            this.props.handleCheckAllRepair(
                                checked,
                                this.state.repairs3months,
                            )
                        }
                        checkedBoxes={this.props.checkedRepair}
                        perEquipment={false}
                        repairEvents={this.state.repairs3months}
                        renderLine={this._renderLineRepair}
                        isRepairByHours={this._isRepairByHours}
                    />
                ) : (
                    <RepairCategory
                        newNextRepair={this.newNextRepair}
                        newLastRepair={this.newLastRepair}
                        nbCheckedEvents={this.nbCheckedEvents(
                            this.state.repairsDone,
                        )}
                        selectAll={checked =>
                            this.props.handleCheckAllRepair(
                                checked,
                                this.state.repairsDone,
                            )
                        }
                        checkedBoxes={this.props.checkedRepair}
                        perEquipment={true}
                        repairEvents={this.state.repairsDone}
                        renderLine={this._renderLineRepair}
                        isRepairByHours={this._isRepairByHours}
                    />
                )}
                {/* ZONE MODAL */}
                <BoatOnModal
                    openCondition={this.state.modalOpen}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        showDocs: this.renderDocuments(),
                        editRepair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                event={this.state.event}
                                language={this.getContext()}
                                handleSelfClose={this.handleCloseModal}
                                handleValidatedEvent={this.validatedEvent}
                                noCross={false}
                                showFollowButton={true}
                            />
                        ),
                        nextEvent: <NextEventModal event={nextEvent} />,
                        nextRepair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                type={`NEXT_REPAIR`}
                                language={this.getContext()}
                                noCross={false}
                                showFollowButton={true}
                            />
                        ),
                        lastRepair: (
                            <RepairModal
                                checkupTypes={this.props.checkupTypes}
                                type={`LAST_REPAIR`}
                                language={this.getContext()}
                                noCross={false}
                                showFollowButton={true}
                            />
                        ),
                    }}
                    titles={{
                        editRepair: this._setTitle(this.state.event),
                        nextEvent: this.displayText(`planeNext`),
                        showDocs: this.displayText(`showDocs`),
                        nextRepair: this.displayText(`createNextRepair`),
                        lastRepair: this.displayText(`createLastRepair`),
                    }}
                    maxWidth={{
                        editRepair: `sm`,
                        nextEvent: `sm`,
                        nextRepair: `sm`,
                        lastRepair: `sm`,
                    }}
                />
                {this.renderPopup()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                rangeDate: true,
                checkupTypesChecked: true,
                amountRange: true,
                rangeHour: true,
                checkupEquipmentFamily: true,
                equipment: true,
                userAttachedRole: true,
                managerSearch: true,
            },
            true,
            state.group.groupsMembers,
        ),
        allEvents: state.bob.events,
        checkupType: state.fetch.checkupType,
        bob: state.bob,
        documents: state.bob.documents,
        boat: state.bob.boat,
        eventTypes: typesActions.getEventTypeCheckup(state.types.eventTypes),
        loadingEvents: state.bob.loading,
        bobFilter: state.filter.bobFilter,
        roles: state.group.roles,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupLoading: state.group.loading,
        typesLoading: state.types.loading,
        redirectEvent: state.redirect.event,
        redirectParams: state.redirect.params,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairList))
