import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/FilterSearchCss'
import BoatOnComponent from '../../common/BoatOnComponent'
import { Autocomplete } from '@material-ui/lab'
import { TextField, Checkbox, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import dictionary from './Dictionary/FilterSearchDico'
import { fixIosHeaderBug } from '../../../utils/usefullFunctions'

class FilterSearch extends BoatOnComponent {
    constructor(props) {
        super(props)
        const { selected, twoArrayToFill, keys } = props
        this.dictionary = dictionary

        this.state = {
            liveSelected: selected
                ? selected
                : twoArrayToFill && keys?.secondary && keys?.primary
                ? { [keys.secondary]: [], [keys.primary]: [] }
                : [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleNotAttributed = this.handleNotAttributed.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { selected } = this.props
        if (JSON.stringify(selected) !== JSON.stringify(prevProps.selected)) {
            this.setState({ liveSelected: selected })
        }
    }

    handleChange(option) {
        const { twoArrayToFill, keys } = this.props
        let liveSelected = JSON.parse(JSON.stringify(this.state.liveSelected))

        if (!twoArrayToFill) {
            if (!liveSelected.find(selectedId => selectedId === option.id)) {
                liveSelected.push(option.id)
            } else {
                liveSelected = liveSelected.filter(
                    selectedId => selectedId !== option.id,
                )
            }
        } else if (twoArrayToFill && keys?.secondary && keys?.primary) {
            if (option[keys.secondary]) {
                if (
                    !liveSelected[keys.secondary].find(
                        selectedId => selectedId === option[keys.secondary].id,
                    )
                ) {
                    liveSelected[keys.secondary].push(option[keys.secondary].id)
                } else {
                    liveSelected[keys.secondary] = liveSelected[
                        keys.secondary
                    ].filter(
                        selectedId => selectedId !== option[keys.secondary].id,
                    )
                }
            }
            if (option[keys.primary]) {
                if (
                    !liveSelected[keys.primary].find(
                        selectedId => selectedId === option[keys.primary].id,
                    )
                ) {
                    liveSelected[keys.primary].push(option[keys.primary].id)
                } else {
                    liveSelected[keys.primary] = liveSelected[
                        keys.primary
                    ].filter(
                        selectedId => selectedId !== option[keys.primary].id,
                    )
                }
            }
        }

        this.setState({ liveSelected: liveSelected }, () => {
            if (!twoArrayToFill) this.props.onChange(liveSelected)
            else {
                this.props.onChange({
                    secondary: liveSelected[keys.secondary],
                    primary: liveSelected[keys.primary],
                })
            }
        })
    }

    handleNotAttributed() {
        const { twoArrayToFill, keys } = this.props

        if (!twoArrayToFill) this.handleChange({ id: -1 })
        else {
            this.handleChange({
                [keys.primary]: { id: -1 },
            })
        }
    }

    getOptionLabel(option) {
        const { twoArrayToFill, keys } = this.props
        if (!twoArrayToFill && option?.translation !== undefined)
            return this.displayTextApi(option.translation)
        else if (!twoArrayToFill && option?.translation === undefined)
            return option.name || option.fullName || ''
        else if (
            keys?.primary &&
            option?.[keys.primary]?.translation !== undefined
        )
            return this.displayTextApi(option[keys.primary].translation)
        else if (
            keys?.primary &&
            option?.[keys.primary]?.noTranslation !== undefined
        )
            return option?.[keys.primary]?.noTranslation
        else if (
            keys?.secondary &&
            option?.[keys.secondary]?.name !== undefined
        )
            return option[keys.secondary].name
        return ''
    }

    renderIcon(option) {
        const { classes } = this.props

        return (
            option.photo && (
                <img
                    src={
                        option.photo
                            ? require(`images/EventBobIcon/${option.photo}`)
                            : ''
                    }
                    width="30px"
                    height="30px"
                    alt={this.displayTextApi(option.translation)}
                    className={classes.picto}
                />
            )
        )
    }

    renderNotAttributed() {
        const { twoArrayToFill, classes, keys } = this.props
        const { liveSelected } = this.state

        return (
            <div
                onClick={this.handleNotAttributed}
                className={classes.rootNotAttributed}
            >
                <Checkbox
                    style={{ marginRight: 8 }}
                    color="primary"
                    checked={
                        (!twoArrayToFill && liveSelected.includes(-1)) ||
                        (twoArrayToFill &&
                            liveSelected[keys.primary].includes(-1))
                    }
                />
                <Typography>{this.displayText('notAttributed')}</Typography>
            </div>
        )
    }

    render() {
        const {
            options,
            classes,
            twoArrayToFill,
            keys,
            groupBy,
            filterOptions,
            isNotAttributed,
        } = this.props
        const { liveSelected } = this.state

        if (!options || (twoArrayToFill && !keys)) return <></>

        return (
            <>
                <Autocomplete
                    multiple
                    classes={{
                        popupIndicatorOpen: classes.popupIndicatorOpen,
                        option: classes.option,
                        paper: classes.paper,
                        focused: classes.focused,
                        groupLabel: classes.groupLabel,
                    }}
                    id="filterSearch"
                    options={options.filter(option => option != null)}
                    disableCloseOnSelect
                    getOptionLabel={option => this.getOptionLabel(option)}
                    renderTags={() => null}
                    renderOption={option => (
                        <div
                            onClick={() => {
                                this.handleChange(option)
                            }}
                            className={classes.optionDiv}
                        >
                            <Checkbox
                                style={{ marginRight: 8 }}
                                color="primary"
                                checked={
                                    !twoArrayToFill
                                        ? liveSelected.includes(option.id)
                                        : liveSelected[keys.secondary].includes(
                                              option?.[keys.secondary]?.id,
                                          ) ||
                                          liveSelected[keys.primary].includes(
                                              option?.[keys.primary]?.id,
                                          )
                                }
                            />
                            {this.renderIcon(option)}
                            {this.getOptionLabel(option)}
                        </div>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            placeholder={this.displayText('search')}
                            style={{ margin: 0 }}
                            onBlur={_ => fixIosHeaderBug()}
                        />
                    )}
                    filterOptions={filterOptions}
                    popupIcon={<SearchIcon style={{ color: '#5F5F5F' }} />}
                    forcePopupIcon
                    open={true}
                    PopperComponent={React.Fragment}
                    groupBy={groupBy}
                />
                {isNotAttributed && this.renderNotAttributed()}
            </>
        )
    }
}

export default withStyles(styles)(FilterSearch)
