import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import dictionary from './Dictionary/RepairListDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/RepairPageCss'
import RepairList from './RepairList'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import catamaranicon from '../../../../images/catamaranicon.svg'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'
import RepairBar from './RepairBar'
import { getRepairExcel } from '../../../../actions/bob.actions'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import { typesActions } from '../../../../actions'

class RepairPage extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.initializeReactGA()
        this.dictionary = dictionary
        this.state = {
            openModalConnection: true,
            checkedRepair: [],
            automaticOpenModalNoBoat: false,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }

        ReactPixel.init('181677145678471', options)
        ReactPixel.pageView()

        this.downloadExcel = this.downloadExcel.bind(this)
        this.handleCheckRepair = this.handleCheckRepair.bind(this)
        this.handleCheckAllRepair = this.handleCheckAllRepair.bind(this)
        this.uncheckAll = this.uncheckAll.bind(this)
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/logbook/my-repairs')
    }

    downloadExcel() {
        const { checkedRepair } = this.state
        this.props.dispatch(
            getRepairExcel(checkedRepair, this.context, this.props.boat?.id),
        )
    }

    handleCheckRepair(repairId) {
        const { checkedRepair } = this.state
        if (checkedRepair.includes(repairId))
            this.setState({
                checkedRepair: checkedRepair.filter(id => id !== repairId),
            })
        else this.setState({ checkedRepair: [...checkedRepair, repairId] })
    }

    handleCheckAllRepair(checked, repairs) {
        let { checkedRepair } = this.state
        if (checked) {
            repairs.forEach(repair => {
                if (!checkedRepair.includes(repair.id))
                    checkedRepair.push(repair.id)
            })
        } else {
            repairs.forEach(repair => {
                if (checkedRepair.includes(repair.id))
                    checkedRepair = checkedRepair.filter(id => id !== repair.id)
            })
        }
        this.setState({ checkedRepair: checkedRepair }, () => {
            console.log(this.state.checkedRepair)
        })
    }

    uncheckAll() {
        this.setState({
            checkedRepair: [],
        })
    }

    componentDidUpdate() {
        const { boat, loadingBob } = this.props
        if (
            this.props.subscriptions.length === 0 &&
            this.props.groupLoading < 1
        ) {
            this.historyPush(history, AppRoute.LogBook.Inventory)
        }
        // if(!boat && loadingBob === 0 && this.state.automaticOpenModalNoBoat===false){
        //     this.setState({openModalNoBoat: true, automaticOpenModalNoBoat: true})
        // }
    }

    render() {
        const { classes, user, checkupTypes, checkupCategories } = this.props
        const { boat, loadingBob } = this.props
        if (!user) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        if (!boat && loadingBob === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                    {
                        <ModalNoBoat
                            open={this.state.openModalNoBoat}
                            handleClose={this.handleClose}
                            modalShow="infoPage"
                        />
                    }
                </div>
            )
        }

        return (
            <div className={classes.root}>
                <div className={classes.main}>
                    <RepairList
                        checkupTypes={checkupTypes}
                        checkupCategories={checkupCategories}
                        handleCheckRepair={this.handleCheckRepair}
                        handleCheckAllRepair={this.handleCheckAllRepair}
                        checkedRepair={[...this.state.checkedRepair]}
                        uncheckAll={this.uncheckAll}
                    />
                    <RepairBar
                        open={this.state.checkedRepair.length}
                        downloadExcel={this.downloadExcel}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkupTypes: state.fetch.checkupTypes,
        checkupCategories: state.fetch.checkupCategories,
        loading: state.fetch.loading,
        loadingBob: state.bob.loading,
        boat: state.bob.boat,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupLoading: state.group.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RepairPage))
