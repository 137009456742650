import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button } from '../common/BoatOnButton'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'
import InsertPhoto from '@material-ui/icons/InsertPhoto'
import dictionary from './Dictionary/ParkingTabDico'
import styles from './Styles/ParkingTabCss'
import BoatOnComponent from '../common/BoatOnComponent'
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    CardHeader,
} from '@material-ui/core'
import Masonry from 'react-masonry-css'
import StatusTitle from './../common/StatusTitle'
import { allParkingsActions } from '../../actions/parkings/allParkings.actions'
import { parkingActions } from '../../actions/parkings/parking.actions'
import { inqueriesActions } from '../../actions/inqueries/inqueries.actions'
import AppRoute from '../../constants/AppRoute'
import { history } from '../../helpers'
import { CLIENT_ID } from '../../services/config.service'
import { companiesActions } from '../../actions/companies/companies.actions'

class ParkingTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            parkings: [],
            parkingPictures: '',
        }

        this.getParkingWithParkingId = this.getParkingWithParkingId.bind(this)
        this.renderCurrency = this.renderCurrency.bind(this)
        this.renderOrderDetails = this.renderOrderDetails.bind(this)
        this.updateStatus = this.updateStatus.bind(this)
        this.updateParking = this.updateParking.bind(this)
        this.showStripePortail = this.showStripePortail.bind(this)
    }

    componentDidMount() {
        const { parking, receivedInqueries } = this.props

        if (!parking || parking.length === 0) {
            this.updateParking()
        }
        if (!receivedInqueries || receivedInqueries.length === 0) {
            this.updateReceivedInqueries()
        }
        this.props.dispatch(companiesActions.getUserCompany())
    }
    componentDidUpdate(prevProps) {
        const { parkingLoading, inqueriesLoading } = this.props

        if (parkingLoading === 0 && prevProps.parkingLoading > 0) {
            this.updateParking()
        }
        /*if (inqueriesLoading === 0 && prevProps.inqueriesLoading > 0) {
            this.updateReceivedInqueries()
        }*/
    }

    showStripePortail() {
        const { user } = this.props
        let phone = ''
        const email = user.email || ''
        const firstName = user.firstName || ''
        const lastName = user.lastName || ''
        const contactDetail =
            user.contactDetails.find(
                contact => contact?.contactType?.id === 1,
            ) || null
        if (contactDetail) phone = contactDetail.contact
        const city = user?.address?.city || ''
        const zipcode = user?.address?.zipcode || ''
        const fullAddress = user?.address?.fullAddress || ''

        phone =
            phone.indexOf('+33') === 0
                ? phone.substr(3) // format phone number de stripe +33 x xx xx xx, il n'y a pas 0
                : phone.substr(1)

        if (email) {
            const urlStripeSetup = `https://connect.stripe.com/express/oauth/authorize?response_type=code&amp;client_id=${CLIENT_ID}&amp;&stripe_user[first_name]=${firstName}&amp;&stripe_user[last_name]=${lastName}&amp;stripe_user[email]=${email}&amp;stripe_user[country]=FR&amp;stripe_user[phone_number]=${phone}&amp;stripe_user[url]=https%3A%2F%2Fwww.boaton.fr&amp;stripe_user[city]=${city}&amp;stripe_user[zip]=${zipcode}&amp;stripe_user[street_address]=${fullAddress}&amp;redirect_uri=http://www.boaton.fr/fr/mes-emplacements&amp;scope=read_write`
            window.location.assign(urlStripeSetup)
        }
    }

    updateReceivedInqueries() {
        this.props.dispatch(inqueriesActions.getReceivedInqueries())
    }

    updateParking() {
        this.props.dispatch(allParkingsActions.getPersonalParkings())
    }

    updateStatus(activationStatusId, parkingId) {
        if (activationStatusId === 1) {
            this.props.dispatch(parkingActions.deactivateParking(parkingId))
            alert(this.displayText('alertDesactivate'))
        } else {
            this.props.dispatch(parkingActions.activateParking(parkingId))
            alert(this.displayText('alertActivate'))
        }
    }

    getParkingWithParkingId(parkingId) {
        const { parkings } = this.state

        for (const key in parkings) {
            if (parkings[key].id === parkingId) {
                return parkings[key]
            }
        }
        return null
    }

    renderCurrency(currency) {
        return '€'
    }

    renderOrderDetails(receivedInquerie) {
        this.historyPush(history, AppRoute.Inquery, '', {
            inquery: receivedInquerie,
        })
    }

    render() {
        const { classes, parkingsLoading } = this.props
        const parkings = this.props.parking || []
        const receivedInqueries = this.props.receivedInqueries || []
        if (parkingsLoading > 0) {
            this.renderLoading('100')
        }

        if (parkings && parkings.length === 0) {
            return (
                <div>
                    <Typography className={classes.nothing}>
                        {this.displayText('noParking')}
                    </Typography>
                </div>
            )
        }
        return (
            <div className={classes.root}>
                <div className={classes.currentlyBox}>
                    <Typography className={classes.currently}>
                        {this.displayText('currently')}
                    </Typography>
                    <Typography className={classes.nbEmplacements}>
                        {this.displayText('have')} {parkings.length}{' '}
                        {this.displayText('location')}
                    </Typography>
                </div>

                <Masonry
                    breakpointCols={{
                        default: 2,
                        1200: 1,
                    }}
                    className={classes.myMasonryGrid}
                    columnClassName={classes.myMasonryGridColumn}
                >
                    {parkings.map((parking, i) => (
                        <React.Fragment key={i}>
                            <ParkingCard
                                parent={this}
                                parking={parking}
                                receivedInqueries={receivedInqueries}
                            />
                        </React.Fragment>
                    ))}
                </Masonry>
            </div>
        )
    }
}

function ParkingCard({ parent, parking, receivedInqueries }) {
    const { classes, user } = parent.props
    const imgs = parkingActions.getImageToDisplay(parking.files)
    const img = imgs.length > 0 ? imgs[0].link : ''
    const imgId = imgs.length > 0 ? imgs[0].id : ''
    const full = imgs.length > 0 ? imgs[0].link.split('.') : []
    const video = ['mp4', 'mov', 'mpg'].includes(full[full.length - 1])

    return (
        <Card className={classes.cardRoot} elevation={0} square>
            <CardHeader
                title={
                    <div className={classes.cardTitle}>
                        {parking.titleBoaton
                            ? parent.displayTextApi(parking.titleBoaton)
                            : parking.titleStockon
                            ? parent.displayTextApi(parking.titleStockon)
                            : ''}
                        {parking?.activationStatus?.id === 2 ? (
                            <span className={classes.disableTag}>
                                {parent.displayText('desactivated')}
                            </span>
                        ) : (
                            <span className={classes.activeTag}>
                                {parent.displayText('activated')}
                            </span>
                        )}
                    </div>
                }
                subheader={
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {parent.displayText('ad')} {parking.totalView || 0}{' '}
                        {parent.displayText('times')}
                    </Typography>
                }
            ></CardHeader>
            {imgs[0] ? (
                <div className={classes.pictures}>
                    {video ? (
                        <div className={classes.importantMovie}>
                            <video
                                controls
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    margin: 'auto',
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                }}
                            >
                                <source
                                    src={img}
                                    alt={imgId}
                                    type={'video/mp4'}
                                ></source>
                            </video>
                        </div>
                    ) : (
                        <div className={classes.importantImage}>
                            <CardMedia
                                component="img"
                                alt={imgs[0].id}
                                height="200"
                                image={imgs[0].link}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={classes.pictures}>
                    <InsertPhoto
                        style={{
                            width: '30%',
                            height: '200px',
                            margin: 'auto',
                            display: 'block',
                        }}
                    />
                </div>
            )}

            <CardContent>
                {receivedInqueries ? (
                    <div>
                        {receivedInqueries.map((receivedInquerie, i) => (
                            <React.Fragment key={i}>
                                <OrderLine
                                    parent={parent}
                                    receivedInquerie={receivedInquerie}
                                    parking={parking}
                                />
                            </React.Fragment>
                        ))}
                    </div>
                ) : (
                    <div />
                )}
            </CardContent>
            <CardActions>
                <Button
                    size="small"
                    type="primary"
                    addProps={{
                        component: Link,
                        to: `/edit-my-parking/${parking.id}`,
                    }}
                    label={parent.displayText('edit')}
                />
                {user.stripeAccountId ? (
                    <Button
                        size="small"
                        type="secondary"
                        addProps={{
                            component: Link,
                            to: `/duplicate-my-parking/${parking.id}`,
                        }}
                        label={parent.displayText('clone')}
                        classNameOverride={classes.buttonGroup}
                    />
                ) : (
                    <Button
                        size="small"
                        type="secondary"
                        onClick={parent.showStripePortail}
                        label={parent.displayText('clone')}
                        classNameOverride={classes.buttonGroup}
                    />
                )}
                {parking?.activationStatus?.id === 2 ? (
                    <Button
                        size="small"
                        type="secondary"
                        onClick={() => {
                            parent.updateStatus(
                                parking?.activationStatus?.id,
                                parking.id,
                            )
                        }}
                        label={parent.displayText('activate')}
                        classNameOverride={classes.buttonGroup}
                    />
                ) : (
                    <Button
                        size="small"
                        type="secondary"
                        onClick={() => {
                            parent.updateStatus(
                                parking?.activationStatus?.id,
                                parking.id,
                            )
                        }}
                        label={parent.displayText('desactivate')}
                        classNameOverride={classes.buttonGroup}
                    />
                )}
            </CardActions>
        </Card>
    )
}

function OrderLine({ parent, receivedInquerie, parking, id }) {
    const { classes } = parent.props

    const start = receivedInquerie?.delimitedDate?.startDate
        ? new Date(receivedInquerie?.delimitedDate?.startDate)
        : new Date()
    const end = receivedInquerie?.delimitedDate?.endDate
        ? new Date(receivedInquerie?.delimitedDate?.endDate)
        : new Date()
    const orderStartDate = start.toLocaleDateString()
    const orderEndDate = end.toLocaleDateString()
    const orderCancelDate =
        receivedInquerie?.inqueryRelations[0]?.responseTime &&
        receivedInquerie?.inqueryRelations[0]?.inqueryStatus?.id === 3
            ? new Date(
                  receivedInquerie?.inqueryRelations[0]?.responseTime,
              ).toLocaleDateString()
            : receivedInquerie.cancelDate &&
              receivedInquerie?.inqueryRelations[0]?.inqueryStatus?.id === 4
            ? new Date(receivedInquerie.cancelDate).toLocaleDateString()
            : ''
    const transaction = receivedInquerie?.parkingInq?.transactions[0]
    return (
        <div key={id}>
            {receivedInquerie?.parkingInq?.parking?.id === parking.id ? (
                <div>
                    <div className={classes.inquery}>
                        <div className={classes.nameAndDate}>
                            <Typography className={classes.wantsToRent}>
                                {receivedInquerie.user.firstName}{' '}
                                {parent.displayText('pitch')}{' '}
                            </Typography>
                            <div className={classes.labelAndDate}>
                                <StatusTitle
                                    status={
                                        receivedInquerie?.inqueryRelations[0]
                                            ?.inqueryStatus.id
                                    }
                                />
                                {receivedInquerie?.inqueryRelations[0]
                                    ?.inqueryStatus.id !== 3 ? (
                                    transaction?.recurenceType?.id === 4 &&
                                    !receivedInquerie.delimitedDate.endDate ? (
                                        <Typography className={classes.dates}>
                                            {parent.displayText('fromThe')}{' '}
                                            {orderStartDate}
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.dates}>
                                            {parent.displayText('from')}{' '}
                                            {orderStartDate}{' '}
                                            {parent.displayText('at')}{' '}
                                            {orderEndDate}
                                        </Typography>
                                    )
                                ) : (
                                    <Typography className={classes.dates}>
                                        {parent.displayText('the')}{' '}
                                        {orderCancelDate}
                                    </Typography>
                                )}
                            </div>
                        </div>
                        <div className={classes.priceAndButton}>
                            {transaction?.recurenceType?.id === 4 ? (
                                <div className={classes.quotation}>
                                    <Typography
                                        className={classes.quotationText}
                                    >
                                        {parent.props.userCompany?.vat?.percent
                                            ? transaction.amountEt +
                                              (transaction.amountEt *
                                                  parent.props.userCompany?.vat
                                                      ?.percent) /
                                                  100
                                            : transaction.amountEt}{' '}
                                        {parent.renderCurrency()}{' '}
                                        {parent.displayText('month')}
                                    </Typography>
                                </div>
                            ) : (
                                <div className={classes.quotation}>
                                    <Typography
                                        className={classes.quotationText}
                                    >
                                        {parent.props.userCompany?.vat?.percent
                                            ? transaction.amountEt +
                                              (transaction.amountEt *
                                                  parent.props.userCompany?.vat
                                                      ?.percent) /
                                                  100
                                            : transaction.amountEt}{' '}
                                        {parent.renderCurrency()}
                                    </Typography>
                                </div>
                            )}
                            <div className={classes.answerButton}>
                                <Button
                                    type="primary"
                                    size="small"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() =>
                                        parent.renderOrderDetails(
                                            receivedInquerie,
                                        )
                                    }
                                    label={parent.displayText('answer')}
                                />
                            </div>
                        </div>
                    </div>
                    <Divider className={classes.divider} />
                </div>
            ) : (
                <div />
            )}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        receivedInqueries: state.inqueries.receivedParkingInqueries,
        parking: state.allParkings.personalParkings,
        parkingsLoading: state.allParkings.loading,
        parkingLoading: state.parking.loading,
        parkingPictures: state.fetch.parkingPictures,
        inqueriesLoading: state.inqueries.loading,
        userCompany: state.companies.userCompany,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingTab))
