import { withStyles } from '@material-ui/styles'
import React from 'react'
import styles from './styles/PaidVacationsSettingsPageCss'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './dictionary/PaidVacationsSettingsPageDictionary'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { Paper, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { settingsActions } from '../../../../actions/bob/settings.actions'
import { frFR } from '@mui/x-date-pickers'
import PaidVacationsDefaultSettingsTab from './PaidVacationsDefaultSettingsTab/PaidVacationsDefaultSettingsTab'
import UserPaidVacationsTab from './UserPaidVacationsTab/UserPaidVacationsTab'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import { groupActions } from '../../../../actions'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'

class PaidVacationsSettingsPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.nbPaidVacationAllowedDefault = 25
        this.state = {
            settings: {
                nbPaidVacationAllowed: this.nbPaidVacationAllowedDefault,
                dayStartRefPeriod: 1,
                monthStartRefPeriod: 6,
                allowPaidVacationAddition: false,
                allowedVacationsClickedLinkUserId: null, //Définit pour quel utilisateur on modifie les jours autorisés
            },
            tab: 0,
            openedModal: null,
        }

        this.nbHeuresParJour = 7

        this.frenchLocale =
            frFR.components.MuiLocalizationProvider.defaultProps.localeText
    }

    componentDidMount() {
        const { currentGroupId } = this.props

        if (currentGroupId) {
            // Récupération des settings de congés pour ce groupe
            this.props.dispatch(
                settingsActions.getPaidVacationsSettings({
                    groupId: currentGroupId,
                }),
            )

            // Récupération des settings de temps de travail pour ce groupe
            // Utilisé pour calculer correctement la durée des congés pris
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: currentGroupId,
                }),
            )
        }
    }

    componentDidUpdate(prevProps) {
        const { subscriptions, groupLoading } = this.props
        // Redirection vers l'inventaire si l'utilisateur n'a pas les droits
        if (subscriptions.length === 0 && groupLoading < 1) {
            this.historyPush(history, AppRoute.LogBook.Inventory)
        }
    }

    _renderTabDefaultSettings() {
        const { classes, settingsLoading } = this.props
        const { tab, settings } = this.state

        return (
            <BoatOnBlock
                blockIds={62}
                page
                children={
                    <TabPanel
                        value={1}
                        classes={{ root: classes.tabPanel }}
                        className={tab !== 1 ? classes.hidden : undefined}
                    >
                        {settings && !settingsLoading ? (
                            <PaidVacationsDefaultSettingsTab
                                settings={settings}
                                updateSettings={(settingName, value) => {
                                    const {
                                        paidVacationSettings,
                                        currentGroupId,
                                        groupMembers,
                                    } = this.props

                                    clearTimeout(this.state.timeout)

                                    this.setState({
                                        settings: {
                                            ...this.state.settings,
                                            [settingName]: value,
                                        },
                                        timeout: setTimeout(async () => {
                                            // Si il n'existe pas de setting en back pour ce groupe
                                            // Alors on le créé
                                            if (!paidVacationSettings.id) {
                                                this.props.dispatch(
                                                    settingsActions.postGroupPaidVacationsSettings(
                                                        {
                                                            groupId: currentGroupId,
                                                            body: this.state
                                                                .settings,
                                                        },
                                                    ),
                                                )
                                            } else {
                                                // Sinon mise à jour de l'existant
                                                await this.props.dispatch(
                                                    settingsActions.putGroupPaidVacationsSettings(
                                                        {
                                                            groupSettingId:
                                                                paidVacationSettings.id,
                                                            body: this.state
                                                                .settings,
                                                        },
                                                    ),
                                                )
                                            }

                                            if (
                                                settingName ===
                                                `nbPaidVacationAllowed`
                                            ) {
                                                // Mise à jour du nombre de congés max pour tous les utilisateurs
                                                // Dont le nombre de congés autorisés est supérieur à la nouvelle valeur

                                                const usersToUpdate = groupMembers
                                                    .filter(
                                                        gm =>
                                                            gm.nbPaidVacationAllowed >=
                                                            paidVacationSettings.nbPaidVacationAllowed,
                                                    )
                                                    .map(gm => ({
                                                        userLinkId: gm.id,
                                                        nbPaidVacationAllowed: parseInt(
                                                            value,
                                                        ),
                                                        userGroupId: currentGroupId,
                                                    }))

                                                if (usersToUpdate.length > 0) {
                                                    this.props.dispatch(
                                                        groupActions.updateMultipleUserLink(
                                                            usersToUpdate,
                                                            currentGroupId,
                                                        ),
                                                    )
                                                }
                                            }
                                            this.props.dispatch(
                                                settingsActions.getPaidVacationsSettings(
                                                    {
                                                        groupId: currentGroupId,
                                                    },
                                                ),
                                            )
                                        }, 1000),
                                    })
                                }}
                            />
                        ) : (
                            this.renderLoading(100, 100)
                        )}
                    </TabPanel>
                }
            />
        )
    }

    _renderTabCongesUtilisateurs() {
        const { classes, settingsLoading, groupLoading } = this.props

        const { tab, settings } = this.state

        return (
            <TabPanel
                value={0}
                classes={{ root: classes.tabPanel }}
                className={tab !== 0 ? classes.hidden : undefined}
            >
                {/*
                    Si les paramètres sont encore en cours de chargement
                    OU
                    Si les jours de congés sont encore en cours de chargement
                    Alors affichage du spinner de chargement
                */}
                {settingsLoading > 0 || groupLoading > 0 ? (
                    this.renderLoading(100, 100)
                ) : (
                    <UserPaidVacationsTab settings={settings} />
                )}
                {/* {this._renderPaidVacationsPopover()} */}
            </TabPanel>
        )
    }

    render() {
        const { classes } = this.props
        const { tab } = this.state

        // if (absenceLoading ) return this.renderLoading('50')

        return (
            <Paper className={classes.container} elevation={5}>
                {/* Titre */}
                <Typography
                    className={classes.title}
                    component="h1"
                    variant="h1"
                >
                    {this.displayText(`title`)}
                </Typography>
                <TabContext value={tab}>
                    <TabList
                        onChange={(_, v) => this.setState({ tab: v })}
                        indicatorColor="primary"
                    >
                        <Tab
                            label={this.displayText('paidVacationsByUser')}
                            value={0}
                            classes={{ root: classes.tabButton }}
                        />
                        <BoatOnBlock
                            blockIds={62}
                            page
                            children={
                                <Tab
                                    label={this.displayText(
                                        'defaultParameters',
                                    )}
                                    value={1}
                                    classes={{ root: classes.tabButton }}
                                />
                            }
                        />
                    </TabList>

                    {/* Tab Congés utilisateurs */}
                    {this._renderTabCongesUtilisateurs()}

                    {/* Tab Paramètres par défaut */}
                    {this._renderTabDefaultSettings()}
                </TabContext>
            </Paper>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groups: state.group.groupsMembers,
        currentGroupId: state.group.currentGroupId,
        paidVacationSettings: state.settings.paidVacationSettings,
        settingsLoading: state.settings.loading,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        absences: state.absence.absences,
        absencesGroup: state.absence.absencesGroup,
        absenceLoading: state.absence.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupLoading: state.group.loading,
        groupPaidVacationsAllowed: state.absence.paidVacationsAllowed,
        workTimeGroups: state.settings.workTimeSettings,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(PaidVacationsSettingsPage),
)
