export default {
    back: {
        FR: 'Retour',
        EN: 'Back',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    departure: {
        FR: 'Départ',
        EN: 'Departure',
    },
    arrival: {
        FR: 'Arrivée',
        EN: 'Arrival',
    },
    departurePlace: {
        FR: 'Lieu de départ',
        EN: 'Departure place',
    },
    departureDate: {
        FR: 'Date de départ',
        EN: 'Departure date',
    },
    departureTime: {
        FR: 'Heure de départ',
        EN: 'Departure time',
    },
    nbPassengers: {
        FR: 'Nombre de passagers a bord',
        EN: 'Number of passengers on board',
    },

    stopover: {
        FR: 'Escale',
        EN: 'Stopover',
    },
    deleteStopover: {
        FR: `Supprimer l'escale`,
        EN: 'Delete stopover',
    },
    stopoverPlace: {
        FR: `Lieu de l'escale`,
        EN: 'Stopover place',
    },
    stopoverStartDate: {
        FR: `Date d'arrivée`,
        EN: `Arrival date`,
    },
    stopoverStartTime: {
        FR: `Heure d'arrivée`,
        EN: `Arrival time`,
    },
    stopoverEndDate: {
        FR: `Date de départ`,
        EN: `Departure date`,
    },
    stopoverEndTime: {
        FR: `Heure de départ`,
        EN: `Departure time`,
    },
    nbPassengersIn: {
        FR: 'Passagers montés',
        EN: 'Passengers onboarded',
    },
    nbPassengersOut: {
        FR: 'Passagers descendus',
        EN: 'Passengers landed',
    },

    addStopover: {
        FR: 'Ajouter une escale',
        EN: 'Add a stopover',
    },
    arrivalPlace: {
        FR: `Lieu d'arrivée`,
        EN: 'Arrival place',
    },
    arrivalDate: {
        FR: `Date d'arrivée`,
        EN: 'Arrival date',
    },
    arrivalTime: {
        FR: `Heure d'arrivée`,
        EN: 'Arrival time',
    },
    captainJudgment: {
        FR: 'Appréciation du capitaine',
        EN: 'Captain judgment',
    },
    photoAndDocument: {
        FR: 'Photo et documents',
        EN: 'Photo and documents',
    },
    nbPassengersOutError: {
        FR:
            'Vous ne pouvez pas avoir plus de passagers descendus que le nombre étant à bord',
        EN: 'You cannot have more passengers disembarked than are on board.',
    },
}
