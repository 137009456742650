import React from 'react'
import { connect } from 'react-redux'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import BobFilter from '../BobFilter'
import BoatOnComponent from '../../../common/BoatOnComponent'
import BoatOnModal from '../../../common/BoatOnModal'
import { Button as ButtonBON } from '../../../common/BoatOnButton'

import dictionary from './Dictionary/LogBookListDico'
import LogBookCard from './LogBookCard'
import LogBookModal from './Modals/LogBookModal'
import LogBookAISModal from './Modals/LogBookAISModal'
import LogBookManualSetupModal from './Modals/LogBookManualSetupModal'

import {
    addNewNavigation,
    getNavigationsFromGroup,
} from '../../../../actions/bob/navigation.actions'
import { putBOBEvents } from '../../../../actions/bob.actions'
import { boatActions } from '../../../../actions/boat.actions'
import { getAisByNavigation } from '../../../../actions/bob/navigationApiData.actions'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookListCss'
import BoatOnLoading from '../../../common/UsefullComponents/BoatOnLoading'

class LogBookList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedModal: false,
            createdNavigation: null,
            usedNavigations: null,
            size: 1,
        }

        this.cancelCreation = this.cancelCreation.bind(this)
        this.openNewNavigation = this.openNewNavigation.bind(this)
        this.closeNewNavigation = this.closeNewNavigation.bind(this)
        this.saveAISModal = this.saveAISModal.bind(this)
        this.cancelManualSetup = this.cancelManualSetup.bind(this)
        this.saveManualSetup = this.saveManualSetup.bind(this)
        this.save = this.save.bind(this)
        this.redirectToCard = this.redirectToCard.bind(this)
        this.getUpdatedEvents = this.getUpdatedEvents.bind(this)
        this.getMoreNav = this.getMoreNav.bind(this)
    }

    componentDidMount() {
        const { dispatch, groupId } = this.props

        dispatch(getNavigationsFromGroup(groupId))
        dispatch(boatActions.requestLinkedBoats())
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, navigations, ais } = this.props
        const { size, usedNavigations } = this.state

        if (
            (loading === 0 && prevProps.loading !== 0) ||
            (navigations &&
                (prevState.size !== size ||
                    usedNavigations === null ||
                    navigations?.length !== prevProps.navigations?.length))
        ) {
            const navigationToStart = navigations.filter(
                item =>
                    !item.delimitedDate.endDate &&
                    item.nbDepartingPassengers === null,
            )
            const navigationInProgess = navigations.filter(
                item =>
                    item.delimitedDate.startDate &&
                    !item.delimitedDate.endDate &&
                    item.nbDepartingPassengers !== null,
            )
            const navigationDone = navigations.filter(
                item =>
                    item.delimitedDate.startDate && item.delimitedDate.endDate,
            )

            const usedNavigations = [
                ...navigationToStart,
                ...navigationInProgess.sort(
                    (a, b) =>
                        b.delimitedDate.startDate - a.delimitedDate.startDate,
                ),
                ...navigationDone.sort(
                    (a, b) =>
                        b.delimitedDate.startDate - a.delimitedDate.startDate,
                ),
            ].slice(0, size * 5)

            this.setState(
                {
                    usedNavigations,
                },
                () => {
                    for (const nav of usedNavigations) {
                        const currentAis = ais.filter(
                            item => item.navigationId === nav.id,
                        )

                        if (nav.isAisUsed && currentAis.length === 0)
                            this.props.dispatch(getAisByNavigation(nav.id))
                    }
                },
            )
        }
    }

    openNewNavigation() {
        this.setState({ selectedModal: 'create' })
    }

    closeNewNavigation(navigation) {
        if (!navigation) {
            this.setState({
                selectedModal: false,
                createdNavigation: null,
            })
        } else {
            this.setState({
                selectedModal: 'AISUsed',
                createdNavigation: navigation,
            })
        }
    }

    saveAISModal(AIS) {
        let createdNavigation = { ...this.state.createdNavigation }

        createdNavigation.isAisUsed = AIS

        this.setState(
            {
                createdNavigation,
                selectedModal: AIS ? false : 'manualSetup',
            },
            () => {
                if (AIS) this.save()
            },
        )
    }

    cancelManualSetup() {
        this.setState({
            selectedModal: 'AISUsed',
        })
    }

    saveManualSetup(travelDetails) {
        let createdNavigation = { ...this.state.createdNavigation }

        createdNavigation = { ...createdNavigation, ...travelDetails }

        this.setState(
            {
                createdNavigation: createdNavigation,
                selectedModal: false,
            },
            this.save,
        )
    }

    getUpdatedEvents() {
        const { createdNavigation } = this.state
        const events = JSON.parse(JSON.stringify(this.props.events))

        const workingEquipmentHours = createdNavigation.workingEquipmentHours
        const workingEquipmentHoursID = workingEquipmentHours.map(
            item => item.equipment.id,
        )
        const eventsHours = events.filter(event => {
            if (
                !(
                    event?.detail?.equipment &&
                    workingEquipmentHoursID.includes(event.detail.equipment.id)
                )
            )
                return false

            const equipment = workingEquipmentHours.find(
                item => item.equipment.id === event.detail.equipment.id,
            )
            if (!equipment) return false
            if (equipment.hours <= event.detail.hourActual) return false

            return true
        })

        const fillingLevels = createdNavigation.fillingLevels
        const fillingLevelsID = fillingLevels.map(item => item.equipment.id)

        const eventsFilledLevel = events.filter(event => {
            if (
                !(
                    event?.detail?.equipment &&
                    fillingLevelsID.includes(event.detail.equipment.id)
                )
            )
                return false

            const equipment = fillingLevels.find(
                item => item.equipment.id === event.detail.equipment.id,
            )

            if (!equipment) return false

            if (equipment.level === event.detail.optionnalDetail?.fillingLevel)
                return false

            return true
        })

        let eventsToUpdate = eventsHours.concat(eventsFilledLevel)

        eventsToUpdate = eventsToUpdate.reduce((map, event) => {
            map[event.id] = event
            return map
        }, {})

        eventsToUpdate = Object.values(eventsToUpdate)

        const updatedEvent = eventsToUpdate.map(event => {
            const equipmentHours = workingEquipmentHours.find(
                item => item.equipment.id === event.detail.equipment.id,
            )

            if (
                equipmentHours &&
                event.detail.hourActual < equipmentHours.hours
            )
                event.detail.hourActual = equipmentHours.hours

            const equipmentFillingLevel = fillingLevels.find(
                item => item.equipment.id === event.detail.equipment.id,
            )
            const optionnalDetail = event.detail.optionnalDetail
            const optionnalDetailActivated =
                event.detail?.equipment?.equipmentType?.optionnalDetailActivated

            if (
                equipmentFillingLevel &&
                optionnalDetailActivated?.fillingLevel
            ) {
                if (
                    optionnalDetail?.fillingLevel &&
                    optionnalDetail.fillingLevel !== equipmentFillingLevel.level
                )
                    event.detail.optionnalDetail.fillingLevel =
                        equipmentFillingLevel.level
                else {
                    event.detail.optionnalDetail = {
                        ...event.detail.optionnalDetail,
                        fillingLevel: equipmentFillingLevel.level,
                    }
                }
            }

            return event
        })

        return updatedEvent
    }

    save() {
        const { createdNavigation } = this.state

        this.props.dispatch(addNewNavigation(createdNavigation))
        const eventsToUpdate = this.getUpdatedEvents()

        eventsToUpdate.forEach(event => {
            this.props.dispatch(
                putBOBEvents(event.id, event.title, {
                    detail: event.detail,
                }),
            )
        })
    }

    cancelCreation() {
        this.setState({
            selectedModal: false,
            createdNavigation: null,
        })
    }

    redirectToCard(id) {
        this.historyPush(
            history,
            AppRoute.LogBook.LogBookID,
            '',
            {},
            { id: `${id}` },
        )
    }

    getMoreNav() {
        this.setState({ size: this.state.size + 1 })
    }

    render() {
        const { selectedModal, usedNavigations } = this.state
        const {
            classes,
            navigations,
            loading,
            isMobile,
            bobFilter,
        } = this.props

        let content = (
            <BoatOnLoading description={`${this.displayText('loading')} ...`} />
        )

        let canBeExtend = false

        if (usedNavigations && usedNavigations.length > 0) {
            content = usedNavigations.map(navigation => (
                <LogBookCard
                    key={`logbookcard-${navigation.id}`}
                    navigation={navigation}
                    onClick={id => this.redirectToCard(id)}
                />
            ))

            if (navigations?.length !== usedNavigations?.length)
                canBeExtend = true
        } else if (
            loading === 0 &&
            (bobFilter.selectedCrew?.length > 0 ||
                bobFilter.rangeDate?.start ||
                bobFilter.rangeDate?.end ||
                bobFilter.searchString?.length > 0 ||
                bobFilter.selectedBoat?.length > 0 ||
                bobFilter.selectedBoatsByFleet?.length > 0)
        ) {
            content = (
                <div className={classes.noContent}>
                    {this.displayText('NoNavigationWithFilters')}
                </div>
            )
        } else if (loading === 0) {
            content = (
                <div className={classes.noContent}>
                    {this.displayText('NoNavigation')}
                </div>
            )
        }

        return (
            <div className={classes.root}>
                <div className={classes.filterContainer}>
                    <BobFilter
                        activeButton={{
                            rangeDate: true,
                            crew: true,
                            boatsByFloat: true,
                        }}
                        noDivider
                        rightComponent={
                            !isMobile && (
                                <ButtonBON
                                    variant="contained"
                                    color="primary"
                                    label={this.displayText('newNavigation')}
                                    size={`large`}
                                    fullWidth={isMobile}
                                    onClick={this.openNewNavigation}
                                />
                            )
                        }
                    />
                </div>
                {isMobile && (
                    <div className={classes.newNavigation}>
                        <ButtonBON
                            variant="contained"
                            color="primary"
                            label={this.displayText('newNavigation')}
                            size={`large`}
                            fullWidth={isMobile}
                            onClick={this.openNewNavigation}
                        />
                    </div>
                )}

                {content}

                {canBeExtend && (
                    <div className={classes.seeMore} onClick={this.getMoreNav}>
                        {this.displayText('seeMore')}
                    </div>
                )}

                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.cancelCreation}
                    modalCores={{
                        create: (
                            <LogBookModal
                                onClose={this.closeNewNavigation}
                                noCross={false}
                            />
                        ),
                        AISUsed: (
                            <LogBookAISModal
                                handleValid={this.saveAISModal}
                                noCross={false}
                            />
                        ),
                        manualSetup: (
                            <LogBookManualSetupModal
                                onCancel={this.cancelManualSetup}
                                handleValid={this.saveManualSetup}
                                noCross={false}
                            />
                        ),
                    }}
                    maxWidth={{
                        create: 'sm',
                        AISUsed: 'sm',
                        manualSetup: 'sm',
                    }}
                    titles={{
                        create: this.displayText('newNavigation'),
                        AISUsed: this.displayText('AISUsed'),
                        manualSetup: this.displayText('manualSetup'),
                    }}
                />
            </div>
        )
    }
}

function filterNavigation(navigations, bobFilter, users) {
    if (
        bobFilter.selectedBoatsByFleet &&
        bobFilter.selectedBoatsByFleet.length > 0
    ) {
        navigations = navigations.filter(nav =>
            bobFilter.selectedBoatsByFleet.includes(nav.boatId),
        )
    }

    if (bobFilter.selectedCrew && bobFilter.selectedCrew.length > 0) {
        navigations = navigations.filter(nav => {
            const totalCrew = [
                ...nav.navigationMembers.map(member => member.userLinkId),
                nav.captainLinkId,
            ]
            let keepNav = false

            bobFilter.selectedCrew.forEach(search => {
                if (totalCrew.includes(search)) keepNav = true
            })

            return keepNav
        })
    }

    if (bobFilter.rangeDate.start && bobFilter.rangeDate.end) {
        const start = new Date(bobFilter.rangeDate.start)
        const end = new Date(bobFilter.rangeDate.end)

        navigations = navigations.filter(nav => {
            if (
                nav.delimitedDate.startDate &&
                start < new Date(nav.delimitedDate.startDate) &&
                end > new Date(nav.delimitedDate.startDate)
            ) {
                return true
            }
            return false
        })
    }

    if (bobFilter.searchString && bobFilter.searchString !== '') {
        const search = bobFilter.searchString.toLowerCase()
        navigations = navigations.filter(nav => {
            const totalCrewIds = [
                ...nav.navigationMembers.map(member => member.userLinkId),
                nav.captainLinkId,
            ]
            const totalCrew = totalCrewIds.map(id => {
                const item = users.find(user => user.id === id)

                if (item?.user)
                    return `${item.user.firstName} ${item.user.lastName}`
                if (item?.userSubscribe) return item?.userSubscribe?.mail
                return ''
            })
            let itemFound = false
            if (nav.comment?.toLowerCase()?.search(search) !== -1) return true

            if (
                nav.departureAddress?.fullText &&
                nav.departureAddress?.fullText
                    ?.toLowerCase()
                    ?.search(search) !== -1
            )
                return true

            if (
                nav.arrivalAddress?.fullText &&
                nav.arrivalAddress?.fullText?.toLowerCase()?.search(search) !==
                    -1
            )
                return true

            if (
                nav.boat?.name &&
                nav.boat?.name?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.maker &&
                nav.boat?.maker?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.model &&
                nav.boat?.model?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.serialNumber &&
                nav.boat?.serialNumber?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.description &&
                nav.boat?.description?.toLowerCase()?.search(search) !== -1
            )
                return true

            nav.stopOvers.forEach(stop => {
                if (
                    stop.address?.fullText &&
                    stop.address?.fullText?.toLowerCase()?.search(search) !== -1
                )
                    itemFound = true
            })

            totalCrew.forEach(user => {
                if (user.toLowerCase()?.search(search) !== -1) itemFound = true
            })

            if (itemFound === true) return true

            return false
        })
    }

    return navigations
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600
    const bobFilter = state.filter.bobFilter
    const users = state.group?.groupsMembers?.linkRGU
    let navigations = state.logbook?.navigations

    if (navigations) {
        navigations = filterNavigation(navigations, bobFilter, users)
    }

    return {
        isMobile,
        bobFilter,
        navigations: navigations,
        loading: state.logbook.loading,
        groupId: state.group?.currentGroupId,
        events: state.bob.events || [],
        ais: state.navigationApiData.ais || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookList))
