import { withStyles } from '@material-ui/styles'
import styles from './Styles/NewUserModalCss'
import dictionary from './Dictionary/NewUserModalDictionary'
import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { MenuItem, TextField, Tooltip, Typography } from '@material-ui/core'
import { Button } from '../../../common'
import { groupActions } from '../../../../actions/group.actions'
import InfoIcon from '@material-ui/icons/Info'
import InputLabel from '../../../common/UsefullComponents/InputLabel'
import { connect } from 'react-redux'

const ToolTip2 = React.forwardRef(function ToolTipLink(props, ref) {
    const HtmlTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: '#ffffff',
            color: 'black',
            maxWidth: 330,
            marginTop: -8,
            border: '1px solid #dadde9',
            fontSize: '12px',
            '& span': {
                '&:hover': {
                    cursor: `pointer`,
                },
                fontWeight: `bold`,
                textDecoration: `underline`,
            },
        },
    }))(Tooltip)

    return (
        <HtmlTooltip interactive ref={ref} title={<p>{props.title}</p>}>
            <InfoIcon />
        </HtmlTooltip>
    )
})
class NewUserModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            email: ``,
            role: ``,
            roles: [],
            boatsGroup: [],
            errors: false,
        }

        this.send = this.send.bind(this)
        this._changeEmail = this._changeEmail.bind(this)
        this._changeRole = this._changeRole.bind(this)
        this._changeFleet = this._changeFleet.bind(this)
    }

    componentDidMount() {
        const { boatId, boats } = this.props
        const { boatsGroup } = this.state

        const fetching = async () => {
            const results = await groupActions.getRoles()
            this.setState({ roles: results })
        }

        fetching()

        if (boats && boatId && boatsGroup.length === 0) {
            for (var i = 0; i < boats.length; i++) {
                if (boats[i].id == boatId) {
                    this.setState({ boatsGroup: [boats[i]] })
                }
            }
        }
    }

    send() {
        const { email, role, boatsGroup } = this.state
        if (
            /^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(email) &&
            role != '' &&
            boatsGroup.length > 0
        ) {
            this.props.handleSend(email, role, boatsGroup)
        } else {
            this.setState({ errors: true })
        }
    }
    _changeEmail({ target: { value } }) {
        this.setState({ email: value })
    }
    _changeRole({ target: { value } }) {
        this.setState({ role: value })
    }
    _changeFleet(boats) {
        if (boats) {
            this.setState({ boatsGroup: boats })
        }
    }

    render() {
        const { classes, groupLoading, groupId } = this.props
        const { role, email, boatsGroup, errors } = this.state

        const roles = this.state.roles?.filter(
            role => role.id < 7 || role.userGroupId === groupId,
        )
        if (groupLoading > 0) return this.renderLoading(`100px`)

        return (
            <div className={classes.container}>
                <Typography className={classes.title}>
                    {' '}
                    {this.displayText(`invite`)}{' '}
                </Typography>
                <TextField
                    label={this.displayText(`email`)}
                    onChange={this._changeEmail}
                    className={classes.field}
                    value={email}
                    variant="outlined"
                    type="email"
                    style={{ marginBottom: 29 }}
                    error={
                        !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                            email,
                        ) && errors
                    }
                    helperText={
                        !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                            email,
                        ) && errors
                            ? this.displayText('errorEmail')
                            : ''
                    }
                    required
                />
                <TextField
                    select
                    label={this.displayText(`role`)}
                    onChange={this._changeRole}
                    className={classes.field}
                    value={role}
                    variant="outlined"
                    style={{ marginBottom: 29 }}
                    error={role === '' && errors}
                    helperText={
                        role === '' && errors
                            ? this.displayText('errorRole')
                            : ''
                    }
                    required
                >
                    {roles.map((r, index) => (
                        <MenuItem
                            value={r.id}
                            key={index}
                            className={classes.menuItemRole}
                        >
                            {' '}
                            {r.userRole
                                ? r.userRole
                                : this.displayTextApi(r.translation)}
                            {r.translation ? (
                                <ToolTip2
                                    style={{ fontSize: `20px` }}
                                    title={this.displayTextApi(
                                        r.translationDescription,
                                    )}
                                    aria-label="add"
                                />
                            ) : (
                                <></>
                            )}
                        </MenuItem>
                    ))}
                </TextField>
                <InputLabel
                    boats={this.props.boats}
                    className={{
                        width: `100%`,
                        color: `black`,
                        marginTop: 0,
                        '& .MuiSelect-selectMenu': {
                            display: `flex`,
                            alignItems: `center`,
                            justifyContent: `space-between`,
                        },
                    }}
                    onTagsChange={this._changeFleet}
                    modalOpen={this.state.modalOpen}
                    handleOpenModal={this.handleOpenModal}
                    selected={boatsGroup?.length > 0 ? boatsGroup : []}
                    error={!boatsGroup?.length > 0 && errors}
                    helperText={
                        !boatsGroup?.length > 0 && errors
                            ? this.displayText('errorBoat')
                            : ''
                    }
                    required
                />
                <Typography className={classes.advice}>
                    {' '}
                    {this.displayText(`care`)}{' '}
                </Typography>
                <Button
                    label={this.displayText(`send`)}
                    onClick={this.send}
                    classNameOverride={classes.button}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        groupLoading: state.group.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(NewUserModal))
