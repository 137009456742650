export default {
    electricityTab: {
        FR: 'Eclair',
        EN: 'ELECTRICITY',
    },
    understood: {
        FR: `J'ai compris`,
        EN: `I understood`,
    },
    addEngine: {
        FR: `Pour ajouter un moteur, cliquez sur le bouton ajouter (+) puis sur "Ajoutez un équipement"`,
        EN: `To add an engine, click on the add button (+) and then on "Add a device".`,
    },
    engineTab: {
        FR: 'MOTEUR',
        EN: 'ENGINE',
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    electronicTab: {
        FR: 'ELECTRONIQUE',
        EN: 'ELECTRONIC',
    },
    waterTab: {
        FR: 'CIRCUIT EAU DOUCE',
        EN: 'FRESHWATER CIRCUIT',
    },
    hullTab: {
        FR: 'COQUE',
        EN: 'HULL',
    },
    sailingTab: {
        FR: 'ACCASTILLAGE ET GREEMENT',
        EN: 'FITTINGS AND RIGGING',
    },
    comfortTab: {
        FR: 'CONFORT ET SECURITE',
        EN: 'COMFORT AND SAFETY',
    },
    other: {
        FR: 'AUTRE',
        EN: 'OTHER',
    },
    renewedPartsMonth: {
        FR: 'Pièce à renouveler dans les 3 mois',
        EN: 'Part to be renewed within 3 months',
    },
    renewedEquipmentMonth: {
        FR: 'Équipement à renouveler dans les 3 mois',
        EN: 'Equipment to be renewed within 3 months',
    },
    dateReplaceDeprecated: {
        FR: 'La date de remplacement est dépassée',
        EN: 'The replacement date is deprecated',
    },
    partInStock: {
        FR: 'Pièce en stock',
        EN: 'Part in stock',
    },
    equipmentInStock: {
        FR: 'Équipement en stock',
        EN: 'Equipment in stock',
    },

    addEquipement: {
        FR: 'Ajouter un équipement',
        EN: 'Add an equipment',
    },
    addPart: {
        FR: 'Ajouter une pièce',
        EN: 'Add a part',
    },
    userAddsAlert: {
        FR:
            "Vous êtes en train d'ajouter un équipement à contrôler, à vous de jouer !",
        EN:
            'You are adding adding an equipment to control, it is up to you to play !',
    },
    dateCommissioning: {
        FR: 'Date de mise en service',
        EN: 'Installation date',
    },
    dateReplacement: {
        FR: 'Date de remplacement',
        EN: 'Replacement date',
    },

    commissioning: {
        FR: 'Mis(e) en service le',
        EN: 'Commissioning',
    },
    replacement: {
        FR: `À remplacer le`,
        EN: `To replace the`,
    },
    replacementHours: {
        FR: 'Remplacement (h)',
        EN: 'Replacement (h)',
    },
    stock: {
        FR: 'En stock',
        EN: 'In stock',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer ce document ?',
        EN: 'Do you really want to delete this document?',
    },
    completingInventory: {
        FR: 'Vous êtes en train de compléter votre inventaire, bien joué !',
        EN: 'You are completing your inventory, well done!',
    },
    dateModal: {
        FR: "Dates et nombres d'heures",
        EN: 'Dates and number of hours',
    },
    details: {
        FR: 'Détails',
        EN: 'Details',
    },
    brand: {
        FR: 'Marque',
        EN: 'Brand',
    },
    model: {
        FR: 'Modèle',
        EN: 'Model',
    },
    serialNumber: {
        FR: 'Numéro de série',
        EN: 'Serial number',
    },
    stockModal: {
        FR: 'Stock',
        EN: 'Stock',
    },
    supplier: {
        FR: 'Fournisseur',
        EN: 'Supplier',
    },
    amountVAT: {
        FR: 'Montant (TTC)',
        EN: 'Amount (incl. VAT)',
    },
    docPho: {
        FR: 'Documents et photos',
        EN: 'Documents and pictures',
    },
    notes: {
        FR: 'Notes',
        EN: 'Notes',
    },
    importantInformation: {
        FR: "Ecrivez ici d'autres informations importantes",
        EN: 'Write here other important information',
    },
    internalReference: {
        FR: 'Référence interne  ',
        EN: 'Internal reference',
    },
    datePurchase: {
        FR: "Date d'achat",
        EN: 'Date of purchase',
    },
    createReference: {
        FR:
            'Créez une référence qui vous permettra de retrouver cet élément facilement.',
        EN: 'Create a reference that will allow you to find this item easily.',
    },
    indicateDate: {
        FR:
            "Indiquez ici les dates d'installation et de changement de cet élement. Vous pouvez également ajouter le nombre d'heures.",
        EN:
            'Indicate here the dates of installation and changes of this element. You can also add the number of hours.',
    },
    actuelNbrHours: {
        FR: "Nombre d'heures actuel",
        EN: 'Current number of hours',
    },
    replaceHours: {
        FR: 'Echéance de remplacement (h)',
        EN: 'Replacement time (h)',
    },
    addHours: {
        FR: "Ajouter le nombres d'heures",
        EN: 'Add the number of hours',
    },
    deleteHours: {
        FR: "Enlever le nombres d'heures",
        EN: 'Add the number of hours',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    parts: {
        FR: 'pièce en stock',
        EN: 'part in stock',
    },
    partsPluriel: {
        FR: 'pièces en stock',
        EN: 'parts in stock',
    },
    missingPart: {
        FR: 'pièce manquante',
        EN: 'missing part',
    },
    missingParts: {
        FR: 'pièces manquantes',
        EN: 'missing parts',
    },
    renewedPart: {
        FR: 'pièce à renouveler',
        EN: 'part to be renewed',
    },
    renewedParts: {
        FR: 'pièces à renouveler',
        EN: 'part to be renewed',
    },
    errorStock: {
        FR: `Vous devez définir un stock.`,
        EN: `You must define a stock.`,
    },
    errorDate: {
        FR: `Vous devez remplir une date de mise en service.`,
        EN: `You must fill in a start-up date.`,
    },
    validateDeleteInv: {
        FR: 'Voulez-vous vraiment supprimer cet ou ces élément(s) ?',
        EN: 'Do you really want to delete this item(s)?',
    },
    minDateMessage: {
        FR: `La date de remplacement ne peut pas être inférieure à la date de mise en service.`,
        EN: `The replacement date cannot be less than the date of commissioning.`,
    },
    edition: {
        FR: `Édition`,
        EN: `Edition`,
    },
    sub: {
        FR: `Ordre de commande`,
        EN: `Ordering`,
    },
    mailPart1: {
        FR: `Bonjour madame, monsieur,\n\n J'aurais besoin des pièces suivantes :\n`,
        EN: `Good morning, Madam, Sir,\n\n I need the following items:\n`,
    },
    mailPart2: {
        FR: `Pouvez vous me confirmer les délais de livraison et de prix pour cette commande ?\n\n`,
        EN: `Can you confirm the delivery time and price for this order?\n\n`,
    },
    mailPart3: {
        FR: `Cordialement\n`,
        EN: `Sincerely\n`,
    },
    helpEdit: {
        FR: `Cliquez ici pour modifier l'élément et gérer le stock`,
        EN: `Click here to modify the item and manage the stock`,
    },
    controlHourLabel: {
        FR: `Contrôlé à`,
        EN: `Controlled at`,
    },
    replaceHourLabel: {
        FR: `À remplacer dans`,
        EN: `To be replaced in`,
    },
    power: {
        FR: `Puissance`,
        EN: `Power`,
    },
    totalHours: {
        FR: `Nombre d'heures moteur`,
        EN: `Number of engine hours`,
    },
    descr: {
        FR:
            'Vous utilisez une version limitée du BoatOn Book. Pour ajouter de nouveaux équipements, suivre vos dépenses, vos tâches et accéder à toutes les fonctionnalités, ',
        EN:
            'You are using a limited version of the BoatOn Book. To add new equipment, track your expenses, tasks and access all features, ',
    },
    premium: {
        FR: 'passez au mode Amiral.',
        EN: 'switch to Admiral mode.',
    },
    helpHour: {
        FR: `Vous devez renseigner le nombre d'heure d'utilisation actuelle pour connaitre l'heure du dernier controle`,
        EN: `You must fill in the number of hours of current use to know the time of the last control`,
    },
    addNumberOfHours: {
        FR: `Ajouter le nombres d'heures`,
        EN: `Add the number of hours`,
    },
    hideNumberOfHours: {
        FR: `Enlever le nombres d'heures`,
        EN: `Hide the number of hours`,
    },
    inventaire: {
        FR: `Mon inventaire`,
        EN: `My stock`,
    },
    dateEmpty: {
        FR: 'jj/mm/aaaa',
        EN: 'mm/dd/yyyy',
    },
    addElement: {
        FR: 'Ajouter un article',
        EN: 'Add an item',
    },
    warrantyStart: {
        FR: 'Début de garantie',
        EN: 'Warranty start',
    },
    warrantyEnd: {
        FR: 'Fin de garantie',
        EN: 'Warranty end',
    },
    capacity: {
        FR: 'Capacité totale',
        EN: 'Maximal capacity',
    },
    level: {
        FR: 'Niveau actuel',
        EN: 'Actual level',
    },
    nearDateReplace: {
        FR: 'Date bientot passée',
        EN: 'Date soon passed',
    },
    outDatedReplace: {
        FR: 'Date passée',
        EN: 'Date passed',
    },

    amountIt: {
        FR: 'TTC',
        EN: 'VAT incl.',
    },
    amountEt: {
        FR: 'HT',
        EN: 'VAT excl.',
    },
    tax: {
        FR: 'TVA',
        EN: 'VAT',
    },
}
