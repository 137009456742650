export default {
    entretiens: {
        FR: 'Mes tâches',
        EN: 'My task',
    },
    apercu: {
        FR: `Aperçu du rapport`,
        EN: `Overview of the report`,
    },
    edit: {
        FR: `Modifier la tâche`,
        EN: `Modify the task`,
    },
    valid: {
        FR: `Valider la tâche`,
        EN: `Validate the task`,
    },
    unvalid: {
        FR: `Dévalider la tâche`,
        EN: `Cancel the validation`,
    },
    files: {
        FR: `Voir les fichiers joints`,
        EN: `See attached files`,
    },
    supprimerEntretien: {
        FR: `Supprimer la tâche`,
        EN: `Delete the task`,
    },
    soonDone: {
        FR: `Bientôt à échéance`,
        EN: `Due`,
    },
    more3month: {
        FR: `Dans plus de 3 mois`,
        EN: `In more than 3 months`,
    },
    maintened: {
        FR: `Effectués`,
        EN: `Done`,
    },
    repairDone: {
        FR: `Tâche effectuée`,
        EN: `Past task`,
    },
    repairToDo: {
        FR: `Future tâche`,
        EN: `Future task`,
    },
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    electricity: {
        FR: 'Electricité',
        EN: 'Electricity',
    },
    electronic: {
        FR: 'Electronique',
        EN: 'Electronic',
    },
    freshWaterCircuit: {
        FR: 'Circuit eau douce',
        EN: 'Fresh water circuit',
    },
    hardwareAndRigging: {
        FR: 'Accastillage et gréement',
        EN: 'Fittings and rigging',
    },
    comfortAndSafety: {
        FR: 'Confort et sécurité',
        EN: 'Comfort and safety',
    },
    shell: {
        FR: 'Coque',
        EN: 'Hull',
    },
    other: {
        FR: 'Autre',
        EN: 'Other',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    userAddsRepair: {
        FR: "Vous êtes en train d'ajouter une tâche, bien joué !",
        EN: 'You are currently adding a task, well done!',
    },
    userEditRepair: {
        FR: 'Vous êtes en train de modifier une tâche, bien joué !',
        EN: 'You are currently edit a task, well done !',
    },
    userAddsMaintenance: {
        FR: 'Ajouter une tâche',
        EN: 'add task',
    },
    userAddsRepairLayout: {
        FR: 'Ajouter une réparation ou un aménagement',
        EN: 'Add repair or layout',
    },
    typeRepair: {
        FR: 'Choisir un type de tâche',
        EN: 'Choose a task type',
    },
    operationRepair: {
        FR: "Renseigner l'opération à effectuer",
        EN: 'Enter the operation to be performed',
    },

    familyRepair: {
        FR: "Choisir une famille d'équipements",
        EN: 'Choose an equipment family,',
    },
    equipement: {
        FR: 'Choisir un equipement',
        EN: 'Choose an equipment',
    },
    piece: {
        FR: 'Choisir une piece',
        EN: 'Choose a piece',
    },
    dateLast: {
        FR: 'Dernier contrôle',
        EN: 'Last date',
    },
    dateNext: {
        FR: 'Prochain contrôle',
        EN: 'Next date',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    detailsDernierControle: {
        FR: 'Détails du dernier contrôle',
        EN: 'Commentary of the last check',
    },
    detailsProchainControle: {
        FR: 'Détails du prochain contrôle',
        EN: 'Commentary of the next check',
    },
    addDocumentLast: {
        FR: 'Documents du dernier contrôle',
        EN: 'Document of the last check',
    },
    addDocumentNext: {
        FR: 'Documents du prochain contrôle',
        EN: 'Document of the next check',
    },
    addRepair: {
        FR: 'Ajouter une tâche',
        EN: 'Create new task',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Confirm',
    },
    gerer: {
        FR: 'Vous pouvez visualiser et gérer tous vos tâches ici.',
        EN: 'You can view and manage all your task here',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer cette tâche ?',
        EN: 'Do you really want to delete this task ?',
    },
    alert: {
        FR:
            "Vous devez remplir les champs type de tâche, famille d'équipements, nom, dernier contrôle et prochain contrôle",
        EN:
            'You must fill in the task type, equipment family, name, last check and next check',
    },
    alert1: {
        FR:
            'Vous devez remplir les champs dernier contrôle et prochain contrôle',
        EN: 'You must fill in the last check and next check',
    },
    alert2: {
        FR: 'Vous devez remplir le champs dernier contrôle',
        EN: 'You must fill in the last check',
    },
    alert3: {
        FR: 'Vous devez remplir le champs prochain contrôle',
        EN: 'You must fill in the next check',
    },
    euro: {
        FR: '€',
        EN: '€',
    },
    empty: {
        FR: 'Tout',
        EN: 'All',
    },

    chosePerson: {
        FR: "Choisir le responsable de l'opération",
        EN: 'Choose the person in charge of the operation',
    },
    controlCarried: {
        FR: 'Contrôle effectué dans les délais ?',
        EN: 'Control carried out in time?',
    },
    reasonsDelay: {
        FR:
            'Si besoin, vous pouvez écrire ici les raisons du retard du contrôle',
        EN:
            'If necessary, you can write here the reasons for the delay of the control',
    },
    errorChoiceType: {
        FR: `Vous devez choisir un type de transaction.`,
        EN: `You must choose a transaction type.`,
    },
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    errorDate: {
        FR: `Vous devez choisir une date.`,
        EN: `You must choose a date.`,
    },
    errorActualCycleLengthNext: {
        FR: `Indiquez ici le nombre d'heures auquel va etre fait ce prochain contrôle.`,
        EN: `Please enter the current number of hours your equipment has been in operation.`,
    },
    errorActualCycleLengthLast: {
        FR: `Indiquez ici le nombre d'heures auquel a été fait ce dernier contrôle. Nous pourrons ainsi calculer la prochaine échéance.`,
        EN: `Please enter the current number of hours your equipment has been in operation. This will allow us to calculate the next inspection deadline.`,
    },
    actualCycleHours: {
        FR: `Nombre d'heures`,
        EN: `Current time of use in hours`,
    },
    validateText: {
        FR: `Êtes vous sûr de vouloir valider cette opération ?`,
        EN: `Are you sure you want to validate this operation?`,
    },
    unValidateText: {
        FR: `Êtes vous sûr de vouloir dévalider cette opération ?`,
        EN: `Are you sure you want to cancel the validation of this checkup?`,
    },
    checkupDate: {
        FR: `Date du contrôle`,
        EN: `Checkup date`,
    },
    editRepair: {
        FR: `Edition d'une tâche`,
        EN: `Editing a task`,
    },
    createNextRepair: {
        FR: `Création d'une future tâche`,
        EN: `Creating a future task`,
    },
    createLastRepair: {
        FR: `Enregistrement d'une tâche effectuée`,
        EN: `Recording of a task`,
    },
    planeNext: {
        FR: `Planifier le prochain contrôle`,
        EN: `Plan the next inspection`,
    },
    descr: {
        FR:
            'Vous utilisez une version limitée du BoatOn Book. Pour ajouter de nouveaux équipements, suivre vos dépenses, vos tâches et accéder à toutes les fonctionnalités, ',
        EN:
            'You are using a limited version of the BoatOn Book. To add new equipment, track your expenses, tasks and access all features, ',
    },
    premium: {
        FR: 'passez au mode Bosco.',
        EN: 'switch to Bosco mode.',
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    validEventTitle: {
        FR: `Validation de l'opération`,
        EN: `Checkup confirmation`,
    },
    cancel: {
        FR: `Annuler`,
        EN: `Go back`,
    },
    unvalidEventTitle: {
        FR: `Annulation de la validation`,
        EN: `Cancellation of the validation`,
    },
    showDocs: {
        FR: `Mes documents`,
        EN: `Documents`,
    },
}
