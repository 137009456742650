import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../common/BoatOnComponent'
import Navigation from '../common/Navigation'

import ProfileTab from './ProfileTab'
import ProfileMenu from './ProfileMenu'
import ParkingTab from './ParkingTab'
import BoatTab from './BoatTab'
import PaymentTab from './PaymentTab'
import styles from './Styles/ProfilePageCss'
import dictionary from './Dictionary/ProfilePageDico'
import { fetchData } from '../../actions/api.actions'
import { history } from '../../helpers'
import { API_URL } from '../../services/config.service'
import { userActions } from '../../actions'
import AppRoute from '../../constants/AppRoute'
import { Container, Grid } from '@material-ui/core'
import { getCurrentRouteKey } from '../../languages/LocalizerUtils'
import { allParkingsActions } from '../../actions/parkings/allParkings.actions'
import InqueriesPage from '../InqueriesPage/InqueriesPage'

class ProfilePage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            messageStripe: null,
        }

        this.handleLogout = this.handleLogout.bind(this)
    }

    async checkStripeRedirect() {
        const location = window.location.href

        var index = location.indexOf('?code=')
        if (index !== -1) {
            var StripeAuthID = location.slice(Math.max(0, index + 6))

            while (StripeAuthID.indexOf('?code=') > 0) {
                index = StripeAuthID.indexOf('?code=')
                StripeAuthID = StripeAuthID.slice(Math.max(0, index + 6))
            }
            await this.props.dispatch(
                userActions.updateStripeAccountId(StripeAuthID),
            )
            // alert(
            //     'Vous avez recu un courrier électronique de la part de BoatOn pour valider votre compte bancaire',
            // )
            // //history.push('/paymentInfos')
            // this.historyPush(history, AppRoute.PaymentInfos)
        }
    }

    async componentDidMount() {
        const { user } = this.props
        await this.checkStripeRedirect()
        if (!user) {
            return
        }
        if (user && !user.stripeCustomerId) {
            this.props.dispatch(userActions.init())
        }
        this.props.dispatch(allParkingsActions.getPersonalParkings())
    }

    componentDidUpdate(prevProps) {
        if (!this.props.user && !this.props.loading === 0) {
            this.historyPush(history, AppRoute.Login)
        }
        if (prevProps.user !== this.props.user) {
            this.props.dispatch(allParkingsActions.getPersonalParkings())
        }
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    handleLogout() {
        this.props.dispatch(userActions.logout())
    }

    render() {
        const { classes, loadingParkings, loading } = this.props
        const url = this.props.location.pathname
        var currentAppRoute = getCurrentRouteKey(
            url,
            this.getContext(),
            this.getMessages(),
        )
        if (currentAppRoute == AppRoute.Profile)
            currentAppRoute = AppRoute.PersonnalInfos

        return (
            <div className={classes.root}>
                <Navigation location={this.props.location}>
                    {loading > 0 || loadingParkings > 0 ? (
                        this.renderLoading('100')
                    ) : (
                        <Container maxWidth="xl">
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    lg={3}
                                    classes={{ root: classes.menuRoot }}
                                >
                                    <ProfileMenu
                                        onChange={value =>
                                            this.handleChange(value, 'menu')
                                        }
                                        menu={currentAppRoute}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} lg={9}>
                                    {currentAppRoute ===
                                    AppRoute.PersonnalInfos ? (
                                        <ProfileTab />
                                    ) : currentAppRoute ===
                                      AppRoute.PaymentInfos ? (
                                        <PaymentTab />
                                    ) : currentAppRoute ===
                                      AppRoute.BoatInfos ? (
                                        <BoatTab />
                                    ) : currentAppRoute ===
                                      AppRoute.ParkingInfos ? (
                                        <ParkingTab />
                                    ) : currentAppRoute ===
                                          AppRoute.Inqueries ||
                                      currentAppRoute ===
                                          AppRoute.RepairsInqueries ||
                                      currentAppRoute ===
                                          AppRoute.InsuranceInqueries ||
                                      currentAppRoute ===
                                          AppRoute.InsuranceDetails ||
                                      currentAppRoute === AppRoute.ChatBox ||
                                      currentAppRoute ===
                                          AppRoute.InqueriesParking ? (
                                        <InqueriesPage
                                            location={this.props.location}
                                        />
                                    ) : (
                                        <div />
                                    )}
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                </Navigation>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        parkingMenu: state.allParkings.personalParkings,
        user: state.authentication.user,
        loading: state.authentication.loading,
        loadingParkings: state.allParkings.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ProfilePage))
