export default {
    filterBtn: {
        FR: 'Stock des pièces',
        EN: 'Stock',
    },
    familyEquipement: {
        FR: "Familles d'équipements",
        EN: 'Equipment family',
    },
    unfoldAll: {
        FR: 'Tout déplier',
        EN: 'Unfold all',
    },
    dateMise: {
        FR: 'Date de mise en service',
        EN: 'Installation date',
    },
    dateRemplacement: {
        FR: 'Date de remplacement',
        EN: 'Replacement date',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    moreFilters: {
        FR: 'Plus de filtres',
        EN: 'More filters',
    },
    selectAll: {
        FR: 'Tout sélectionner',
        EN: 'Select all',
    },
    parts: {
        FR: 'pièces en stock',
        EN: 'parts in stock',
    },
    missingParts: {
        FR: 'pièces manquantes',
        EN: 'missing parts',
    },
    renewedParts: {
        FR: 'pièces à renouveler',
        EN: 'parts to be renewed',
    },
    dates: {
        FR: `Dates`,
        EN: `Dates`,
    },
    checkupType: {
        FR: `Types de tâches`,
        EN: `Repair types`,
    },
    transactionType: {
        FR: `Types de transaction`,
        EN: `Transaction types`,
    },
    workEventType: {
        FR: `Type d'évenement`,
        EN: `Event type`,
    },
    absenceState: {
        FR: `Statut`,
        EN: `State`,
    },
    workTime: {
        FR: 'Temps de travail',
        EN: 'Work time',
    },
    amount: {
        FR: `Montant`,
        EN: `Amount`,
    },
    hours: {
        FR: `Heures`,
        EN: `Hours`,
    },
    equipments: {
        FR: `Équipements`,
        EN: `Equipments`,
    },
    userAttachedRole: {
        FR: `Responsable`,
        EN: `Responsible`,
    },
    managerSearch: {
        FR: `Manager`,
        EN: `Manager`,
    },
    pending: {
        FR: 'En attente',
        EN: 'Pending',
    },
    boatSelector: {
        FR: 'Bateau',
        EN: 'Boat',
    },
    boatsSelector: {
        FR: 'Bateaux',
        EN: 'Boats',
    },
    boatsSelectorLabel: {
        FR: 'Bateaux',
        EN: 'Boats',
    },
    crewSelector: {
        FR: 'Equipage',
        EN: 'Crew',
    },
}
