import React from 'react'
import BoatOnComponent from '../BoatOnComponent'
import InfoIcon from '@material-ui/icons/Info'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import styles from './Styles/BoatOnAlertCss'
import { withStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'

class BoatOnAlert extends BoatOnComponent {
    _addLink() {
        const { linkText, linkAction, classes } = this.props

        if (!linkText && !linkAction) return <></>
        else if (!linkText || !linkAction)
            console.error(`no linkText or linkAction`)

        return (
            <span onClick={linkAction} className={classes.link}>
                {linkText}
            </span>
        )
    }

    _renderTextAlert() {
        const { description, classes, severity } = this.props
        const classText = {
            info: classes.text,
            warning: classes.textWarning,
        }

        return (
            <p className={classText[severity || `info`]}>
                {description}
                {this._addLink()}
            </p>
        )
    }

    _renderComponentAlert() {
        const { component, classes, severity } = this.props
        const classText = {
            info: classes.text,
            warning: classes.textWarning,
        }

        return <div className={classText[severity || `info`]}>{component}</div>
    }

    render() {
        const {
            classes,
            severity,
            style,
            description,
            component,
            title,
            onClose,
        } = this.props
        const classContainer = {
            info: classes.container,
            warning: classes.containerWarning,
        }

        return (
            <>
                <Alert
                    severity={severity || `info`}
                    icon={
                        severity === `info` ? (
                            <InfoIcon
                                className={classes.infoIcon}
                                width={100}
                                height={100}
                            />
                        ) : severity === `warning` ? (
                            <WarningIcon width={100} height={100} />
                        ) : null
                    }
                    onClose={onClose}
                    variant={`outlined`}
                    className={classContainer[severity || `info`]}
                    style={style || {}}
                    classes={{
                        message:
                            severity === `warning`
                                ? `${classes.message} ${classes.warningMessage}`
                                : classes.message,
                        icon: classes.icon,
                        action:
                            severity === `warning`
                                ? `${classes.action} ${classes.warningAction}`
                                : classes.action,
                    }}
                >
                    {title && (
                        <AlertTitle
                            className={
                                severity === `warning`
                                    ? classes.warningTitle
                                    : classes.title
                            }
                        >
                            {/* different title because modal for warning is different for now*/}
                            {title}{' '}
                        </AlertTitle>
                    )}
                    {description && this._renderTextAlert()}
                    {component && this._renderComponentAlert()}
                </Alert>
            </>
        )
    }
}

export default withStyles(styles)(BoatOnAlert)
