import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/BoatSelecterDico'
import styles from './Styles/BoatSelecterCss'
import BoatOnComponent from '../../common/BoatOnComponent'
import homepage from '../../../images/homepageiphone.jpg'
import voiliericon from '../../../images/voiliericon.svg'
import moteurboaticon from '../../../images/moteurboaticon.svg'
import semirigideicon from '../../../images/semirigideicon.svg'
import catamaranicon from '../../../images/catamaranicon.svg'
import penicheicon from '../../../images/penicheicon.svg'
import jetskyicon from '../../../images/jetskyicon.svg'
import { Typography } from '@material-ui/core'
import { addFileToBoat, initBobWithBoat } from '../../../actions/bob.actions'
import { getTotalNumberBoat } from '../../../actions/bob/boat.actions'
import { userActions } from '../../../actions/user.actions'
import { typesActions } from '../../../actions/types.actions'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import { API_URL } from '../../../services/config.service'
import BoatOnAutoComplete from '../../common/BoatOnAutoComplete'
import { groupActions } from '../../../actions/group.actions'
import { Add } from '@material-ui/icons'
import BoatOnModal from '../../common/BoatOnModal'
import RenderBoatCreate from '../../common/UsefullComponents/RenderBoatCreate'
import {
    getCycleHours,
    getHoursOfLastCheckup,
    getCheckupEquipment,
    getElementWithDetails,
} from '../../../actions/bob.actions'
import { history } from '../../../helpers'
import AppRoute from '../../../constants/AppRoute'
import { filterActions } from '../../../actions'
import { getContextFromUrl } from '../../../languages/LocalizerUtils'
const hoursThreshold = 75 //%

class BoatSelecter extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            totalExpense: 0,
            totalIncome: 0,
            checkups: [],
            checkupsLate: [],
            fileNoUrl: { boat: [] },
            displayNewFiles: { boat: [] },
            arrayBoats: null,
            inputSelecter: ``,
            numberFleet: 0,
            openModal: false,
        }
        this.clickPicture = this.clickPicture.bind(this)
        this.addPicture = this.addPicture.bind(this)
        this.changeBoat = this.changeBoat.bind(this)
        this.changeInputSelecter = this.changeInputSelecter.bind(this)
        this.isOptionEqualToValue = this.isOptionEqualToValue.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.valid = this.valid.bind(this)
        this._goToCheckupPage = this._goToCheckupPage.bind(this)
        this._goToSpendingPage = this._goToSpendingPage.bind(this)
    }

    componentWillMount() {
        const { events, eventTypes } = this.props

        if (events && eventTypes) {
            this.setState({ totalExpense: this.getTotalExpense() })
            this.setState({ totalIncome: this.getTotalIncome() })
            this.setState({ checkups: this.getTotalCheckups3Month() })
            this.setState({ checkupsLate: this.getTotalCheckupsLate() })
        }
    }

    _selectBoats(boatId) {
        const { arrayBoats } = this.state

        return arrayBoats.find(array => array.boat?.id === parseInt(boatId))
    }

    _sortBoats(arrayBoats) {
        arrayBoats.sort((a, b) => {
            if (!b.fleetName) return -1
            else if (!a.fleetName) return 1
            return -b.fleetName?.localeCompare(a.fleetName)
        })
    }

    componentDidMount() {
        const { subscriptions } = this.props
        if (subscriptions) this._setBoats()
    }

    _setBoats() {
        const { user, subscriptions, onProfile } = this.props
        let arrayBoats = []
        let fleetAdded = []
        if (!onProfile && user?.userGroup && Array.isArray(user?.userGroup)) {
            user.userGroup.forEach(group => {
                if (
                    group?.linkRGU &&
                    Array.isArray(group?.linkRGU) &&
                    group.linkRGU.length === 1
                ) {
                    if (
                        group.linkRGU[0].boats.length === 0 &&
                        group.id !== user?.ownGroup?.id
                    ) {
                        arrayBoats.push({
                            fleetId: group.id,
                            fleetName: group.name,
                            boat: null,
                        })
                    } else {
                        group.linkRGU[0].boats.forEach(boat => {
                            arrayBoats.push({
                                fleetId: group.id,
                                fleetName: group.name,
                                boat: boat,
                            })
                            if (
                                !fleetAdded.some(
                                    fleedId => fleedId === group.id,
                                )
                            ) {
                                fleetAdded.push(group.id)
                            }
                        })
                    }
                }
            })
        }

        if (user?.ownGroup?.boats) {
            if (user.ownGroup.boats.length === 0) {
                arrayBoats.push({
                    fleetId: user.ownGroup.id,
                    fleetName: user.ownGroup.name,
                    boat: null,
                })
                fleetAdded.push(user.ownGroup.id)
            } else {
                if (userActions.checkSubscriptionMultiBoat(subscriptions)) {
                    user.ownGroup.boats.forEach(boat => {
                        arrayBoats.push({
                            fleetId: user.ownGroup.id,
                            fleetName: user.ownGroup.name,
                            boat: boat,
                        })
                        if (
                            !fleetAdded.some(
                                fleedId => fleedId === user.ownGroup.id,
                            )
                        ) {
                            fleetAdded.push(user.ownGroup.id)
                        }
                    })
                } else {
                    arrayBoats.push({
                        fleetId: user.ownGroup.id,
                        fleetName: user.ownGroup.name,
                        boat: user.ownGroup.boats[0],
                    })
                    if (
                        !fleetAdded.some(
                            fleedId => fleedId === user.ownGroup.id,
                        )
                    ) {
                        fleetAdded.push(user.ownGroup.id)
                    }
                }
            }
        }

        this._sortBoats(arrayBoats)

        if (
            !subscriptions ||
            !userActions.checkSubscriptionMultiBoat(subscriptions)
        ) {
            let isFirstOwnBoatAdded = false
            arrayBoats = arrayBoats.filter(element => {
                if (
                    isFirstOwnBoatAdded &&
                    element.fleetId === user?.ownGroup?.id
                )
                    return false
                else if (
                    !isFirstOwnBoatAdded &&
                    element.fleetId === user?.ownGroup?.id
                ) {
                    isFirstOwnBoatAdded = true
                }
                return true
            })
        }

        this.setState({
            arrayBoats: arrayBoats,
            numberFleet: fleetAdded.length,
        })
    }

    shouldComponentUpdate(_, nextState) {
        if (this.state.openModal && nextState.openModal !== false) return false
        return true
    }

    componentDidUpdate(prevProps) {
        const {
            loading,
            events,
            eventTypes,
            loadingUser,
            subscriptions,
        } = this.props

        if (events && eventTypes && prevProps.loading > 0 && loading === 0) {
            this.setState({ totalExpense: this.getTotalExpense() })
            this.setState({ totalIncome: this.getTotalIncome() })
            this.setState({ checkups: this.getTotalCheckups3Month() })
            this.setState({ checkupsLate: this.getTotalCheckupsLate() })
        }

        if (
            (!this.state.arrayBoats ||
                (loadingUser === 0 && prevProps.loadingUser !== 0)) &&
            subscriptions
        ) {
            this._setBoats()
        }
    }

    getTotalCheckups3Month() {
        const { events, eventTypes } = this.props
        const array = []
        const date3Month = new Date().setMonth(new Date().getMonth() + 3)
        events.forEach(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            if (
                eventType &&
                event.checkup &&
                eventType.repair &&
                ((event.delimitedDate && event.delimitedDate.startDate) ||
                    event.actualDuration)
            ) {
                const eventWithActualHours = getElementWithDetails(
                    events,
                    getCheckupEquipment(event),
                )
                const minValue = getHoursOfLastCheckup(events, event) || 0

                const maxValue = event.actualDuration || getCycleHours(event)
                const value =
                    eventWithActualHours?.detail?.hourActual ||
                    getHoursOfLastCheckup(events, event) ||
                    0
                if (
                    value &&
                    maxValue - minValue > 0 &&
                    !event?.delimitedDate?.endDate
                ) {
                    if (
                        ((value - minValue) * 100) / (maxValue - minValue) >=
                        hoursThreshold
                    ) {
                        array.push(event)
                    }
                } else if (
                    event.delimitedDate &&
                    event.delimitedDate.startDate
                ) {
                    const date = new Date(
                        event.delimitedDate.startDate,
                    ).getTime()
                    const dateOfDay = new Date()
                    dateOfDay.setDate(dateOfDay.getDate() - 1)
                    if (
                        date >= dateOfDay &&
                        date < date3Month &&
                        !event.delimitedDate.endDate
                    ) {
                        array.push(event)
                    }
                }
            }
        })
        return array
    }

    getTotalCheckupsLate() {
        const { events, eventTypes } = this.props
        const array = []
        events.forEach(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            if (
                eventType &&
                event.checkup &&
                eventType.repair &&
                ((event.delimitedDate && event.delimitedDate.startDate) ||
                    event.actualDuration)
            ) {
                const eventWithActualHours = getElementWithDetails(
                    events,
                    getCheckupEquipment(event),
                )
                const minValue = getHoursOfLastCheckup(events, event) || 0

                const maxValue = event.actualDuration || getCycleHours(event)
                const value =
                    eventWithActualHours?.detail?.hourActual ||
                    getHoursOfLastCheckup(events, event) ||
                    0
                if (
                    value &&
                    maxValue - minValue > 0 &&
                    !event?.delimitedDate?.endDate
                ) {
                    if (value >= maxValue) {
                        array.push(event)
                    }
                } else if (
                    event.delimitedDate &&
                    event.delimitedDate.startDate
                ) {
                    const date = new Date(
                        event.delimitedDate.startDate,
                    ).getTime()
                    if (date < new Date() && !event.delimitedDate.endDate) {
                        array.push(event)
                    }
                }
            }
        })
        return array
    }

    getTotalIncome() {
        const { events, eventTypes } = this.props
        var total = 0

        events.forEach(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            if (
                eventType &&
                event.transaction &&
                eventType.earning &&
                !eventType.spending &&
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                new Date(event.delimitedDate.startDate).getFullYear() ===
                    new Date().getFullYear()
            )
                total += parseFloat(Math.abs(event.transaction.amountIt))
            else if (
                eventType &&
                eventTypes &&
                event?.transaction?.amountIt > 0 &&
                eventType.spending &&
                event.delimitedDate &&
                eventType.earning &&
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                new Date(event.delimitedDate.startDate).getFullYear() ===
                    new Date().getFullYear()
            ) {
                total += parseFloat(event.transaction.amountIt)
            }
        })
        return total
    }

    getTotalExpense() {
        const { events, eventTypes } = this.props
        var total = 0

        events.forEach(event => {
            const eventType = typesActions.getEventTypeById(
                eventTypes,
                event.eventType.id,
            )
            if (
                eventType &&
                eventType.spending &&
                !eventType.earning &&
                event.delimitedDate &&
                event.delimitedDate.startDate &&
                event.transaction &&
                new Date(event.delimitedDate.startDate).getFullYear() ===
                    new Date().getFullYear()
            )
                total += parseFloat(event.transaction.amountIt)
            else if (
                eventType &&
                eventTypes &&
                event?.transaction?.amountIt < 0 &&
                eventType.spending &&
                eventType.earning &&
                new Date(event.delimitedDate.startDate).getFullYear() ===
                    new Date().getFullYear()
            ) {
                total += parseFloat(event.transaction.amountIt * -1)
            }
        })
        return total
    }

    clickPicture() {
        this.refs.boatSelecterFileUploader.click()
    }

    addPicture(boat) {
        addFileToBoat(
            this,
            boat,
            this.props.boat,
            this.state.fileNoUrl.boat,
            this.state.displayNewFiles.boat,
        )
    }

    changeBoat(_, element) {
        const { user, groupId, dispatch } = this.props
        const lastGroupId = groupId || localStorage.getItem('groupIdSelect')

        if (element) {
            if (!element.boat) {
                this.setState({ openModal: 'newBoat' })
            } else {
                localStorage.setItem('boatIdSelect', element?.boat?.id || -1)
                if (parseInt(lastGroupId) !== element?.fleetId) {
                    dispatch(
                        groupActions.changeCurrentGroup(element?.fleetId || -1),
                    )
                }
                dispatch(initBobWithBoat({ user, boatId: element?.boat?.id }))
            }
        }
    }

    changeInputSelecter(value) {
        this.setState({ inputSelecter: value })
    }

    isOptionEqualToValue(option) {
        const { boat } = this.props
        const { arrayBoats } = this.state
        const boatId = localStorage.getItem('boatIdSelect')
            ? localStorage.getItem('boatIdSelect')
            : boat && boat.id
            ? boat.id
            : arrayBoats[0] && arrayBoats[0]?.boat?.id
            ? arrayBoats[0].boat.id
            : null
        const selectedBoat = this._selectBoats(boatId)

        if (selectedBoat?.boat?.id === option.boat?.id) {
            return true
        }
        return false
    }

    _renderAutoComplete() {
        const { boat, classes } = this.props
        const { arrayBoats, inputSelecter, numberFleet } = this.state
        let boatId = localStorage.getItem('boatIdSelect')
            ? localStorage.getItem('boatIdSelect')
            : boat && boat.id
            ? boat.id
            : arrayBoats[0] && arrayBoats[0]?.boat?.id
            ? arrayBoats[0].boat.id
            : null
        const selectedBoat = this._selectBoats(boatId)
        return (
            <BoatOnAutoComplete
                autoComplete
                value={selectedBoat ? selectedBoat.boat.name : inputSelecter}
                getOptionSelected={this.isOptionEqualToValue}
                options={arrayBoats.sort(
                    (a, b) => -b.fleetName?.localeCompare(a.fleetName),
                )}
                placeholder={this.displayText('boatName')}
                inputClass={classes.name}
                onChange={this.changeBoat}
                onInputChange={this._changeInputSelecter}
                labelAdd={this.displayText('addLabelButton')}
                onAddButtonClicked={this.handleOpenDialog}
                getElementToShow={option => {
                    return typeof option === `string`
                        ? option
                        : option?.boat?.name || (
                              <div className={classes.flexRow}>
                                  <Add className={classes.plus} />
                                  <Typography>
                                      {this.displayText('addBoat')}
                                  </Typography>
                              </div>
                          )
                }}
                filterOptions={(option, input) =>
                    !input.inputValue ||
                    option?.boat?.name
                        .toLowerCase()
                        .includes(input.inputValue.toLowerCase())
                }
                groupBy={option => (numberFleet > 1 ? option.fleetName : null)}
            />
        )
    }

    _goToCheckupPage() {
        this.historyPush(history, AppRoute.LogBook.Repair)
    }

    _goToSpendingPage() {
        this.historyPush(history, AppRoute.LogBook.ExpensesAndIncome)
    }

    async valid(next, boatId) {
        const { user } = this.props
        if (boatId) {
            await this.props.dispatch(
                groupActions.changeCurrentGroup(user.ownGroup.id),
            )
            this.props.dispatch(initBobWithBoat({ boatId, user }))
        }
        if (next && typeof next === 'string') {
            this.setState({ openModalNewBoat: next, newBoatId: boatId })
            this.setBoat()
        } else {
            this.handleClose()
        }
    }

    handleClose() {
        this.setState({
            openModal: false,
            newBoatId: false,
        })
    }

    renderBoatSelecter() {
        const {
            classes,
            boat,
            style,
            subscriptions,
            onProfile,
            bob,
            user,
        } = this.props
        const {
            totalExpense,
            totalIncome,
            checkups,
            arrayBoats,
            checkupsLate,
        } = this.state
        var engineTime = 0
        if (
            bob?.inventories?.equipmentFamilies &&
            Object.entries(bob?.inventories?.equipmentFamilies).length > 0
        ) {
            const familyMotor = Object.entries(
                bob?.inventories?.equipmentFamilies,
            ).filter(equipmentFamily => equipmentFamily[1].type?.id === 1)
            if (
                familyMotor[0] &&
                familyMotor[0][1]?.equipments &&
                Object.entries(familyMotor[0][1].equipments).length > 0
            ) {
                const motor = Object.entries(
                    familyMotor[0][1].equipments,
                ).filter(equipment => equipment[1].equipmentType?.id === 5)
                if (motor.length > 0 && motor[0][1].event.detail.hourActual) {
                    engineTime = motor[0][1].event.detail.hourActual
                }
            }
        }
        const entretien =
            checkups.length > 1
                ? this.displayText('maintenancePlural')
                : this.displayText('maintenanceSingular')
        const checkupLate =
            checkupsLate.length > 1
                ? this.displayText('maintenanceLatePlural')
                : this.displayText('maintenanceLateSingular')

        if (getTotalNumberBoat(user) === 0 || !arrayBoats) return <></>
        return (
            <div
                className={onProfile ? classes.rootProfile : classes.root}
                style={style ? style : {}}
            >
                {boat && boat.files && boat.files.length > 0 ? (
                    <div className={classes.title} onClick={this.clickPicture}>
                        <div className={classes.iconBoat}>
                            <input
                                onChange={this.addPicture}
                                type="file"
                                id="boatSelecterFileUploader"
                                ref="boatSelecterFileUploader"
                                style={{ display: 'none' }}
                                multiple
                                required
                                size="60"
                            />
                            <AddAPhotoIcon className={classes.addPicture} />
                            <img
                                src={
                                    boat.files &&
                                    boat.files.length >= 1 &&
                                    boat.files[boat.files.length - 1] &&
                                    boat.files[boat.files.length - 1].link &&
                                    API_URL +
                                        '/files/' +
                                        boat.files[boat.files.length - 1].link
                                }
                                alt={
                                    boat.files[boat.files.length - 1].link
                                        ? boat.files[boat.files.length - 1].link
                                        : boat.files[boat.files.length - 1].blop
                                }
                                className={classes.image}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={classes.title} onClick={this.clickPicture}>
                        <img src={homepage} alt="homepage" />
                        <input
                            onChange={this.addPicture}
                            type="file"
                            id="boatSelecterFileUploader"
                            ref="boatSelecterFileUploader"
                            style={{ display: 'none' }}
                            multiple
                            required
                        />
                        <AddAPhotoIcon className={classes.addPicture} />
                        <div className={classes.iconBoat2}>
                            {boat && boat.boatType != null ? (
                                boat.boatType.id === 2 ? (
                                    <img src={voiliericon} alt="voilier" />
                                ) : boat.boatType.id === 1 ? (
                                    <img
                                        src={moteurboaticon}
                                        alt="moteurboaticon"
                                    />
                                ) : boat.boatType.id === 3 ? (
                                    <img
                                        src={semirigideicon}
                                        alt="semirigideicon"
                                    />
                                ) : boat.boatType.id === 4 ? (
                                    <img
                                        src={catamaranicon}
                                        alt="catamaranicon"
                                    />
                                ) : boat.boatType.id === 5 ? (
                                    <img src={penicheicon} alt="penicheicon" />
                                ) : boat.boatType.id === 6 ? (
                                    <img src={jetskyicon} alt="jetskyicon" />
                                ) : (
                                    <div />
                                )
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                )}
                {this._renderAutoComplete()}
                <div className={classes.block}>
                    {userActions.checkSubscriptionPage(subscriptions, 4) && (
                        <>
                            {checkups.length > 0 || checkupsLate.length > 0 ? (
                                <div
                                    onClick={this._goToCheckupPage}
                                    className={classes.maintenance}
                                >
                                    <Typography variant={`body1`}>
                                        <span className={classes.info}>
                                            {checkups.length}
                                        </span>{' '}
                                        {entretien}
                                    </Typography>
                                    <Typography variant={`body1`}>
                                        <span className={classes.info}>
                                            {checkupsLate.length}
                                        </span>{' '}
                                        {checkupLate}
                                    </Typography>
                                </div>
                            ) : (
                                <Typography variant={`body1`}>
                                    {this.displayText('noMaintenance')}
                                </Typography>
                            )}
                            <Typography variant={`body1`}>
                                <span className={classes.info}>
                                    {engineTime}
                                </span>{' '}
                                {engineTime > 0
                                    ? this.displayText('hours')
                                    : this.displayText('hour')}{' '}
                                {new Date().getDate()}{' '}
                                {new Date().toLocaleString('default', {
                                    month: 'long',
                                })}
                            </Typography>{' '}
                        </>
                    )}
                    {userActions.checkSubscriptionPage(subscriptions, 5) && (
                        <>
                            <div
                                onClick={this._goToSpendingPage}
                                className={classes.maintenance}
                            >
                                <Typography variant={`body1`}>
                                    <span className={classes.info}>
                                        {totalExpense
                                            .toFixed(2)
                                            .replace(/[.,]00$/, '')}
                                    </span>{' '}
                                    {totalExpense > 0
                                        ? this.displayText('spents')
                                        : this.displayText('spent')}{' '}
                                    {new Date().getFullYear()}
                                </Typography>
                                <Typography variant={`body1`}>
                                    <span className={classes.info}>
                                        {totalIncome
                                            .toFixed(2)
                                            .replace(/[.,]00$/, '')}
                                    </span>{' '}
                                    {totalIncome > 0
                                        ? this.displayText('earneds')
                                        : this.displayText('earned')}{' '}
                                    {new Date().getFullYear()}
                                </Typography>{' '}
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    render() {
        const { user } = this.props

        return (
            <>
                {this.renderBoatSelecter()}
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleClose}
                    modalCores={{
                        newBoat: (
                            <RenderBoatCreate
                                addNow={true}
                                valid={this.valid}
                                addToOwnGroupId={user?.ownGroup?.id}
                            />
                        ),
                    }}
                    titles={{
                        newBoat: this.displayText('addBoat'),
                    }}
                    maxWidth={{
                        newBoat: `sm`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    return {
        user: state.authentication.user,
        loadingUser: state.authentication.loading,
        loadingBoats: state.boat.loading,
        checkups: state.bob.activecheckups,
        boat: state.bob.boat,
        loading: state.bob.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || null,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {},
            true,
        ),
        bob: state.bob,
        groupId: state.group.currentGroupId,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatSelecter))
