import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import BoatOnComponent from '../../../common/BoatOnComponent'
import MovementTab from './MovementTab'
import BudgetTab from './BudgetTab'
import dictionary from './Dictionary/ExpIncPageDico'
import styles from './Styles/ExpIncPageCss'
import {
    removeTransactionFromBOBEvent,
    setBOBStateEvent,
} from '../../../../actions/bob.actions'
import { typesActions } from '../../../../actions/types.actions'
import { filterActions } from '../../../../actions/filter.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import BudgetModal from './BudgetModal'
import catamaranicon from '../../../../images/catamaranicon.svg'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import BudgetBar from './BudgetBar'
import { Button as ButtonBON } from '../../../common/BoatOnButton'
import BobFilter from '../BobFilter'
import { Checkbox, Divider, Switch, Typography } from '@material-ui/core'
import ModifyDateModal from './ModifyDateModal'
import { generateExcelBudget } from '../../../../actions/bob/budget.actions'
import { redirectActions } from '../../../../actions/redirect.actions'

class ExpIncPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            events: [],
            event: null,
            openModalConnection: true,
            openMenu: false,
            openAddModal: 0,
            openEditModal: 0,
            transactionId: null,
            type: '',
            date: '',
            amount: '',
            title: '',
            details: '',
            update: 0,
            edit: false,
            displayNewFiles: { event: [] },
            fileNoUrl: { event: [] },
            deletedFiles: { event: [] },
            error: false,
            checkedExpenses: [],
            accordionExpenseExpanded: false,
            accordionIncomeExpanded: false,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()
        this.initializeReactGA()

        this.handleClickMenu = this.handleClickMenu.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleAddExpense = this.handleAddExpense.bind(this)
        this.handleAddIncome = this.handleAddIncome.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.delete = this.delete.bind(this)
        this.edit = this.edit.bind(this)
        this.handleMovementCheck = this.handleMovementCheck.bind(this)
        this.handleCheckAll = this.handleCheckAll.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.deleteMultiple = this.deleteMultiple.bind(this)
        this.toggleAllPanels = this.toggleAllPanels.bind(this)
        this.handleChangePanel = this.handleChangePanel.bind(this)
        this.downloadExcel = this.downloadExcel.bind(this)
    }

    componentDidUpdate(prevProps) {
        const {
            events,
            eventTypes,
            loading,
            bobFilter,
            redirectEvent,
            redirectParams,
        } = this.props

        if (!this.props.bob.loading && prevProps.bob.loading) {
            this.handleClose()
        }

        if (
            (events &&
                eventTypes &&
                prevProps.loading !== 0 &&
                loading === 0) ||
            JSON.stringify(prevProps.bobFilter) !== JSON.stringify(bobFilter)
        ) {
            const transaction = []
            let f = []

            if (events !== null) {
                events.forEach(event => {
                    const eventType = typesActions.getEventTypeById(
                        eventTypes,
                        event.eventType.id,
                    )
                    if (
                        event.transaction &&
                        eventType &&
                        (eventType.spending || eventType.earning)
                    ) {
                        transaction.push(event)
                    }
                })

                f = events.filter(e => e.delimitedDate)
            }

            this.setState({
                events: transaction,
                accordionExpenseExpanded: f.length > 0,
            })
        }

        if (
            redirectEvent &&
            redirectParams &&
            loading === 0 &&
            this.state.openEditModal === 0
        ) {
            this.setState({
                // On ouvre la modale dépense/revenu
                openAddModal: redirectEvent.eventType.earning ? 2 : 1,
                event: redirectEvent,
            })
            this.props.dispatch(redirectActions.resetRedirectionParams())
        }
    }

    componentDidMount() {
        const { events, eventTypes } = this.props

        if (events && eventTypes && events.length) {
            const transaction = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (
                    event.transaction &&
                    eventType &&
                    (eventType.spending || eventType.earning)
                ) {
                    transaction.push(event)
                }
            })
            const f = events.filter(e => e.delimitedDate)

            this.setState({
                events: transaction,
                accordionExpenseExpanded: f.length > 0,
            })
        }
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook/my-expenses-and-incomes')
    }

    handleClose() {
        this.setState({
            transactionId: null,
            openModalConnection: false,
            openAddModal: 0,
            openEditModal: 0,
            type: '',
            date: '',
            amount: '',
            title: '',
            details: '',
            edit: false,
            displayNewFiles: { event: [] },
            fileNoUrl: { event: [] },
            deletedFiles: { event: [] },
            error: false,
        })
    }

    handleClickMenu() {
        this.setState({ openMenu: !this.state.openMenu })
    }

    handleAddExpense() {
        this.setState({ openAddModal: 1 })
        setBOBStateEvent(this)
    }

    handleAddIncome() {
        this.setState({ openAddModal: 2 })
        setBOBStateEvent(this)
    }

    handleEdit() {
        this.setState({ openEditModal: 1 })
    }

    handleUpdate() {
        this.setState({ update: this.state.update - 1 })
    }

    toggleAllPanels() {
        const value = !(
            this.state.accordionExpenseExpanded &&
            this.state.accordionIncomeExpanded
        )

        this.setState({
            accordionExpenseExpanded: value,
            accordionIncomeExpanded: value,
        })
    }

    // renderMenu() {
    //     return (
    //         <div
    //             style={{
    //                 padding: '5px 0px',
    //                 display: 'flex',
    //                 justifyContent: 'flex-end',
    //                 width: 'calc(100% - 50px)',
    //                 margin: '0px 25px',
    //             }}
    //         >
    //             <ButtonBON
    //                 dropDown={[
    //                     {
    //                         label: this.displayText(`addExpense`),
    //                         action: this.handleAddExpense,
    //                         rightBlockIds: 9,
    //                     },
    //                     {
    //                         label: this.displayText(`addIncome`),
    //                         action: this.handleAddIncome,
    //                         rightBlockIds: 8,
    //                     },
    //                 ]}
    //                 variant="contained"
    //                 color="primary"
    //                 label={this.displayText('addMovement')}
    //                 size={`large`}
    //             />
    //         </div>
    //     )
    // }

    edit(transaction) {
        const { eventTypes } = this.props
        const eventType = typesActions.getEventTypeById(
            eventTypes,
            transaction.eventType.id,
        )

        this.setState({
            edit: true,
            event: transaction,
            openAddModal:
                (eventType && eventType.earning && !eventType.spending) ||
                (transaction?.transaction?.amountIt > 0 && eventType?.id === 1)
                    ? 2
                    : 1,
        })
    }

    delete(transaction) {
        this.props.dispatch(removeTransactionFromBOBEvent(transaction.id))
        this.setState({
            checkedExpenses: [],
        })
    }

    deleteMultiple() {
        if (window.confirm(this.displayText('validateDeleteMultiple'))) {
            this.state.checkedExpenses.forEach(id => {
                this.props.dispatch(removeTransactionFromBOBEvent(id))
            })
            this.setState({
                checkedExpenses: [],
            })
        }
    }

    handleMovementCheck(id) {
        let c = [...this.state.checkedExpenses]
        if (c.indexOf(id) >= 0) {
            c.splice(c.indexOf(id), 1)
        } else {
            c.push(id)
        }

        this.setState({
            checkedExpenses: c,
        })
    }

    handleCheckAll() {
        const { events, checkedExpenses } = this.state

        let c = [...checkedExpenses]

        // Si tous les éléments sont déjà cochés alors on décoche tout
        if (events.length == checkedExpenses.length) {
            c = []
        } else {
            // Pour chaque mouvement on vérifie si il est déjà coché, sinon on le coche
            events.forEach(spending => {
                if (c.indexOf(spending.id) == -1) {
                    c.push(spending.id)
                }
            })
        }

        this.setState({
            checkedExpenses: c,
        })
    }

    handleChangePanel(panel, value = undefined) {
        const s = {}
        s[panel] = value ?? !this.state[panel]
        this.setState(s)
    }

    downloadExcel() {
        const { checkedExpenses } = this.state
        this.props.dispatch(
            generateExcelBudget(
                checkedExpenses,
                this.context,
                this.props.boat?.id,
            ),
        )
    }

    _getDetailAndChekupTitle(event) {
        const { checkup, detail } = event
        if (checkup)
            return checkup?.checkupType
                ? this.displayTextApi(checkup.checkupType.translation)
                : checkup?.userCheckup
                ? checkup.userCheckup.name
                : event.title
        else if (detail) {
            const part = detail.part
            const equipment = detail.equipment
            if (part) {
                if (part.userPart) return part.userPart.name
                else if (part.partType)
                    return this.displayTextApi(part.partType.translation)
            } else if (equipment) {
                if (equipment.userEquipment) {
                    return equipment.userEquipment.name
                } else if (equipment.equipmentType)
                    return this.displayTextApi(
                        equipment.equipmentType.translation,
                    )
            }
        }
        return event.title
    }

    _setTitle(event) {
        const { openAddModal } = this.state
        if (!event) return ''
        if (event.checkup || event.detail)
            return `${
                openAddModal === 1
                    ? this.displayText('expense')
                    : this.displayText('income')
            } - ${this._getDetailAndChekupTitle(event)}`
        return openAddModal === 1
            ? this.displayText('expense')
            : this.displayText('income')
    }

    render() {
        const { classes, boats, user, loading, boat } = this.props
        const {
            openAddModal,
            openEditModal,
            checkedExpenses,
            events,
            event,
        } = this.state
        const isMobile = window.innerWidth < 600

        let expCheckWithoutDate = false

        checkedExpenses.forEach(expenseId => {
            let elem = events.find(e => e.id === expenseId)

            if (elem && elem.delimitedDate === null) expCheckWithoutDate = true
        })

        if (!user) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }
        if (!boat && loading === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                    {
                        <ModalNoBoat
                            open={this.state.openModalNoBoat}
                            handleClose={this.handleClose}
                        />
                    }
                </div>
            )
        }
        if (loading !== 0)
            return <>{this.renderLoading('100px', '100px', true)}</>

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.fixedHeader}>
                    <Typography className={classes.title}>
                        {this.displayText('budget')} - {boat.name}
                    </Typography>
                    <BobFilter
                        activeButton={{
                            rangeDate: true,
                            equipment: true,
                            equipmentFamily: true,
                            transactionType: true,
                            rangeHour: true,
                            rangeAmount: true,
                        }}
                        repair
                        noDivider
                        selectAll={this.props.selectAll}
                        rightComponent={
                            <ButtonBON
                                dropDown={[
                                    {
                                        label: this.displayText(`addExpense`),
                                        action: this.handleAddExpense,
                                        rightBlockIds: 9,
                                    },
                                    {
                                        label: this.displayText(`addIncome`),
                                        action: this.handleAddIncome,
                                        rightBlockIds: 8,
                                    },
                                ]}
                                variant="contained"
                                color="primary"
                                label={this.displayText('addMovement')}
                                size={`large`}
                                fullWidth={isMobile ? true : false}
                            />
                        }
                    />
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        {isMobile && (
                            <div>
                                {this.displayText('expandAll')}
                                <Switch
                                    color="primary"
                                    onChange={this.toggleAllPanels}
                                    checked={
                                        this.state.accordionExpenseExpanded &&
                                        this.state.accordionIncomeExpanded
                                    }
                                />
                            </div>
                        )}
                        <div>
                            {this.displayText('selectAll')}
                            <Checkbox
                                checked={
                                    checkedExpenses.length != 0 &&
                                    checkedExpenses.length == events.length
                                }
                                onClick={this.handleCheckAll}
                                color="primary"
                            />
                        </div>
                    </div>
                    <Divider />
                </div>
                <div className={classes.firstPart}>
                    <BudgetTab
                        boats={boats}
                        update={this.state.update}
                        updated={this.handleUpdate}
                        spendings={this.state.events}
                        checkedExpenses={[...checkedExpenses]}
                        onCheckAll={this.handleCheckAll}
                        accordionExpenseExpanded={
                            this.state.accordionExpenseExpanded
                        }
                        accordionIncomeExpanded={
                            this.state.accordionIncomeExpanded
                        }
                        handleChangePanel={this.handleChangePanel}
                        // handleAddExpense={this.handleAddExpense}
                        // handleAddIncome={this.handleAddIncome}
                    />
                    <MovementTab
                        boats={boats}
                        update={this.state.update}
                        updated={this.handleUpdate}
                        edit={this.edit}
                        delete={this.delete}
                        spendings={this.state.events}
                        onMovementCheck={this.handleMovementCheck}
                        checkedExpenses={[...checkedExpenses]}
                    />
                    {/* <BoatOnBlock
                        blockIds={[8, 9]}
                        onlyOneId={true}
                        children={this.renderMenu()}
                    /> */}
                </div>
                <BoatOnModal
                    openCondition={
                        openAddModal > 0 && openAddModal < 3
                            ? 'budget'
                            : openEditModal > 0
                            ? 'editDates'
                            : null
                    }
                    fullWidth={false}
                    handleClose={this.handleClose}
                    modalCores={{
                        budget: (
                            <BudgetModal
                                event={this.state.event}
                                openAddModal={openAddModal}
                                events={this.props.events}
                                eventTypes={this.props.eventTypes}
                                noCross={false}
                            />
                        ),
                        editDates: (
                            <ModifyDateModal
                                selectedEvents={checkedExpenses.map(expenseId =>
                                    this.props.events.find(
                                        e => e.id == expenseId,
                                    ),
                                )}
                                handleClose={this.handleClose}
                            />
                        ),
                    }}
                    titles={{
                        budget: event?.id
                            ? this._setTitle(event)
                            : openAddModal === 1
                            ? this.displayText('expense')
                            : this.displayText('income'),
                        editDates: this.displayText('editDate'),
                    }}
                />
                <BudgetBar
                    open={this.state.checkedExpenses.length}
                    handleEdit={this.handleEdit}
                    disabledEdit={expCheckWithoutDate}
                    handleDelete={this.deleteMultiple}
                    handleDownload={this.downloadExcel}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkups: state.bob.checkups,
        checkupTypes: state.fetch.checkupTypes,
        boat: state.bob.boat,
        bob: state.bob,
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                checkupTypesChecked: true,
                rangeDate: true,
                rangeHour: true,
                amountRange: true,
                checkupEquipmentFamily: true,
                equipment: true,
            },
        ),
        loading: state.bob.loading,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        bobFilter: state.filter.bobFilter,
        redirectEvent: state.redirect.event,
        redirectParams: state.redirect.params,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ExpIncPage))
