export const fr = {
    'routes.root': '/',
    'routes.home': '/accueil',
    'routes.inqueries': '/mes-demandes',
    'routes.inqueries.insurance': '/mes-demandes/assurance',
    'routes.inqueries.insurance.details': '/mes-demandes/assurance/details',
    'routes.inqueries.repairs': '/mes-demandes/reparations',
    'routes.inqueries.parking': '/mes-demandes/parking',
    'routes.inqueries.parking.chatbox': '/mes-demandes/parking/messagerie',
    'routes.inquery': '/ma-demande',
    'routes.login': '/connexion',
    'routes.loginInGroup': '/connexion/:id',
    'routes.register': '/register',
    'routes.profile': '/mon-profil',
    'routes.404': '/erreur-404',
    'routes.rent-my-parking': '/louer-mon-emplacement',
    'routes.messages': '/messages',
    'routes.forget': '/forget',
    'routes.parkingid': '/emplacement/:id',
    'routes.booking': '/reservation',
    'routes.personnal-infos': '/mes-informations-personnelles',
    'routes.payment-infos': '/payment-infos',
    'routes.parkings-infos': '/mes-emplacements',
    'routes.boat-infos': '/mon-bateau',
    'routes.le-blog': '/le-blog',
    'routes.search-boat-parking': '/chercher-un-parking',
    'routes.get-boat-insurance': '/trouver-assurance-bateau',
    'routes.profile.boat-infos': '/mon-bateau',
    'routes.sepa': '/paiement-sepa',
    'routes.prices': '/nos-offres',
    'routes.prices.part': '/nos-offres/part',
    'routes.prices.yacht': '/nos-offres/yacht',
    'routes.logbook.home': '/boaton-book',
    'routes.logbook.alerts': '/boaton-book/mes-alertes',
    'routes.logbook.inventory': '/boaton-book/mon-inventaire',
    'routes.logbook.fleet': '/boaton-book/ma-flotte',
    'routes.logbook.repairs': '/boaton-book/mes-entretiens',
    'routes.logbook.informations': '/boaton-book/mon-bateau',
    'routes.logbook.exps-a-incm': '/boaton-book/mes-depenses-et-revenus',
    'routes.logbook.documents': '/boaton-book/mes-documents',
    'routes.logbook.book': '/boaton-book/mon-boaton-book',
    'routes.logbook.calendar': '/boaton-book/mon-boaton-calendrier',
    'routes.logbook.worktime': '/boaton-book/mon-temps-de-travail',
    'routes.logbook.pricing': '/boaton-book/tarifs',
    'routes.logbook.order-form': '/boaton-book/bon-de-commande',
    'routes.logbook.my-users': '/boaton-book/mes-utilisateurs',
    'routes.logbook.settings.worktimes':
        '/boaton-book/parametres/temps-de-travail',
    'routes.logbook.settings.paid-vacations':
        '/boaton-book/parametres/conges-payes',
    'routes.logbook.logbook': '/boaton-book/journal-de-bord',
    'routes.logbook.logbookid': '/boaton-book/journal-de-bord/:id',

    'routes.mainsite': '/site-principal',
    'routes.redirect': '/redirect',
}
