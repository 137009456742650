import React from 'react'
import dictionary from './Dictionary/PricesPageDico.js'
import styles from './Styles/PricesPageCss'
import { Typography, withStyles } from '@material-ui/core'
import BoatOnComponent from '../common/BoatOnComponent.js'
import Vector1 from '../../images/pricesPage/Vector1.png'
import Vector2 from '../../images/pricesPage/Vector2.png'
import Vector3 from '../../images/pricesPage/Vector3.png'
import Vector4 from '../../images/pricesPage/Vector4.png'
import OfferCard from './OfferCard.jsx'
import { Link } from 'react-router-dom'
import press4 from '../../images/pricesPage/bpifrance.JPG'
import press3 from '../../images/pricesPage/wistream.JPG'
import press2 from '../../images/pricesPage/ffpp.jpg'
import press1 from '../../images/pricesPage/samboat.jpg'
import press5 from '../../images/pricesPage/unitec.png'
import photo1 from '../../images/pricesPage/face1.jpeg'
import photo2 from '../../images/pricesPage/face2.jpg'
import photo3 from '../../images/pricesPage/face3.jpeg'
import photo4 from '../../images/pricesPage/face4.jpg'
import photo5 from '../../images/pricesPage/face5.jpeg'
import Vector5 from '../../images/pricesPage/1.png'
import Vector6 from '../../images/pricesPage/2.png'
import Vector7 from '../../images/pricesPage/3.png'
import Navigation from '../common/Navigation'
import AppRoute from '../../constants/AppRoute'
import ModalConnection from '../common/UsefullComponents/ModalConnection/ModalConnection.jsx'
import Comment from './Comment.jsx'
import BoatOnModal from '../common/BoatOnModal'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { STRIPE_PUBLIC_KEY } from '../../services/config.service'
import ModalSubscribe from '../common/UsefullComponents/ModalConnection/ModalSubscribe'
import { connect } from 'react-redux'
import { userActions } from '../../actions/user.actions.js'
import { history } from '../../helpers'
import pricesConfig from './pricesConfig.js'

const comments = [
    {
        nom: `Pierre`,
        photo: photo1,
        date: `13 mars 2021`,
    },
    {
        nom: `Benoît`,
        photo: photo2,
        date: `29 juillet 2021`,
    },
    {
        nom: `Stéphane`,
        photo: photo3,
        date: `12 mai 2021`,
    },
    {
        nom: `Eric`,
        photo: photo4,
        date: `16 juin 2021`,
    },
    {
        nom: `Jean-Luc 33`,
        photo: photo5,
        date: `23 janvier 2021`,
    },
]

class PricesPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        let urlSplit = window.location?.pathname.split('/')
        let urlParam = urlSplit ? urlSplit[urlSplit.length - 1] : null
        this.state = {
            particulier: !!this.props.particulier
                ? this.props.particulier
                : this.props.location?.state
                ? this.props.location.state.particulier
                : urlParam === 'yacht'
                ? false
                : true,
            openModalSubscribe: null,
            openModalConnection: false,
            subscribeType: null,
            byYear: true,
        }

        this._renderHeader = this._renderHeader.bind(this)
        this._changeTab = this._changeTab.bind(this)
        this.subscribeTo = this.subscribeTo.bind(this)
        this._getMainContent = this._getMainContent.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleClosePayment = this.handleClosePayment.bind(this)
        this.saveUpdateSubscription = this.saveUpdateSubscription.bind(this)
        this.redirectInfos = this.redirectInfos.bind(this)
    }

    _changeTab() {
        this.setState({ particulier: !this.state.particulier })
    }

    componentDidUpdate(_, prevState) {
        const {
            subscribeType,
            openModalConnection,
            openModalSubscribe,
        } = this.state
        const { user } = this.props

        if (
            subscribeType &&
            !openModalConnection &&
            !openModalSubscribe &&
            user &&
            subscribeType > 0
        ) {
            this.setState({ openModalSubscribe: `subscribe` })
        }
    }

    handleClose() {
        const { user, subscriptions, subscribeType } = this.props
        if (
            user &&
            (!subscriptions || subscriptions.length === 0) &&
            subscribeType > 0
        ) {
            this.setState({
                openModalConnection: false,
                openModalSubscribe: `subscribe`,
            })
        } else {
            this.setState({
                openModalConnection: false,
                openModalSubscribe: null,
            })
        }
    }

    handleClosePayment() {
        if (this.props.onChangeSubscriptionProgress) {
            this.props.onChangeSubscriptionProgress({
                subscribeType: this.state.subscribeType,
            })
        }
        this.setState({
            openModalConnection: false,
            openModalSubscribe: null,
            subscribeType: null,
        })
    }

    saveUpdateSubscription() {
        if (this.state.subscribeType === -1) {
            // pas de paiement à faire donc redirection
            this.historyPush(history, AppRoute.LogBook.Inventory)
        } else {
            this.handleClose()
        }
    }

    subscribeTo(id) {
        const { user, subscriptions, userSub } = this.props
        if (!user) {
            this.setState({
                openModalConnection: true,
                subscribeType: id,
            })
        } else {
            if (id > 0) {
                if (subscriptions?.length > 0 && userSub.email === user.email) {
                    this.redirectInfos()
                    return
                }
                this.setState({
                    openModalSubscribe: `subscribe`,
                    subscribeType: id,
                })
            }
        }
    }

    async redirectInfos() {
        const url = await userActions.showClientPortail()
        window.location.assign(url)
    }

    _renderHeader() {
        const { classes } = this.props

        return (
            <div className={classes.header}>
                <Typography component="h1" className={classes.title}>
                    {this.displayText(`title`)}
                </Typography>
                <Typography component="h2" className={classes.subtitle}>
                    {this.displayText(`subtitle1`)}
                    <sup>{this.displayText(`subtitle2`)} </sup>
                    {this.displayText(`subtitle3`)}
                </Typography>

                <div className={classes.tabs}>
                    <Typography
                        onClick={this._changeTab}
                        className={`${classes.tab} ${this.state.particulier &&
                            classes.active}`}
                    >
                        {this.displayText(`individual`)}
                    </Typography>
                    <Typography
                        onClick={this._changeTab}
                        className={`${classes.tab} ${!this.state.particulier &&
                            classes.active}`}
                    >
                        {this.displayText(`boatManager`)}
                    </Typography>
                </div>

                <img
                    alt="vector"
                    title="vector"
                    src={Vector1}
                    className={`${classes.vector1} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector2}
                    className={`${classes.vector2} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector3}
                    className={`${classes.vector3} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector4}
                    className={`${classes.vector4} ${classes.vector}`}
                />

                <img
                    alt="vector"
                    title="vector"
                    src={Vector5}
                    className={`${classes.vector5} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector7}
                    className={`${classes.vector7} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector6}
                    className={`${classes.vector6} ${classes.vector}`}
                />
            </div>
        )
    }

    _getMainContent() {
        const { classes } = this.props
        const { byYear } = this.state

        const prices = pricesConfig

        return (
            <>
                <div className={classes.container}>
                    {this._renderHeader()}
                    <div className={classes.cards}>
                        {this.state.particulier ? (
                            <>
                                <OfferCard
                                    id={-1}
                                    subscribeTo={this.subscribeTo}
                                    buttonType="cancel"
                                    free={false}
                                    color={`#5FA7F6`}
                                    name={this.displayText(`offerT1`)}
                                    desc={this.displayText(`offer1`)}
                                    price={false}
                                    droits={[
                                        true,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                    ]}
                                    byYear={byYear}
                                    onTabChange={v => {
                                        this.setState({
                                            byYear: !byYear,
                                        })
                                    }}
                                />
                                <OfferCard
                                    id={1}
                                    subscribeTo={this.subscribeTo}
                                    buttonType="primary"
                                    free={true}
                                    color={`#FFC107`}
                                    name={this.displayText(`offerT2`)}
                                    desc={this.displayText(`offer2`)}
                                    price={
                                        pricesConfig.find(p => p.skipper)
                                            .skipper.month.ht
                                    }
                                    priceYear={
                                        pricesConfig.find(p => p.skipper)
                                            .skipper.year.ht
                                    }
                                    droits={[
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                    ]}
                                    byYear={byYear}
                                    onTabChange={v => {
                                        this.setState({
                                            byYear: !byYear,
                                        })
                                    }}
                                />
                                <OfferCard
                                    id={3}
                                    subscribeTo={this.subscribeTo}
                                    buttonType="cancel"
                                    free={false}
                                    color={`#66AEBD`}
                                    name={this.displayText(`offerT3`)}
                                    desc={this.displayText(`offer3`)}
                                    price={
                                        pricesConfig.find(p => p.bosco).bosco
                                            .month.ht
                                    }
                                    priceYear={
                                        pricesConfig.find(p => p.bosco).bosco
                                            .year.ht
                                    }
                                    droits={[
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        false,
                                        false,
                                        false,
                                        false,
                                        false,
                                    ]}
                                    byYear={byYear}
                                    onTabChange={v => {
                                        this.setState({
                                            byYear: !byYear,
                                        })
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <OfferCard
                                    id={9}
                                    subscribeTo={this.subscribeTo}
                                    buttonType="primary"
                                    free={false}
                                    color={`#5FA7F6`}
                                    name={this.displayText(`offerT4`)}
                                    desc={this.displayText(`offer4`)}
                                    price={
                                        pricesConfig.find(p => p.captain)
                                            .captain.month.ht
                                    }
                                    priceYear={
                                        pricesConfig.find(p => p.captain)
                                            .captain.year.ht
                                    }
                                    droits={[
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        false,
                                        false,
                                        false,
                                    ]}
                                    byYear={byYear}
                                    onTabChange={v => {
                                        this.setState({
                                            byYear: !byYear,
                                        })
                                    }}
                                />
                                <OfferCard
                                    id={5}
                                    subscribeTo={this.subscribeTo}
                                    buttonType="cancel"
                                    free={true}
                                    color={`#FFC107`}
                                    name={this.displayText(`offerT5`)}
                                    desc={this.displayText(`offer5`)}
                                    price={
                                        pricesConfig.find(p => p.commandant)
                                            .commandant.month.ht
                                    }
                                    priceYear={
                                        pricesConfig.find(p => p.commandant)
                                            .commandant.year.ht
                                    }
                                    droits={[
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        false,
                                    ]}
                                    byYear={byYear}
                                    onTabChange={v => {
                                        this.setState({
                                            byYear: !byYear,
                                        })
                                    }}
                                />
                                <OfferCard
                                    id={7}
                                    subscribeTo={this.subscribeTo}
                                    buttonType="primary"
                                    free={false}
                                    color={`#66AEBD`}
                                    name={this.displayText(`offerT6`)}
                                    desc={this.displayText(`offer6`)}
                                    price={
                                        pricesConfig.find(p => p.amiral).amiral
                                            .month.ht
                                    }
                                    priceYear={
                                        pricesConfig.find(p => p.amiral).amiral
                                            .year.ht
                                    }
                                    droits={[
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                        true,
                                    ]}
                                    byYear={byYear}
                                    onTabChange={v => {
                                        this.setState({
                                            byYear: !byYear,
                                        })
                                    }}
                                />
                            </>
                        )}
                    </div>

                    <Typography
                        className={classes.moreFonc}
                        onClick={this._changeTab}
                    >
                        {' '}
                        {this.state.particulier
                            ? this.displayText(`more1`)
                            : this.displayText(`more2`)}{' '}
                    </Typography>
                    <Typography className={classes.specificFonc}>
                        {' '}
                        {this.displayText(`custom`)}
                    </Typography>
                    <Typography className={classes.specificFonc1}>
                        {' '}
                        {this.displayText(`custom1`)}{' '}
                        <Link
                            to={{
                                pathname: `https://www.info.boaton.fr/contactez-nous`,
                            }}
                            target="_blank"
                            className={classes.contactUs}
                        >
                            {' '}
                            {this.displayText(`contact`)}{' '}
                        </Link>{' '}
                    </Typography>

                    <Typography className={classes.confidence}>
                        {' '}
                        {this.displayText(`confiance`)}{' '}
                    </Typography>
                    <div className={classes.part}>
                        <img src={press1} alt="samboat" title="samboat" />
                        <img src={press2} alt="ffpp" title="ffpp" />
                        <img src={press3} alt="wistream" title="wistream" />
                        <img src={press4} alt="bpifrance" title="bpifrance" />
                        <img src={press5} alt="unitec" title="unitec" />
                    </div>

                    <div className={classes.reviews}>
                        {comments.map((com, index) => (
                            <Comment
                                descr={this.displayText(`desc${index}`)}
                                {...com}
                                key={index}
                            />
                        ))}
                    </div>
                </div>
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={this.handleClose}
                    saveUpdateSubscription={this.saveUpdateSubscription}
                />
                <BoatOnModal
                    openCondition={this.state.openModalSubscribe}
                    handleClose={this.handleClosePayment}
                    maxWidth={{
                        subscribe: `sm`,
                    }}
                    modalCores={{
                        subscribe: (
                            <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
                                <Elements>
                                    <ModalSubscribe
                                        handleClose={this.handleClosePayment}
                                        subscribeType={this.state.subscribeType}
                                        user={this.props.user}
                                        title={this.displayText('subscription')}
                                    />
                                </Elements>
                            </StripeProvider>
                        ),
                    }}
                    titles={{
                        subscribe: this.displayText('subscription'),
                    }}
                />
            </>
        )
    }

    render() {
        return this.props.logbook ? (
            this._getMainContent()
        ) : (
            <Navigation location={this.props.location}>
                {this._getMainContent()}
                <ModalConnection
                    open={this.state.modalCo}
                    handleClose={this.handleClose}
                />
            </Navigation>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        userSub: state.group?.groupsMembers?.user || {},
    }
}

export default connect(mapStateToProps)(withStyles(styles)(PricesPage))
