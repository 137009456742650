export default theme => ({
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    titleSection: {
        marginBottom: 10,
        fontSize: 14,
    },
    inputAddress: {
        width: '100%',
    },
    stopoverBtn: {
        margin: '10px 0',
        color: theme.palette.primary.main,
    },
    divider: {
        margin: '20px 0',
    },
    captainJudgment: {
        marginBottom: 20,
    },
    dateTimeSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    inputDate: {
        flex: 1,
    },
    inputTime: {
        flex: 1,
        fontSize: '14px !important',
        '& .MuiInputLabel-root': {
            fontSize: '14px !important',
        },
        '& .MuiInputBase-input': {
            fontSize: '14px !important',
        },
    },
    inputTimeError: {
        flex: 1,
        border: '1px solid',
        borderColor: 'red',
    },
    documentContainer: {
        width: '100%',
        border: 'solid 1px rgba(0, 0, 0, 0.2)',
        borderRadius: 5,
        height: 70,
        borderStyle: 'dashed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    AddDocIcon: {
        color: 'grey',
        height: 70,
        width: 70,
        opacity: 0.5,
    },
    stopoverContainer: {
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    stopoverItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    stopoverTitle: {
        marginBottom: 10,
        fontSize: 14,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    passengersContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    deleteContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        color: '#EB3131',
        '&:hover': {
            cursor: 'pointer',
        },
    },
})
