export default {
    cycleLengthMonth: {
        FR: `Dans combien de mois est le prochain contrôle ?`,
        EN: `In how many months is the next checkup?`,
    },
    cycleLengthHours: {
        FR: `Dans combien d'heures est le prochain contrôle ?`,
        EN: `In how many hours is the next checkup?`,
    },
    buttonMonth: {
        FR: `Créer un cycle par mois`,
        EN: `Create a cycle per month`,
    },
    buttonHours: {
        FR: `Créer un cycle par heure`,
        EN: `Create a cycle per hour`,
    },
    errorCycleLength: {
        FR: `Vous devez remplir un cycle sur lequel sera basé cette opération.`,
        EN: `You must complete a cycle on which this operation will be based.`,
    },
    errorCycleChoose: {
        FR: `Vous devez choisir un cycle sur lequel sera basé cette opération.`,
        EN: `You must choose a cycle on which this operation will be based.`,
    },
    cycleMonth: {
        FR: `Mois`,
        EN: `Months`,
    },
    cycleHours: {
        FR: `Heures`,
        EN: `Hours`,
    },
}
