import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/BoatSelecterDico'
import styles from './Styles/BoatSelecterCss'
import BoatOnComponent from '../../common/BoatOnComponent'
import { NativeSelect, Paper } from '@material-ui/core'
import { initBobWithBoat } from '../../../actions/bob.actions'
import { getTotalNumberBoat } from '../../../actions/bob/boat.actions'
import { userActions } from '../../../actions/user.actions'
import { typesActions } from '../../../actions/types.actions'
import { groupActions } from '../../../actions/group.actions'
import { filterActions } from '../../../actions'
import { getContextFromUrl } from '../../../languages/LocalizerUtils'

class MobileBoatSelecter extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            arrayBoats: null,
            inputSelecter: ``,
            numberFleet: 0,
            openModal: false,
        }
        this.changeBoat = this.changeBoat.bind(this)
        this.changeInputSelecter = this.changeInputSelecter.bind(this)
        this.isOptionEqualToValue = this.isOptionEqualToValue.bind(this)
        this.valid = this.valid.bind(this)
    }

    componentWillMount() {}

    _selectBoats(boatId) {
        const { arrayBoats } = this.state

        return arrayBoats.find(array => array.boat?.id === parseInt(boatId))
    }

    _sortBoats(arrayBoats) {
        arrayBoats.sort((a, b) => {
            if (!b.fleetName) return -1
            else if (!a.fleetName) return 1
            return -b.fleetName?.localeCompare(a.fleetName)
        })
    }

    componentDidMount() {
        const { subscriptions } = this.props
        if (subscriptions) this._setBoats()
    }

    _setBoats() {
        const { user, subscriptions, onProfile } = this.props
        let arrayBoats = []
        let fleetAdded = []
        if (!onProfile && user?.userGroup && Array.isArray(user?.userGroup)) {
            user.userGroup.forEach(group => {
                if (
                    group?.linkRGU &&
                    Array.isArray(group?.linkRGU) &&
                    group.linkRGU.length === 1
                ) {
                    if (
                        group.linkRGU[0].boats.length === 0 &&
                        group.id !== user?.ownGroup?.id
                    ) {
                        arrayBoats.push({
                            fleetId: group.id,
                            fleetName: group.name,
                            boat: null,
                        })
                    } else {
                        group.linkRGU[0].boats.forEach(boat => {
                            arrayBoats.push({
                                fleetId: group.id,
                                fleetName: group.name,
                                boat: boat,
                            })
                            if (
                                !fleetAdded.some(
                                    fleedId => fleedId === group.id,
                                )
                            ) {
                                fleetAdded.push(group.id)
                            }
                        })
                    }
                }
            })
        }

        if (user?.ownGroup?.boats) {
            if (user.ownGroup.boats.length === 0) {
                arrayBoats.push({
                    fleetId: user.ownGroup.id,
                    fleetName: user.ownGroup.name,
                    boat: null,
                })
                fleetAdded.push(user.ownGroup.id)
            } else {
                user.ownGroup.boats.forEach(boat => {
                    arrayBoats.push({
                        fleetId: user.ownGroup.id,
                        fleetName: user.ownGroup.name,
                        boat: boat,
                    })
                    if (
                        !fleetAdded.some(
                            fleedId => fleedId === user.ownGroup.id,
                        )
                    ) {
                        fleetAdded.push(user.ownGroup.id)
                    }
                })
            }
        }

        this._sortBoats(arrayBoats)

        if (
            !subscriptions ||
            !userActions.checkSubscriptionMultiBoat(subscriptions)
        ) {
            let isFirstOwnBoatAdded = false
            arrayBoats = arrayBoats.filter(element => {
                if (
                    isFirstOwnBoatAdded &&
                    element.fleetId === user?.ownGroup?.id
                )
                    return false
                else if (
                    !isFirstOwnBoatAdded &&
                    element.fleetId === user?.ownGroup?.id
                ) {
                    isFirstOwnBoatAdded = true
                }
                return true
            })
        }

        this.setState({
            arrayBoats: arrayBoats,
            numberFleet: fleetAdded.length,
        })
    }

    shouldComponentUpdate(_, nextState) {
        if (this.state.openModal && nextState.openModal !== false) return false
        return true
    }

    componentDidUpdate(prevProps) {
        const { loadingUser, subscriptions } = this.props

        if (
            (!this.state.arrayBoats ||
                (loadingUser === 0 && prevProps.loadingUser !== 0)) &&
            subscriptions
        ) {
            this._setBoats()
        }
    }

    changeBoat(_, element) {
        const { user, groupId, dispatch } = this.props
        const lastGroupId = groupId || localStorage.getItem('groupIdSelect')

        if (element) {
            if (!element.boat) {
                this.setState({ openModal: 'newBoat' })
            } else {
                localStorage.setItem('boatIdSelect', element?.boat?.id || -1)
                if (parseInt(lastGroupId) !== element?.fleetId) {
                    dispatch(
                        groupActions.changeCurrentGroup(element?.fleetId || -1),
                    )
                }
                dispatch(initBobWithBoat({ user, boatId: element?.boat?.id }))
            }
        }
    }

    changeInputSelecter(value) {
        this.setState({ inputSelecter: value })
    }

    isOptionEqualToValue(option) {
        const { boat } = this.props
        const { arrayBoats } = this.state
        const boatId = localStorage.getItem('boatIdSelect')
            ? localStorage.getItem('boatIdSelect')
            : boat && boat.id
            ? boat.id
            : arrayBoats[0] && arrayBoats[0]?.boat?.id
            ? arrayBoats[0].boat.id
            : null
        const selectedBoat = this._selectBoats(boatId)

        if (selectedBoat?.boat?.id === option.boat?.id) {
            return true
        }
        return false
    }

    render() {
        const { boat, classes, user } = this.props
        const { arrayBoats } = this.state

        if (
            getTotalNumberBoat(user) === 0 ||
            !arrayBoats ||
            arrayBoats.length === 0
        )
            return <></>

        let boatId = localStorage.getItem('boatIdSelect')
            ? localStorage.getItem('boatIdSelect')
            : boat && boat.id
            ? boat.id
            : arrayBoats[0] && arrayBoats[0]?.boat?.id
            ? arrayBoats[0].boat.id
            : null
        let selectedBoat = this._selectBoats(boatId)

        if (selectedBoat == null) return <></>

        const options = arrayBoats
            .sort((a, b) => -b.fleetName?.localeCompare(a.fleetName))
            .filter(o => o.boat != null)

        let fleet = options.reduce((total, current) => {
            if (total[current.fleetName] === undefined) {
                total[current.fleetName] = []
            }
            total[current.fleetName].push(current)
            return total
        }, {})

        return (
            <Paper
                elevation={2}
                style={{
                    padding: 10,
                    background: 'white',
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                }}
            >
                <div
                    style={{
                        borderRadius: 5,
                        background: '#CBCFE5',
                        color: '#303F9F',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        aspectRatio: 1,
                        width: 35,
                        height: 35,
                        fontWeight: 500,
                    }}
                >
                    {selectedBoat.boat.name.trim()[0]}
                </div>
                <NativeSelect
                    value={selectedBoat.boat.id}
                    onChange={e => {
                        const boat = options.find(
                            o => o.boat.id == e.target.value,
                        )

                        this.changeBoat(e, boat)
                    }}
                    disableUnderline={true}
                    classes={{
                        // root: {
                        //     border: "none",
                        // },
                        select: classes.dropdownSelectRootSelect,
                    }}
                >
                    {Object.values(fleet).map((boatsInFleet, i) => {
                        return (
                            <optgroup
                                label={Object.keys(fleet)[i]}
                                key={Object.keys(fleet)[i]}
                            >
                                {boatsInFleet.map(option => {
                                    if (option.boat != null) {
                                        return (
                                            <option
                                                value={option.boat.id}
                                                key={option.boat.id}
                                            >
                                                {option.boat.name}
                                            </option>
                                        )
                                    }
                                })}
                            </optgroup>
                        )
                    })}
                </NativeSelect>
                {/* <BoatOnAutoComplete
                    autoComplete
                    value={selectedBoat ? selectedBoat.boat.name : inputSelecter}
                    getOptionSelected={this.isOptionEqualToValue}
                    options={arrayBoats.sort(
                        (a, b) => -b.fleetName?.localeCompare(a.fleetName),
                    )}
                    placeholder={this.displayText('boatName')}
                    inputClass={classes.name}
                    onChange={this.changeBoat}
                    onInputChange={this._changeInputSelecter}
                    labelAdd={this.displayText('addLabelButton')}
                    onAddButtonClicked={this.handleOpenDialog}
                    getElementToShow={option => {
                        return typeof option === `string`
                            ? option
                            : option?.boat?.name || (
                                  <div className={classes.flexRow}>
                                      <Add className={classes.plus} />
                                      <Typography>
                                          {this.displayText('addBoat')}
                                      </Typography>
                                  </div>
                              )
                    }}
                    filterOptions={(option, input) =>
                        !input.inputValue ||
                        option?.boat?.name
                            .toLowerCase()
                            .includes(input.inputValue.toLowerCase())
                    }
                    groupBy={option => (numberFleet > 1 ? option.fleetName : null)}
                /> */}
            </Paper>
        )
    }

    async valid(next, boatId) {
        const { user } = this.props
        if (boatId) {
            await this.props.dispatch(
                groupActions.changeCurrentGroup(user.ownGroup.id),
            )
            this.props.dispatch(initBobWithBoat({ boatId, user }))
        }
        if (next && typeof next === 'string') {
            this.setState({ openModalNewBoat: next, newBoatId: boatId })
            this.setBoat()
        } else {
            this.handleClose()
        }
    }

    handleClose() {
        this.setState({
            openModal: false,
            newBoatId: false,
        })
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    return {
        user: state.authentication.user,
        loadingUser: state.authentication.loading,
        loadingBoats: state.boat.loading,
        boat: state.bob.boat,
        loading: state.bob.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || null,
        eventTypes: typesActions.getEventTypeTransaction(
            state.types.eventTypes,
        ),
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {},
            true,
        ),
        bob: state.bob,
        groupId: state.group.currentGroupId,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MobileBoatSelecter))
